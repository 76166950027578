import { showRequestNotifications } from '@/api/helpers/showNotifications';
import { ComplexProjectData, projectsApi } from '@/api/projects';
import { store } from '@/app/store';
import { openRemoveConfirmModal } from '@/components/ui/confirmModals/RemoveConfirmModal';
import { redirectToProject } from '@/helpers/redirect';
import { Text } from '@mantine/core';
import { t } from 'i18next';

type RemoveFolderModalProps = {
  folder: ComplexProjectData;
};

export const openRemoveFolderModal = ({ folder }: RemoveFolderModalProps) =>
  openRemoveConfirmModal({
    title: t('removeFolder.title'),
    children: (
      <Text
        dangerouslySetInnerHTML={{
          __html: t('removeFolder.confirm', { folder: folder.Name }),
        }}
      />
    ),
    onConfirm: () => {
      const subscribe = store
        .dispatch(projectsApi.endpoints.deleteFolderById.initiate(folder.Id))
        .unwrap();

      showRequestNotifications(subscribe, {
        successMsg: t('notification.success.deleteFolder'),
        errorMsg: t('notification.error.deleteFolder'),
      }).then(() => {
        redirectToProject(folder.rootProjectId);
      });
    },
  });

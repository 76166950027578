import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ApiCommon } from '@/api/common';
import { ApiUser, ApiUsersSearch } from '@/api/users';
import { useAppSelector } from '@/app/store';
import { ErrorAlert } from '@/components/ui/ErrorAlert';
import { useAccess } from '@/hooks/useAccess';
import {
  Box,
  Button,
  Divider,
  Flex,
  Group,
  Skeleton,
  Stack,
} from '@mantine/core';
import { QueryStatus } from '@reduxjs/toolkit/query';

import { InviteLink } from './components/InviteLink';
import { ParticipantsSelect } from './components/ParticipantsSelect';
import { UserRow } from './components/UserRow';

type UserUnion = ApiUsersSearch.IUser | ApiUser.IUser;

export interface ISelectedUser {
  user: UserUnion;
  label: string;
}

export interface IParticipantsFormParams {
  userIds: number[];
  type: ApiCommon.AccessType;
}

export type ParticipantsFormUser = {
  Id: number;
  Name: string;
  FirstName: string | null;
  Surname: string | null;
  Avatar: string | null;
  Email: string | null;
  accessType: ApiCommon.AccessType;
  isOwner: boolean;
  Status: ApiCommon.UserStatus | null;
};

export const ParticipantsForm = <USER extends ParticipantsFormUser>({
  usersData,
  onRemove,
  onAdd,
  onUpdate,
  status,
  projectId,
}: {
  usersData: USER[];
  onRemove?: (userId: ParticipantsFormUser) => void;
  onAdd?: (params: IParticipantsFormParams) => void;
  onUpdate?: (params: IParticipantsFormParams) => void;
  status: QueryStatus;
  projectId: number;
}) => {
  const { t } = useTranslation();
  const access = useAccess();

  const currentUserId = useAppSelector((s) => s.usersCache.currentUser.Id);

  const [users, setUsers] = useState<USER[]>([]);
  const [role, setRole] = useState<ApiCommon.AccessType>(
    ApiCommon.AccessType.Read,
  );

  const excludeUsers = useMemo(
    () => [...usersData.map((u) => u.Id), ...users.map((u) => u.Id)],
    [users, usersData],
  );

  return (
    <Stack gap={16} pos={'relative'}>
      {access.participants.create && (
        <Group gap={12}>
          <ParticipantsSelect
            value={users}
            onChange={setUsers}
            excludeUsers={excludeUsers}
            role={role}
            setRole={setRole}
          />
          <Button
            size="md"
            onClick={() => {
              if (!users) return;
              onAdd?.({ userIds: users.map((u) => u.Id), type: role });
              setUsers([]);
            }}
            disabled={!users.length}
          >
            {t('common.action.add')}
          </Button>
        </Group>
      )}

      {status === QueryStatus.pending && !usersData?.length ? (
        <Loading count={usersData?.length} />
      ) : status === QueryStatus.rejected ? (
        <ErrorAlert message={t('notification.error.getUsers')} />
      ) : usersData?.length ? (
        <Box h={216} style={{ overflowY: 'scroll' }}>
          {usersData?.map((user) => (
            <UserRow
              key={user.Id}
              user={user}
              onRemove={() => onRemove?.(user)}
              isCurrentUser={user.Id === currentUserId}
              onChange={(type) =>
                onUpdate?.({
                  type,
                  userIds: [user.Id],
                })
              }
            />
          ))}
        </Box>
      ) : null}

      {access.participants.create && (
        <>
          <Divider />
          <InviteLink projectId={projectId} />
        </>
      )}
    </Stack>
  );
};

const UserRowSkeleton = () => (
  <Flex gap={8} h={56} px={12} justify={'space-between'}>
    <Flex gap={8} flex={1}>
      <Skeleton style={{ flexShrink: 0 }} w={38} h={38} radius={'xl'} />
      <Skeleton w={'50%'} h={20} />
    </Flex>
    <Skeleton w={'10%'} h={20} />
  </Flex>
);

const Loading = ({ count }: { count?: number }) => (
  <div>
    {Array(count || 4)
      .fill(null)
      .map((_, index) => (
        <UserRowSkeleton key={index} />
      ))}
  </div>
);

import { customBaseQuery } from '@/api/customBaseQuery';
import { createApi } from '@reduxjs/toolkit/query/react';

import { ApiIcons } from './types';

export const iconsApi = createApi({
  reducerPath: 'iconsApi',
  baseQuery: customBaseQuery(''),
  endpoints: (builder) => ({
    getList: builder.query<ApiIcons.IIconDefinition[], void>({
      query: () => ({
        url: `https://unpkg.com/@tabler/icons@3.0.1/icons.json`,
        method: 'GET',
      }),
    }),
  }),
});

import { useMemo } from 'react';

import { projectTasksApi } from '@/api/tasks';
import { useAppSelector } from '@/app/store';
import { useDebouncedValue } from '@mantine/hooks';
import { groupBy, orderBy } from 'lodash-es';

type SprintTaskListProps = {
  projectId?: number;
  sprintId?: number;
  performerIds: number[];
  search?: string;
};

export const useTasksData = ({
  projectId,
  sprintId,
  performerIds,
  search,
}: SprintTaskListProps) => {
  const skeleton = useAppSelector((s) => s.devSlice.skeletons.tasks);
  const [debouncedSearch] = useDebouncedValue(search, 800);

  const {
    data,
    isError,
    isSuccess,
    isLoading,
    isFetching,
    originalArgs,
    currentData,
  } = projectTasksApi.useGetTasksQuery({
    projectIds: projectId ? [projectId] : null,
    sprintId: sprintId || null,
    performerIds,
    like: debouncedSearch?.trim(),
  });

  const groupedBySprint = useMemo(
    () => groupBy(orderBy(data, 'Order'), 'SprintId'),
    [data],
  );

  return {
    data,
    groupedBySprint,
    isSuccess,
    isLoading: isLoading || !currentData || skeleton,
    isError,
    isFetching,
    isSearching: !!originalArgs?.like?.length,
  };
};

import { useTranslation } from 'react-i18next';

import { authApi } from '@/api/auth';
import lightLogo from '@/assets/svg/logo.svg';
import darkLogo from '@/assets/svg/whiteLogo.svg';
import { useIsDarkTheme } from '@/helpers/themeUtils';
import {
  Alert,
  Button,
  Divider,
  Flex,
  FocusTrap,
  Group,
  Image,
  LoadingOverlay,
  Paper,
  PasswordInput,
  Stack,
  TextInput,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';

type FormValues = {
  Login: string;
  Password: string;
};

export const LoginForm = () => {
  const { t } = useTranslation();

  const [login, { isLoading, isError, isSuccess }] = authApi.useLoginMutation();
  const isDark = useIsDarkTheme();
  const form = useForm<FormValues>({
    initialValues: {
      Login: '',
      Password: '',
    },
    validate: {
      Login: (v) =>
        (v || '').trim().length > 0 ? null : t('auth.requiredField'),
      Password: (v) =>
        (v || '').trim().length > 0 ? null : t('auth.requiredField'),
    },
  });

  const handleSubmit = (values: typeof form.values) => {
    login(values)
      .unwrap()
      .then((data) => {
        if (import.meta.env.DEV) {
          localStorage.setItem(
            'AuthToken',
            data.Response.Data.Tokens.AuthToken,
          );
        }

        window.location.href = '/';
      });
  };

  return (
    <Paper shadow="md" p={24} w={'100%'} maw={450}>
      <form onSubmit={form.onSubmit(handleSubmit)} data-cy="loginForm">
        <LoadingOverlay
          visible={isLoading}
          zIndex={1000}
          overlayProps={{ radius: 'sm', blur: 2 }}
        />

        <FocusTrap active>
          <Stack gap={16}>
            <Group gap={8} justify={'space-between'}>
              <Title order={2} ta={'center'}>
                {t('auth.title')}
              </Title>

              <Flex component="span" gap={8} justify={'center'}>
                <Image src={isDark ? darkLogo : lightLogo} h={'30'} w={160} />
              </Flex>
            </Group>

            <Divider />

            {isError && <Alert color={'red'}>{t('auth.error')}</Alert>}
            {isSuccess && <Alert color={'blue'}>{t('auth.success')}</Alert>}

            <TextInput
              data-autofocus
              variant="default"
              label={t('auth.fields.login.label')}
              placeholder={t('auth.fields.login.placeholder')}
              required
              {...form.getInputProps('Login')}
            />

            <PasswordInput
              variant="default"
              label={t('auth.fields.password.label')}
              placeholder={t('auth.fields.password.placeholder')}
              required
              {...form.getInputProps('Password')}
            />
          </Stack>
        </FocusTrap>

        <Button type="submit" fullWidth data-cy="submitButton" mt={32}>
          {t('auth.title')}
        </Button>
      </form>
    </Paper>
  );
};

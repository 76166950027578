import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { store } from '@/app/store';
import { layoutSlice } from '@/components/layout/AppLayout/slice';
import { openCreateProjectModal } from '@/features/EditEntityModals';
import { ActionIcon, NavLink, Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { IconPlus } from '@tabler/icons-react';

import styles from '../Navigation.module.css';

const close = () => store.dispatch(layoutSlice.actions.closeSidebar());

export const AddProject = () => {
  const { t } = useTranslation();
  const isBurger = useMediaQuery('(max-width: 1200px)');

  const handleCreate = useCallback(() => {
    isBurger && close?.();
    setTimeout(openCreateProjectModal, 0);
  }, [isBurger]);

  return (
    <NavLink
      onClick={handleCreate}
      classNames={{
        root: styles.root,
      }}
      h={24}
      childrenOffset={0}
      label={
        <Text size="sm" c={'dark.2'}>
          {t('sidebar.addProject')}
        </Text>
      }
      leftSection={
        <ActionIcon size={26} ml={4} variant="transparent" color="#808080">
          <IconPlus size="1rem" stroke={1.5} />
        </ActionIcon>
      }
    />
  );
};

import { ReactNode } from 'react';

import Icon404 from '@/assets/svg/noData/404.svg';
import Icon500 from '@/assets/svg/noData/500.svg';
import AddDataIcon from '@/assets/svg/noData/addData.svg';
import EmptyIcon from '@/assets/svg/noData/empty.svg';
import EmptyBoard from '@/assets/svg/noData/emptyBoard.svg';
import FolderIcon from '@/assets/svg/noData/folder.svg';
import NoAccessIcon from '@/assets/svg/noData/noAccess.svg';
import noCommentsIcon from '@/assets/svg/noData/noComments.svg';
import NoDataIcon from '@/assets/svg/noData/noData.svg';
import NoImageIcon from '@/assets/svg/noData/noImage.svg';
import NoResultsIcon from '@/assets/svg/noData/noResults.svg';
import NoTaskResultsIcon from '@/assets/svg/noData/noTaskResults.svg';
import NoTasksIcon from '@/assets/svg/noData/noTasks.svg';
import TrashIcon from '@/assets/svg/noData/trash.svg';
import { useIsDarkTheme } from '@/helpers/themeUtils.ts';
import {
  Button,
  ButtonProps,
  Group,
  Image,
  Stack,
  StackProps,
  Text,
  TextProps,
  Title,
  TitleProps,
} from '@mantine/core';

export const illustrationMap = {
  empty: EmptyIcon,
  noAccess: NoAccessIcon,
  trash: TrashIcon,
  noImage: NoImageIcon,
  noResults: NoResultsIcon,
  noTasks: NoTasksIcon,
  noComments: noCommentsIcon,
  noData: NoDataIcon,
  addData: AddDataIcon,
  emptyFolder: FolderIcon,
  emptyBoard: EmptyBoard,
  noTaskResults: NoTaskResultsIcon,
  '404': Icon404,
  '500': Icon500,
};

type IllustrationType = keyof typeof illustrationMap;

interface NoDataProps {
  title?: ReactNode;
  titleProps?: TitleProps;
  description?: ReactNode;
  descriptionProps?: TextProps;
  buttons?: (Omit<ButtonProps, 'onClick'> & {
    buttonText: string;
    handleClick: () => void;
  })[];
  illustration?: IllustrationType;
  size?: number;
}

export const NoData: React.FC<NoDataProps & StackProps> = ({
  title,
  titleProps,
  description,
  descriptionProps,
  buttons,
  illustration,
  size = 240,
  children,
  ...stackProps
}) => {
  const isDark = useIsDarkTheme();

  const illustrationSrc = illustration
    ? illustrationMap[illustration]
    : EmptyIcon;

  return (
    <Stack gap="sm" justify="center" flex={1} align="center" {...stackProps}>
      <div style={{ height: size }}>
        <Image
          src={illustrationSrc}
          alt={title}
          style={{
            height: size,
            width: size,
            objectFit: 'contain',
            ...(isDark ? { filter: 'brightness(0.7)' } : {}),
          }}
        />
      </div>
      {title && (
        <Title order={2} ta="center" maw={400} {...titleProps}>
          {title}
        </Title>
      )}
      {description && (
        <Text fz="md" ta="center" c="dimmed" maw={400} {...descriptionProps}>
          {description}
        </Text>
      )}
      {buttons?.length ? (
        <Group gap="xs" justify="center">
          {buttons.map(({ buttonText, handleClick, ...rest }, index) => (
            <Button key={index} onClick={handleClick} {...rest}>
              {buttonText}
            </Button>
          ))}
        </Group>
      ) : null}
      {children}
    </Stack>
  );
};

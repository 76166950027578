import { ComponentPropsWithoutRef, ReactNode, memo } from 'react';

import { ApiProjectTask } from '@/api/tasks';
import { TaskPriorityBadge } from '@/components/ui/badges/TaskPriorityBadge';
import { Box, Flex, Text } from '@mantine/core';

import { TaskPerformer } from './components/TaskPerformer';
import { TaskStatus } from './components/TaskStatus';
import { TaskType } from './components/TaskType';

import styles from './TaskCard.module.css';

type TaskCardProps = {
  task: ApiProjectTask.ITask;
  showStatus?: boolean;
  showType?: boolean;
  showPerformer?: boolean;
  showPriority?: boolean;
  border?: boolean;
  rightSection?: ReactNode;
} & ComponentPropsWithoutRef<'div'>;

export const TaskCard = memo(
  ({
    task,
    showPerformer = true,
    showStatus = true,
    showType = true,
    showPriority = true,
    border,
    rightSection,
    onClick,
    ...props
  }: TaskCardProps) => {
    const { Key, Title, Priority } = task;

    return (
      <Box
        className={styles.root}
        {...props}
        onClick={onClick}
        data-border={border}
      >
        <Flex align={'center'} gap={8} miw={0} className={styles.title}>
          <Text size={'sm'} className={styles.cardDescription} truncate={'end'}>
            {Title}
          </Text>
        </Flex>
        <Flex gap={8} className={styles.type}>
          {showType && <TaskType task={task} />}
          <Text size={'sm'} className={styles.cardProjectId + ' ' + styles.key}>
            {Key}
          </Text>
        </Flex>
        <Flex gap={8} className={styles.controls}>
          {showStatus && <TaskStatus task={task} />}
          {showPriority && (
            <TaskPriorityBadge priority={Priority} withLabel={false} />
          )}
          {showPerformer && <TaskPerformer task={task} />}
          {rightSection}
        </Flex>
      </Box>
    );
  },
);

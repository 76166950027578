import { ApiProjectTask } from '@/api/tasks';
import { Stack } from '@mantine/core';

import { Activity } from '../Activity/Activity';
import { Attachments } from '../Attachments/Attachments';
import { CustomFields } from '../CustomFields/CustomFields';
import { TaskLinks } from '../TaskLinks/TaskLinks';
import { Description } from './components/Description';

export const TaskContent = ({ task }: { task: ApiProjectTask.ITask }) => {
  return (
    <>
      <Stack gap={16}>
        <Description task={task} />
        <CustomFields task={task} />
        <Attachments task={task} />
        <TaskLinks task={task} />
      </Stack>
      <Activity task={task} />
    </>
  );
};

import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ApiProjectTask } from '@/api/tasks';
import { MoveTaskButton } from '@/features/MoveTaskButton/MoveTaskButton';
import { removeTask } from '@/helpers/removeTask';
import { useAccess } from '@/hooks/useAccess';
import { ActionIcon, Menu } from '@mantine/core';
import { IconDots, IconTrash } from '@tabler/icons-react';

type TaskActionsProps = {
  task: ApiProjectTask.ITask;
};

export const TaskActions = ({ task }: TaskActionsProps) => {
  const { t } = useTranslation();
  const access = useAccess();

  const [opened, setOpened] = useState(false);

  const handleIconClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    setOpened((o) => !o);
  };

  if (!access.tasks.edit && !access.tasks.delete) {
    return null;
  }

  return (
    <>
      <Menu
        opened={opened}
        closeOnItemClick={false}
        keepMounted={false}
        withinPortal={false}
        onChange={setOpened}
      >
        <Menu.Target>
          <ActionIcon
            variant="subtle"
            size={'md'}
            color="dark"
            onClick={handleIconClick}
          >
            <IconDots size={20} />
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown onClick={(e) => e.stopPropagation()}>
          <div onClick={(e) => e.stopPropagation()}>
            {access.tasks.edit && (
              <MoveTaskButton task={task} onClose={() => setOpened(false)} />
            )}
            {access.tasks.delete && (
              <Menu.Item
                leftSection={<IconTrash size={18} />}
                color="red.8"
                onClick={() => {
                  removeTask(task);
                  setOpened((s) => !s);
                }}
              >
                {t('common.action.delete')}
              </Menu.Item>
            )}
          </div>
        </Menu.Dropdown>
      </Menu>
    </>
  );
};

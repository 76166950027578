import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { showRequestNotifications } from '@/api/helpers/showNotifications';
import { ApiTaskLinks, tasksLinksApi } from '@/api/links';
import { openRemoveConfirmModal } from '@/components/ui/confirmModals/RemoveConfirmModal';
import { ActionIcon } from '@mantine/core';
import { IconX } from '@tabler/icons-react';

import styles from './Section/Section.module.css';

export const RemoveLinkButton = memo(
  ({ link }: { link: ApiTaskLinks.ILink }) => {
    const { t } = useTranslation();

    const [removeLink, { isLoading }] =
      tasksLinksApi.endpoints.deleteLink.useMutation();

    return (
      <ActionIcon
        ml={-4}
        size={24}
        variant="subtle"
        color="gray.6"
        loading={isLoading}
        className={styles.removeButton}
        onClick={(e) => {
          e.stopPropagation();
          openRemoveConfirmModal({
            title: t('removeLink.title', { task: link.To?.Key }),
            children: t('removeLink.description'),
            onConfirm: () => {
              showRequestNotifications(removeLink(link).unwrap(), {
                successMsg: t('notification.success.deleteLink'),
                errorMsg: t('notification.error.deleteLink'),
              });
            },
          });
        }}
      >
        <IconX size={16} />
      </ActionIcon>
    );
  },
);

import { useOverflown } from '@/hooks/useOverflown';
import { InheritedProps } from '@/types/inheritedProps';
import { Text, TextProps, Tooltip } from '@mantine/core';

export const Ellipsis = ({
  check,
  children,
  ...props
}: InheritedProps<
  'p',
  TextProps & { component?: 'div'; check?: 'x' | 'y' | 'xy' }
>) => {
  const { isOverflown, checkOverflown } = useOverflown(check);

  return (
    <Tooltip
      disabled={!isOverflown}
      label={children}
      openDelay={400}
      multiline
      maw={1000}
      events={{ hover: true, focus: true, touch: false }}
    >
      <Text
        component="p"
        size="sm"
        {...props}
        truncate
        onMouseEnter={checkOverflown}
      >
        {children}
      </Text>
    </Tooltip>
  );
};

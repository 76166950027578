import { useTranslation } from 'react-i18next';

import { ApiProjectTask } from '@/api/tasks';
import { Ellipsis } from '@/components/ui/Ellipsis';
import { useAccess } from '@/hooks/useAccess';
import { useEditTextValue } from '@/hooks/useEditTextValue';
import {
  ActionIcon,
  Button,
  Flex,
  FocusTrap,
  TextInput,
  Tooltip,
} from '@mantine/core';
import { IconCornerDownLeft, IconPencil } from '@tabler/icons-react';

import { TaskActions } from './TaskActions';

import styles from '../TaskCard.module.css';

type TaskTitleProps = {
  task: ApiProjectTask.ITask;
  onUpdate?: (v: string) => void;
  onClick?: () => void;
};

export const TaskTitle = ({ task, onUpdate, onClick }: TaskTitleProps) => {
  const { t } = useTranslation();
  const access = useAccess();

  const {
    isEdit,
    setIsEdit,
    value,
    setValue,
    valueEmpty,
    handleEdit,
    handleUpdate,
    ref,
  } = useEditTextValue({
    originalValue: task.Title,
    updateOnBlur: true,
    onUpdate,
  });

  if (isEdit) {
    return (
      <FocusTrap>
        <form
          onSubmit={handleUpdate}
          onClick={(e) => e.stopPropagation()}
          className={styles.form + ' ' + styles.title}
          ref={ref}
        >
          <Flex w={'100%'} align={'center'}>
            <TextInput
              maxLength={500}
              styles={{
                root: {
                  width: '100%',
                },
                input: {
                  height: '40px',
                  borderColor: valueEmpty
                    ? 'var(--mantine-color-red-6)'
                    : undefined,
                },
              }}
              placeholder={t('createTask.fields.title.shortPlaceholder')}
              value={value}
              onChange={(event) => setValue(event.currentTarget.value)}
            />
            <Flex gap={8}>
              <Button
                variant="subtle"
                color="dark"
                size="compact-sm"
                onClick={() => setIsEdit(false)}
              >
                {t('common.action.cancel')}
              </Button>
              <Button
                variant="filled"
                color="blue"
                type="submit"
                size="compact-sm"
                rightSection={<IconCornerDownLeft size={16} />}
              >
                {t('common.action.save')}
              </Button>
            </Flex>
          </Flex>
        </form>
      </FocusTrap>
    );
  }

  return (
    <Flex align={'center'} gap={8} miw={0} className={styles.title}>
      <Ellipsis className={styles.cardDescription} onClick={onClick}>
        {task.Title}
      </Ellipsis>
      <Flex gap={4} className={styles.actions}>
        {access.tasks.edit && (
          <Tooltip
            label={t('createTask.fields.title.tooltip')}
            withArrow
            position="top"
            openDelay={300}
          >
            <ActionIcon
              variant="subtle"
              size={'md'}
              color="dark"
              onClick={(e) => {
                e.stopPropagation();
                handleEdit();
              }}
            >
              <IconPencil size={18} />
            </ActionIcon>
          </Tooltip>
        )}

        <TaskActions task={task} />
      </Flex>
    </Flex>
  );
};

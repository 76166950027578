import { useMemo } from 'react';

import {
  anyAttachIcon,
  attachIcons,
} from '../components/Attachments/components/Icons';
import { fileGroups, typeGroups } from '../constants';

export const useAttachIcon = (mimeType: string) => {
  return useMemo(() => {
    const group = fileGroups.find((group) =>
      typeGroups[group].includes(mimeType),
    );

    if (group) {
      return attachIcons[group];
    } else {
      return anyAttachIcon;
    }
  }, [mimeType]);
};

import { useRef, useState } from 'react';

import { DateValue, DatesRangeValue } from '@mantine/dates';
import { isEqual } from 'lodash-es';

import { isInRange } from '../helpers/isInRange';

export const useDatesState = ({
  value,
  onChange,
}: {
  value: DatesRangeValue;
  onChange?: (value: DatesRangeValue) => void;
}) => {
  const startRef = useRef<HTMLInputElement>(null);
  const endRef = useRef<HTMLInputElement>(null);

  const [hoveredDate, setHoveredDate] = useState<DateValue>(null);
  const [target, setTarget] = useState<'start' | 'end'>('end');

  const isStart = target === 'start';

  const onDateChange = (date: Date) => {
    const result = isStart
      ? ([date, value[1]] as [Date, Date])
      : ([value[0], date] as [Date, Date]);
    const sorted: DatesRangeValue = isEqual(
      result,
      result.toSorted((a, b) => a?.getTime() - b?.getTime()),
    )
      ? result
      : [date, date];

    if (!isEqual(value, sorted)) onChange?.(sorted);

    (isStart ? endRef : startRef).current?.focus();
  };

  const isDateInRange = (date: Date) => {
    const pickedDate = isStart ? value[1] : value[0];
    if (
      pickedDate instanceof Date &&
      hoveredDate instanceof Date &&
      (isStart ? hoveredDate < pickedDate : hoveredDate > pickedDate)
    ) {
      return isInRange(date, [hoveredDate, pickedDate]);
    }

    if (value[0] instanceof Date && value[1] instanceof Date) {
      return isInRange(date, value as [Date, Date]);
    }

    return false;
  };

  const onRootMouseLeave = () => {
    setHoveredDate(null);
  };

  const getControlProps = (date: Date) => {
    return { inRange: isDateInRange(date) };
  };

  const onHoveredDateChange = setHoveredDate;

  return {
    target,
    onDateChange,
    onRootMouseLeave,
    onHoveredDateChange,
    getControlProps,
    setTarget,
    refs: { start: startRef, end: endRef },
  };
};

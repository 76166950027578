import { modals } from '@mantine/modals';
import { OpenConfirmModal } from '@mantine/modals/lib/context';
import { t } from 'i18next';

export const openRemoveConfirmModal = (props: OpenConfirmModal) =>
  modals.openConfirmModal({
    keepMounted: false,
    centered: true,
    labels: {
      confirm: t('common.action.delete'),
      cancel: t('common.action.cancel'),
    },
    confirmProps: { color: 'red.8', size: 'md', radius: 'md', fullWidth: true },
    cancelProps: {
      size: 'md',
      radius: 'md',
      fullWidth: true,
      variant: 'subtle',
      color: 'dark',
    },
    groupProps: {
      wrap: 'nowrap',
    },
    ...props,
  });

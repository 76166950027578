import { forwardRef, memo, useMemo } from 'react';

import { ApiTaskType } from '@/api/taskTypes';
import { ActionIcon, Button, Flex, Menu, MenuProps, Text } from '@mantine/core';

import { TaskTypeBadge } from '../badges/TaskTypeBadge';
import { EmptyTypes } from './EmptyTypes';

type TaskTypeSelectProps = {
  value: number;
  onChange?: (v: number) => void;
  className?: string;
  types: ApiTaskType.IType[];
  withLabel?: boolean;
  placeholder?: string;
  readonly?: boolean;
  iconSize?: number;
  menuProps?: MenuProps;
};

export const TaskTypeSelect = memo(
  forwardRef<HTMLDivElement, TaskTypeSelectProps>(
    (
      {
        value,
        onChange,
        types,
        className,
        withLabel,
        placeholder,
        readonly,
        iconSize = 20,
        menuProps,
      },
      ref,
    ) => {
      const typeValue = useMemo(
        () => types.find((s) => s.Id === value),
        [types, value],
      );

      if (readonly) {
        return (
          <TaskTypeBadge
            iconSize={iconSize}
            className={className}
            withLabel={withLabel}
            type={typeValue}
            placeholder={placeholder}
          />
        );
      }

      const badge = (
        <TaskTypeBadge
          iconSize={iconSize}
          className={className}
          withLabel={false}
          type={typeValue}
          placeholder={placeholder}
        />
      );

      return (
        <Menu
          shadow="md"
          keepMounted={false}
          position="bottom-start"
          offset={{
            mainAxis: 6,
            crossAxis: -13,
          }}
          {...menuProps}
        >
          <Menu.Target>
            <Flex gap={8} onClick={(e) => e.stopPropagation()}>
              {withLabel ? (
                <>
                  <Button variant="subtle" color="dark" leftSection={badge}>
                    <Text size={'sm'}>{typeValue?.Name || placeholder}</Text>
                  </Button>
                </>
              ) : (
                <ActionIcon variant="subtle" color="dark" size={iconSize + 8}>
                  {badge}
                </ActionIcon>
              )}
            </Flex>
          </Menu.Target>
          <Menu.Dropdown
            miw={200}
            ref={ref}
            onClick={(e) => e.stopPropagation()}
          >
            {types.length > 0 ? (
              types.map((type) => (
                <Menu.Item
                  key={type.Id}
                  component={'li'}
                  onClick={() => {
                    if (value !== type.Id) onChange?.(type.Id);
                  }}
                >
                  <TaskTypeBadge type={type} />
                </Menu.Item>
              ))
            ) : (
              <EmptyTypes />
            )}
          </Menu.Dropdown>
        </Menu>
      );
    },
  ),
);

import {
  KeyboardEventHandler,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { ApiProjectTask } from '@/api/tasks';
import { ErrorAlert } from '@/components/ui/ErrorAlert';
import { NoData } from '@/components/ui/NoData/NoData';
import { useDisableScroll } from '@/hooks/useDisableScroll';
import { useGlobalTaskSearch } from '@/hooks/useGlobalTasksSearch';
import {
  ActionIcon,
  Combobox,
  Flex,
  Loader,
  Pill,
  PillsInput,
  Text,
  useCombobox,
} from '@mantine/core';
import { useFocusTrap } from '@mantine/hooks';
import { IconSearch, IconX } from '@tabler/icons-react';

import { TaskOption } from './components/TaskOption';
import { TaskPill } from './components/TaskPill/TaskPill';

import styles from './TaskLinkSearch.module.css';

export type TaskLinkSearchProps = {
  value: ApiProjectTask.ITask[];
  onChange: (v: ApiProjectTask.ITask[]) => void;
  excludeTaskIds: number[];
  projectId?: number;
};

export const TaskLinkSearch = ({
  value,
  onChange,
  excludeTaskIds,
  projectId,
}: TaskLinkSearchProps) => {
  const { t } = useTranslation();

  const [opened, onOpenedChange] = useState(false);
  const ref = useRef<HTMLInputElement>(null);

  const inputRef = useFocusTrap();

  const { isSuccess, data, isLoading, isError, isFetching, query, setQuery } =
    useGlobalTaskSearch({ skip: !opened, projectId });

  const combobox = useCombobox({
    opened,
    onOpenedChange,
  });

  const handleSubmit = useCallback(
    (optValue: string) => {
      const opt = data?.find((t) => t.Id.toString() === optValue);

      if (opt) {
        onChange(
          [...value, opt as unknown as ApiProjectTask.ITask].splice(0, 20),
        );
      }

      setQuery('');
      combobox.closeDropdown();

      setTimeout(() => {
        ref.current?.scrollTo({
          top: ref.current.scrollHeight,
        });
      }, 0);
    },
    [combobox, data, onChange, setQuery, value],
  );

  const handleBackspace = useCallback<KeyboardEventHandler<HTMLInputElement>>(
    (event) => {
      if (event.key === 'Backspace' && !event.currentTarget.value.length) {
        event.preventDefault();
        onChange(value.slice(0, -1));
      }
    },
    [onChange, value],
  );

  const options = useMemo(() => {
    const selectedIds = value.map((i) => i.Id);

    const filteredData =
      data?.filter(
        (t) => !selectedIds.includes(t.Id) && !excludeTaskIds.includes(t.Id),
      ) || [];

    if (isSuccess && filteredData.length === 0) {
      return (
        <NoData
          pb={16}
          size={150}
          illustration="noTaskResults"
          description={t('nodata.noTasks3.description')}
          descriptionProps={{ fz: 14, maw: 300 }}
        />
      );
    }

    const searchOptions = filteredData.map((opt) => {
      return (
        <Combobox.Option
          value={opt.Id.toString()}
          key={opt.Id}
          className={styles.taskOption}
        >
          <TaskOption task={opt} />
        </Combobox.Option>
      );
    });

    return searchOptions;
  }, [data, isSuccess, value, excludeTaskIds, t]);

  const optionsRef = useDisableScroll<HTMLDivElement>(opened);

  return (
    <Combobox
      keepMounted={false}
      withinPortal={true}
      store={combobox}
      position="bottom-end"
      shadow="md"
      onOptionSubmit={handleSubmit}
    >
      <Combobox.DropdownTarget>
        <PillsInput
          data-cy="tasks-select"
          ref={ref}
          rightSection={<Combobox.Chevron style={{ flexShrink: 0 }} />}
          pointer
          variant="filled"
          w={'100%'}
          size="sm"
          onClick={() => combobox.toggleDropdown()}
          styles={{
            input: {
              display: 'flex',
              alignItems: 'center',
              paddingTop: 4,
              paddingBottom: 4,
              minHeight: 36,
              // maxHeight: 102,
              // overflow: 'auto',
            },
          }}
        >
          <Flex justify="space-between">
            <Pill.Group>
              {isFetching ? (
                <Loader size={18} />
              ) : (
                <IconSearch color="var(--mantine-color-gray-6)" size={18} />
              )}
              {value.map((t) => (
                <TaskPill
                  key={t.Id}
                  task={t}
                  onRemove={() =>
                    onChange(value.filter((task) => task.Id !== t.Id))
                  }
                />
              ))}

              <Combobox.EventsTarget>
                <PillsInput.Field
                  ref={inputRef}
                  value={query}
                  onChange={(event) => {
                    setQuery(event.currentTarget.value);
                    combobox.openDropdown();
                  }}
                  placeholder={t('nodata.noTasks3.action')}
                  onKeyDown={handleBackspace}
                />
              </Combobox.EventsTarget>
            </Pill.Group>
          </Flex>
        </PillsInput>
      </Combobox.DropdownTarget>

      <Combobox.Dropdown
        classNames={{
          dropdown: styles.dropdown,
        }}
      >
        <Flex justify={'space-between'} pl={12} pr={4} pb={6} pt={6}>
          <Text tt="uppercase" fz={11} c="gray.6">
            {t('createTask.taskList')}
          </Text>
          <ActionIcon
            onClick={() => combobox.closeDropdown()}
            color="gray.6"
            variant="subtle"
          >
            <IconX size={16} />
          </ActionIcon>
        </Flex>
        <Combobox.Options className={styles.options} ref={optionsRef}>
          {isLoading ? (
            <Preloader />
          ) : isError ? (
            <ErrorAlert
              message={t('notification.error.searchTask')}
              w={'100%'}
            />
          ) : (
            options
          )}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};

const Preloader = () => (
  <Flex justify={'center'} py={12}>
    <Loader size={'sm'} />
  </Flex>
);

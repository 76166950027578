import {
  Box,
  Divider,
  Group,
  Paper,
  Skeleton,
  Stack,
  Table,
} from '@mantine/core';

export const TimelineLoader = ({ noHeader }: { noHeader?: boolean }) => {
  return (
    <Stack gap={8} flex={1} h="100%">
      {!noHeader && (
        <Group h={32} justify="space-between" wrap="nowrap">
          <Group gap={8} px={10} wrap="nowrap">
            <Skeleton h={16} w={16} circle />
            <Skeleton h={16} w={32} />
          </Group>
          <Group px={10} wrap="nowrap">
            <Skeleton h={16} w={32} />
            <Skeleton h={16} w={32} />
            <Skeleton h={16} w={32} />
            <Skeleton h={16} w={32} />
            <Skeleton h={16} w={32} />
          </Group>
        </Group>
      )}

      <Paper
        withBorder
        flex={1}
        style={{ display: 'flex', overflow: 'hidden' }}
      >
        <Box w="100%">
          <Group h={56} pos="relative" px={12}>
            <Skeleton h={16} w={80} />
            <Divider pos="absolute" bottom={-1} left={0} w="100%" />
          </Group>
        </Box>
        <Divider orientation="vertical" />
        <Box w="100%">
          <Stack gap={0} h={56} pos="relative">
            <Skeleton h={12} w={80} mt={9} ml={10} />
            <Table
              withColumnBorders
              verticalSpacing={4}
              pos="absolute"
              bottom={0}
              left={0}
            >
              <Table.Tbody>
                <Table.Tr />
                <Table.Tr>
                  {Array.from({ length: 10 }).map((_, i) => (
                    <Table.Td key={i}>
                      <Skeleton h={16} w={16} circle />
                    </Table.Td>
                  ))}
                </Table.Tr>
              </Table.Tbody>
            </Table>
            <Divider pos="absolute" bottom={-1} left={0} w="100%" />
          </Stack>
        </Box>
      </Paper>
    </Stack>
  );
};

export const MAX_FILES = 5;

export const allowedMimeTypes = [
  'application/pdf',
  'text/rtf',
  'text/plain',
  'application/zip',
  'application/epub+zip',
  'application/vnd.oasis.opendocument.text',
  'image/jpeg',
  'image/svg+xml',
  'text/csv',
  'image/png',
  'text/tab-separated-values',
  'application/vnd.oasis.opendocument.spreadsheet',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/json',
  'application/x-iwork-pages-sffpages',
  'application/x-iwork-numbers-sffnumbers',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.oasis.opendocument.presentation',
  'audio/wav',
  'audio/mpeg',
  'audio/ogg',
  'image/gif',
  'video/mp4',
  'video/quicktime',
  'image/heif',
  'image/heic',
  'image/heif-sequence',
  'image/heic-sequence',
];

export const fileGroups = [
  'doc',
  'sheet',
  'presentation',
  'photo',
  'media',
  'pdf',
  'music',
  'wav',
  'archive',
  'graphics',
] as const;

export type FileGroupType = (typeof fileGroups)[number];
export type MimeType = (typeof allowedMimeTypes)[number];

export const typeGroups: Record<FileGroupType, string[]> = {
  doc: [
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'text/plain',
    'application/x-iwork-pages-sffpages',
  ],
  sheet: [
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/x-iwork-numbers-sffnumbers',
    'text/csv',
    'text/tab-separated-values',
    'application/vnd.oasis.opendocument.spreadsheet',
  ],
  presentation: [
    'application/vnd.oasis.opendocument.presentation',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  ],
  graphics: ['image/svg+xm', 'image/svg+xml'],
  photo: [
    'image/gif',
    'image/jpeg',
    'image/png',
    'image/webp',
    'image/heic',
    'image/heif',
  ],
  media: ['video/mp4'],
  pdf: ['application/pdf'],
  music: ['audio/mpeg', 'audio/ogg'],
  wav: ['audio/wav'],
  archive: ['application/zip'],
};

import { createBrowserRouter } from 'react-router-dom';

import { ProtectedRoute } from '@/app/routes/ProtectedRoute';
import { AppLayout } from '@/components/layout/AppLayout/AppLayout';
import { AppRoutes } from '@/config/links';
import { MyTasksPage } from '@/features/MyTasksPage';
import { ErrorPage } from '@/pages/ErrorPage';
import { InvitePage } from '@/pages/InvitePage';
import { LoginPage } from '@/pages/LoginPage';
import { NotFoundPage } from '@/pages/NotFoundPage';
import { FolderPage } from '@/pages/Projects/FolderPage';
import { RedirectToFirstProject } from '@/pages/Projects/RedirectToFirstProject';
import { KanbanSprintPage } from '@/pages/Sprints/KanbanSprintPage';
import { ListSprintPage } from '@/pages/Sprints/ListSprintPage';
import { TimelineSprintPage } from '@/pages/Sprints/TimelinePage';
import { UpdatesPage } from '@/pages/UpdatesPage/UpdatesPage';

const isPreviewBuild = import.meta.env.VITE_PREVIEW_BUILD === 'true';
const previewUUID = import.meta.env.VITE_PREVIEW_UUID;

export const basename = isPreviewBuild ? `/${previewUUID}/` : '';

const basenameOption = { basename };

export const appRoute = createBrowserRouter(
  [
    {
      path: AppRoutes.login,
      element: <LoginPage />,
      ErrorBoundary: ErrorPage,
    },
    {
      path: '/',
      element: (
        <ProtectedRoute>
          <AppLayout />
        </ProtectedRoute>
      ),
      ErrorBoundary: ErrorPage.withLayout(AppLayout),
      children: [
        { path: AppRoutes.folder, element: <FolderPage /> },
        { path: AppRoutes.sprintList, element: <ListSprintPage /> },
        { path: AppRoutes.sprintKanban, element: <KanbanSprintPage /> },
        { path: AppRoutes.sprintTimeline, element: <TimelineSprintPage /> },
        { path: AppRoutes.myIssues, element: <MyTasksPage /> },
        { path: AppRoutes.updates, element: <UpdatesPage /> },
        { path: AppRoutes.invite, element: <InvitePage /> },
        { path: '/', index: true, element: <RedirectToFirstProject /> },
        { path: '*', element: <NotFoundPage /> },
      ],
    },
  ],
  basenameOption,
);

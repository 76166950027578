import {
  MantineColorsTuple,
  MantineThemeOverride,
  createTheme,
  mergeThemeOverrides,
} from '@mantine/core';

import { CUSTOMER, CustomerType } from './constants';

const defaultColors: MantineColorsTuple = [
  '#e7f5ff',
  '#d0ebff',
  '#a5d8ff',
  '#74c0fc',
  '#4dabf7',
  '#339af0',
  '#228be6',
  '#1c7ed6',
  '#1971c2',
  '#1864ab',
];

const baseTheme = createTheme({
  components: {
    Menu: {
      defaultProps: {
        keepMounted: false,
      },
      styles: {
        dropdown: {
          maxWidth: 'calc(100dvw - 20px)',
        },
      },
    },
    Popover: {
      defaultProps: {
        keepMounted: false,
      },
      styles: {
        dropdown: {
          maxWidth: 'calc(100dvw - 20px)',
        },
      },
    },
    Flex: {
      defaultProps: {
        align: 'center',
        gap: 8,
      },
    },

    Modal: {
      defaultProps: {
        keepMounted: false,
      },
    },
    Tooltip: {
      defaultProps: {
        events: { hover: true, focus: false, touch: true },
      },
      styles: {
        tooltip: {
          maxWidth: 'calc(100dvw - 20px)',
        },
      },
    },
  },
});

const r7Theme = createTheme({
  fontFamily: 'Roboto, sans-serif',
  headings: {
    fontFamily: 'Roboto, sans-serif',
  },
  colors: {
    r7: defaultColors,
  },
  primaryColor: 'r7',
  primaryShade: 4,
  defaultRadius: 'sm',
  components: {
    Checkbox: {
      defaultProps: {
        radius: 4,
      },
    },
    Notification: {
      styles: {
        closeButton: {
          alignSelf: 'flex-start',
          color: 'var(--mantine-color-gray-6)',
        },
        description: {
          color:
            'light-dark(var(--mantine-color-gray-8), var(--mantine-color-gray-3))',
        },
        icon: {
          marginRight: '12px',
          background: 'none',
          color: 'var(--notification-color)',
          width: '30px',
          height: '30px',
        },
        root: {
          boxShadow:
            '0px 0px 1px 0px #10182833, 0px 15px 30px 0px #1018280F, 0px 2px 8px 0px #1018281A',
        },
      },
    },
    TextInput: {
      defaultProps: {
        variant: 'filled',
        radius: 4,
      },
    },
    PasswordInput: {
      styles: {
        label: {
          textTransform: 'uppercase',
          fontSize: '10px',
          marginBottom: '16px',
          fontWeight: '500',
        },
      },
      defaultProps: {
        variant: 'filled',
      },
    },
    Textarea: {
      styles: {
        label: {
          textTransform: 'uppercase',
          fontSize: '10px',
          marginBottom: '16px',
          fontWeight: '500',
        },
      },
      defaultProps: {
        variant: 'filled',
      },
    },
    Button: {
      defaultProps: {
        radius: '4px',
      },
    },
    Menu: {
      defaultProps: {
        shadow: 'md',
        radius: 'md',
      },
    },
    Popover: {
      defaultProps: {
        shadow: 'md',
        radius: 'md',
      },
    },
    Flex: {
      defaultProps: {
        align: 'center',
        gap: 8,
      },
    },
    ColorSwatch: {
      styles: {
        shadowOverlay: {
          display: 'none',
        },
      },
    },
    Modal: {
      defaultProps: {
        radius: 4,
        overlayProps: {
          backgroundOpacity: 0.35,
        },
      },
      styles: {
        header: {
          marginLeft: '16px',
          marginRight: '16px',
          paddingLeft: '0',
          paddingRight: '0',
          marginBottom: '0',
          borderRadius: '0',
        },
        body: {
          paddingBottom: '16px',
          paddingLeft: '16px',
          paddingRight: '16px',
          paddingTop: '0',
        },
        title: {
          color:
            'light-dark(var(--mantine-color-dark-5), var(--mantine-color-text))',
          fontSize: '18px',
          fontWeight: 500,
        },
      },
    },
    Tooltip: {
      styles: {
        tooltip: {
          backgroundColor: '#000',
          color: '#FFF',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        },
      },
    },
  },
});

const crudocTheme = createTheme({});

const themeByAppType: Record<CustomerType, MantineThemeOverride> = {
  CRUDOC: crudocTheme,
  R7: r7Theme,
};

export const theme = mergeThemeOverrides(baseTheme, themeByAppType[CUSTOMER]);

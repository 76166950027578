import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { attachmentsApi } from '@/api/attachments/slice';
import { ApiAttachments } from '@/api/attachments/types';
import { useDeleteAttach } from '@/features/TaskPanel/hooks/useDeleteAttach';
import { formatDate } from '@/helpers/formatDate';
import {
  ActionIcon,
  Box,
  Card,
  Flex,
  Image,
  Text,
  Tooltip,
} from '@mantine/core';
import { IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { IconDownload, IconTrash } from '@tabler/icons-react';

import { useAttachActions } from '../../../../hooks/useAttachActions';
import { useAttachIcon } from '../../../../hooks/useAttachIcon';

import styles from './StripAttachment.module.css';

interface StripAttachmentProps {
  attachment: ApiAttachments.IAttachment;
  readonly?: boolean;
  onClick: (
    v: ApiAttachments.IAttachment & { fileUrl: string | undefined },
  ) => void;
}

export const StripAttachment = memo(
  ({ attachment, onClick, readonly }: StripAttachmentProps) => {
    const { t } = useTranslation();

    const isImage = IMAGE_MIME_TYPE.includes(attachment.MimeType);

    const {
      handleDownload,
      getFileUrl,
      isLoading: attachLoading,
    } = useAttachActions(attachment);

    const {
      handleDelete,
      isLoading: deleteLoading,
      isSuccess: deleteSuccess,
    } = useDeleteAttach([attachment]);

    const { data: fileUrl } = attachmentsApi.useGetQuery(
      {
        id: attachment.Id,
      },
      { skip: !isImage },
    );

    const icon = useAttachIcon(attachment.MimeType);

    const isBlocked = attachLoading || deleteLoading || deleteSuccess;

    const handleClick = async () => {
      if (isBlocked) return;

      const fileUrl = await getFileUrl();
      if (fileUrl) {
        onClick({ ...attachment, fileUrl });
      }
    };

    return (
      <Card
        shadow="sm"
        padding="lg"
        radius="sm"
        w={180}
        p={0}
        className={styles.imageAttachment}
        onClick={handleClick}
      >
        <Flex
          gap={4}
          pos={'absolute'}
          top={8}
          right={8}
          className={styles.imageAttachmentActions}
        >
          <Tooltip
            label={t('common.action.download')}
            openDelay={200}
            keepMounted={false}
          >
            <ActionIcon
              color="dark"
              size={'sm'}
              variant="white"
              className={styles.downloadImage}
              onClick={handleDownload}
              disabled={isBlocked}
              loading={attachLoading}
            >
              <IconDownload size={16} stroke={1.5} />
            </ActionIcon>
          </Tooltip>

          {!readonly && (
            <Tooltip
              label={t('common.action.delete')}
              openDelay={200}
              keepMounted={false}
            >
              <ActionIcon
                size={'sm'}
                variant="white"
                className={styles.removeImage}
                onClick={handleDelete}
                disabled={isBlocked}
                loading={deleteLoading}
              >
                <IconTrash size={16} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
          )}
        </Flex>
        <Card.Section>
          {isImage ? (
            <Image
              h={70}
              src={fileUrl}
              className={styles.imageAttachmentImage}
            />
          ) : (
            <Flex h={70} justify={'center'} align={'center'}>
              {icon}
            </Flex>
          )}
        </Card.Section>
        <Box py={6} px={8}>
          <Text size="sm" c="light-dark(black, white)" truncate={'end'}>
            {attachment.Name}
          </Text>
          <Text size="xs" c="light-dark(black, white)" truncate={'end'} mt={2}>
            {formatDate(attachment.Timestamp, 'long')}
          </Text>
        </Box>
      </Card>
    );
  },
);

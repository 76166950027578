import { useTranslation } from 'react-i18next';
import { Link, NavLink } from 'react-router-dom';

import { projectsApi } from '@/api/projects';
import { store } from '@/app/store';
import { layoutSlice } from '@/components/layout/AppLayout/slice';
import { Logotype } from '@/components/ui/Logotype';
import { CUSTOMER } from '@/config/constants';
import { AppRoutes } from '@/config/links';
import { openCreateTaskModal } from '@/features/CreateTaskModal';
import { generateLink } from '@/helpers/generateLink';
import { useOpenedSprintData } from '@/hooks/useOpenedSprintData';
import { openUpdatesModal } from '@/pages/UpdatesPage/UpdatesModal';
import {
  ActionIcon,
  AppShell,
  Box,
  Button,
  Divider,
  Flex,
  useComputedColorScheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import {
  IconChevronsLeft,
  IconNews,
  IconPlus,
  IconProgressCheck,
} from '@tabler/icons-react';

const toggle = () => store.dispatch(layoutSlice.actions.toggleSidebar());

export const Header = () => {
  const { t } = useTranslation();

  const sprintData = useOpenedSprintData();
  const folderId = projectsApi.endpoints.getProjects
    .useQueryState()
    .data?.list.at(0)?.Id;

  const isMobile = useMediaQuery('(max-width: 768px)');
  const isBurger = useMediaQuery('(max-width: 1200px)');
  const isLightTheme =
    useComputedColorScheme('light', {
      getInitialValueInEffect: true,
    }) === 'light';

  const getButtonColor = (isActive: boolean) => {
    if (isLightTheme) {
      return !isActive ? 'dark' : 'blue.0';
    } else return 'dark';
  };

  return (
    <>
      <AppShell.Section>
        <Flex
          justify={'space-between'}
          align={'center'}
          px={'md'}
          pl={32}
          h={65}
        >
          <Link
            to={folderId ? generateLink(AppRoutes.folder, { folderId }) : '/'}
            onClick={isBurger ? toggle : undefined}
            style={{ outline: 'none' }}
          >
            <Flex component="span" gap={8}>
              <Logotype />
            </Flex>
          </Link>
          <ActionIcon
            variant={isMobile ? 'transparent' : 'subtle'}
            color="dark.1"
            mr={8}
            onClick={toggle}
          >
            <IconChevronsLeft size={16} />
          </ActionIcon>
        </Flex>

        <Box px={8} pt={12}>
          <Button
            fullWidth
            leftSection={<IconPlus size={24} stroke={1.5} />}
            justify="flex-start"
            variant={'filled'}
            fw={600}
            fz={16}
            px={24}
            size="md"
            onClick={() => {
              isBurger && toggle();
              openCreateTaskModal({ defaultValues: { sprint: sprintData } });
            }}
          >
            {t('createTask.action.create')}
          </Button>
          <NavLink
            to={AppRoutes.myIssues}
            onClick={isBurger ? toggle : undefined}
          >
            {({ isActive }) => (
              <Button
                fullWidth
                leftSection={<IconProgressCheck size={24} stroke={1.5} />}
                justify="flex-start"
                variant={!isActive ? 'subtle' : 'filled'}
                color={getButtonColor(isActive)}
                c={isLightTheme ? 'black' : 'white'}
                fw={400}
                fz={16}
                px={24}
                size="md"
                mt={4}
              >
                {t('common.myTasks')}
              </Button>
            )}
          </NavLink>
          {CUSTOMER === 'R7' && (
            <NavLink to={AppRoutes.updates}>
              {({ isActive }) => (
                <Button
                  fullWidth
                  leftSection={<IconNews size={24} stroke={1.5} />}
                  justify="flex-start"
                  variant={!isActive ? 'subtle' : 'filled'}
                  color={getButtonColor(isActive)}
                  c={isLightTheme ? 'black' : 'white'}
                  fw={400}
                  fz={16}
                  px={24}
                  size="md"
                  mt={4}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    isBurger && toggle();

                    if (!isActive) {
                      openUpdatesModal();
                    }
                  }}
                >
                  {t('common.whatsNew')}
                </Button>
              )}
            </NavLink>
          )}
          <Divider size={1} mt={'xs'} />
        </Box>
      </AppShell.Section>
    </>
  );
};

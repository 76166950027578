import { QueryStatus } from '@reduxjs/toolkit/query';

export const combineStatuses = (statuses: QueryStatus[]): QueryStatus => {
  if (statuses.includes(QueryStatus.rejected)) {
    return QueryStatus.rejected;
  }
  if (statuses.includes(QueryStatus.pending)) {
    return QueryStatus.pending;
  }

  if (statuses.every((s) => s === QueryStatus.fulfilled)) {
    return QueryStatus.fulfilled;
  }

  return QueryStatus.uninitialized;
};

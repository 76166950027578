import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';

import { showRequestNotifications } from '@/api/helpers/showNotifications';
import { ApiProjectTask, projectTasksApi } from '@/api/tasks';
import { useAppSelector } from '@/app/store';
import { EditCommentForm } from '@/components/ui/EditCommentForm/EditCommentForm';
import { UserAvatar } from '@/components/ui/UserAvatar';
import { useAccess } from '@/hooks/useAccess';
import { Group } from '@mantine/core';

import styles from './CreateComment.module.css';

export const CreateComment = ({
  task,
  creation,
  setCreation,
}: {
  task: ApiProjectTask.ITask;
  creation: boolean;
  setCreation: (v: number | null | 'new') => void;
}) => {
  const { t } = useTranslation();
  const access = useAccess();

  const currentUser = useAppSelector((s) => s.usersCache.currentUser);

  const { ref, inView } = useInView({ threshold: 0.99 });
  const wrapRef = useRef<HTMLDivElement>(null);

  const [createComment, { isLoading }] =
    projectTasksApi.useCreateCommentMutation();

  const handleCreate = useCallback(
    (message: string) => {
      showRequestNotifications(
        createComment({
          projectId: task.ProjectId,
          taskId: task.Id,
          message,
        }).unwrap(),
        {
          successMsg: t('notification.success.addComment'),
          errorMsg: t('notification.error.addComment'),
        },
      ).then(() => {
        setCreation(null);
      });
    },
    [createComment, setCreation, task.Id, task.ProjectId, t],
  );

  if (!access.comments.create) return null;

  return (
    <>
      <div
        ref={ref}
        data-pinned={!inView}
        data-sticky={!creation}
        className={styles.root}
      >
        <Group gap={8} wrap="nowrap" align="start" pl={8}>
          <UserAvatar user={currentUser} size={36} />
          <EditCommentForm
            anchor={!inView ? wrapRef : undefined}
            editing={creation}
            loading={isLoading}
            onEditing={(v) => setCreation(v ? 'new' : null)}
            onChange={handleCreate}
          />
        </Group>
        <div ref={wrapRef} className={styles.anchor} />
      </div>
    </>
  );
};

import { useMemo } from 'react';

import { ApiTaskActivity, projectTasksApi } from '@/api/tasks';
import { usersApi } from '@/api/users';
import { find, flatMapDeep, isNumber, uniq } from 'lodash-es';

import { IFilledActivity, IFilledAssignActivity } from '../types';

export const useActivityData = <T extends ApiTaskActivity.IActivityType>(
  taskId: number,
  types?: T[],
): {
  data: T extends ApiTaskActivity.IActivityType.AssignTask
    ? IFilledAssignActivity[]
    : IFilledActivity[];
  isLoading: boolean;
} => {
  const { data, isLoading } = projectTasksApi.useGetActivitiesQuery({
    taskId,
    types,
  });

  const { data: users, isLoading: isUsersLoading } = usersApi.useGetByIdQuery({
    ids: uniq(
      flatMapDeep(data, (el) =>
        el.Type === ApiTaskActivity.IActivityType.AssignTask
          ? [
              el.AuthorId,
              el.Json.Changes.map((item) => [item.OldValue, item.NewValue]),
            ]
          : [el.AuthorId],
      ).filter(isNumber),
    ),
  });

  const preparedData = useMemo<IFilledActivity[]>(
    () =>
      data?.map((el) => {
        return {
          ...el,
          Author: find(users, ['Id', el.AuthorId]),
          ...(el.Type === ApiTaskActivity.IActivityType.AssignTask
            ? {
                Json: {
                  ...el.Json,
                  Changes: el.Json.Changes.map((item) => ({
                    ...item,
                    OldValue: find(users, ['Id', item.OldValue]),
                    NewValue: find(users, ['Id', item.NewValue]),
                  })),
                },
              }
            : {}),
        } as IFilledActivity;
      }) || [],
    [data, users],
  );

  return { data: preparedData, isLoading: isLoading || isUsersLoading } as any;
};

import { useTranslation } from 'react-i18next';

import { ApiProjectTask } from '@/api/tasks';
import { AppRoutes } from '@/config/links';
import { IFilledActivity } from '@/features/TaskPanel/components/Activity/types';

import { CopyButton } from '../../CopyButton';
import { ActivityHeader } from './ActivityHeader';

import styles from '../Comment.module.css';

interface CommentHeaderProps {
  activity: Pick<IFilledActivity, 'Id' | 'Author' | 'Timestamp'>;
  task: Pick<ApiProjectTask.ITask, 'Id' | 'SprintId'>;
  showType?: boolean;
}

export const CommentHeader = ({
  activity,
  showType,
  task,
}: CommentHeaderProps) => {
  const { t } = useTranslation();

  return (
    <ActivityHeader
      activity={activity}
      description={
        showType
          ? {
              action: t('taskAction.add'),
              predicate: t('activities.comment_one'),
              type: t('activities.comment_many'),
            }
          : undefined
      }
      actions={
        <CopyButton
          className={styles.copy}
          color="gray.5"
          value={AppRoutes.getCommentLink({
            activityId: activity.Id,
            taskId: task.Id,
            sprintId: task.SprintId,
          })}
        />
      }
    />
  );
};

import { customBaseQuery } from '@/api/customBaseQuery';
import {
  ApiProjectAttributes,
  ApiProjects,
  ApiSprints,
} from '@/api/projects/types';
import { store } from '@/app/store';
import { createApi } from '@reduxjs/toolkit/query/react';
import { t } from 'i18next';
import qs from 'qs';

import { ApiCommon } from '../common';
import { showErrorNotification } from '../helpers/showNotifications';

export type ComplexProjectData = Omit<
  ApiProjects.IProject,
  'Children' | 'Sprints'
> & {
  pathById: number[];
  rootProjectId: number;
  isProject: boolean;
  isFolder: boolean;
  Children: ComplexProjectData[];
  Sprints: ComplexSprintData[];

  /** EntityId по которому нужно получать статусы */
  statusesEntityId: number;
  /** EntityType по которому нужно получать статусы */
  statusesEntityType: ApiCommon.Entity;
};

export type ComplexSprintData = ApiSprints.ISprint & {
  statusesEntityId: number;
  statusesEntityType: ApiCommon.Entity;
  pathById: number[];
  rootProjectId: number;
};

type FolderResponse = {
  list: ComplexProjectData[];
  folderEntities: Record<number, ComplexProjectData>;
  sprintEntities: Record<number, ComplexSprintData>;
};

type RemoveStatusesPayload = {
  ids: number[];
  entityId: number;
  entityType: ApiCommon.Entity;
  newId?: number;
};

export const _projectsApi = createApi({
  reducerPath: 'projectsApi',
  baseQuery: customBaseQuery('/api'),
  tagTypes: ['Project', 'Statuses', 'Attribute'],
  endpoints: (builder) => ({
    getProjects: builder.query<FolderResponse, void>({
      query: (params) => ({
        url: `/v1/Project/Items`,
        method: 'GET',
        body: params,
      }),
      providesTags: ['Project'],
      transformResponse: (res: ApiProjects.IGetResponse) => {
        const prepareProject = <F extends ApiProjects.IProject>(
          project: F,
          parentProject?: ComplexProjectData,
        ): ComplexProjectData => {
          const provideStatuses =
            parentProject && project.Flags === ApiCommon.ProjectFlags.DEFAULT;

          const statusesEntityId = provideStatuses
            ? parentProject.statusesEntityId
            : project.Id;

          const statusesEntityType = provideStatuses
            ? parentProject.statusesEntityType
            : ApiCommon.Entity.Project;

          const pathById = [...(parentProject?.pathById || []), project.Id];

          const data: ComplexProjectData = {
            ...project,
            pathById: pathById,
            rootProjectId: pathById[0],
            isProject: pathById.length === 1,
            isFolder: pathById.length > 1,
            statusesEntityId,
            statusesEntityType,
            Children: [],
            Sprints: [],
          };

          data.Children =
            project.Children?.map((f) => prepareProject(f, data)) || [];
          data.Sprints =
            project.Sprints?.map((s) => prepareSprint(s, data)) || [];

          return data;
        };

        const prepareSprint = <F extends ApiSprints.ISprint>(
          sprint: F,
          parentProject?: ComplexProjectData,
        ): ComplexSprintData => {
          const pathById = [...(parentProject?.pathById || []), sprint.Id];

          const provideStatuses =
            parentProject && sprint.Flags === ApiCommon.ProjectFlags.DEFAULT;
          const statusesEntityId = provideStatuses
            ? parentProject.statusesEntityId
            : sprint.Id;
          const statusesEntityType = provideStatuses
            ? parentProject.statusesEntityType
            : ApiCommon.Entity.Sprint;

          return {
            ...sprint,
            pathById,
            rootProjectId: pathById[0],
            statusesEntityId,
            statusesEntityType,
          };
        };

        const currentUserId = store.getState().usersCache.currentUser.Id;

        const list: ComplexProjectData[] = [
          ...res.filter((p) => p.OwnerId === currentUserId),
          ...res.filter((p) => p.OwnerId !== currentUserId),
        ].map((f) => prepareProject(f));

        const folderEntities: Record<number, ComplexProjectData> = {};
        const sprintEntities: Record<number, ComplexSprintData> = {};

        const addEntity = (list: ComplexProjectData[]) => {
          list.forEach((folder) => {
            folderEntities[folder.Id] = folder;

            folder.Sprints?.forEach((s) => (sprintEntities[s.Id] = s));

            addEntity(folder.Children);
          });
        };

        addEntity(list);

        return {
          list,
          folderEntities,
          sprintEntities,
        };
      },
    }),
    createFolder: builder.mutation<
      ApiProjects.ICreateResponse,
      ApiProjects.ICreateRequest
    >({
      query: (body) => ({ url: `/v1/Project`, method: 'POST', body }),
      invalidatesTags: ['Project'],
    }),
    updateFolder: builder.mutation<
      ApiProjects.IUpdateResponse,
      ApiProjects.IUpdateRequest
    >({
      query: (body) => ({ url: `/v1/Project`, method: 'POST', body }),
      invalidatesTags: ['Project'],
    }),
    deleteFolderById: builder.mutation<void, number>({
      query: (folderId) => ({
        url: `/v1/Project?Id=${folderId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Project'],
    }),
    createSprint: builder.mutation<
      ApiSprints.ICreateResponse,
      ApiSprints.ICreateRequest
    >({
      query: (body) => ({
        url: `/v1/Project/SaveSprint`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Project'],
    }),
    updateSprint: builder.mutation<
      ApiSprints.IUpdateRequest,
      ApiSprints.IUpdateResponse
    >({
      query: (body) => ({
        url: `/v1/Project/SaveSprint`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Project'],
    }),
    deleteSprint: builder.mutation<void, number>({
      query: (id) => ({ url: `/v1/Project/Sprint?id=${id}`, method: 'DELETE' }),
      invalidatesTags: ['Project'],
    }),
    getAvailableStatuses: builder.query<
      ApiProjects.IGetAvailableStatusesResponse,
      ApiProjects.IGetAvailableStatusesRequest
    >({
      query: ({ entityIds, entityType, onlyForCurrent = true }) => ({
        url: `/v1/Project/AvailableStatuses`,
        method: 'POST',
        body: {
          entityIds,
          entityType,
          onlyForCurrent,
        },
      }),
      providesTags: (_, __, params) => {
        return params.entityIds.map((id) => ({
          type: 'Statuses',
          id: id + '_' + params.entityType,
        }));
      },
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          showErrorNotification({
            message: t('notification.error.getStatuses'),
          });
        }
      },
      transformResponse: (res: ApiProjects.IGetAvailableStatusesResponse) => {
        if (res.length < 1) {
          throw Error('Empty task statuses');
        }

        return res;
      },
    }),
    getDefaultStatuses: builder.query<
      ApiProjects.IGetDefaultStatusesResponse,
      ApiProjects.IGetDefaultStatusesRequest
    >({
      query: () => ({
        url: `/v1/project/getDefaultStatuses`,
        method: 'GET',
      }),
      transformResponse: (res: ApiProjects.IGetDefaultStatusesResponse) => {
        if (res.length < 1) {
          throw Error('Empty default statuses');
        }

        return res;
      },
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          showErrorNotification({
            message: t('notification.error.getDefaultStatuses'),
          });
        }
      },
    }),
    saveStatuses: builder.mutation<void, ApiProjects.ISaveTaskStatusRequest>({
      query: (body) => ({
        url: `/v1/Project/SaveStatus`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (_, __, args) =>
        args.length > 0
          ? [
              {
                type: 'Statuses',
                id: args.at(0)?.EntityId + '_' + args.at(0)?.EntityType,
              },
            ]
          : [],
    }),
    removeStatuses: builder.mutation<void, RemoveStatusesPayload>({
      query: ({ ids, newId }) => ({
        url: `/v1/Project/DeleteStatus`,
        method: 'DELETE',
        body: {
          ids,
          newId,
        },
      }),
      invalidatesTags: (_, __, args) => [
        { type: 'Statuses', id: args.entityId + '_' + args.entityType },
      ],
    }),
  }),
});

// With Attributes
export const projectsApi = _projectsApi.injectEndpoints({
  endpoints: (builder) => ({
    getAttributes: builder.query<
      ApiProjectAttributes.IGetResponse,
      ApiProjectAttributes.IGetRequest
    >({
      query: (params) => {
        return {
          url: `/v1/ProjectAttributes${qs.stringify(params, { addQueryPrefix: true })}`,
          method: 'GET',
        };
      },
      providesTags: (res) => [
        'Attribute',
        ...(res ?? []).map((el) => ({
          type: 'Attribute' as const,
          id: el.AttributeId,
        })),
      ],
    }),
    postAttribute: builder.mutation<
      ApiProjectAttributes.IPostResponse,
      ApiProjectAttributes.IPostRequest
    >({
      query: (params) => {
        return {
          url: `/v1/ProjectAttributes`,
          method: 'POST',
          body: params,
        };
      },
      invalidatesTags: ['Attribute'],
    }),
    deleteAttribute: builder.mutation<
      void,
      { projectId: number; attributeId: number }
    >({
      query: (params) => {
        return {
          url: `/v1/ProjectAttributes${qs.stringify(params, { addQueryPrefix: true })}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: (_, __, args) => [
        { type: 'Attribute', id: args.attributeId },
      ],
    }),
  }),
  overrideExisting: false,
});

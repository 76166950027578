import { useTranslation } from 'react-i18next';

import { createFormContext } from '@mantine/form';
import { cloneDeep, get } from 'lodash-es';

import { IEntityFormValues, IStatusForm } from '../types';

const [FormProvider, useFormContext, useForm] =
  createFormContext<IEntityFormValues>();

export const useEntityForm = ({
  defaultValue,
  entityType,
}: {
  defaultValue: IEntityFormValues;
  entityType: 'project' | 'folder' | 'sprint';
}) => {
  const { t } = useTranslation();

  return useForm({
    initialValues: cloneDeep(defaultValue),
    validate: {
      name: (v, values) => {
        if (!values.formType && !v.trim().length)
          return t(`${tKey}.title.${entityType}`);

        return null;
      },
      statuses: {
        current: {
          name: (v, values, path) => {
            const item: IStatusForm = get(
              values,
              path.split('.').slice(0, -1).join('.'),
            );
            const isUniq = values.statuses.current
              .filter((el) => el.state !== 'removed')
              .every(
                (el) =>
                  el.id === item.id || el.name.trim() !== item.name.trim(),
              );

            if (item.state === 'removed') return null;
            if (!v.trim().length) return t(`${tKey}.statuses.required`);
            if (!isUniq) return t(`${tKey}.statuses.uniq`);
            return null;
          },
        },
      },
      types: (v) =>
        entityType !== 'project' || v.length ? null : t(`${tKey}.types`),
      custom: {
        name: (v, values, path) => {
          const item: IStatusForm = get(
            values,
            path.split('.').slice(0, -1).join('.'),
          );
          const isUniq = values.custom
            .filter((el) => el.state !== 'removed')
            .every(
              (el) => el.id === item.id || el.name.trim() !== item.name.trim(),
            );

          if (item.state === 'removed') return null;
          if (!v.trim().length) return t(`${tKey}.customFields.required`);
          if (v.trim().length > 250)
            return t(`${tKey}.maxLength`, { count: 250 });
          if (!isUniq) return t(`${tKey}.customFields.uniq`);
          return null;
        },
      },
    },
    transformValues(values) {
      return {
        ...values,
        name: values.name.trim(),
        statuses: {
          ...values.statuses,
          current: values.statuses.current.map((el) => ({
            ...el,
            name: el.name.trim(),
            state: setUpdateState(
              defaultValue.statuses.current.find((elp) => elp.id === el.id),
              el,
            ),
          })),
        },
        custom: values.custom.map((el) => ({ ...el, name: el.name.trim() })),
      };
    },
  });
};

const tKey = 'entitySettings.errors';

const setUpdateState = (
  providedItem: IStatusForm | undefined,
  item: IStatusForm,
) => {
  if (
    item.state === 'original' &&
    providedItem &&
    (providedItem.name !== item.name || providedItem.color !== item.color)
  ) {
    return 'updated';
  }
  return item.state;
};

export const useEntityFormContext = useFormContext;
export const EntityFormProvider = FormProvider;

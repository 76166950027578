import { ApiProjects } from '@/api/projects';
import { Ellipsis } from '@/components/ui/Ellipsis';
import { Avatar, Button, Flex, useComputedColorScheme } from '@mantine/core';
import { IconFolder } from '@tabler/icons-react';

export const FolderButton = ({ folder }: { folder: ApiProjects.IProject }) => {
  const isLightTheme =
    useComputedColorScheme('light', {
      getInitialValueInEffect: true,
    }) === 'light';

  return (
    <Flex>
      <Button
        fullWidth
        justify="left"
        leftSection={
          <Avatar
            variant="filled"
            radius="sm"
            size={20}
            color={folder.Color || 'gray'}
          >
            <IconFolder size={14} />
          </Avatar>
        }
        variant="default"
        size="md"
        fw={'normal'}
        c={isLightTheme ? 'black' : 'white'}
      >
        <Ellipsis>{folder.Name}</Ellipsis>
      </Button>
    </Flex>
  );
};

import { NotificationData, showNotification } from '@mantine/notifications';
import { IconCheck, IconCircleXFilled } from '@tabler/icons-react';
import { isFunction, isObject } from 'lodash-es';

type MessageType = string | number | NotificationData;

export const showRequestNotifications = <Response,>(
  request: Promise<Response>,
  options: {
    notifyId?: string;
    successMsg?: ((res: Response) => MessageType) | MessageType;
    errorMsg?: ((err: any) => MessageType) | MessageType;
  } = {},
): Promise<Response> => {
  const { successMsg, errorMsg } = options;

  return request
    .then((res) => {
      if (successMsg) {
        const message = isFunction(successMsg) ? successMsg(res) : successMsg;

        const notifyOpts = {
          id: options.notifyId,
          ...(isObject(message) ? message : { message }),
        };

        showSuccessNotification(notifyOpts);
      }
      return res;
    })
    .catch((err) => {
      if (errorMsg) {
        const message = isFunction(errorMsg) ? errorMsg(err) : errorMsg;

        const notifyOpts = {
          id: options.notifyId,
          ...(isObject(message) ? message : { message }),
        };

        showErrorNotification(notifyOpts);
      }
      throw err;
    });
};

export const showSuccessNotification = (props: NotificationData) => {
  showNotification({
    color: 'blue',
    icon: <IconCheck size={30} />,
    ...props,
  });
};

export const showErrorNotification = (props: NotificationData) => {
  showNotification({
    color: 'red',
    icon: <IconCircleXFilled size={30} />,
    ...props,
  });
};

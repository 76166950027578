import { useTranslation } from 'react-i18next';

import { UserAvatar } from '@/components/ui/UserAvatar';
import { Flex, Text } from '@mantine/core';

import { PerformerOption } from '../../features/PerformerSelect/types';

export const UserOption = <USER extends PerformerOption>({
  user,
}: {
  user?: USER | null;
  active?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <Flex align={'center'} gap={8} pl={0} w="100%">
      <UserAvatar size={24} user={user} />
      <Text
        style={{
          width: '100%',
          whiteSpace: 'nowrap',
          textAlign: 'left',
        }}
        c={
          'light-dark(var(--mantine-color-dark-4), var(--mantine-color-dark-0))'
        }
        size={'sm'}
        truncate={'end'}
      >
        {user?.Name || t('members.notAssigned')}
      </Text>
    </Flex>
  );
};

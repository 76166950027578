import { useTranslation } from 'react-i18next';

import { ComplexSprintData } from '@/api/projects';
import { openCreateTaskModal } from '@/features/CreateTaskModal';
import { BoxProps, Button } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';

interface IProps {
  sprint: ComplexSprintData;
  statusId: number;
  order?: number;
}

export const CreateTask = ({
  sprint,
  statusId,
  order,
  ...props
}: IProps & BoxProps) => {
  const { t } = useTranslation();

  return (
    <Button
      fullWidth
      leftSection={<IconPlus size={12} />}
      size="xs"
      variant="subtle"
      color="gray.8"
      justify="flex-start"
      onClick={() =>
        openCreateTaskModal({
          defaultValues: { sprint, Status: statusId, Order: order },
        })
      }
      {...props}
    >
      {t('sidebar.addTask')}
    </Button>
  );
};

import { Group, Skeleton, Stack } from '@mantine/core';

import styles from '../Navigation.module.css';

export const SkeletonNavLoader = () => {
  return (
    <Stack gap="0" className={styles.root}>
      {Array.from({ length: 4 }).map((_, i) => {
        const widths = ['70%', '40%', '65%', '75%'];
        return (
          <Group
            justify="flex-start"
            align="center"
            gap="lg"
            preventGrowOverflow={true}
            wrap={'nowrap'}
            mih={'41.67px'}
            mb={'2px'}
            key={i}
          >
            <Skeleton visible={true} height={20} width="20px" />
            <Skeleton visible={true} height={10} width={widths[i]} />
          </Group>
        );
      })}
    </Stack>
  );
};

import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DEFAULT_VALUES } from '@/app/constants';
import { ProjectIconType } from '@/components/ui/icons/ProjectIcon';
import { Button, Stack } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconPlus } from '@tabler/icons-react';

import { IFormItemState, ITypeForm } from '../../types';
import { EditTypeModal } from '../TypeRow/EditTypeModal';
import { TypeRow } from '../TypeRow/TypeRow';

type TaskTypesFormProps = {
  value: ITypeForm[];
  onChange: (types: ITypeForm[]) => void;
};

const getNewType = (): ITypeForm => ({
  id: new Date().valueOf(),
  name: '',
  icon: ProjectIconType.apps,
  color: DEFAULT_VALUES.TASK_TYPE_COLOR,
  state: 'new',
});

export const TaskTypesForm = ({ onChange, value }: TaskTypesFormProps) => {
  const { t } = useTranslation();

  const [opened, { close, open }] = useDisclosure();
  const [task, setTask] = useState<ITypeForm>(getNewType());
  const [state, setState] = useState<IFormItemState>('new');

  const visibleItems = value.filter((t) => t.state !== 'removed');

  return (
    <Stack gap={8} align="flex-start" onSubmit={(e) => e.stopPropagation()}>
      {visibleItems.map((t) => (
        <TypeRow
          type={t}
          key={t.id}
          removable={visibleItems.length > 1}
          onEdit={() => {
            // Оставляем элемент как новый даже после редактирования
            setTask({ ...t, state: t.state === 'new' ? 'new' : 'updated' });
            setState('updated');
            open();
          }}
          onRemove={() => {
            if (t.state === 'new') {
              // Полностью удаляем элемент если он новый
              onChange(value.filter((el) => el.id !== t.id));
            } else {
              onChange(
                value.map((el) =>
                  el.id === t.id ? { ...el, state: 'removed' } : el,
                ),
              );
            }
          }}
        />
      ))}

      <Button
        leftSection={<IconPlus size={12} />}
        size="xs"
        onClick={() => {
          setTask(getNewType());
          setState('new');
          open();
        }}
      >
        {t('entitySettings.typeSection.addType')}
      </Button>

      <EditTypeModal
        opened={opened}
        onClose={close}
        onSubmit={(t) => {
          if (state === 'new') {
            onChange([...value, t]);
          }
          if (state === 'updated') {
            onChange(value.map((el) => (el.id === t.id ? t : el)));
          }
          close();
        }}
        defaultValue={task}
        confirmLabel={t(`common.action.${state === 'new' ? 'add' : 'edit'}`)}
      />
    </Stack>
  );
};

import { SprintOption } from '@/components/ui/SprintOption';

import { GanttSprint } from '../transformTaskData';

export const SprintCell = ({
  sprint: { _sprintData },
}: {
  sprint: GanttSprint;
}) => {
  return <SprintOption sprint={_sprintData} />;
};

import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { appRoute } from '@/app/routes/AppRoute';
import { NoData } from '@/components/ui/NoData/NoData';
import { CUSTOMER } from '@/config/constants';

export const NoSprint = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>404 | {t('appName.' + CUSTOMER)}</title>
      </Helmet>

      <NoData
        title={t('404.title')}
        description={t('404.description', { entity: t('404.entities.page') })}
        illustration="404"
        buttons={[
          {
            buttonText: 'Вернуться на главную',
            handleClick: () => appRoute.navigate('/'),
          },
        ]}
      />
    </>
  );
};

import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { isNumber } from 'lodash-es';

export const isNumberValue = (value: unknown): value is number => {
  return isNumber(value) && !isNaN(value);
};

export const useNumberQueryParam = (
  paramName: 'folderId' | 'sprintId' | 'taskId',
) => {
  const { [paramName]: value } = useParams();

  return useMemo(() => {
    const id = Number(value || 'NaN');
    return isNumberValue(id) ? id : null;
  }, [value]);
};

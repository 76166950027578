import { isNil } from 'lodash-es';

import { sprintViewsList } from './const';

export type ISprintView = (typeof sprintViewsList)[number];

export const isSprintView = (
  v: string | null | undefined,
): v is ISprintView => {
  return !isNil(v) && sprintViewsList.includes(v);
};

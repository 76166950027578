import { ReactNode, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { showErrorNotification } from '@/api/helpers/showNotifications';
import { ComplexSprintData } from '@/api/projects';
import { ApiProjectTask } from '@/api/tasks';
import { SprintLoader } from '@/components/shared/SkeletonLoaders/SprintLoader';
import { ErrorAlert } from '@/components/ui/ErrorAlert';
import { useAccess } from '@/hooks/useAccess';
import { useStatusesData } from '@/hooks/useStatusesData';
import { useTaskTypesData } from '@/hooks/useTaskTypesData';
import { DragDropContext } from '@hello-pangea/dnd';
import { Stack } from '@mantine/core';

import { CreateTask } from './CreateTask/CreateTask';
import { TasksGroup } from './TasksGroup';
import { useDnd } from './hooks/useDnd';

type SprintTaskListProps = {
  sprint: ComplexSprintData | null;
  tasks: ApiProjectTask.ITask[];
  isLoading?: boolean;
  isError?: boolean;
  emptyState?: ReactNode;
};

export const SprintTaskList = memo(
  ({
    sprint,
    tasks,
    emptyState = null,
    isError,
    isLoading,
  }: SprintTaskListProps) => {
    const { t } = useTranslation();
    const access = useAccess();

    const { statuses } = useStatusesData(sprint);
    const { taskTypes } = useTaskTypesData(sprint?.ProjectId);

    const { groupedData, onDragEnd } = useDnd({
      tasks,
      statuses,
      revertStatusesOrder: true,
    });

    if (isError) {
      return <ErrorAlert message={t('notification.error.getSprint')} />;
    }

    if (isLoading || !sprint) return <SprintLoader />;

    if (groupedData.length) {
      return (
        <Stack>
          <DragDropContext onDragEnd={onDragEnd}>
            {groupedData.map(({ status, tasks }) => (
              <TasksGroup
                key={status.Id}
                sprint={sprint}
                statuses={statuses}
                types={taskTypes}
                status={status}
                tasks={tasks}
              />
            ))}
          </DragDropContext>
        </Stack>
      );
    }

    const defaultStatus = statuses[0]?.Id;

    const handleClick = !defaultStatus
      ? () => {
          showErrorNotification({
            message: t('notification.error.getStatusesExtended'),
          });
        }
      : undefined;

    return emptyState ? (
      emptyState
    ) : (
      <>
        {access.tasks.create && sprint && (
          <div onClick={handleClick}>
            <CreateTask
              disabled={!defaultStatus}
              sprint={sprint}
              types={taskTypes}
              status={defaultStatus}
            />
          </div>
        )}
      </>
    );
  },
);

import { forwardRef } from 'react';

import { DEFAULT_VALUES } from '@/app/constants';
import {
  CheckIcon,
  ColorSwatch,
  ColorSwatchProps,
  Flex,
  FlexProps,
} from '@mantine/core';

type ColorPickerProps = {
  value?: string | null;
  onChange?: (v: string) => void;
} & Omit<FlexProps, 'onChange'>;

const Picker = forwardRef<HTMLDivElement, ColorPickerProps>(
  ({ value, onChange, ...props }, ref) => {
    return (
      <Flex wrap={'wrap'} ref={ref} gap={8} {...props}>
        {DEFAULT_VALUES.COLORS.map((color) => {
          const isActive =
            color === value || (!value && color === DEFAULT_VALUES.COLORS[0]);
          return (
            <ColorCircle
              value={color}
              key={color}
              isActive={isActive}
              size={26}
              onClick={() => onChange?.(color)}
            />
          );
        })}
      </Flex>
    );
  },
);

const Component = Picker;

interface ColorCircleProps
  extends Omit<ColorSwatchProps, 'color'>,
    Omit<
      React.ComponentPropsWithoutRef<'div'>,
      keyof Omit<ColorSwatchProps, 'color'>
    > {
  value?: string | null;
  isActive?: boolean;
}

const ColorCircle = forwardRef<HTMLDivElement, ColorCircleProps>(
  ({ value, isActive, ...props }, ref) => (
    <ColorSwatch
      ref={ref}
      color={value || 'var(--mantine-color-gray-5)'}
      style={{ cursor: 'pointer', color: 'var(--mantine-color-white)' }}
      {...props}
    >
      {isActive && <CheckIcon size={12} />}
    </ColorSwatch>
  ),
);

export const ColorPicker = Object.assign(Component, {
  ColorCircle,
});

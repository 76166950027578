import { ReactNode } from 'react';

import { useFormatDistance } from '@/app/lang/useDateFnsLocale';
import { IFilledActivity } from '@/features/TaskPanel/components/Activity/types';
import { Badge, Group } from '@mantine/core';

import styles from '../Comment.module.css';

interface IProps {
  activity: Pick<IFilledActivity, 'Author' | 'Timestamp'>;
  description?: {
    action: string;
    predicate: string;
    type?: string;
  };
  actions?: ReactNode;
}

export const ActivityHeader = ({ activity, description, actions }: IProps) => {
  const formatDistance = useFormatDistance();

  return (
    <Group gap={14} align="center">
      <Group gap={4}>
        <p className={styles.author}>{activity.Author?.Name}</p>
        {description && (
          <p className={styles.action}>
            {description.action} <b>{description.predicate}</b>
          </p>
        )}
      </Group>
      <p className={styles.time}>
        {activity.Timestamp && formatDistance(activity.Timestamp)}
      </p>
      {description && description.type && (
        <Badge color="gray.5" size="sm" radius="sm">
          {description.type}
        </Badge>
      )}
      {actions}
    </Group>
  );
};

import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ApiProjectAttributes } from '@/api/projects';
import { projectTasksApi } from '@/api/tasks';
import {
  ICustomField,
  customFieldIconByType,
} from '@/components/complex/EntityForm';
import { CopyButton } from '@/components/ui/CopyButton';
import { useAccess } from '@/hooks/useAccess';
import { useOverflown } from '@/hooks/useOverflown';
import { ActionIcon, Group, TextInput, Tooltip } from '@mantine/core';
import { useField } from '@mantine/form';
import {
  getHotkeyHandler,
  useDebouncedCallback,
  useFocusWithin,
} from '@mantine/hooks';
import { IconPencil, IconX } from '@tabler/icons-react';
import { isString } from 'lodash-es';

import styles from './CustomFields.module.css';

interface Props extends ICustomField {
  value?: string;
  taskId: number;
  id: number;
  recordId?: number;
  isExternal?: boolean;
}

export const CustomField = memo(
  ({ name, type, value, taskId, id, recordId, isExternal }: Props) => {
    const { t } = useTranslation();
    const access = useAccess();

    const [addAttribute] = projectTasksApi.useEditAttributeMutation();
    const field = useField({
      initialValue: value,
      validate: (value) => {
        const trimmed = (value || '').trim();

        if (!trimmed) return null;
        if (trimmed.length > 500)
          return t('entitySettings.errors.maxLength', { count: 500 });
        if (isLink && !urlPattern.test(trimmed))
          return t('entitySettings.errors.link');

        return null;
      },
    });

    const { ref, focused } = useFocusWithin<HTMLInputElement>();
    const { isOverflown, checkOverflown } = useOverflown();
    const [isEdit, setIsEdit] = useState(false);

    const link =
      !value || value.startsWith('http') ? value : `https://${value}`;
    const isLink = type === ApiProjectAttributes.IValueType.Link;

    const handleSave = useDebouncedCallback(() => {
      const result = (field.getValue() ?? '').trim();
      if (result === (value || '')) return;
      addAttribute({
        TaskId: taskId,
        AttributeId: id,
        Value: result,
        Id: recordId,
      });
    }, 500);
    const handleCheck = useCallback(
      (v?: string) => {
        isString(v) && field.setValue(v);
        field.validate().then((err) => {
          if (err) return;
          setIsEdit(false);
          handleSave();
        });
      },
      [field, handleSave],
    );

    const inputProps = useMemo(() => {
      const fieldProps = field.getInputProps();
      const isReadonly = isLink && !isEdit;

      return {
        ...fieldProps,
        value:
          isReadonly && fieldProps.value?.startsWith('http')
            ? fieldProps.value.replace('https://', '').replace('http://', '')
            : fieldProps.value ||
              ((!isReadonly || !link) && focused ? '' : '-'),
        onBlur: () => {
          handleCheck();
          fieldProps.onBlur();
        },
        styles: {
          input: isReadonly
            ? {
                cursor: value
                  ? 'pointer'
                  : access.tasks.edit
                    ? 'default'
                    : 'text',
              }
            : {},
        },
        ...(isReadonly
          ? {
              readOnly: !!link,
              onClick: () => link && window.open(link, '_blank')?.focus(),
            }
          : {}),
        ...(!access.tasks.edit ? { readOnly: true } : {}),
      };
    }, [
      field,
      focused,
      isLink,
      isEdit,
      link,
      access.tasks.edit,
      value,
      handleCheck,
    ]);

    useEffect(() => {
      field.setValue(value);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);
    useEffect(() => {
      if (isEdit) ref.current.focus();
    }, [isEdit, ref]);

    return (
      <Group gap={4} wrap="nowrap" align="start">
        <Tooltip
          label={name}
          openDelay={400}
          multiline
          maw={1000}
          position="top-start"
          disabled={!isOverflown}
        >
          <TextInput
            variant="default"
            readOnly
            value={name}
            leftSection={customFieldIconByType[type]}
            className={styles.input}
            data-external={isExternal}
            w={150}
            onMouseEnter={checkOverflown}
          />
        </Tooltip>
        <TextInput
          {...inputProps}
          ref={ref}
          onKeyDown={getHotkeyHandler([['Enter', handleCheck]])}
          className={styles.input}
          data-external={isExternal}
          flex={1}
          variant="default"
          rightSection={
            <Group gap={4} wrap="nowrap">
              {access.tasks.edit && (
                <ActionIcon
                  size="sm"
                  variant="subtle"
                  color="gray.6"
                  onClick={() => setIsEdit(true)}
                >
                  <IconPencil size="70%" />
                </ActionIcon>
              )}
              <CopyButton
                value={(isLink ? link : value) || ''}
                size="sm"
                color="gray.6"
                iconProps={{ size: '70%' }}
              />
              {access.tasks.edit && (
                <ActionIcon
                  size="sm"
                  variant="subtle"
                  color="gray.6"
                  onClick={() => handleCheck('')}
                >
                  <IconX size="70%" />
                </ActionIcon>
              )}
            </Group>
          }
          rightSectionWidth={access.tasks.edit ? 82 : undefined}
        />
      </Group>
    );
  },
);

const urlPattern =
  /^(https?:\/\/)?[\w-]+(\.[\w-]+)+[\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-]$/i;

import { matchPath } from 'react-router-dom';

import { appRoute } from '@/app/routes/AppRoute';
import { AppRoutes } from '@/config/links';

import { generateLink } from './generateLink';

export const redirectToProject = (id: number) => {
  appRoute.navigate(
    {
      pathname: generateLink(AppRoutes.folder, { folderId: id }),
      search: appRoute.state.location.search,
    },
    {
      replace: true,
    },
  );
};

export const redirectToSprint = (id: number) => {
  appRoute.navigate(
    {
      pathname: generateLink(AppRoutes.sprintList, { sprintId: id }),
      search: appRoute.state.location.search,
    },
    {
      replace: true,
    },
  );
};

export const redirectAfterProjectDeletion = (
  projectIds: number[],
  removedProject: number,
) => {
  const openedFolderId = matchPath(
    AppRoutes.folder,
    appRoute.state.location.pathname,
  )?.params.folderId;

  // редиректим только если удаляется открытый проект
  if (openedFolderId !== removedProject.toString()) return;

  // убираем из списка проектов тот что удаляем
  const ids = projectIds.filter((id) => id !== removedProject) || [];

  // редирект на страницу моих задач если нет оставшихся проектов
  if (!ids.length) {
    appRoute.navigate(
      {
        pathname: AppRoutes.myIssues,
        search: appRoute.state.location.search,
      },
      { replace: true },
    );
    return;
  }

  // редирект на первый доступный проект
  redirectToProject(ids[0]);
};

import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { showErrorNotification } from '@/api/helpers/showNotifications';
import { projectsApi } from '@/api/projects';
import { shareApi } from '@/api/share';
import { store } from '@/app/store';
import { layoutSlice } from '@/components/layout/AppLayout/slice';
import { ProjectIcon } from '@/components/ui/icons/ProjectIcon';
import { AppRoutes } from '@/config/links';
import { generateLink } from '@/helpers/generateLink';
import { Button, Flex, Group, Stack, Text, Title } from '@mantine/core';

import { NotFoundPage } from '../NotFoundPage';

const setWithoutSidebar = (v: boolean) =>
  store.dispatch(layoutSlice.actions.setWithoutSidebar(v));

export const InvitePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const uid = searchParams.get('uid') || '';

  const { data: projectsData } =
    projectsApi.endpoints.getProjects.useQueryState();
  const { data, isError } = shareApi.useLinkInfoQuery({ uid });
  const [applyLink] = shareApi.useApplyLinkMutation();

  const project = data?.Entity;
  const isAlreadyApplied = projectsData?.list.some(
    ({ Id }) => Id === project?.Id,
  );

  const handleApply = useCallback(() => {
    if (isAlreadyApplied) {
      navigate(generateLink(AppRoutes.folder, { folderId: project?.Id || 0 }));
      return;
    }

    applyLink({ uid })
      .unwrap()
      .then(async (res) => {
        await store
          .dispatch(
            projectsApi.endpoints.getProjects.initiate(undefined, {
              forceRefetch: true,
            }),
          )
          .unwrap();

        return res;
      })
      .then((res) =>
        navigate(generateLink(AppRoutes.folder, { folderId: res.Id })),
      )
      .catch(() =>
        showErrorNotification({ message: t('members.links.invalid') }),
      );
  }, [isAlreadyApplied, uid, project?.Id, applyLink, navigate, t]);

  useEffect(() => {
    setWithoutSidebar(true);

    return () => {
      setWithoutSidebar(false);
    };
  }, []);

  if (isError) return <NotFoundPage />;
  if (!project) return null;

  return (
    <Flex justify="center" flex={1}>
      <Stack gap={40} align="center">
        <Group>
          <ProjectIcon
            name={project.Name}
            color={project.Color}
            icon={project.IconId}
            size={48}
          />
          <Title fz={32} fw={500}>
            {project.Name}
          </Title>
        </Group>
        <Text maw={260} ta="center">
          {t(
            `members.links.${isAlreadyApplied ? 'alreadyInvited' : 'invite'}`,
            { project: project.Name },
          )}
        </Text>
        <Button size="lg" radius={8} onClick={handleApply}>
          {isAlreadyApplied
            ? t('members.action.goToProject')
            : t('members.action.join')}
        </Button>
      </Stack>
    </Flex>
  );
};

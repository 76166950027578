import { ReactNode } from 'react';

import { EntityFormProvider, useEntityForm } from '../hooks/useForm';
import { IEntityFormValues } from '../types';
import { EntityFormBody } from './EntityFormBody';
import { EntityFormHeader } from './EntityFormHeader';
import { CustomFieldsForm } from './Subforms/CustomFieldsForm';
import { IconForm } from './Subforms/IconForm';
import { MainForm } from './Subforms/MainForm';
import { StatusesForm } from './Subforms/StatusesForm';
import { TaskTypesForm } from './Subforms/TaskTypesForm';

interface IProps {
  title: ReactNode;
  confirmLabel: string;
  defaultValue: IEntityFormValues;
  entityType: 'project' | 'folder' | 'sprint';
  parentIsFolder?: boolean;
  isLoading?: boolean;
  onClose: VoidFunction;
  onConfirm: (v: IEntityFormValues) => void;
}

export const EntityForm = ({
  title,
  confirmLabel,
  defaultValue,
  entityType,
  parentIsFolder,
  isLoading,
  onClose,
  onConfirm,
}: IProps) => {
  const form = useEntityForm({ defaultValue, entityType });

  const formType = form.getValues().formType;
  const isProject = entityType === 'project';
  const parentType = !isProject
    ? parentIsFolder
      ? 'folder'
      : 'project'
    : undefined;

  return (
    <EntityFormProvider form={form}>
      <EntityFormHeader title={title} onClose={onClose} />

      <EntityFormBody
        confirmLabel={confirmLabel}
        isLoading={isLoading}
        onClose={onClose}
        onConfirm={onConfirm}
      >
        {!formType && <MainForm isProject={isProject} />}
        {formType === 'icon' && (
          <IconForm
            name={form.values.name || 'P'}
            color={form.values.color}
            icon={form.values.icon}
            showInitials
            onColorChange={(v) => form.setFieldValue('color', v)}
            onIconChange={(v) => form.setFieldValue('icon', v)}
          />
        )}
        {formType === 'statuses' && <StatusesForm parentType={parentType} />}
        {formType === 'taskTypes' && (
          <TaskTypesForm
            value={form.values.types}
            onChange={(types) => form.setFieldValue('types', types)}
          />
        )}
        {formType === 'customFields' && <CustomFieldsForm />}
      </EntityFormBody>
    </EntityFormProvider>
  );
};

import { ApiCommon } from '@/api/common';
import { projectsApi } from '@/api/projects';
import { store } from '@/app/store';
import { IStatusForm } from '@/components/complex/EntityForm';
import { t } from 'i18next';
import { orderBy } from 'lodash-es';

export const getStatuses = async <
  DATA extends {
    entityId: number;
    entityType: ApiCommon.Entity;
  },
>(
  data: DATA,
) => {
  const currentStatusesData = await store
    .dispatch(
      projectsApi.endpoints.getAvailableStatuses.initiate({
        entityType: data.entityType,
        entityIds: [data.entityId],
      }),
    )
    .unwrap();

  return orderBy(
    currentStatusesData.map<IStatusForm>((s) => ({
      color: s.Color,
      id: s.Id,
      name: s.Name,
      type: s.Type,
      state: 'original',
      patternId: s.PatternId,
      order: s.Order,
    })),
    'order',
  );
};

export const getDefaultStatuses = async (): Promise<IStatusForm[]> => {
  const data = await store
    .dispatch(projectsApi.endpoints.getDefaultStatuses.initiate())
    .unwrap();

  return orderBy(
    data.map((s, i) => ({
      id: s.Id,
      patternId: s.Id,
      color: s.Color,
      name: t('taskStatusTypes.' + s.Type),
      type: s.Type,
      state: 'new',
      order: i + 1,
    })),
    'order',
  );
};

import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';

import { ApiCommon } from '@/api/common';
import { showSuccessNotification } from '@/api/helpers/showNotifications';
import { shareApi } from '@/api/share';
import { basename } from '@/app/routes/AppRoute';
import { useDisclosure } from '@mantine/hooks';
import qs from 'qs';

import { openLinkModal } from '../helpers/linkModal';

export const useInvite = (projectId: number) => {
  const { t } = useTranslation();

  const [enabled, { toggle }] = useDisclosure(false);
  const [role, setRole] = useState<ApiCommon.AccessType>(
    ApiCommon.AccessType.Read,
  );

  const { data, isFetching: isGetLoading } = shareApi.useAccessQuery({
    entityId: projectId,
    entityType: ApiCommon.Entity.Project,
  });
  const [generateLink, { isLoading: isGenerateLoading }] =
    shareApi.useGenerateLinkMutation();
  const [deleteLink, { isLoading: isDeleteLoading }] =
    shareApi.useDeleteLinkMutation();

  const isLoading = isGetLoading || isGenerateLoading || isDeleteLoading;
  const link = useMemo(() => {
    const uid = data?.SharedLinks.find((el) => el.AccessType === role)?.Uid;

    return `${window.location.origin}${generatePath(
      `${basename}/invite?${qs.stringify({ uid })}`,
    )}`;
  }, [data?.SharedLinks, role]);

  const deleteLinks = useCallback(async () => {
    if (!data) return;
    await Promise.all(
      data?.SharedLinks.map(({ Uid }) => deleteLink({ uid: Uid }).unwrap()),
    );
  }, [data, deleteLink]);

  const generateLinks = useCallback(async () => {
    await Promise.all(
      [
        ApiCommon.AccessType.Read,
        ApiCommon.AccessType.Write,
        ApiCommon.AccessType.Full,
      ].map((role) => {
        generateLink({
          id: projectId,
          type: ApiCommon.Entity.Project,
          accessType: role,
          lifetime: ApiCommon.ILinkLifeTime.Unlimited,
        });
      }),
    );
  }, [projectId, generateLink]);

  const onToggle = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        generateLinks();
        toggle();
        return;
      }

      openLinkModal({
        title: t('members.links.disableTitle'),
        labels: {
          confirm: t('common.action.disable'),
          cancel: t('common.action.cancel'),
        },
        onConfirm: () => {
          deleteLinks();
          toggle();
        },
      });
    },
    [toggle, deleteLinks, generateLinks, t],
  );
  const onUpdate = useCallback(() => {
    openLinkModal({
      onConfirm: async () => {
        await deleteLinks();
        await generateLinks();
        showSuccessNotification({
          message: t('notification.success.editLinks'),
        });
      },
    });
  }, [deleteLinks, generateLinks, t]);

  useEffect(() => {
    if (data?.SharedLinks.length && !enabled) toggle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return useMemo(
    () => ({ isLoading, link, enabled, role, setRole, onToggle, onUpdate }),
    [isLoading, link, enabled, role, setRole, onToggle, onUpdate],
  );
};

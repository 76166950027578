import { Tooltip } from '@mantine/core';
import { GanttProps } from '@sinups/ds-gantt';
import { IconArrowNarrowLeft, IconArrowNarrowRight } from '@tabler/icons-react';
import { t } from 'i18next';

import { GanttItem } from '../transformTaskData';
import { TaskBar } from './TaskBar';

import styles from '../Timeline.module.css';

export const renderGroupBar: GanttProps<GanttItem>['renderGroupBar'] = (
  barInfo,
  { width, height },
) => {
  const color = barInfo?.record?.color ?? 'var(--mantine-color-blue-5)';

  return (
    <div
      style={{
        width,
        height,
        backgroundColor: color,
      }}
    >
      <svg
        style={{
          position: 'absolute',
          left: 0,
          top: height + 'px',
          transform: 'scaleX(-1)',
        }}
        width={height}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M24 0H0L24 24V0Z" fill={color} />
      </svg>
      <svg
        style={{ position: 'absolute', top: height + 'px', right: 0 }}
        width={height}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M24 0H0L24 24V0Z" fill={color} />
      </svg>
    </div>
  );
};

export const renderBar: GanttProps<GanttItem>['renderBar'] = (
  barInfo,
  { width },
) => {
  return (
    <div
      style={{
        width,
        height: 24,
        transform: 'translateY(-8px)',
        borderRadius: '6px',
        backgroundColor: 'var(--mantine-color-blue-5)',
      }}
    >
      {barInfo.record.type === 'task' && (
        <TaskBar task={barInfo.record._taskData} isSticky={width < 300} />
      )}
    </div>
  );
};

export const renderInvalidBar: GanttProps<GanttItem>['renderInvalidBar'] =
  () => {
    return (
      <Tooltip
        label={t('timelineSettings.invalidBar')}
        opened
        withArrow
        arrowSize={8}
        withinPortal={false}
        zIndex={1}
      >
        <div
          style={{
            width: '100%',
            height: '24px',
            transform: 'translateY(-8px)',
            borderRadius: '6px',
            backgroundColor: 'var(--mantine-color-blue-3)',
            border: '1px solid var(--mantine-color-blue-5)',
          }}
        />
      </Tooltip>
    );
  };

export const renderBarThumb: GanttProps<GanttItem>['renderBarThumb'] = (
  _,
  type,
) => {
  if (type === 'left') {
    return <IconArrowNarrowLeft size={20} className={styles.thumb} />;
  }

  if (type === 'right') {
    return <IconArrowNarrowRight size={20} className={styles.thumb} />;
  }

  return null;
};

import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { ApiProjectTask } from '@/api/tasks';
import { store } from '@/app/store';
import { openRemoveConfirmModal } from '@/components/ui/confirmModals/RemoveConfirmModal';
import { useDeleteAttach } from '@/features/TaskPanel/hooks/useDeleteAttach';
import { downloadURL } from '@/helpers/download';
import { useAccess } from '@/hooks/useAccess';
import { ActionIcon, Badge, Flex, Menu } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import {
  IconDots,
  IconDownload,
  IconList,
  IconPlus,
  IconTrash,
} from '@tabler/icons-react';

import { openDropFilesModal } from './DropFilesModal';

export type ViewType = 'list' | 'strip';

export const Actions = ({
  task,
  viewType,
  onViewTypeChange,
}: {
  task: ApiProjectTask.ITask;
  viewType: ViewType;
  onViewTypeChange: (v: ViewType) => void;
}) => {
  const { t } = useTranslation();
  const access = useAccess();

  const isMobile = useMediaQuery('(max-width: 992px)');

  const { handleDelete } = useDeleteAttach(task.Attachments);

  const handleDownloadAll = useCallback(() => {
    const queriesData = store.getState().attachmentsApi.queries;

    const filesData = task.Attachments?.map((attach) => {
      const fileUrl = queriesData[`get(${JSON.stringify({ id: attach.Id })})`]
        ?.data as string | null;

      return {
        id: attach.Id,
        name: attach.Name,
        fileUrl,
      };
    });

    filesData?.forEach((attach) => {
      if (attach.fileUrl) {
        downloadURL(attach.fileUrl, attach.name || 'file');
      }
    });
  }, [task.Attachments]);

  const handleDeleteAll = useCallback(() => {
    openRemoveConfirmModal({
      title: t('removeFiles.title'),
      children: t('removeFiles.confirm'),
      onConfirm: handleDelete,
    });
  }, [handleDelete, t]);

  return (
    <Flex gap={8}>
      <Menu>
        <Menu.Target>
          <ActionIcon variant="subtle" color="dark">
            <IconDots size={14} />
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown>
          {!isMobile && (
            <>
              {viewType === 'strip' && (
                <Menu.Item
                  onClick={() => onViewTypeChange('list')}
                  leftSection={<IconList />}
                >
                  {t('attachments.chooseList')}
                </Menu.Item>
              )}
              {viewType === 'list' && (
                <Menu.Item
                  onClick={() => onViewTypeChange('strip')}
                  leftSection={<IconList />}
                >
                  {t('attachments.chooseStrip')}
                </Menu.Item>
              )}
            </>
          )}

          {viewType === 'strip' && (
            <Menu.Item
              onClick={handleDownloadAll}
              leftSection={<IconDownload />}
              rightSection={
                <Badge variant="light" color="blue" px={6}>
                  {task.Attachments?.length}
                </Badge>
              }
            >
              {t('common.action.downloadAll')}
            </Menu.Item>
          )}
          {access.tasks.edit && (
            <Menu.Item
              leftSection={<IconTrash />}
              color="red"
              onClick={handleDeleteAll}
            >
              {t('common.action.deleteAll')}
            </Menu.Item>
          )}
        </Menu.Dropdown>
      </Menu>
      {access.tasks.edit && (
        <ActionIcon
          variant="subtle"
          color="dark"
          onClick={() => openDropFilesModal(task)}
        >
          <IconPlus size={14} />
        </ActionIcon>
      )}
    </Flex>
  );
};

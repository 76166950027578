import { Text } from '@mantine/core';
import { modals } from '@mantine/modals';
import { OpenConfirmModal } from '@mantine/modals/lib/context';
import { t } from 'i18next';

export const openLinkModal = (props?: OpenConfirmModal) =>
  modals.openConfirmModal({
    keepMounted: false,
    centered: true,
    withCloseButton: false,
    labels: {
      confirm: t('common.action.update'),
      cancel: t('common.action.cancel'),
    },
    cancelProps: { variant: 'subtle' },
    groupProps: { wrap: 'nowrap' },
    title: t('members.links.updateTitle'),
    children: (
      <Text size="sm" c="gray.6">
        {t('members.links.description')}
      </Text>
    ),
    ...props,
  });

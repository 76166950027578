import { useTranslation } from 'react-i18next';

import { GanttLocale } from '@sinups/ds-gantt/dist/types/Gantt';
import { isObject } from 'lodash-es';

export const useGanttLocale = () => {
  const { t } = useTranslation();

  const locale = t('gantt', { returnObjects: true });

  return isObject(locale) ? (locale as GanttLocale) : undefined;
};

import { ComplexProjectData, projectsApi } from '@/api/projects';

export const useSprintStatuses = (projectData: ComplexProjectData) => {
  return projectsApi.useGetAvailableStatusesQuery(
    {
      entityType: projectData?.statusesEntityType || 0,
      entityIds: [projectData?.statusesEntityId || 0],
    },
    { skip: !projectData },
  );
};

import { customBaseQuery } from '@/api/customBaseQuery';
import { RootState } from '@/app/store';
import { createApi } from '@reduxjs/toolkit/query/react';
import { t } from 'i18next';
import { difference, remove, uniq, without } from 'lodash-es';
import queryString from 'query-string';

import { ApiCommon } from '../common';
import {
  showErrorNotification,
  showSuccessNotification,
} from '../helpers/showNotifications';
import { ApiProjects, ComplexProjectData, projectsApi } from '../projects';
import { ApiShare } from './types';

export const shareApi = createApi({
  reducerPath: 'shareApi',
  baseQuery: customBaseQuery('/api'),
  tagTypes: ['Access'],
  endpoints: (builder) => ({
    get: builder.query<ApiShare.IGetResponse, ApiShare.IGetRequest>({
      query: (payload) => ({
        url: `/v1/Share?${queryString.stringify(payload)}`,
        method: 'GET',
      }),
      providesTags: (res, _, args) => [
        ...(res?.UserAccessRights.map((el) => ({
          type: 'Access' as const,
          id: el.PrincipalId,
        })) || []),
        { type: 'Access' as const, id: `entity_${args.entityId}` },
      ],
    }),
    remove: builder.mutation<void, ApiShare.IDeleteRequest>({
      query: (payload) => ({
        url: `/v1/Share/Delete?${queryString.stringify(payload)}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, __, args) => [{ type: 'Access', id: args.userId }],
      async onQueryStarted(
        { entityId, userId },
        { dispatch, queryFulfilled, getState },
      ) {
        const state = getState() as RootState;

        const patchResult = dispatch(
          shareApi.util.updateQueryData(
            'get',
            shareApi.util.selectCachedArgsForQuery(state, 'get').at(-1)!,
            (draft) => {
              remove(
                draft.UserAccessRights,
                (right) =>
                  right.EntityId === entityId && right.PrincipalId === userId,
              );
            },
          ),
        );
        const projectsPatchResult = dispatch(
          projectsApi.util.updateQueryData(
            'getProjects',
            undefined,
            (draft) => {
              const project = draft.folderEntities[entityId];
              const result = without(project.UserIds, userId);
              fillUserIds(project, result);
            },
          ),
        );

        try {
          await queryFulfilled;
          showSuccessNotification({
            message: t('notification.success.deleteUser'),
          });
        } catch (err) {
          showErrorNotification({
            message: t('notification.error.deleteUser'),
          });
          patchResult.undo();
          projectsPatchResult.undo();
        }
      },
    }),
    give: builder.mutation<ApiShare.IGiveResponse, ApiShare.IGiveRequest>({
      query: (payload) => ({
        url: `/v1/Share?${queryString.stringify(payload)}`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (res) => {
        return (
          res?.Ids.map((id) => ({ type: 'Access', id: `entity_${id}` })) || []
        );
      },
      async onQueryStarted(
        { userIds, type, ids },
        { dispatch, queryFulfilled, getState },
      ) {
        const state = getState() as RootState;
        const projectId = ids[0];
        let isNew = false;

        const patchResult = dispatch(
          shareApi.util.updateQueryData(
            'get',
            shareApi.util.selectCachedArgsForQuery(state, 'get').at(-1)!,
            (draft) => {
              userIds.forEach((id) => {
                const right = draft.UserAccessRights.find(
                  (right) => right.PrincipalId === id,
                );

                if (right) {
                  right.Type = type;
                } else {
                  isNew = true;
                  draft.UserAccessRights.push({
                    EntityId: projectId,
                    EntityType: ApiCommon.Entity.Project,
                    Kind: 1,
                    OwnerId: state.usersCache.currentUser.Id,
                    PrincipalId: id,
                    Type: type,
                  });
                }
              });
            },
          ),
        );
        const projectsPatchResult = dispatch(
          projectsApi.util.updateQueryData(
            'getProjects',
            undefined,
            (draft) => {
              const project = draft.folderEntities[projectId];
              const result = uniq([...project.UserIds, ...userIds]);
              if (difference(result, project.UserIds).length) {
                fillUserIds(project, result);
              }
            },
          ),
        );

        try {
          await queryFulfilled;

          const message = isNew
            ? t('notification.success.addUser', { count: userIds.length })
            : t('notification.success.editAccess');

          showSuccessNotification({
            message,
          });
        } catch (err) {
          showErrorNotification({
            message: t('notification.error.editAccess'),
          });
          patchResult.undo();
          projectsPatchResult.undo();
        }
      },
    }),
    access: builder.query<ApiShare.IAccessResponse, ApiShare.IAccessRequest>({
      query: (payload) => ({
        url: `/v1/Share/Access?${queryString.stringify(payload)}`,
        method: 'GET',
      }),
      providesTags: (res) => [
        'Access',
        ...(res?.SharedLinks.map((el) => ({
          type: 'Access' as const,
          id: `uid_${el.Uid}`,
        })) ?? []),
      ],
    }),
    leave: builder.mutation<ApiShare.IAccessResponse, ApiShare.IAccessRequest>({
      query: (payload) => ({
        url: `/v1/Share/UnShare?${queryString.stringify(payload)}`,
        method: 'PUT',
      }),
    }),

    generateLink: builder.mutation<
      ApiShare.IGenerateResponse,
      ApiShare.IGenerateRequest
    >({
      query: (payload) => ({
        url: `/v1/link/generate?${queryString.stringify(payload)}`,
        method: 'GET',
      }),
      invalidatesTags: () => ['Access'],
    }),
    linkInfo: builder.query<ApiShare.IInfoResponse, { uid: string }>({
      query: (payload) => ({
        url: `/v1/link/info?${queryString.stringify(payload)}`,
        method: 'GET',
      }),
    }),
    deleteLink: builder.mutation<void, { uid: string }>({
      query: (payload) => ({
        url: `/v1/link?${queryString.stringify(payload)}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, __, args) => [
        { type: 'Access', id: `uid_${args.uid}` },
      ],
    }),
    applyLink: builder.mutation<ApiProjects.IProject, { uid: string }>({
      query: (payload) => ({
        url: `/v1/link?${queryString.stringify(payload)}`,
        method: 'GET',
      }),
    }),
  }),
});

const fillUserIds = (project: ComplexProjectData, ids: number[]) => {
  project.UserIds = ids;
  project.Children.map((el) => fillUserIds(el, ids));
};

import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { ProjectIcon } from '@/components/ui/icons/ProjectIcon';
import { ActionIcon, Flex, Text, Tooltip } from '@mantine/core';
import { IconPencil } from '@tabler/icons-react';

import { ITypeForm } from '../../types';
import { DeleteAction } from '../DeleteAction';

import styles from './TypeRow.module.css';

interface IStatusRowProps {
  type: ITypeForm;
  onEdit?: () => void;
  onRemove?: () => void;
  removable?: boolean;
}

export const TypeRow = memo(
  ({ type, onRemove, removable, onEdit }: IStatusRowProps) => {
    const { t } = useTranslation();

    return (
      <div className={styles.root}>
        <ProjectIcon
          color={type.color}
          name={type.name}
          icon={type.icon}
          radius={4}
          size={20}
        />
        <Text w={'100%'} size={'sm'} truncate="end">
          {type.name}
        </Text>
        <Flex gap={0}>
          <Tooltip label={t('common.action.change')}>
            <ActionIcon
              size="md"
              variant="subtle"
              color="gray.8"
              onClick={onEdit}
            >
              <IconPencil size={18} />
            </ActionIcon>
          </Tooltip>
          <DeleteAction disabled={!removable} onClick={onRemove} />
        </Flex>
      </div>
    );
  },
);

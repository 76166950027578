import React from 'react';
import { useTranslation } from 'react-i18next';

import { ActionIcon, Loader, TextInput, TextInputProps } from '@mantine/core';
import { IconSearch, IconX } from '@tabler/icons-react';

import styles from './TasksSearchInput.module.css';

interface TasksSearchInputProps extends Omit<TextInputProps, 'onChange'> {
  onChange: (value: string) => void;
  loading?: boolean;
}

export const TasksSearchInput: React.FC<TasksSearchInputProps> = ({
  value,
  onChange,
  loading,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <TextInput
      placeholder={t('common.action.searchTask')}
      variant="filled"
      leftSection={
        value && loading ? <Loader size={16} /> : <IconSearch size={16} />
      }
      className={styles.root}
      value={value}
      onChange={(event) => onChange(event.currentTarget.value)}
      rightSection={
        value && (
          <ActionIcon
            variant="subtle"
            color="gray"
            radius="md"
            aria-label="Clear"
            onClick={() => onChange('')}
          >
            <IconX size={16} color="gray" />
          </ActionIcon>
        )
      }
      {...rest}
    />
  );
};

import { Avatar, NavLink, Text } from '@mantine/core';
import { IconListDetails } from '@tabler/icons-react';

import { SprintType } from '../types';

export const SprintItem = ({
  sprint,
  onClick,
  currentSprint,
}: {
  sprint: SprintType;
  onClick: (s: SprintType) => void;
  currentSprint?: number | null;
}) => {
  return (
    <NavLink
      active={currentSprint === sprint.Id}
      onClick={() => onClick(sprint)}
      styles={{
        root: {
          borderRadius: '4px',
        },
      }}
      label={<Text truncate="end">{sprint.Name}</Text>}
      leftSection={
        <Avatar
          size={20}
          radius={4}
          variant={'transparent'}
          color={sprint.Color || 'gray.7'}
        >
          <IconListDetails size={16} stroke={1.5} />
        </Avatar>
      }
    />
  );
};

import { SyntheticEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { attachmentsApi } from '@/api/attachments/slice';
import { ApiAttachments } from '@/api/attachments/types';
import { showRequestNotifications } from '@/api/helpers/showNotifications';
import { projectTasksApi } from '@/api/tasks';
import { store } from '@/app/store';
import { map } from 'lodash-es';

export const useDeleteAttach = (
  attachments?: ApiAttachments.IAttachment[] | null,
) => {
  const { t } = useTranslation();

  const [deleteAttach, data] = attachmentsApi.useDeleteAttachMutation();

  const handleDelete = useCallback(
    (e?: SyntheticEvent) => {
      e?.stopPropagation();

      showRequestNotifications(deleteAttach(map(attachments, 'Id')).unwrap(), {
        successMsg: t('notification.success.deleteFiles'),
        errorMsg: t('notification.error.deleteFiles'),
      }).then(() => {
        store.dispatch(
          projectTasksApi.util.invalidateTags([
            {
              type: 'Task',
              id: attachments?.at(0)?.EntityId,
            },
          ]),
        );
      });
    },
    [attachments, deleteAttach, t],
  );

  return { handleDelete, ...data };
};

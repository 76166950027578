import { useTranslation } from 'react-i18next';
import { useMatch } from 'react-router-dom';

import { settingsApi } from '@/api/settings';
import { store, useAppSelector } from '@/app/store';
import logoSrc from '@/assets/svg/logo.svg';
import whiteLogoSrc from '@/assets/svg/whiteLogo.svg';
import { AppMenu } from '@/components/ui/AppMenu';
import { Ellipsis } from '@/components/ui/Ellipsis';
import { UserMenu } from '@/components/ui/UserMenu';
import {
  ProjectIcon,
  ProjectIconType,
} from '@/components/ui/icons/ProjectIcon';
import { AppRoutes } from '@/config/links';
import { SprintViewSelect } from '@/features/SprintViewSelect';
import { GlobalTaskSearch } from '@/features/TasksSearch/TasksSearch';
import { useOpenedFolderData } from '@/hooks/useOpenedFolderData';
import { useOpenedSprintData } from '@/hooks/useOpenedSprintData';
import {
  ActionIcon,
  Avatar,
  Burger,
  Flex,
  Group,
  Image,
  Tooltip,
  useComputedColorScheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import {
  IconBell,
  IconChevronsRight,
  IconListDetails,
  IconProgressCheck,
} from '@tabler/icons-react';

import { layoutSlice } from '../AppLayout/slice';

const toggle = () => store.dispatch(layoutSlice.actions.toggleSidebar());

export const Header = () => {
  const { t } = useTranslation();

  const opened = useAppSelector((s) => s.layoutSlice.sidebarOpened);
  const isLightTheme =
    useComputedColorScheme('light', {
      getInitialValueInEffect: true,
    }) === 'light';
  const disabled = useAppSelector((s) => s.layoutSlice.withoutSidebar);
  const { data } = settingsApi.endpoints.get.useQueryState();

  const openedProject = useOpenedFolderData();
  const openedSprint = useOpenedSprintData();

  const isTablet = useMediaQuery(`(max-width: 992px)`);

  const isMyTasks = !!useMatch(AppRoutes.myIssues);

  return (
    <Flex h="100%" px="md" gap={16}>
      {!opened && !disabled && (
        <ActionIcon
          variant="subtle"
          color="dark.1"
          visibleFrom="lg"
          onClick={toggle}
        >
          <IconChevronsRight size={16} />
        </ActionIcon>
      )}
      {disabled ? (
        <Image src={isLightTheme ? logoSrc : whiteLogoSrc} h={'30'} w={160} />
      ) : (
        <Burger
          opened={opened}
          onClick={toggle}
          hiddenFrom="lg"
          size="sm"
          color="gray.6"
        />
      )}
      <Flex
        gap={16}
        align="center"
        flex={1}
        style={{ overflowX: 'hidden', height: '100%' }}
      >
        {isMyTasks && (
          <Flex>
            <IconProgressCheck stroke={1.2} /> {t('common.myTasks')}
          </Flex>
        )}
        {openedProject && (
          <Flex align="center" gap={12} style={{ overflow: 'hidden' }}>
            <ProjectIcon
              size={24}
              name={openedProject.Name}
              icon={
                openedProject.isFolder
                  ? ProjectIconType.folder
                  : openedProject.IconId
              }
              color={openedProject.Color}
            />
            <Ellipsis>{openedProject.Name}</Ellipsis>
          </Flex>
        )}

        {openedSprint && (
          <Flex align="center" gap={12} style={{ overflow: 'hidden' }}>
            <Avatar
              variant="transparent"
              radius="sm"
              size={24}
              color={openedSprint.Color || 'gray.5'}
            >
              <IconListDetails size={18} />
            </Avatar>
            <Ellipsis>{openedSprint.Name}</Ellipsis>
          </Flex>
        )}

        {openedSprint && (
          <SprintViewSelect visibleFrom="sm" sprintId={openedSprint.Id} />
        )}
      </Flex>
      <Flex gap={8}>
        <Group gap={isTablet ? 0 : 8}>
          <GlobalTaskSearch />
          <Tooltip label={t('common.inDev')}>
            <ActionIcon
              variant="subtle"
              color="gray.6"
              aria-label="Notification"
              size={'lg'}
            >
              <IconBell size={22} />
            </ActionIcon>
          </Tooltip>
          <AppMenu data={data} />
        </Group>
        <UserMenu />
      </Flex>
    </Flex>
  );
};

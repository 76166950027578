import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { usersApi } from '@/api/users';
import { useFolderData } from '@/hooks/useFolderData';
import { Combobox, Flex, FocusTrap, Loader } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { IconSearch } from '@tabler/icons-react';

import { UserOption } from '../../components/ui/UserOption';
import { PerformerOption, PerformerSelectProps } from './types';

export const PerformerSelectDropdown = <OPTION extends PerformerOption>({
  value,
  onChange,
  projectId,
}: Pick<PerformerSelectProps<OPTION>, 'onChange' | 'value' | 'projectId'>) => {
  const { t } = useTranslation();

  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebouncedValue(query, 200);

  const project = useFolderData(projectId);

  const { data, isLoading } = usersApi.useGetByIdQuery(
    {
      ids: project?.UserIds || [],
    },
    { skip: !project?.UserIds.length },
  );

  const users = useMemo(() => {
    return (
      data?.filter((u) =>
        u.Name.toLowerCase().includes(debouncedQuery.trim().toLowerCase()),
      ) || []
    );
  }, [data, debouncedQuery]);

  const options = useMemo(() => {
    if (!users.length)
      return <Combobox.Empty>{t('nodata.noResults.subtitle')}</Combobox.Empty>;

    const searchOptions = users.map((user) => (
      <Combobox.Option
        value={user.Id.toString()}
        key={user.Id}
        onClick={() => onChange?.(user as unknown as OPTION)}
        style={
          user.Id === value?.Id
            ? {
                background:
                  'light-dark(var(--mantine-color-gray-3), var(--mantine-color-gray-7))',
              }
            : undefined
        }
      >
        <UserOption user={user} /*active={user.Id === value?.Id}*/ />
      </Combobox.Option>
    ));

    if (!debouncedQuery && value) {
      const unassignedOpt = (
        <Combobox.Option
          key={'unassigned'}
          value="unassigned"
          onClick={() => onChange?.(null)}
        >
          <UserOption />
        </Combobox.Option>
      );

      return [unassignedOpt, ...searchOptions];
    }

    return searchOptions;
  }, [debouncedQuery, users, value, onChange, t]);

  return (
    <>
      <FocusTrap active>
        <Combobox.Search
          leftSection={
            <Flex pr={6}>
              <IconSearch size={16} />
            </Flex>
          }
          placeholder={t('createTask.fields.performer.search')}
          value={query}
          onChange={(event) => setQuery(event.currentTarget.value)}
          rightSection={isLoading && <Loader size={18} />}
        />
      </FocusTrap>
      <Combobox.Options mah={250} style={{ overflow: 'auto' }}>
        {options}
      </Combobox.Options>
    </>
  );
};

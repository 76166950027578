import { ApiProjectTask } from '@/api/tasks';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface DiffStatusesModalState {
  opened: boolean;
  task?: ApiProjectTask.ITask;
  sprintId?: number;
}

const initialState: DiffStatusesModalState = {
  opened: false,
};

export const diffStatusesModalSlice = createSlice({
  name: 'diffStatusesModal',
  initialState,
  reducers: {
    close(state) {
      state.opened = false;
    },
    open(
      state,
      action: PayloadAction<Omit<Required<DiffStatusesModalState>, 'opened'>>,
    ) {
      state.opened = true;
      state.task = action.payload.task;
      state.sprintId = action.payload.sprintId;
    },
  },
});

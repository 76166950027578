import { ProjectIconType } from '@/components/ui/icons/ProjectIcon';

import { ApiCommon } from '../common';
import { ApiTaskType } from '../taskTypes';

type TaskStatusPayload = {
  Color: string | null;
  Name: string;
  Type: ApiCommon.ITastStatusType;
  PatternId: number | null;
}[];

type TaskTypePayload = {
  Name: string;
  Color: string | null;
  Type: number;
  IconId: ProjectIconType | null;
}[];

export namespace ApiProjects {
  export type IProject = {
    Id: number;
    Name: string;
    Description: string | null;
    ImageBase64: null;
    Order: number;
    Color: null | string;
    ImageId: number | null;
    Key: string;
    CustomerId: number;
    Type: number;
    OwnerId: number;
    ParentId: number | null;
    TemplateId: null;
    Status: number;
    Timestamp: string;
    Children: IProject[] | null;
    Sprints: ApiSprints.ISprint[] | null;
    UserIds: number[];
    TaskStatuses: ApiCommon.ITaskStatus[] | null;
    TaskTypes: ApiTaskType.IType[] | null;
    IconId: ProjectIconType | null;
    Flags: ApiCommon.ProjectFlags;
    AccessType: ApiCommon.AccessType;
  };

  export type IGetResponse = IProject[];

  export type ICreateRequest = {
    Name: string;
    Color?: string | null;
    ImageId?: number | null;
    IconId: number | null;
    CustomerId?: number | null;
    Type?: number | null;
    ParentId?: number | null;
    TemplateId?: number | null;
    Status?: number | null;
    Description?: string | null;
    Flags?: ApiCommon.ProjectFlags;
    TaskStatuses?: TaskStatusPayload | null;
    TaskTypes: TaskTypePayload | null;
  };

  export type ICreateResponse = IProject;

  export type IUpdateRequest = Omit<
    ICreateRequest,
    'TaskStatuses' | 'TaskTypes'
  > & { Id: number };

  export type IUpdateResponse = IProject;

  export type IGetAvailableStatusesRequest = {
    entityIds: number[];
    entityType: ApiCommon.Entity;
    onlyForCurrent?: boolean;
  };

  export type IGetAvailableStatusesResponse = ApiCommon.ITaskStatus[];

  export type ISaveTaskStatusRequest = (
    | ApiCommon.ITaskStatus
    | Omit<ApiCommon.ITaskStatus, 'Id'>
  )[];

  export type IRemoveTaskStatusRequest = number[];

  export type IGetDefaultStatusesRequest = void;
  export type IGetDefaultStatusesResponse = ApiCommon.ITaskStatus[];
}

export namespace ApiSprints {
  export type ISprint = {
    Id: number;
    Name: string;
    Description: string | null;
    AuthorId: number;
    ProjectId: number;
    StartDate: null;
    EndDate: null;
    Status: number;
    Type: number;
    Color: string | null;
    Flags: ApiCommon.ProjectFlags;
    TaskStatuses: null;
  };

  export type ICreateRequest = {
    Name: string;
    Color: string | null;
    ProjectId: number;
    Flags?: ApiCommon.ProjectFlags;
    TaskStatuses?: TaskStatusPayload | null;
  };

  export type ICreateResponse = ISprint;

  export type IUpdateRequest = Omit<ICreateRequest, 'TaskStatuses'> & {
    id: number;
  };

  export type IUpdateResponse = ISprint;
}

export namespace ApiProjectAttributes {
  export enum IValueType {
    Int = 1,
    Long = 2,
    String = 3,
    Double = 4,
    Date = 5,
    Array = 6,
    Link = 7,
  }
  export interface IAttribute {
    Id: number;
    AttributeId: number;
    ProjectId: number;
    Name: string;
    ValueType: IValueType;
    Values?: unknown;
  }

  export interface IGetRequest {
    projectId: number;
  }
  export type IGetResponse = IAttribute[];

  export type IPostRequest = Pick<
    IAttribute,
    'ProjectId' | 'Name' | 'ValueType' | 'Values'
  >;
  export type IPostResponse = IAttribute;
}

import { customBaseQuery } from '@/api/customBaseQuery';
import { createApi } from '@reduxjs/toolkit/query/react';

import { ApiSettings } from './types';

export const settingsApi = createApi({
  reducerPath: 'settingsApi',
  baseQuery: customBaseQuery('/api'),
  endpoints: (builder) => ({
    get: builder.query<ApiSettings.IResponse, void>({
      query: () => ({ url: `/v1/settings`, method: 'GET' }),
    }),
  }),
});

import { ReactNode, memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ComplexSprintData } from '@/api/projects';
import { ApiProjectTask } from '@/api/tasks';
import { KanbanLoader } from '@/components/shared/SkeletonLoaders/KanbanLoader';
import { ErrorAlert } from '@/components/ui/ErrorAlert';
import { useAccess } from '@/hooks/useAccess';
import { useStatusesData } from '@/hooks/useStatusesData';
import { useTaskTypesData } from '@/hooks/useTaskTypesData';
import {
  DragDropContext,
  Draggable,
  Droppable,
  OnDragEndResponder,
} from '@hello-pangea/dnd';

import { useDnd } from '../hooks/useDnd';
import { CreateStatus } from './components/CreateStatus';
import { CreateTask } from './components/CreateTask';
import { TaskCard } from './components/TaskCard/TaskCard';
import { TasksColumn } from './components/TasksColumn/TasksColumn';

import styles from './Kanban.module.css';
import columnStyles from './components/TasksColumn/TasksColumn.module.css';

type KanbanProps = {
  sprint: ComplexSprintData;
  tasks: ApiProjectTask.ITask[];
  emptyState?: ReactNode;
  isLoading?: boolean;
  isError?: boolean;
};

export const Kanban = memo(
  ({ sprint, tasks, isError, isLoading, emptyState = null }: KanbanProps) => {
    const { t } = useTranslation();
    const access = useAccess();

    const [isDragging, setIsDragging] = useState(false);

    const { statuses } = useStatusesData(sprint);
    const { taskTypes } = useTaskTypesData([sprint.ProjectId]);

    const { groupedData, onDragEnd } = useDnd({
      tasks,
      statuses,
      includeAllStatuses: true,
    });

    const handleDragStart = useCallback(() => {
      setIsDragging(true);
    }, []);

    const handleDrag = useCallback<OnDragEndResponder>(
      (result, provided) => {
        setIsDragging(false);

        onDragEnd(result, provided);
      },
      [onDragEnd],
    );

    if (isError) {
      return <ErrorAlert message={t('notification.error.getSprint')} />;
    }

    if (isLoading) return <KanbanLoader />;

    if (tasks?.length) {
      return (
        <div
          className={`${styles.root} ${isDragging ? styles.dragging : ''}`}
          style={{
            gridTemplateColumns: `repeat(${groupedData.length}, minmax(300px, 420px)) ${access.sprints.edit ? '300px' : ''}`,
          }}
        >
          <DragDropContext onDragStart={handleDragStart} onDragEnd={handleDrag}>
            {groupedData.map(({ status, tasks }) => (
              <Droppable key={status.Id} droppableId={status.Id.toString()}>
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    <TasksColumn
                      count={tasks.length}
                      status={status}
                      sprint={sprint}
                      order={getOrder(tasks)}
                    >
                      {tasks.map((t, index) => (
                        <Draggable
                          key={t.Id}
                          draggableId={t.Id.toString()}
                          index={index}
                          isDragDisabled={!access.tasks.edit}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <TaskCard
                                key={t.Id}
                                statuses={statuses}
                                task={t}
                                types={taskTypes}
                                my={3}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                      {access.tasks.create && (
                        <CreateTask
                          className={styles.createTaskKanban}
                          sprint={sprint}
                          statusId={status.Id}
                          order={getOrder(tasks, 'last')}
                          my={3}
                        />
                      )}
                    </TasksColumn>
                  </div>
                )}
              </Droppable>
            ))}
          </DragDropContext>

          {access.sprints.edit && (
            <div>
              <div className={columnStyles.root}>
                <CreateStatus sprint={sprint} my={3} />
              </div>
            </div>
          )}
        </div>
      );
    }

    return <>{emptyState}</>;
  },
);

const getOrder = (
  list: { Order: number }[],
  position: 'first' | 'last' = 'first',
) => {
  const item = list.at(position === 'first' ? 0 : -1);

  if (!item) return undefined;

  return item.Order + (position === 'first' ? -1 : 1);
};

import { useTranslation } from 'react-i18next';

import { showRequestNotifications } from '@/api/helpers/showNotifications';
import { ComplexSprintData } from '@/api/projects';
import { ApiProjectTask, projectTasksApi } from '@/api/tasks';
import { AppRoutes } from '@/config/links';
import { PerformerOption } from '@/features/PerformerSelect/types';
import { openTaskModal } from '@/features/TaskModal/store';
import { Button, CopyButton, Group, Stack } from '@mantine/core';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';

type UseCreateTaskProps = {
  defaultValues?: Partial<CreateTaskFormData>;
  afterCreate?: (res: ApiProjectTask.ITask) => void;
};

export type CreateTaskFormData = Pick<
  ApiProjectTask.ITask,
  'Title' | 'Priority' | 'Status' | 'TypeId'
> & {
  sprint: ComplexSprintData | null;
  performer: PerformerOption | null;
  Order?: number;
};

export const emptyNumberValue = -1;

const initValues: CreateTaskFormData = {
  Title: '',
  TypeId: emptyNumberValue,
  Priority: 3,
  Status: emptyNumberValue,
  sprint: null,
  performer: null,
};

const mergeTaskFormValues = (values?: Partial<CreateTaskFormData>) => {
  return {
    Title: values?.Title || initValues.Title,
    TypeId: values?.TypeId || initValues.TypeId,
    Priority: values?.Priority || initValues.Priority,
    Status: values?.Status || initValues.Status,
    Order: values?.Order,

    sprint: values?.sprint || initValues.sprint,
    performer: values?.performer || initValues.performer,
  };
};

export const useCreateTask = ({
  defaultValues,
  afterCreate,
}: UseCreateTaskProps) => {
  const { t } = useTranslation();

  const [createTask, { isLoading }] = projectTasksApi.useCreateTaskMutation();

  const form = useForm<CreateTaskFormData>({
    initialValues: mergeTaskFormValues(defaultValues),
    validate: {
      Title: (v) => (v.trim().length > 0 ? null : t('createTask.error.title')),
      TypeId: (v) =>
        v !== emptyNumberValue ? null : t('createTask.error.type'),
      sprint: (v) => (v !== null ? null : t('createTask.error.sprint')),
      Status: (v) =>
        v !== emptyNumberValue ? null : t('createTask.error.status'),
    },
  });

  const handleAdd = async (values: typeof form.values) => {
    if (!values.sprint) return;

    const payload: ApiProjectTask.ICreateRequest = {
      ProjectId: values.sprint.ProjectId,
      SprintId: values.sprint.Id,
      Title: values.Title,
      TypeId: values.TypeId,
      Status: values.Status,
      Priority: values.Priority,
      PerformerId: values.performer?.Id,
      Order: values.Order,
    };

    const notifyId = 'newTaskNotify' + new Date().valueOf();

    showRequestNotifications<ApiProjectTask.ITask>(
      createTask({ ...payload, ProjectId: values.sprint.ProjectId }).unwrap(),
      {
        notifyId,
        successMsg: (res) => ({
          message: (
            <Stack gap={4}>
              {t('notification.success.createTask')}
              <Group gap={8} mt={4}>
                <CopyButton
                  value={AppRoutes.getTaskLink({
                    sprintId: res.SprintId,
                    taskId: res.Id,
                  })}
                >
                  {({ copied, copy }) => (
                    <Button
                      px={7}
                      fw={'400'}
                      onClick={copy}
                      size="xs"
                      variant="light"
                      color="blue.6"
                    >
                      {t(`copyButton.${copied ? 'copied' : 'copy'}`)}
                    </Button>
                  )}
                </CopyButton>
                <Button
                  px={7}
                  fw={'400'}
                  size="xs"
                  variant="light"
                  color="blue.6"
                  onClick={() => {
                    openTaskModal(res.Id);
                    notifications.hide(notifyId);
                  }}
                >
                  {t('createTask.action.open')}
                </Button>
              </Group>
            </Stack>
          ),
        }),
        errorMsg: t('notification.error.createTask'),
      },
    ).then((res) => afterCreate?.(res));
  };

  return {
    form,
    handleAdd,
    isLoading,
  };
};

import { ProjectIconType } from '@/components/ui/icons/ProjectIcon';

export namespace ApiTaskType {
  export interface IType {
    Id: number;
    Name: string;
    ProjectId: number;
    Color: string | null;
    Type: TaskType;
    IconId: ProjectIconType | null;
  }

  export enum TaskType {
    Custom = 0,
    Epic = 1,
    LeadTask = 2,
    Task = 3,
    Bug = 4,
    SubTask = 5,
    Milestone = 6,
    Improvement = 7,
    Innovation = 8,
  }

  type ProjectId = number;

  export type IGetRequest = ProjectId[];
  export type IGetResponse = { ProjectId: number; Types: IType[] }[];

  export type ISaveRequest = (IType | Omit<IType, 'Id'>)[];
  export type ISaveResponse = IType[];

  export type IRemoveRequest = number[];
}

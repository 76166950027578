import { customBaseQuery } from '@/api/customBaseQuery';
import { getCookieToken } from '@/api/helpers/getCookieToken';
import { RootState } from '@/app/store';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { createApi } from '@reduxjs/toolkit/query/react';
import axios, { isAxiosError } from 'axios';

import { ApiUser, ApiUsersGet, ApiUsersSearch } from './types';

export type GetUsersByIdPayload = {
  force?: boolean;
  ids: number[];
};

interface UsersCacheState {
  currentUser: ApiUser.IUser;
  entities: Record<number, ApiUsersGet.IUser | null>;
}

const initialState: UsersCacheState = {
  currentUser: null as unknown as ApiUser.IUser,
  entities: {},
};

export const usersCacheSlice = createSlice({
  name: 'usersCache',
  initialState,
  reducers: {
    addUsers: (state, action: PayloadAction<ApiUsersGet.IResponse>) => {
      action.payload.forEach((user) => {
        state.entities[user.Id] = user;
      });
    },
    setCurrentUser: (state, action: PayloadAction<ApiUser.IUser>) => {
      state.currentUser = action.payload;
    },
  },
});

export type UserSearchResponse = {
  currentData: ApiUsersSearch.IUser[];
  data: ApiUsersSearch.IUser[];
  hasMore: boolean;
};

export const usersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: customBaseQuery('/api'),
  tagTypes: ['Users'],
  endpoints: (builder) => ({
    search: builder.query<
      UserSearchResponse,
      ApiUsersSearch.IRequest & { prevData?: ApiUsersSearch.IUser[] }
    >({
      query: (body: ApiUsersSearch.IRequest) => ({
        url: `/v1/user/search`,
        method: 'POST',
        body: {
          query: body.query,
          size: body.size,
          offset: body.offset,
        },
      }),
      transformResponse: (res: ApiUsersSearch.IUser[], _, args) => {
        return {
          currentData: res,
          data: [...(args.prevData || []), ...res],
          hasMore: res.length >= (args.size || 0),
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(
            usersCacheSlice.actions.addUsers(
              data.currentData.map((user) => ({
                Avatar: user.Avatar,
                ContactId: user.ContactId,
                Email: user.Email,
                FirstName: user.FirstName,
                Id: user.Id,
                Jid: user.Jid,
                Name: user.Name,
                Surname: user.Surname,
                Type: user.Type,
              })),
            ),
          );
        } catch (err) {
          console.log(err);
        }
      },
    }),
    getCurrentUser: builder.query<ApiUser.IUser, void>({
      query: () => ({
        url: `/v1/user`,
        method: 'GET',
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(usersCacheSlice.actions.setCurrentUser(data));

          const cacheData: ApiUsersGet.IUser = {
            Avatar: data.Avatar,
            ContactId: data.ContactId,
            Email: data.Email,
            FirstName: data.FirstName,
            Id: data.Id,
            Jid: data.Jid,
            Name: data.Name,
            Surname: data.Surname,
            Type: data.Type,
          };

          dispatch(usersCacheSlice.actions.addUsers([cacheData]));
        } catch (err) {
          console.log(err);
        }
      },
    }),
    getById: builder.query<ApiUsersGet.IResponse, GetUsersByIdPayload>({
      queryFn: async ({ ids, force }, api) => {
        if (force) {
          try {
            const res = await axios.post<ApiUsersGet.IResponse>(
              '/api/v1/user/get',
              { ids },
              {
                headers: {
                  Authorization: getCookieToken(),
                },
              },
            );
            api.dispatch(usersCacheSlice.actions.addUsers(res.data));

            return { data: res.data };
          } catch (err) {
            if (isAxiosError(err)) {
              return {
                error: {
                  status: err.response?.status ?? 500,
                  statusText: err.response?.statusText,
                  data: err.response?.data,
                },
              };
            } else {
              return { error: { status: 500, statusText: '', data: '' } };
            }
          }
        }

        const state = api.getState() as RootState;
        const loaded = state.usersCache.entities;

        const [cached, notCached] = ids.reduce<[ApiUsersGet.IUser[], number[]]>(
          (acc, id) => {
            if (loaded[id]) {
              acc[0].push(loaded[id] as ApiUsersGet.IUser);
            } else {
              acc[1].push(id);
            }

            return acc;
          },
          [[], []],
        );

        if (notCached.length === 0) {
          return { data: cached };
        }

        try {
          const res = await axios.post<ApiUsersGet.IResponse>(
            '/api/v1/user/get',
            { ids: notCached },
            {
              headers: {
                Authorization: getCookieToken(),
              },
            },
          );
          api.dispatch(usersCacheSlice.actions.addUsers(res.data));

          return { data: [...cached, ...res.data] };
        } catch (err) {
          if (isAxiosError(err)) {
            return {
              error: {
                status: err.response?.status ?? 500,
                statusText: err.response?.statusText,
                data: err.response?.data,
              },
            };
          } else {
            return { error: { status: 500, statusText: '', data: '' } };
          }
        }
      },
    }),
  }),
});

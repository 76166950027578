import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { ComplexSprintData } from '@/api/projects';
import { ApiProjectTask } from '@/api/tasks';
import { Ellipsis } from '@/components/ui/Ellipsis';
import { Accordion, Badge, Flex } from '@mantine/core';

import { SprintTaskList } from './SprintTaskList';

import styles from './Sprint.module.css';

type SprintProps = {
  sprint: ComplexSprintData;
  isLoading?: boolean;
  isError?: boolean;
  tasks: ApiProjectTask.ITask[];
};

export const Sprint = memo(
  ({ sprint, tasks, isError, isLoading }: SprintProps) => {
    const { t } = useTranslation();

    return (
      <Accordion
        chevronPosition="left"
        variant="separated"
        defaultValue={'sprint'}
        classNames={{
          item: styles.item,
        }}
      >
        <Accordion.Item value="sprint">
          <Accordion.Control>
            <Flex align="center">
              <Ellipsis>{sprint.Name}</Ellipsis>
              <Badge
                className={styles.sumBadge}
                variant="transparent"
                radius="lg"
              >
                {t('createTask.taskCount', { count: tasks?.length || 0 })}
              </Badge>
            </Flex>
          </Accordion.Control>
          <Accordion.Panel>
            <SprintTaskList
              tasks={tasks}
              sprint={sprint}
              isError={isError}
              isLoading={isLoading}
            />
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    );
  },
);

import { ApiAttachments } from '../attachments/types';
import { ApiProjectAttributes } from '../projects';

export namespace ApiProjectTask {
  export interface IGetRequest {
    AuthorIds?: number[] | null;
    ProjectIds?: number[] | null;
    Like?: string | null;
    SprintIds?: number[] | null;
    PerformerIds?: number[] | null;
  }

  export type IGetResponse = ITask[];

  // не согласованно с беком
  export enum IPriority {
    Highest = 1,
    High = 2,
    Medium = 3,
    Low = 4,
    Lowest = 5,
  }

  export interface ITask {
    Id: number;
    TypeId: number;
    AuthorId: number;
    ParentId: number | null;
    PerformerId: number | null;
    PerformerRoleId: number | null;
    Priority: number;
    Status: number;
    Key: string;
    Title: string;
    Description: string | null;
    SprintId: number;
    ProjectId: number;
    Timestamp: string | null;
    Updated: string | null;
    StartDate: string | null;
    EndDate: string | null;
    DueDate: string | null;
    Estimated: number | null;
    TimeLeft: number | null;
    TimeSpent: number | null;
    HasLinks: boolean;
    HasAttributes: boolean;
    HasAttachments: boolean;
    Activities: any[] | null;
    Attachments: ApiAttachments.IAttachment[] | null;
    Attributes: (ApiProjectAttributes.IAttribute & { Value: string })[];
    Order: number;
    Links: null;
  }

  export type IType = {
    Id: number;
    Name: string;
    Color: string;
    Type: number;
  };

  export type IGetTypesResponse = IType[];

  export type ICreateRequest = {
    Title: string;
    ProjectId: number;
  } & Partial<ApiProjectTask.ITask>;

  export type ICreateResponse = ITask;

  export type IUpdateRequest = ICreateRequest & { Id: number };

  export type IUpdateResponse = ITask;

  export type IUpdateOrderRequest = {
    id: number;
    order: number;
  };

  export interface IAttribute {
    Id: number;
    TaskId: number;
    AttributeId: number;
    Value: string;
  }
}

export namespace ApiTaskActivity {
  export enum IActivityType {
    Comment = 1,
    ChangeStatus = 3,
    ChangeTask = 4,
    UploadAttachments = 5,
    AddTask = 7,
    AssignTask = 8,
  }

  export interface IGetListRequest {
    TaskId: number;
    Types?: IActivityType[];
  }

  export type IActivityChange = Extract<
    keyof ApiProjectTask.ITask,
    | 'Title'
    | 'Description'
    | 'Priority'
    | 'StartDate'
    | 'EndDate'
    | 'DueDate'
    | 'PerformerId'
    | 'Status'
    | 'SprintId'
    | 'TypeId'
  >;

  export interface ICommentActivity {
    Id: number;
    AuthorId: number;
    TaskId: number;
    ProjectId: number;
    ParentId: number | null;
    Description: string;
    Type: IActivityType.Comment;
    FileIds: number[] | null;
    HasAttachments: boolean;
    Timestamp: string | null;
  }

  export interface IHistoryActivity extends Omit<ICommentActivity, 'Type'> {
    Type: IActivityType.ChangeStatus | IActivityType.ChangeTask;
    Json: {
      Changes: {
        FieldName: IActivityChange;
        NewValue: string | null;
        OldValue: string | null;
      }[];
    };
  }

  export interface IAssignActivity extends Omit<ICommentActivity, 'Type'> {
    Type: IActivityType.AssignTask;
    Json: {
      Changes: {
        FieldName: IActivityChange;
        NewValue: number | null;
        OldValue: number | null;
      }[];
    };
  }

  export interface ICreateActivity extends Omit<ICommentActivity, 'Type'> {
    Type: IActivityType.AddTask;
    Json: {
      New: ApiProjectTask.ITask;
    };
  }

  export interface IAttachActivity extends Omit<ICommentActivity, 'Type'> {
    Type: IActivityType.UploadAttachments;
    Json: {
      Id: number;
      FileId: number;
      OwnerId: number;
      Hash: string;
      MimeType: string;
      Size: number;
      EntityId: number;
      EntityType: number;
      Name: string;
      Timestamp: Date;
      ProjectId: number;
    };
  }

  export type IActivity =
    | ICommentActivity
    | IHistoryActivity
    | IAssignActivity
    | ICreateActivity
    | IAttachActivity;

  export interface ICreateItemRequest {
    Id?: number;
    TaskId: number;
    ProjectId: number;
    ParentId?: number;
    Description: string;
    Type: IActivityType;
    FileIds?: number[];
    // Json: null;
  }
}

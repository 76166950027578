import { CopyrightModal } from '@/components/ui/CopyrightModal';
import { CUSTOMER } from '@/config/constants';
import { Box } from '@mantine/core';

import styles from './Footer.module.css';

export const Footer = () => {
  return (
    <Box className={styles.footer}>
      {CUSTOMER === 'R7' && <CopyrightModal />}
    </Box>
  );
};

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ApiCommon } from '@/api/common';
import { projectsApi } from '@/api/projects';
import { ProjectSprints } from '@/components/ui/ProjectSprints/ProjectSprints';
import { CreateTaskFormData } from '@/hooks/useCreateTask';
import { InputError, InputLabel, Popover, Text } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';

import { SprintOption } from '../../../components/ui/SprintOption';
import { FieldValue } from './FieldValue';

export const SprintSelectField = ({
  form,
  allowedProjectIds,
}: {
  form: UseFormReturnType<CreateTaskFormData>;
  allowedProjectIds?: number[];
}) => {
  const { t } = useTranslation();

  const { data } = projectsApi.endpoints.getProjects.useQueryState();

  const [opened, { open, close, toggle }] = useDisclosure();

  const projectsData = useMemo(() => {
    const projects = (data?.list ?? []).filter((el) =>
      [ApiCommon.AccessType.Write, ApiCommon.AccessType.Full].includes(
        el.AccessType,
      ),
    );

    if (allowedProjectIds) {
      return projects.filter((p) => allowedProjectIds.includes(p.Id));
    }

    return projects;
  }, [allowedProjectIds, data?.list]);

  const error = form.errors['sprint'];
  const hasError = !!error;

  return (
    <div>
      <InputLabel required size="sm" mb={4}>
        {t('createTask.fields.sprint.label')}
      </InputLabel>
      <Popover opened={opened} onOpen={open} onClose={close} width="target">
        <Popover.Target>
          <FieldValue onClick={toggle} opened={opened} error={hasError}>
            {form.values.sprint ? (
              <SprintOption sprint={form.values.sprint} />
            ) : (
              <Text
                c={
                  hasError
                    ? 'red.6'
                    : 'light-dark(var(--mantine-color-gray-5), var(--mantine-color-dark-3))'
                }
              >
                {t('createTask.fields.sprint.placeholder')}
              </Text>
            )}
          </FieldValue>
        </Popover.Target>
        <Popover.Dropdown p={0} style={{ overflow: 'hidden' }}>
          <ProjectSprints
            currentSprint={form.values.sprint?.Id}
            projects={projectsData}
            onClick={(s) => {
              close();

              if (s.Id === form.values.sprint?.Id) return;

              form.setValues({
                sprint: s,
                Status: -1,
                TypeId: -1,
                performer: null,
              });
            }}
          />
        </Popover.Dropdown>
      </Popover>
      {!!error && (
        <InputError size="md" mt={5}>
          {error}
        </InputError>
      )}
    </div>
  );
};

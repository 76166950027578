import { useCallback, useState } from 'react';

import { ApiProjectTask, projectTasksApi } from '@/api/tasks';
import { DragEndEvent } from '@dnd-kit/core';

export const useDrag = () => {
  const [updateOrder] = projectTasksApi.useUpdateOrderMutation();

  const [dragItem, setDragItem] = useState<ApiProjectTask.ITask | null>(null);

  const handleDragStart = useCallback((event: DragEndEvent) => {
    const dragTask = event.active.data.current as ApiProjectTask.ITask;
    setDragItem({ ...dragTask });
  }, []);

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      setDragItem(null);

      const overTask = event.over?.data.current as ApiProjectTask.ITask;
      const dragTask = event.active.data.current as ApiProjectTask.ITask;

      if (!overTask) return;

      updateOrder({
        id: dragTask.Id,
        order: overTask.Order + 1,
      });
    },
    [updateOrder],
  );

  return { handleDragStart, handleDragEnd, dragItem };
};

import { ApiProjectTask } from '@/api/tasks';
import { ActionIcon, Button, Menu } from '@mantine/core';

import { TaskPriorityBadge } from '../badges/TaskPriorityBadge';

export const priorityList = [1, 2, 3, 4, 5] as ApiProjectTask.IPriority[];

export type TaskPrioritySelectProps = {
  priority: ApiProjectTask.IPriority;
  withLabel?: boolean;
  onChange?: (v: ApiProjectTask.IPriority) => void;
  readonly?: boolean;
};

export const TaskPrioritySelect = ({
  priority,
  withLabel = false,
  onChange,
  readonly,
}: TaskPrioritySelectProps) => {
  if (readonly) {
    return (
      <TaskPriorityBadge priority={priority} withLabel={withLabel} pl={10} />
    );
  }

  return (
    <Menu
      position="bottom-start"
      shadow="md"
      middlewares={{ flip: true, shift: false }}
    >
      <Menu.Target>
        {withLabel ? (
          <Button
            fullWidth
            justify="flex-start"
            variant="subtle"
            color={'dark'}
            size="compact-md"
            onClick={(e) => e.stopPropagation()}
          >
            <TaskPriorityBadge priority={priority} withLabel={withLabel} />
          </Button>
        ) : (
          <ActionIcon
            variant="subtle"
            color={'dark'}
            size="md"
            onClick={(e) => e.stopPropagation()}
          >
            <TaskPriorityBadge priority={priority} withLabel={withLabel} />
          </ActionIcon>
        )}
      </Menu.Target>
      <Menu.Dropdown onClick={(e) => e.stopPropagation()}>
        {priorityList.map((item) => (
          <Menu.Item
            key={item}
            component={'li'}
            onClick={(e) => {
              e.stopPropagation();
              if (priority !== item) {
                onChange?.(item);
              }
            }}
          >
            <TaskPriorityBadge priority={item} />
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
};

import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { attachmentsApi } from '@/api/attachments/slice';
import { authApi } from '@/api/auth';
import { iconsApi } from '@/api/icons/slice';
import { tasksLinksApi } from '@/api/links';
import { projectsApi } from '@/api/projects';
import { settingsApi } from '@/api/settings';
import { shareApi } from '@/api/share';
import { taskTypesApi } from '@/api/taskTypes';
import { projectTasksApi } from '@/api/tasks';
import { usersApi, usersCacheSlice } from '@/api/users/slice';
import { layoutSlice } from '@/components/layout/AppLayout/slice';
import { createDevSlice } from '@/dev/slice';
import { createTaskModalSlice } from '@/features/CreateTaskModal/slice';
import { diffStatusesModalSlice } from '@/features/DiffStatusesModal/slice';
import { editEntityModalSlice } from '@/features/EditEntityModals/slice';
import { manageUsersModalSlice } from '@/features/EditUsersModal/slice';
import { taskModalSlice } from '@/features/TaskModal/store';
import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit';

export const store = configureStore({
  reducer: {
    // ENDPOINTS
    [settingsApi.reducerPath]: settingsApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [projectsApi.reducerPath]: projectsApi.reducer,
    [attachmentsApi.reducerPath]: attachmentsApi.reducer,
    [iconsApi.reducerPath]: iconsApi.reducer,
    [shareApi.reducerPath]: shareApi.reducer,
    [projectTasksApi.reducerPath]: projectTasksApi.reducer,
    [taskTypesApi.reducerPath]: taskTypesApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [tasksLinksApi.reducerPath]: tasksLinksApi.reducer,
    // OTHER
    [taskModalSlice.reducerPath]: taskModalSlice.reducer,
    [usersCacheSlice.reducerPath]: usersCacheSlice.reducer,
    [editEntityModalSlice.reducerPath]: editEntityModalSlice.reducer,
    [diffStatusesModalSlice.reducerPath]: diffStatusesModalSlice.reducer,
    [layoutSlice.reducerPath]: layoutSlice.reducer,
    [createTaskModalSlice.reducerPath]: createTaskModalSlice.reducer,
    [createDevSlice.reducerPath]: createDevSlice.reducer,
    [manageUsersModalSlice.reducerPath]: manageUsersModalSlice.reducer,
  },

  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['createTaskModal/open'],
        ignoredPaths: ['createTaskModal'],
      },
    })
      .concat(projectTasksApi.middleware)
      .concat(projectsApi.middleware)
      .concat(taskTypesApi.middleware)
      .concat(usersApi.middleware)
      .concat(attachmentsApi.middleware)
      .concat(iconsApi.middleware)
      .concat(shareApi.middleware)
      .concat(settingsApi.middleware)
      .concat(authApi.middleware)
      .concat(tasksLinksApi.middleware);
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { ApiProjectTask, ApiTaskActivity } from '@/api/tasks';
import { HistoryComment } from '@/components/ui/Comment/HistoryComment';
import { NoData } from '@/components/ui/NoData/NoData';
import { Stack } from '@mantine/core';

import { useActivityData } from '../hooks/useActivityData';
import { IFilledActivity } from '../types';
import CommentsLoading from './CommentsLoading';
import { HistoryActivity } from './HistoryActivity';
import { HistoryAttach } from './HistoryAttach';
import { HistoryCreate } from './HistoryCreate';

export const AllTypes = ({ task }: { task: ApiProjectTask.ITask }) => {
  const { t } = useTranslation();

  const { data, isLoading } = useActivityData(task.Id);

  if (isLoading) {
    return (
      <Stack gap={16} mt={12}>
        <CommentsLoading.Comment />
        <CommentsLoading.Comment />
      </Stack>
    );
  }

  if (!data.length) {
    return (
      <NoData
        description={t('nodata.noActivity.title')}
        illustration={'noData'}
        size={120}
        p={20}
      />
    );
  }

  return (
    <Stack gap={16} mt={12}>
      {data?.map((el) => (
        <Fragment key={el.Id}>{drawActivity(task, el)}</Fragment>
      ))}
    </Stack>
  );
};

const drawActivity = (
  task: ApiProjectTask.ITask,
  activity: IFilledActivity,
) => {
  switch (activity.Type) {
    case ApiTaskActivity.IActivityType.Comment: {
      return <HistoryComment task={task} activity={activity} />;
    }
    case ApiTaskActivity.IActivityType.ChangeStatus:
    case ApiTaskActivity.IActivityType.ChangeTask:
    case ApiTaskActivity.IActivityType.AssignTask: {
      return <HistoryActivity sprintId={task.SprintId} activity={activity} />;
    }
    case ApiTaskActivity.IActivityType.UploadAttachments: {
      return <HistoryAttach activity={activity} />;
    }
    case ApiTaskActivity.IActivityType.AddTask: {
      return <HistoryCreate activity={activity} />;
    }
    default:
      return null;
  }
};

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ColorPicker } from '@/components/ui/ColorPicker/ColorPicker';
import {
  ProjectIcon,
  ProjectIconType,
  projectIconsMap,
} from '@/components/ui/icons/ProjectIcon';
import { ActionIcon, Box, Flex, Stack, Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

export const IconForm = ({
  color,
  icon,
  name,
  onColorChange,
  onIconChange,
  showInitials,
}: {
  name: string | null;
  color: string | null;
  icon: ProjectIconType | null;
  onColorChange: (c: string) => void;
  onIconChange: (c: ProjectIconType | null) => void;
  showInitials?: boolean;
}) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width: 576px)');

  const iconsArray = useMemo(() => {
    const fullArray = [
      {
        key: 'initials',
        content: <Text fw={600}>{name?.charAt(0).toUpperCase()}</Text>,
      },
      ...Object.keys(projectIconsMap).map((key) => ({
        key,
        content: projectIconsMap[key as unknown as ProjectIconType],
      })),
    ];

    return showInitials ? fullArray : fullArray.slice(1);
  }, [name, showInitials]);

  return (
    <Flex
      gap={16}
      align={isMobile ? 'center' : 'flex-start'}
      direction={isMobile ? 'column' : 'row'}
    >
      <ProjectIcon
        name={name}
        color={color}
        icon={icon}
        size={isMobile ? 70 : 140}
        radius={'md'}
      />
      <Stack gap={8}>
        <Box
          style={{
            border: '1px solid var(--mantine-color-gray-4)',
            borderRadius: '8px',
          }}
          px={12}
          py={8}
        >
          <Text fw={'bold'} mb={8}>
            {t('entitySettings.iconSection.color')}
          </Text>
          <ColorPicker value={color} onChange={onColorChange} />
        </Box>

        <Flex
          gap={6}
          wrap={'wrap'}
          justify={isMobile ? 'center' : 'flex-start'}
        >
          {iconsArray.map((el) => {
            const isActive =
              icon === (el.key === 'initials' ? null : Number(el.key));
            return (
              <ActionIcon
                key={el.key}
                variant={isActive ? 'filled' : 'subtle'}
                color={isActive ? 'blue' : 'dark'}
                radius={'md'}
                size={24}
                onClick={() =>
                  onIconChange(
                    isActive || el.key === 'initials'
                      ? null
                      : (Number(el.key) as ProjectIconType),
                  )
                }
              >
                {el.content}
              </ActionIcon>
            );
          })}
        </Flex>
      </Stack>
    </Flex>
  );
};

import {
  KeyboardEventHandler,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { ApiCommon } from '@/api/common';
import { Ellipsis } from '@/components/ui/Ellipsis';
import { UserAvatar } from '@/components/ui/UserAvatar';
import { UserRoleSelect } from '@/components/ui/selects/UserRoleSelect/UserRoleSelect';
import {
  Box,
  Button,
  Combobox,
  Flex,
  Loader,
  Pill,
  PillsInput,
  useCombobox,
} from '@mantine/core';
import { useElementSize } from '@mantine/hooks';

import { useScrollEnd } from '../../../../../../hooks/useScrollEnd';
import { useSearchUsers } from '../hooks/useSearchUsers';
import { ParticipantOption } from '../types';
import { UserOption } from './UserOption';

import styles from './ParticipantsSelect.module.css';

export type PerformerSelectProps<OPTION extends ParticipantOption> = {
  value: OPTION[];
  onChange: (v: OPTION[]) => void;
  excludeUsers?: number[];
  role: ApiCommon.AccessType;
  setRole: (v: ApiCommon.AccessType) => void;
};

export const ParticipantsSelect = <OPTION extends ParticipantOption>({
  value,
  onChange,
  excludeUsers,
  role,
  setRole,
}: PerformerSelectProps<OPTION>) => {
  const { t } = useTranslation();

  const [opened, onOpenedChange] = useState(false);
  const ref = useRef<HTMLInputElement>(null);
  const { ref: rightSectionRef, width } = useElementSize();

  const {
    debouncedHandleSearch,
    handleLoadMore,
    handleInitLoad,
    data,
    isSuccess,
    isLoading,
    isLoadingMore,
    query,
    handleSearch,
    setQuery,
  } = useSearchUsers(excludeUsers);

  const combobox = useCombobox({
    opened,
    onOpenedChange,
    onDropdownOpen: handleInitLoad,
  });

  const handleSubmit = useCallback(
    (optValue: string) => {
      const opt = data?.data.find((u) => u.Id.toString() === optValue);

      if (opt) {
        onChange([...value, opt as unknown as OPTION]);
      }

      if (query) {
        setQuery('');
        handleSearch('');
      }

      setTimeout(() => {
        ref.current?.scrollTo({
          top: ref.current.scrollHeight,
        });
      }, 0);
    },
    [onChange, data, value, setQuery, handleSearch, query],
  );

  const handleBackspace = useCallback<KeyboardEventHandler<HTMLInputElement>>(
    (event) => {
      if (event.key === 'Backspace' && !event.currentTarget.value.length) {
        event.preventDefault();
        onChange(value.slice(0, -1));
      }
    },
    [onChange, value],
  );

  const onScrollEnd = useScrollEnd(handleLoadMore);

  const options = useMemo(() => {
    const filteredData =
      data?.data.filter((u) => !excludeUsers?.includes(u.Id)) || [];

    if (isSuccess && data?.data.length === 0) {
      return <Combobox.Empty>{t('members.noResults')}</Combobox.Empty>;
    }

    if (isSuccess && filteredData.length === 0) {
      return <Combobox.Empty>{t('members.noUsers')}</Combobox.Empty>;
    }

    const searchOptions = filteredData.map((user) => {
      return (
        <Combobox.Option
          value={user.Id.toString()}
          key={user.Id}
          disabled={user.Status === ApiCommon.UserStatus.Banned}
        >
          <UserOption user={user} />
        </Combobox.Option>
      );
    });

    return searchOptions;
  }, [excludeUsers, isSuccess, data, t]);

  return (
    <Combobox
      keepMounted={false}
      withinPortal={true}
      store={combobox}
      position="bottom-end"
      shadow="md"
      onOptionSubmit={handleSubmit}
    >
      <Combobox.DropdownTarget>
        <PillsInput
          ref={ref}
          rightSection={
            <Box ref={rightSectionRef} h="100%">
              <UserRoleSelect
                buttonProps={{ h: '100%' }}
                value={role}
                onChange={setRole}
              />
            </Box>
          }
          pointer
          size="md"
          flex={1}
          maw="100%"
          styles={{
            input: {
              maxHeight: '100px',
              overflow: 'auto',
              paddingRight: width,
            },
            section: { width: 'auto' },
          }}
        >
          <Flex justify="space-between">
            <Pill.Group maw="100%">
              {value.map((u) => (
                <Pill
                  key={u.Id}
                  classNames={{ remove: styles.pillRemove }}
                  styles={{ label: { display: 'flex' } }}
                  size="md"
                  bg={'gray.2'}
                  c={'gray.8'}
                  withRemoveButton
                  pl={4}
                  onRemove={() =>
                    onChange(value.filter((user) => user.Id !== u.Id))
                  }
                >
                  <Flex gap={4} w="100%">
                    <UserAvatar size={18} user={u} />
                    <Ellipsis>{u.Name}</Ellipsis>
                  </Flex>
                </Pill>
              ))}

              <Combobox.EventsTarget>
                <PillsInput.Field
                  flex="auto"
                  onFocus={() => combobox.openDropdown()}
                  onBlur={() => combobox.closeDropdown()}
                  value={query}
                  onChange={(event) => {
                    setQuery(event.currentTarget.value);
                    debouncedHandleSearch(event.currentTarget.value);
                  }}
                  placeholder={t('members.addUser')}
                  onKeyDown={handleBackspace}
                />
              </Combobox.EventsTarget>
            </Pill.Group>
          </Flex>
        </PillsInput>
      </Combobox.DropdownTarget>

      <Combobox.Dropdown>
        <Combobox.Options
          onScroll={onScrollEnd}
          style={{
            maxHeight: '275px',
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
          }}
        >
          {isLoading ? (
            <Preloader />
          ) : (
            <>
              {options}
              {data?.hasMore && (
                <Flex justify={'center'}>
                  <Button
                    variant="subtle"
                    loading={isLoadingMore}
                    onClick={handleLoadMore}
                    size="xs"
                  >
                    {t('common.action.loadMore')}
                  </Button>
                </Flex>
              )}
            </>
          )}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};

const Preloader = () => (
  <Flex justify={'center'} py={12}>
    <Loader size={'sm'} />
  </Flex>
);

import { memo } from 'react';

import { ApiProjectTask } from '@/api/tasks';
import { UserAvatar } from '@/components/ui/UserAvatar';
import { TaskTypeBadge } from '@/components/ui/badges/TaskTypeBadge';
import { useFolderData } from '@/hooks/useFolderData';
import { usePerformerData } from '@/hooks/usePerformerData';
import { useTaskTypesData } from '@/hooks/useTaskTypesData';
import { Flex, Stack, StackProps, Text } from '@mantine/core';

type TaskOptionProps = {
  task: ApiProjectTask.ITask;
} & StackProps;

export const TaskOption = memo(({ task, ...props }: TaskOptionProps) => {
  const { Key, Title, TypeId } = task;

  const performerData = usePerformerData(task);

  const projectData = useFolderData(task.ProjectId);
  const { data } = useTaskTypesData(projectData?.rootProjectId);
  const type = data?.mapById[TypeId];

  return (
    <Stack gap={4} {...props}>
      <Text
        fw={500}
        fz={14}
        c={
          'light-dark(var(--mantine-color-gray-8), var(--mantine-color-gray-2))'
        }
        truncate={'end'}
      >
        {Title}
      </Text>

      <Flex justify={'space-between'}>
        <Flex gap={8}>
          <TaskTypeBadge withLabel={false} type={type} />
          <Text fz={14} c={'gray.6'}>
            {Key}
          </Text>
        </Flex>
        <UserAvatar size={24} user={performerData} />
      </Flex>
    </Stack>
  );
});

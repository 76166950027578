import { useMemo } from 'react';

import { ApiCommon } from '@/api/common';
import { ApiProjectTask } from '@/api/tasks';
import { groupBy, keyBy, orderBy } from 'lodash-es';

export type TasksGroup = {
  status: ApiCommon.ITaskStatus;
  tasks: ApiProjectTask.ITask[];
};

export const useGroupTasks = ({
  statuses,
  tasks,
  includeAllStatuses,
  revertStatusesOrder,
}: {
  tasks?: ApiProjectTask.ITask[];
  statuses?: ApiCommon.ITaskStatus[];
  includeAllStatuses?: boolean;
  revertStatusesOrder?: boolean;
}) => {
  const statusesMap = useMemo(() => keyBy(statuses, 'Id'), [statuses]);

  const groupedData = useMemo(() => {
    const toDoStatus = statuses?.find(
      (el) => el.Type === ApiCommon.ITastStatusType.BeExecuted,
    );

    const preparedStatuses = tasks?.map((el) => ({
      ...el,
      Status: statusesMap[el.Status]?.Id || toDoStatus?.Id || 0,
    }));

    const orderedStatuses = orderBy(
      statuses,
      'Order',
      revertStatusesOrder ? 'desc' : 'asc',
    );
    const orderedTasks = orderBy(preparedStatuses, 'Order');
    const groupedTasks = groupBy(orderedTasks, 'Status');

    const mergedData: TasksGroup[] =
      orderedStatuses?.map((s) => ({
        status: s,
        tasks: groupedTasks[s.Id] ?? [],
      })) || [];

    return includeAllStatuses
      ? mergedData
      : mergedData.filter(({ tasks }) => !!tasks?.length);
  }, [tasks, statuses, includeAllStatuses, revertStatusesOrder, statusesMap]);

  return {
    groupedData,
    statusesMap,
  };
};

import { ReactNode } from 'react';

import { Flex, Stack, Text } from '@mantine/core';

import styles from './EntityForm.module.css';

interface IProps {
  items: IRowProps[];
}

export const ConfirmBoard = ({ items }: IProps) => {
  return (
    <Stack className={styles.confirmBoard}>
      {items.map((el, idx) => (
        <ConfirmRow key={idx} {...el} />
      ))}
    </Stack>
  );
};

interface IRowProps {
  label: string;
  detail?: ReactNode;
  onClick?: () => void;
}

const ConfirmRow = ({ label, detail, onClick }: IRowProps) => {
  return (
    <Flex
      justify="space-between"
      className={styles.confirmRow}
      onClick={onClick}
    >
      <Text fz={14} fw={500}>
        {label}
      </Text>
      {detail}
    </Flex>
  );
};

import { useTranslation } from 'react-i18next';

import { Container, Stack, Title } from '@mantine/core';

import { PostCard } from './components/PostCard/PostCard';
import { news } from './news';

export const UpdatesPage = () => {
  const { t } = useTranslation();

  return (
    <Container maw={680} pb={24}>
      <Title
        order={1}
        mb={16}
        fw={500}
        c="light-dark(var(--mantine-color-gray-8), var(--mantine-color-gray-0))"
      >
        {t('updates.title')}
      </Title>

      <Stack gap={32}>
        {news.map((post, i) => (
          <PostCard key={i} data={post} />
        ))}
      </Stack>
    </Container>
  );
};

import { ComplexProjectData } from '@/api/projects';
import { taskTypesApi } from '@/api/taskTypes';
import { store } from '@/app/store';
import { ITypeForm } from '@/components/complex/EntityForm';

export const getTypes = async (data: ComplexProjectData) => {
  const typesData = await store
    .dispatch(taskTypesApi.endpoints.getTaskTypes.initiate([data.Id]))
    .unwrap();

  return typesData.list.map<ITypeForm>((t) => ({
    id: t.Id,
    name: t.Name,
    color: t.Color,
    icon: t.IconId,
    state: 'original',
  }));
};

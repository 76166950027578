import { ComponentPropsWithoutRef } from 'react';

import { ApiProjects } from '@/api/projects';
import { ProjectIcon } from '@/components/ui/icons/ProjectIcon';
import { Avatar } from '@mantine/core';
import {
  IconChevronRight,
  IconFolder,
  IconFolderOpen,
} from '@tabler/icons-react';

import styles from '../Navigation.module.css';

type TreeProjectIconProps = {
  project: ApiProjects.IProject;
  depth: number;
  opened: boolean;
} & ComponentPropsWithoutRef<'div'>;

export const TreeProjectIcon = ({
  project,
  depth,
  opened,
  ...props
}: TreeProjectIconProps) => {
  return (
    <Avatar
      radius="sm"
      variant="filled"
      size={20}
      src={null}
      color={project.Color || 'gray.5'}
      ml={8}
      tt={'uppercase'}
      classNames={{
        root: styles.chevronContainer,
        placeholder: styles.chevronContainerBg,
      }}
      {...props}
    >
      <IconChevronRight
        size="1rem"
        stroke={2}
        color={'#ffffff'}
        className={styles.chevronIcon}
      />
      {depth! < 1 ? (
        <div className={styles.chevronInitials}>
          <ProjectIcon
            name={project.Name}
            color={project.Color}
            icon={project.IconId}
          />
        </div>
      ) : opened! ? (
        <IconFolderOpen
          size="1rem"
          stroke={2}
          color={'#ffffff'}
          className={styles.chevronInitials}
        />
      ) : (
        <IconFolder
          size="1rem"
          stroke={2}
          color={'#ffffff'}
          className={styles.chevronInitials}
        />
      )}
    </Avatar>
  );
};

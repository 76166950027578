import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { SegmentedControl, Select } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

const units = ['day', 'week', 'month', 'quarter', 'halfYear'];

type Props = {
  value: string | null;
  onChange: (v: string | null) => void;
};

const GanttUnitSelectComponent = ({ value, onChange }: Props) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(`(max-width: 576px)`);

  const handleChange = useCallback(
    (v: string | null) => {
      onChange(v);
    },
    [onChange],
  );

  const options = units.map((value) => ({
    value,
    label: t(`timelineSettings.units.${value}`),
  }));
  const transformedValue = (isMobile ? value : value ?? undefined) as
    | string
    | undefined;

  return isMobile ? (
    <Select
      size="xs"
      value={transformedValue}
      onChange={handleChange}
      data={options}
    />
  ) : (
    <SegmentedControl
      size="xs"
      radius={6}
      value={transformedValue}
      onChange={(v) => handleChange(v ?? null)}
      data={options}
    />
  );
};

export const GanttUnitSelect = memo(GanttUnitSelectComponent);

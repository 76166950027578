import { ApiProjectTask } from '@/api/tasks';
import { TaskTypeBadge } from '@/components/ui/badges/TaskTypeBadge';
import { useFolderData } from '@/hooks/useFolderData';
import { useTaskTypesData } from '@/hooks/useTaskTypesData';
import { Flex, Pill, PillProps } from '@mantine/core';

import styles from './TaskPill.module.css';

export const TaskPill = ({
  task,
  onRemove,
}: { task: ApiProjectTask.ITask } & Pick<PillProps, 'onRemove'>) => {
  const projectData = useFolderData(task.ProjectId);
  const { data } = useTaskTypesData(projectData?.rootProjectId);
  const type = data?.mapById[task.TypeId];

  return (
    <Pill
      size="md"
      withRemoveButton
      onRemove={onRemove}
      classNames={{ remove: styles.remove, label: styles.label }}
    >
      <Flex>
        <TaskTypeBadge type={type} iconSize={16} withLabel={false} />
        {task.Key}
      </Flex>
    </Pill>
  );
};

import { useMemo } from 'react';

import { projectsApi } from '@/api/projects';

export const useFoldersListData = (folderIds: number[]) => {
  const { data } = projectsApi.endpoints.getProjects.useQueryState();

  return useMemo(() => {
    if (!data?.folderEntities) return [];

    return folderIds.map((id) => data.folderEntities[id]).filter(Boolean);
  }, [data?.folderEntities, folderIds]);
};

import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { ApiCommon } from '@/api/common';
import { showRequestNotifications } from '@/api/helpers/showNotifications';
import { ApiSprints, projectsApi } from '@/api/projects';
import { useAppSelector } from '@/app/store';
import {
  IEntityFormValues,
  NewEntityForm,
} from '@/components/complex/EntityForm';
import { useFolderData } from '@/hooks/useFolderData';
import { isEqual } from 'lodash-es';

import { getStatusesUpdates } from '../helpers/getStatusesUpdates';
import { EntityModal, closeEntityModal } from './EntityModal';

const Content = memo(() => {
  const { t } = useTranslation();

  const [update, { isLoading }] = projectsApi.useUpdateSprintMutation();

  const { entityType, sprintData, defaultValue, parentId } = useAppSelector(
    (s) => s.editEntityModal,
  );

  const parentProjectData = useFolderData(parentId);
  const parentIsFolder = !!parentProjectData?.ParentId;

  const handleUpdate = (values: IEntityFormValues) => {
    if (!sprintData) return;

    // MAIN DATA
    const oldData: Partial<ApiSprints.ISprint> = {
      Name: sprintData.Name,
      Color: sprintData.Color,
      Flags: sprintData.Flags,
    };

    const newData: Partial<ApiSprints.ISprint> = {
      Name: values.name,
      Color: values.color,
      Flags: values.statuses.provide
        ? ApiCommon.ProjectFlags.DEFAULT
        : ApiCommon.ProjectFlags.USE_CUSTOM_STATUSES,
    };

    const updates: Promise<any>[] = isEqual(oldData, newData)
      ? []
      : [
          update({
            ...sprintData,
            ...newData,
          }).unwrap(),
        ];
    // MAIN DATA - END

    showRequestNotifications(
      Promise.all([
        ...updates,
        ...getStatusesUpdates({
          entityId: sprintData.Id,
          entityType: ApiCommon.Entity.Sprint,
          statuses: values.statuses.provide ? [] : values.statuses.current,
        }),
      ]),
      {
        successMsg: t('notification.success.editSprint'),
        errorMsg: t('notification.error.editSprint'),
      },
    ).then(() => closeEntityModal());
  };

  return (
    <NewEntityForm
      title={t(`entitySettings.title.edit.${entityType}`)}
      entityType={entityType}
      onConfirm={handleUpdate}
      onClose={closeEntityModal}
      confirmLabel={t('common.action.save')}
      defaultValue={defaultValue}
      isLoading={isLoading}
      parentIsFolder={parentIsFolder}
    />
  );
});

export const UpdateSprintModal = () => {
  const { opened, entityType, editType } = useAppSelector(
    (s) => s.editEntityModal,
  );

  return (
    <EntityModal
      opened={opened && entityType === 'sprint' && editType === 'update'}
    >
      <Content />
    </EntityModal>
  );
};

import { ApiCommon } from '@/api/common';
import {
  ComplexProjectData,
  ComplexSprintData,
  projectsApi,
} from '@/api/projects';
import { DEFAULT_VALUES } from '@/app/constants';
import { store } from '@/app/store';
import {
  IEntityFormType,
  IEntityFormValues,
  ITypeForm,
} from '@/components/complex/EntityForm';
import { ProjectIconType } from '@/components/ui/icons/ProjectIcon';
import { t } from 'i18next';
import { orderBy } from 'lodash-es';

import { getDefaultStatuses, getStatuses } from './helpers/getStatues';
import { getTypes } from './helpers/getTypes';
import { editEntityModalSlice } from './slice';

const getDefaultTypes = (): ITypeForm[] => [
  {
    id: 1,
    color: '#66ABE3',
    icon: ProjectIconType.check,
    name: t('createTask.fields.type.options.task'),
    state: 'new',
  },
  {
    id: 2,
    color: '#D45543',
    icon: ProjectIconType.bug,
    name: t('createTask.fields.type.options.bug'),
    state: 'new',
  },
  {
    id: 3,
    color: '#B25BE4',
    icon: ProjectIconType.squareRotated,
    name: t('createTask.fields.type.options.milestone'),
    state: 'new',
  },
];

const defaultValue: IEntityFormValues = {
  formType: null,
  name: '',
  icon: null,
  color: '',
  statuses: { provide: true, current: [], provided: [] },
  types: [],
  custom: [],
};

export const openCreateProjectModal = async () => {
  const defaultStatuses = await getDefaultStatuses();

  store.dispatch(
    editEntityModalSlice.actions.open({
      defaultValue: {
        ...defaultValue,
        color: DEFAULT_VALUES.PROJECT_COLOR,
        statuses: {
          ...defaultValue.statuses,
          provide: false,
          current: defaultStatuses,
        },
        types: getDefaultTypes(),
        // custom: customFields,
      },
      entityType: 'project',
      editType: 'create',
    }),
  );
};

export const openCreateFolderModal = async (project: ComplexProjectData) => {
  const provided = await getStatuses({
    entityId: project.statusesEntityId,
    entityType: project.statusesEntityType,
  });

  const defaultStatuses = await getDefaultStatuses();

  store.dispatch(
    editEntityModalSlice.actions.open({
      defaultValue: {
        ...defaultValue,
        color: DEFAULT_VALUES.FOLDER_COLOR,
        statuses: {
          ...defaultValue.statuses,
          current: defaultStatuses,
          provided,
        },
      },
      entityType: 'folder',
      editType: 'create',
      parentId: project.Id,
    }),
  );
};

export const openCreateSprintModal = async (parent: ComplexProjectData) => {
  const provided = await getStatuses({
    entityId: parent.statusesEntityId,
    entityType: parent.statusesEntityType,
  });

  const defaultStatuses = await getDefaultStatuses();

  store.dispatch(
    editEntityModalSlice.actions.open({
      defaultValue: {
        ...defaultValue,
        color: DEFAULT_VALUES.SPRINT_COLOR,
        statuses: {
          ...defaultValue.statuses,
          current: defaultStatuses,
          provided,
        },
      },
      entityType: 'sprint',
      editType: 'create',
      parentId: parent.Id,
    }),
  );
};

export const openUpdateProjectModal = async (data: ComplexProjectData) => {
  const defaultStatuses = await getDefaultStatuses();
  const customFields = await store
    .dispatch(
      projectsApi.endpoints.getAttributes.initiate({ projectId: data.Id }),
    )
    .unwrap()
    .then((res) =>
      res.map((el) => ({
        id: el.AttributeId,
        name: el.Name,
        type: el.ValueType,
        state: 'original',
      })),
    );

  const current = await getStatuses({
    entityId: data.Id,
    entityType: ApiCommon.Entity.Project,
  });

  const provided = await getStatuses({
    entityId: data.statusesEntityId,
    entityType: data.statusesEntityType,
  });

  const types = await getTypes(data);
  const custom = orderBy(
    customFields.map((el) => ({ ...el, state: 'original' as const })),
    ['type', 'name'],
    ['desc', 'asc'],
  );

  store.dispatch(
    editEntityModalSlice.actions.open({
      entityType: 'project',
      editType: 'update',
      projectData: data,
      defaultValue: {
        formType: null,
        name: data.Name,
        color: data.Color,
        icon: data.IconId,
        statuses: {
          provide: false,
          current: current.length > 0 ? current : defaultStatuses,
          provided,
        },
        types,
        custom,
      },
    }),
  );
};

export const openUpdateFolderModal = async (data: ComplexProjectData) => {
  const { folderEntities } = await store
    .dispatch(projectsApi.endpoints.getProjects.initiate())
    .unwrap();

  const parent = data.ParentId ? folderEntities[data.ParentId] : null;
  if (!parent) return;

  const defaultStatuses = await getDefaultStatuses();

  const current = await getStatuses({
    entityId: data.Id,
    entityType: ApiCommon.Entity.Project,
  });

  const provided = await getStatuses({
    entityId: parent.statusesEntityId,
    entityType: parent.statusesEntityType,
  });

  store.dispatch(
    editEntityModalSlice.actions.open({
      entityType: 'folder',
      editType: 'update',
      projectData: data,
      parentId: data.ParentId,
      defaultValue: {
        formType: null,
        name: data.Name,
        color: data.Color,
        icon: null,
        statuses: {
          provide: data.Flags === ApiCommon.ProjectFlags.DEFAULT,
          current: current.length > 0 ? current : defaultStatuses,
          provided,
        },
        types: [],
        custom: [],
      },
    }),
  );
};

export const openUpdateSprintModal = async (
  data: ComplexSprintData,
  formType?: IEntityFormType,
) => {
  const { folderEntities } = await store
    .dispatch(projectsApi.endpoints.getProjects.initiate())
    .unwrap();

  const parent = folderEntities[data.ProjectId];
  if (!parent) return;

  const defaultStatuses = await getDefaultStatuses();

  const current = await getStatuses({
    entityId: data.Id,
    entityType: ApiCommon.Entity.Sprint,
  });

  const provided = await getStatuses({
    entityId: parent.statusesEntityId,
    entityType: parent.statusesEntityType,
  });

  store.dispatch(
    editEntityModalSlice.actions.open({
      entityType: 'sprint',
      editType: 'update',
      sprintData: data,
      parentId: data.ProjectId,
      defaultValue: {
        formType: formType ?? null,
        name: data.Name,
        color: data.Color,
        icon: null,
        statuses: {
          provide: data.Flags === ApiCommon.ProjectFlags.DEFAULT,
          current: current.length > 0 ? current : defaultStatuses,
          provided,
        },
        types: [],
        custom: [],
      },
    }),
  );
};

import { basename } from '@/app/routes/AppRoute';
import { Carousel } from '@mantine/carousel';
import {
  Badge,
  Flex,
  Image,
  Text,
  TypographyStylesProvider,
} from '@mantine/core';

import styles from './PostCard.module.css';

export type PostData = {
  label?: string;
  date: string;
  images?: string[];
  /** HTML */
  description: string;
};

export const PostCard = ({ data }: { data: PostData }) => {
  return (
    <div>
      <Flex gap={8} mb={8}>
        {data.label && <Badge>{data.label}</Badge>}
        <Text c="gray.6" fz={14}>
          {data.date}
        </Text>
      </Flex>

      {!!data.images?.length && (
        <Carousel
          classNames={{
            indicator: styles.indicator,
            indicators: styles.indicators,
          }}
          withIndicators
          withControls={false}
          mb={16}
          slideGap={16}
          align={'center'}
        >
          {data.images.map((imageSrc) => (
            <Carousel.Slide key={imageSrc}>
              <Image
                src={basename + '/images/updates/' + imageSrc}
                style={{
                  borderRadius: 10,
                }}
              />
            </Carousel.Slide>
          ))}
        </Carousel>
      )}

      <TypographyStylesProvider>
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: data.description,
          }}
        />
      </TypographyStylesProvider>
    </div>
  );
};

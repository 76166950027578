import { useTranslation } from 'react-i18next';

import {
  Button,
  Flex,
  Modal,
  ModalProps,
  Stack,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { isNull } from 'lodash-es';

import { ITypeForm } from '../../types';
import { IconForm } from '../IconForm/IconForm';

type FormValues = ITypeForm;

type ContentProps = {
  defaultValue: ITypeForm;
  onCancel: () => void;
  onSubmit: (t: ITypeForm) => void;
  confirmLabel: string;
};

const Content = ({
  onSubmit,
  onCancel,
  defaultValue,
  confirmLabel,
}: ContentProps) => {
  const { t } = useTranslation();

  const form = useForm<FormValues>({
    initialValues: defaultValue,
    validate: {
      name: (v) => ((v || '').trim().length > 0 ? null : 'Заполните это поле'),
    },
  });

  const handleSubmit = (values: typeof form.values) => onSubmit(values);

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Stack gap={24}>
        <TextInput
          data-autofocus
          maxLength={50}
          placeholder={t('entitySettings.fields.type.placeholder')}
          label={t('entitySettings.fields.type.label')}
          {...form.getInputProps('name')}
        />
        <IconForm
          name={form.values.name}
          color={form.values.color}
          icon={form.values.icon}
          onColorChange={(v) => form.setFieldValue('color', v)}
          onIconChange={(v) => !isNull(v) && form.setFieldValue('icon', v)}
        />
        <Flex gap={16}>
          <Button
            size="md"
            fullWidth
            radius={'md'}
            variant="subtle"
            color="light-dark(var(--mantine-color-dark-3), var(--mantine-color-dark-1))"
            onClick={onCancel}
          >
            {t('common.action.cancel')}
          </Button>
          <Button size="md" fullWidth radius={'md'} type="submit">
            {confirmLabel}
          </Button>
        </Flex>
      </Stack>
    </form>
  );
};

export const EditTypeModal = ({
  onClose,
  opened,
  onSubmit,
  defaultValue,
  confirmLabel,
}: Pick<ModalProps, 'opened' | 'onClose'> & Omit<ContentProps, 'onCancel'>) => {
  const { t } = useTranslation();

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title={t('entitySettings.typeSection.subtitle', { action: confirmLabel })}
      size="lg"
    >
      <Content
        confirmLabel={confirmLabel}
        onSubmit={onSubmit}
        onCancel={onClose}
        defaultValue={defaultValue}
      />
    </Modal>
  );
};

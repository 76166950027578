import { UIEventHandler, useCallback } from 'react';

const THRESHOLD = 10;

export const useScrollEnd = (callback: () => void) => {
  const handleScroll = useCallback<UIEventHandler<HTMLDivElement>>(
    (event) => {
      const { clientHeight, scrollHeight, scrollTop } = event.currentTarget;

      const isBottomEnd = scrollHeight - clientHeight - scrollTop <= THRESHOLD;

      if (isBottomEnd) {
        callback();
      }
    },
    [callback],
  );

  return handleScroll;
};

import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { ActionIcon, Modal } from '@mantine/core';
import { IconChevronLeft } from '@tabler/icons-react';

import { useEntityFormContext } from '../hooks/useForm';

interface IProps {
  title: ReactNode;
  onClose: VoidFunction;
}

export const EntityFormHeader = ({ title, onClose }: IProps) => {
  const { t } = useTranslation();

  const form = useEntityFormContext();
  const formType = form.values.formType;

  return (
    <Modal.Header style={{ alignItems: 'start' }} mx={24} pt={32} pb={24}>
      {formType && (
        <ActionIcon
          color="gray.5"
          c="light-dark(var(--mantine-color-dark-5), var(--mantine-color-dark-0))"
          variant="subtle"
          size="md"
          onClick={() => form.setFieldValue('formType', null)}
        >
          <IconChevronLeft size="70%" />
        </ActionIcon>
      )}

      <Modal.Title fz={24} lh="32px">
        {formType ? t(`entitySettings.title.${formType}`) : title}
      </Modal.Title>

      <Modal.CloseButton onClick={onClose} m={0} />
    </Modal.Header>
  );
};

import { useTranslation } from 'react-i18next';

import { NoData } from '@/components/ui/NoData/NoData';
import { Container } from '@mantine/core';

import classes from './AppError.module.css';

export const AppError = () => {
  const { t } = useTranslation();

  return (
    <Container className={classes.root}>
      <NoData
        title={t('500.title')}
        description={t('500.description')}
        illustration="500"
        buttons={[
          {
            buttonText: t('500.reload'),
            handleClick: () => window.location.reload(),
          },
        ]}
      />
    </Container>
  );
};

import { Ref, forwardRef, useMemo } from 'react';

import { ApiCommon } from '@/api/common';
import { Box, BoxProps, Flex, Text, darken, isLightColor } from '@mantine/core';

import styles from './TaskStatusBadge.module.css';

type TaskStatusBadgeProps = {
  status?: ApiCommon.ITaskStatus;
  filled?: boolean;
  placeholder?: string;
  clickable?: boolean;
  withLabel?: boolean;
  iconSize?: number;
  truncate?: boolean;
} & BoxProps;

const truncateStyles = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  minWidth: 0,
};

const Badge = (
  {
    status,
    filled = true,
    placeholder = 'Без статуса',
    clickable,
    withLabel = true,
    iconSize,

    truncate = false,
    ...props
  }: TaskStatusBadgeProps,
  ref: Ref<HTMLDivElement>,
) => {
  const bgColor = status?.Color || 'var(--mantine-color-gray-2)';

  const textColor = useMemo(() => {
    if (!status?.Color) return 'var(--mantine-color-black)';

    if (isLightColor(status.Color, 0.6)) {
      return '#343A40';
    }

    return '#fff';
  }, [status]);

  const label = status?.Name ?? placeholder;

  if (filled) {
    return (
      <Box
        {...props}
        miw={0}
        ref={ref}
        className={styles.root}
        data-clickable={clickable}
        style={{
          '--badge-bg': bgColor,
          '--badge-bg-hover': darken(bgColor, 0.1),
          '--badge-color': textColor,
          ...props.style,
        }}
      >
        <div style={truncate ? truncateStyles : undefined}>{label}</div>
      </Box>
    );
  }

  return (
    <Flex gap={8} ref={ref} fz={14} {...props}>
      <Box
        className={styles.box}
        style={{
          width: iconSize ? iconSize + 'px' : undefined,
          height: iconSize ? iconSize + 'px' : undefined,
          '--badge-bg': bgColor,
        }}
      />
      {withLabel && (
        <Text truncate={truncate} fz={14} fw={400}>
          {label}
        </Text>
      )}
    </Flex>
  );
};

export const TaskStatusBadge = forwardRef(Badge);

import { ApiProjectTask } from '@/api/tasks';
import { CreateTaskFormData } from '@/hooks/useCreateTask';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface CreateTaskModalState {
  opened: boolean;
  defaultValues?: Partial<CreateTaskFormData>;
  afterCreate?: (res: ApiProjectTask.ITask) => void;
  allowedProjectIds?: number[];
}

const initialState: CreateTaskModalState = {
  opened: false,
};

export type OpenCreateTaskModalProps = Omit<
  Partial<CreateTaskModalState>,
  'opened'
>;

export const createTaskModalSlice = createSlice({
  name: 'createTaskModal',
  initialState,
  reducers: {
    close(state) {
      state.opened = false;
    },
    open(state, action: PayloadAction<OpenCreateTaskModalProps>) {
      state.opened = true;
      state.defaultValues = action.payload.defaultValues;
      state.afterCreate = action.payload.afterCreate;
      state.allowedProjectIds = action.payload.allowedProjectIds;
    },
  },
});

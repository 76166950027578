import { useMemo } from 'react';

import { projectsApi } from '@/api/projects';
import { isNumberValue } from '@/hooks/useNumberQueryParam';

export const useFolderData = (folderId?: number | null) => {
  const { data } = projectsApi.endpoints.getProjects.useQueryState();

  return useMemo(
    () =>
      isNumberValue(folderId) ? data?.folderEntities[folderId] || null : null,
    [data?.folderEntities, folderId],
  );
};

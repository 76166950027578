import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { ApiProjectTask } from '@/api/tasks';
import { AttachmentsGalleryModal } from '@/components/ui/AttachmentsGalleryModal';
import { useAccess } from '@/hooks/useAccess';
import { Flex, ScrollArea, Stack } from '@mantine/core';

import { useAttachments } from '../../hooks/useAttachments';
import { Actions } from './components/Actions';
import { ListAttachment } from './components/ListAttachment/ListAttachment';
import { StripAttachment } from './components/StripAttachment/StripAttachment';

import styles from './Attachments.module.css';

export const Attachments = memo(({ task }: { task: ApiProjectTask.ITask }) => {
  const { t } = useTranslation();
  const access = useAccess();

  const {
    viewType,
    setViewType,
    showAttach,
    setShowAttach,
    opened,
    setOpened,
    images,
    handleAttachClick,
  } = useAttachments(task);

  if (!task.Attachments?.length) return null;

  return (
    <Stack gap={12} pl={8}>
      <Flex justify={'space-between'}>
        <p className={styles.subtitle}>
          {t('attachments.title')} ({task.Attachments?.length})
        </p>
        <Actions
          task={task}
          viewType={viewType}
          onViewTypeChange={setViewType}
        />
      </Flex>
      {viewType === 'strip' ? (
        <ScrollArea offsetScrollbars scrollbars="x" type="always">
          <Flex gap={12}>
            {task.Attachments?.map((el) => (
              <StripAttachment
                onClick={handleAttachClick}
                attachment={el}
                key={el.Id}
                readonly={!access.tasks.edit}
              />
            ))}
          </Flex>
        </ScrollArea>
      ) : (
        <Stack gap={4}>
          {task.Attachments?.map((el) => (
            <ListAttachment
              onClick={handleAttachClick}
              attachment={el}
              key={el.Id}
              readonly={!access.tasks.edit}
            />
          ))}
        </Stack>
      )}
      <AttachmentsGalleryModal
        showAttach={showAttach}
        setShowAttach={setShowAttach}
        opened={opened}
        onOpenChange={setOpened}
        attachments={images}
      />
    </Stack>
  );
});

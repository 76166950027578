import { ApiProjectTask } from '@/api/tasks';
import { IFilledActivity } from '@/features/TaskPanel/components/Activity/types';
import { Group, Stack, Text } from '@mantine/core';

import { UserAvatar } from '../UserAvatar';
import { CommentHeader } from './components/CommentHeader';

import styles from './Comment.module.css';

interface HistoryCommentProps {
  task: Pick<ApiProjectTask.ITask, 'Id' | 'SprintId'>;
  activity: IFilledActivity;
}

export const HistoryComment = ({ task, activity }: HistoryCommentProps) => {
  return (
    <Group className={styles.comment} wrap="nowrap">
      <UserAvatar size={36} user={activity.Author} />
      <Stack gap={6} w="100%">
        <CommentHeader activity={activity} task={task} showType />
        <Text fz={14} c="dark" style={{ whiteSpace: 'pre-line' }}>
          {activity.Description}
        </Text>
      </Stack>
    </Group>
  );
};

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TaskLoader } from '@/components/shared/SkeletonLoaders/TaskLoader';
import { ErrorAlert } from '@/components/ui/ErrorAlert';
import { NoData } from '@/components/ui/NoData/NoData';
import { openTaskModal } from '@/features/TaskModal/store';
import { useGlobalTaskSearch } from '@/hooks/useGlobalTasksSearch';
import { ActionIcon, Flex, Loader, Stack, TextInput } from '@mantine/core';
import { IconSearch, IconX } from '@tabler/icons-react';

import { TaskCard } from '../../TaskCard';

export type TasksSearchProps = {
  onClose: () => void;
};

export const ModalContent = ({ onClose }: TasksSearchProps) => {
  const { t } = useTranslation();

  const {
    isSuccess,
    data,
    isLoading,
    isError,
    debouncedQuery,
    query,
    setQuery,
    isFetching,
  } = useGlobalTaskSearch();

  const options = useMemo(() => {
    if (!isSuccess) return null;

    const searchOptions = (data || []).map((task, index, arr) => (
      <TaskCard
        key={task.Id}
        task={task}
        border={index < arr.length - 1}
        onClick={() => {
          openTaskModal(task.Id);
          onClose();
        }}
      />
    ));

    if (!debouncedQuery) {
      return searchOptions;
    }

    return searchOptions;
  }, [data, debouncedQuery, isSuccess, onClose]);

  return (
    <Stack gap={'lg'}>
      <TextInput
        leftSection={
          isFetching ? <Loader size={16} /> : <IconSearch size={16} />
        }
        variant="filled"
        placeholder={t('nodata.noResults.searchPlaceholder')}
        value={query}
        onChange={(event) => setQuery(event.currentTarget.value)}
        data-autofocus
        rightSection={
          query && (
            <ActionIcon
              variant="subtle"
              color="gray"
              radius="md"
              aria-label="Clear"
              onClick={() => setQuery('')}
            >
              <IconX size={16} color="gray" />
            </ActionIcon>
          )
        }
        style={{ zIndex: 1 }}
      />
      <Flex h={320} justify={'center'}>
        {isLoading ? (
          <Loading />
        ) : isSuccess ? (
          data?.length === 0 ? (
            <NoData
              title={t('nodata.noResults.title')}
              description={t('nodata.noResults.shortDescription')}
              illustration={'noResults'}
              size={120}
            />
          ) : (
            <div style={{ height: '320px', width: '100%', overflow: 'auto' }}>
              {options}
            </div>
          )
        ) : isError ? (
          <ErrorAlert message={t('notification.error.searchTask')} w={'100%'} />
        ) : null}
      </Flex>
    </Stack>
  );
};

const Loading = () => (
  <Stack gap={4} w={'100%'}>
    {Array.from({ length: 6 }).map((_, i) => (
      <TaskLoader key={i} />
    ))}
  </Stack>
);

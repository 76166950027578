import { ReactNode, useState } from 'react';

import {
  Combobox,
  InputError,
  InputLabel,
  Skeleton,
  useCombobox,
} from '@mantine/core';

import { FieldValue } from './FieldValue';

export type SelectFieldProps = {
  onChange?: (v: string) => void;
  options?: ReactNode;
  value?: ReactNode;
  error?: React.ReactNode;
  loading?: boolean;
  label?: ReactNode;
  required?: boolean;
};

export const SelectField = ({
  options,
  value,
  onChange,
  error,
  loading,
  label,
  required,
}: SelectFieldProps) => {
  const [opened, setOpened] = useState(false);

  const combobox = useCombobox({
    opened,
    onOpenedChange: setOpened,
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  if (loading) {
    return <Skeleton h={42} />;
  }

  return (
    <div>
      <Combobox
        store={combobox}
        onOptionSubmit={(val) => {
          onChange?.(val);
          combobox.closeDropdown();
        }}
      >
        {!!label && (
          <InputLabel required={required} size="sm" mb={4}>
            {label}
          </InputLabel>
        )}
        <InputError />
        <Combobox.Target>
          <FieldValue
            onClick={combobox.toggleDropdown}
            opened={opened}
            error={!!error}
          >
            {value}
          </FieldValue>
        </Combobox.Target>

        <Combobox.Dropdown>
          <Combobox.Options>{options}</Combobox.Options>
        </Combobox.Dropdown>
      </Combobox>
      {!!error && (
        <InputError size="md" mt={5}>
          {error}
        </InputError>
      )}
    </div>
  );
};

import { matchPath, useLocation } from 'react-router-dom';

import { AppRoutes } from '@/config/links';
import { Avatar, Group, Skeleton, Stack } from '@mantine/core';

import { FolderLoader } from './FolderLoader';
import { KanbanLoader } from './KanbanLoader';
import { SprintLoader } from './SprintLoader';
import { TimelineLoader } from './TimelineLoader';

import styles from '../../ui/TasksSearchInput/TasksSearchInput.module.css';

export const MainContentLoader = () => {
  const location = useLocation();

  const isFolder = !!matchPath(AppRoutes.folder, location.pathname);
  const isMyIssues = !!matchPath(AppRoutes.myIssues, location.pathname);
  const isSprintList = !!matchPath(AppRoutes.sprintList, location.pathname);
  const isKanban = !!matchPath(AppRoutes.sprintKanban, location.pathname);
  const isTimeline = !!matchPath(AppRoutes.sprintTimeline, location.pathname);
  const isMenuItem = isFolder || isSprintList || isKanban || isTimeline;
  const isSome = isMenuItem || isMyIssues;

  return (
    <Stack p={12} gap={20} flex={1}>
      <Group style={{ gap: '16px 24px' }}>
        {isSome && <Skeleton height={36} className={styles.root} />}
        {isMenuItem && (
          <Avatar.Group spacing="sm">
            {Array.from({ length: 4 }).map((_, i) => (
              <Avatar key={i} radius="xl" size={36}>
                <Skeleton h="100%" circle />
              </Avatar>
            ))}
          </Avatar.Group>
        )}
      </Group>

      {isFolder && <FolderLoader />}
      {isMyIssues && <FolderLoader builder={[[3], [1, 2], [2, 3]]} />}
      {isSprintList && <SprintLoader />}
      {isKanban && <KanbanLoader />}
      {isTimeline && <TimelineLoader />}
    </Stack>
  );
};

import { ReactNode, useLayoutEffect } from 'react';
import { Navigate } from 'react-router-dom';

import { settingsApi } from '@/api/settings';
import { usersApi } from '@/api/users';
import { AppError } from '@/components/layout/AppError/AppError';
import { Preloader } from '@/components/ui/Preloader/Preloader';
import { AppRoutes } from '@/config/links';
import { useRedirectToList } from '@/hooks/useRedirectToList';

export const ProtectedRoute = ({ children }: { children?: ReactNode }) => {
  const user = usersApi.useGetCurrentUserQuery();
  const { data } = settingsApi.endpoints.get.useQueryState();
  useRedirectToList();

  useLayoutEffect(() => {
    if (!data) return;

    const settings = data.ModuleSettings;

    const hasAccess = settings.ModulesAccess.includes('Projects');
    const adminUrl = settings.ModuleUrls?.find((x) => x.Name === 'Admin')?.Url;

    if (adminUrl && !hasAccess) {
      window.location.replace(adminUrl + '/dashboard?from=Projects');
    }
  }, [data]);

  if (data?.UserId === -1) {
    const returnUrl = `${window.location.pathname}${window.location.search}`;

    if (returnUrl !== '/login' && returnUrl !== '/') {
      localStorage.setItem(loginReturnUrl, encodeURIComponent(returnUrl));
    }

    return <Navigate to={AppRoutes.login} replace />;
  }

  if (user.isLoading) return <Preloader />;

  if (user.isError) return <AppError />;

  return <>{children}</>;
};

export const loginReturnUrl = 'LOGIN_RETURN_URL';

import {
  ReactNode,
  Suspense,
  lazy,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { showErrorNotification } from '@/api/helpers/showNotifications';
import { ApiTaskLinks, tasksLinksApi } from '@/api/links';
import { ComplexSprintData } from '@/api/projects';
import { ApiProjectTask } from '@/api/tasks';
import { useGanttLocale } from '@/app/lang/useGanttLocale';
import { TimelineLoader } from '@/components/shared/SkeletonLoaders/TimelineLoader';
import { ErrorAlert } from '@/components/ui/ErrorAlert';
import { openTaskModal } from '@/features/TaskModal/store';
import { useAccess } from '@/hooks/useAccess';
import {
  DndContext,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { Group } from '@mantine/core';
import GanttComponent, { Gantt } from '@sinups/ds-gantt';

import { ColumnsVisible } from './components/ColumnsVisible';
import { DraggableOverlay } from './components/DraggableOverlay';
import {
  renderBar,
  renderBarThumb,
  renderGroupBar,
  renderInvalidBar,
} from './components/GanttCustomRender';
import { GanttUnitSelect } from './components/GanttUnitSelect';
import { useColumns } from './hooks/useColumns';
import { useDrag } from './hooks/useDrag';
import { useTimeUpdate } from './hooks/useTimeUpdate';
import { GanttItem, ganttPrepareSprint } from './transformTaskData';

import styles from './Timeline.module.css';

type TimelineProps = {
  sprint: ComplexSprintData;
  tasks?: ApiProjectTask.ITask[];
  emptyState?: ReactNode;
  isLoading?: boolean;
  isError?: boolean;
};

const RcGantt = lazy(() => import('@sinups/ds-gantt')) as typeof GanttComponent;

export const Timeline = memo(
  ({ sprint, tasks, isError, isLoading, emptyState = null }: TimelineProps) => {
    const { t } = useTranslation();
    const ganttLocale = useGanttLocale();
    const access = useAccess();

    const taskIds = useMemo(
      () =>
        tasks?.filter((t) => t.StartDate && t.DueDate).map((t) => t.Id) || [],
      [tasks],
    );

    const { data: links, isError: linksError } =
      tasksLinksApi.endpoints.getLinks.useQuery(
        {
          ids: taskIds,
        },
        { skip: !taskIds.length },
      );

    useEffect(() => {
      if (linksError) {
        showErrorNotification({ message: t('notification.error.getLinks') });
      }
    }, [linksError, t]);

    const dependencies = useMemo(() => {
      const typeRelation: Record<ApiTaskLinks.LinkType, Gantt.DependenceType> =
        {
          [ApiTaskLinks.LinkType.Relates]: 'finish_start',
          [ApiTaskLinks.LinkType.BlockedBy]: 'start_finish',
          [ApiTaskLinks.LinkType.Blocks]: 'finish_start',
          [ApiTaskLinks.LinkType.Duplicates]: 'finish_start',
          [ApiTaskLinks.LinkType.StartWith]: 'start_start',
          [ApiTaskLinks.LinkType.FinishWith]: 'finish_finish',
        };

      return links
        ?.filter(
          (el) => taskIds.includes(el.FromId) && taskIds.includes(el.ToId),
        )
        ?.map<Gantt.Dependence>((el) => ({
          from: el.FromId.toString(),
          to: el.ToId.toString(),
          type: typeRelation[el.Type],
          color: 'var(--mantine-color-gray-6)',
        }));
    }, [links, taskIds]);

    const [expand, setExpand] = useState<Record<number, boolean>>({});
    const [unit, setUnit] = useState<string | null>('day');

    const { handleDragStart, handleDragEnd, dragItem } = useDrag();

    const { columns, setShowColumns, showColumns } = useColumns({
      dragItem,
      sprint,
    });

    const { handleUpdate } = useTimeUpdate();

    const transformedData = useMemo(
      () => [ganttPrepareSprint(sprint, tasks || [], expand)],
      [expand, sprint, tasks],
    );

    const handleBarClick = useCallback((record: GanttItem) => {
      if (record.type === 'task') {
        openTaskModal(record._taskData.Id);
      }
    }, []);

    const handleExpand = useCallback(
      (record: Gantt.Record<GanttItem>, expand: boolean): void => {
        setExpand((prev) => ({ ...prev, [record.id]: expand }));
      },
      [],
    );

    const mouseSensor = useSensor(MouseSensor, {
      // Require the mouse to move by 10 pixels before activating
      activationConstraint: {
        delay: 100,
        distance: 0,
      },
    });
    const touchSensor = useSensor(TouchSensor, {
      // Press delay of 250ms, with tolerance of 5px of movement
      activationConstraint: {
        delay: 250,
        distance: 0,
      },
    });

    const sensors = useSensors(mouseSensor, touchSensor);

    if (isError) {
      return <ErrorAlert message={t('notification.error.getSprint')} />;
    }

    if (isLoading) return <TimelineLoader />;

    if (tasks?.length) {
      return (
        <div className={styles.root}>
          <Group mb={8} gap={8} justify="space-between">
            <ColumnsVisible value={showColumns} onChange={setShowColumns} />
            <GanttUnitSelect value={unit} onChange={setUnit} />
          </Group>

          <DndContext
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            sensors={sensors}
          >
            <div style={{ width: '100%', height: '100%' }}>
              <Suspense fallback={<TimelineLoader noHeader />}>
                <RcGantt<GanttItem>
                  data={transformedData}
                  columns={columns}
                  dependencies={dependencies}
                  // renders
                  renderBar={renderBar}
                  renderInvalidBar={renderInvalidBar}
                  renderGroupBar={renderGroupBar}
                  renderBarThumb={renderBarThumb}
                  // handlers
                  onExpand={handleExpand}
                  onUpdate={handleUpdate}
                  onBarClick={handleBarClick}
                  // styles
                  renderLeftText={() => null}
                  renderRightText={() => null}
                  alwaysShowTaskBar={false}
                  rowHeight={40}
                  tableIndent={20}
                  // other
                  showUnitSwitch={false}
                  unit={(unit || 'day') as Gantt.Sight}
                  locale={ganttLocale}
                  disabled={!access.tasks.edit}
                />
              </Suspense>
            </div>

            <DraggableOverlay />
          </DndContext>
        </div>
      );
    }

    return <>{emptyState}</>;
  },
);

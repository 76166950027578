import { useState } from 'react';

import { ComplexProjectData } from '@/api/projects';
import { projectTasksApi } from '@/api/tasks';
import { useDebouncedValue } from '@mantine/hooks';
import { isNumber } from 'lodash-es';

import { useFolderData } from './useFolderData';

export const useGlobalTaskSearch = (
  props: { skip?: boolean; projectId?: number | null } | void,
) => {
  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebouncedValue(query, 800);

  const folderData = useFolderData(props?.projectId);
  const projectIds = getTreeIds(folderData);

  const request = projectTasksApi.endpoints.getTasks.useQuery(
    {
      projectIds,
      sprintId: null,
      like: debouncedQuery,
      performerIds: null,
    },
    {
      skip: props?.skip,
    },
  );

  return { ...request, debouncedQuery, query, setQuery };
};

const getTreeIds = (folderData: ComplexProjectData | null): number[] | null => {
  if (!folderData) return null;

  return [folderData.Id, ...folderData.Children.map(getTreeIds).flat()].filter(
    isNumber,
  );
};

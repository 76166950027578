import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '@/app/store';
import { UserOption } from '@/components/ui/UserOption';
import { PerformerSelectDropdown } from '@/features/PerformerSelect';
import { CreateTaskFormData } from '@/hooks/useCreateTask';
import {
  ActionIcon,
  Button,
  Combobox,
  Flex,
  InputLabel,
  Text,
  useCombobox,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { useHotkeys } from '@mantine/hooks';
import { IconX } from '@tabler/icons-react';

import { FieldValue } from './FieldValue';

export const PerformerSelectField = ({
  form,
}: {
  form: UseFormReturnType<CreateTaskFormData>;
}) => {
  const { t } = useTranslation();

  const currentUser = useAppSelector((s) => s.usersCache.currentUser);

  const value = form.values.performer;

  const [opened, setOpened] = useState(false);

  const combobox = useCombobox({
    opened,
    onOpenedChange: setOpened,
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  useHotkeys([['M', () => form.setFieldValue('performer', currentUser)]]);

  return (
    <div>
      <InputLabel size="sm" mb={4}>
        {t('createTask.fields.performer.label')}
      </InputLabel>
      <Combobox
        keepMounted={false}
        store={combobox}
        position="bottom-end"
        shadow="md"
      >
        <Combobox.Target>
          <FieldValue
            onClick={combobox.toggleDropdown}
            opened={opened}
            error={!!form.errors['performer']}
          >
            {value ? (
              <Flex justify={'space-between'} w={'100%'} gap={8}>
                <UserOption user={value} />
                <ActionIcon
                  color="gray.7"
                  variant="subtle"
                  onClick={(e) => {
                    e.stopPropagation();
                    form.setFieldValue('performer', null);
                  }}
                >
                  <IconX size={16} />
                </ActionIcon>
              </Flex>
            ) : (
              <Text
                c={
                  'light-dark(var(--mantine-color-gray-5), var(--mantine-color-dark-3))'
                }
              >
                {t('createTask.fields.performer.placeholder')}
              </Text>
            )}
          </FieldValue>
        </Combobox.Target>

        <Combobox.Dropdown style={{ overflow: 'hidden' }}>
          <PerformerSelectDropdown
            value={value}
            projectId={form.values.sprint?.rootProjectId}
            onChange={(opt) => {
              form.setFieldValue('performer', opt || null);
              combobox.closeDropdown();
            }}
          />
        </Combobox.Dropdown>
      </Combobox>

      {!value && (
        <Button
          mt={4}
          variant="transparent"
          px={0}
          color="blue"
          size="compact-xs"
          onClick={() => form.setFieldValue('performer', currentUser)}
        >
          {t('createTask.fields.performer.description')}
        </Button>
      )}
    </div>
  );
};

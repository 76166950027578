import { AppShell, ScrollArea } from '@mantine/core';

import { Footer } from './Footer/Footer';
import { Header } from './Header/Header';
import { Navigation } from './Navigation/Navigation';

export const Aside = () => {
  return (
    <AppShell.Navbar style={{ zIndex: 100 }}>
      <Header />
      <AppShell.Section grow scrollbarSize={6} component={ScrollArea}>
        <Navigation />
      </AppShell.Section>
      <Footer />
    </AppShell.Navbar>
  );
};

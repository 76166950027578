import { ApiCommon } from '@/api/common';
import { ComplexSprintData } from '@/api/projects';
import { ApiTaskType } from '@/api/taskTypes';
import { ApiProjectTask } from '@/api/tasks';
import { useAccess } from '@/hooks/useAccess';
import { Draggable, DraggableProvided, Droppable } from '@hello-pangea/dnd';

import { EditableTaskCard } from '../TaskCard';
import { CreateTask } from './CreateTask/CreateTask';
import { TasksGroupCard } from './TasksGroupCard';

interface TaskGroupProps {
  status: ApiCommon.ITaskStatus;
  tasks: ApiProjectTask.ITask[];
  sprint: ComplexSprintData;
  statuses: ApiCommon.ITaskStatus[];
  types: ApiTaskType.IType[];
}

export const TasksGroup = ({
  status,
  tasks,
  sprint,
  statuses,
  types,
}: TaskGroupProps) => {
  const access = useAccess();

  return (
    <TasksGroupCard status={status} count={tasks.length}>
      <Droppable droppableId={status.Id.toString()}>
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {tasks.map((task, index, arr) => (
              <Draggable
                isDragDisabled={!access.tasks.edit}
                key={task.Id}
                draggableId={task.Id.toString()}
                index={index}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style,
                    )}
                  >
                    <EditableTaskCard
                      key={task.Id}
                      task={task}
                      statuses={statuses}
                      types={types}
                      border={index < arr.length - 1 || access.tasks.create}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      {access.tasks.create && sprint && (
        <CreateTask
          types={types}
          sprint={sprint}
          status={status?.Id}
          prevOrder={tasks[tasks.length - 1]?.Order}
        />
      )}
    </TasksGroupCard>
  );
};

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver
    ? 'light-dark(var(--mantine-color-gray-2), var(--mantine-color-dark-5))'
    : 'none',
});

const getItemStyle = (
  isDragging: boolean,
  draggableStyle: DraggableProvided['draggableProps']['style'],
) => ({
  boxShadow: isDragging ? '0 0 0 1px var(--mantine-color-blue-5)' : 'none',
  ...draggableStyle,
});

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button, Center, Group, Stack, Text, Title } from '@mantine/core';
import { IconChevronLeft, IconHome2 } from '@tabler/icons-react';

import styles from './NotFoundPage.module.css';

export const NotFoundPage = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  return (
    <Center
      style={{
        height: '100dvh',
        color:
          'light-dark(var(--mantine-color-dark-5), var(--mantine-color-gray-5))',
      }}
    >
      <Stack>
        <div className={styles.label}>404</div>
        <Title className={styles.title}>{t('404.title')}</Title>
        <Text fz="md" ta="center" className={styles.description}>
          {t('404.description', { entity: t('404.entities.page') })}
        </Text>
        <Group justify="center" mt="md">
          <Button
            size="md"
            variant="subtle"
            leftSection={<IconChevronLeft size={18} />}
            onClick={() => navigate(-1)}
          >
            {t('404.goBack')}
          </Button>
          <Button
            size="md"
            variant="subtle"
            leftSection={<IconHome2 size={18} />}
            onClick={() => navigate('/')}
          >
            {t('404.goHome')}
          </Button>
        </Group>
      </Stack>
    </Center>
  );
};

import { Box, Group, Paper, Skeleton, Stack } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

import { StatusLoader } from './StatusLoader';
import { TaskLoader } from './TaskLoader';

interface IProps {
  /**
   * taskCount = 4;
   *
   * builder={taskCount}
   */
  builder?: number;
}

export const TaskGroupLoader = ({ builder = 4 }: IProps) => {
  const isDesktop = useMediaQuery('(min-width: 992px)', true, {
    getInitialValueInEffect: false,
  });

  return (
    <Paper withBorder={!isDesktop} radius={8} p={!isDesktop ? 12 : 0}>
      <Stack gap={12}>
        <Group gap={8} wrap="nowrap" h={26}>
          <Skeleton height={16} width={16} mx={3} circle />
          <StatusLoader />
          <Skeleton height={20} width={60} />
        </Group>

        <Box pl={isDesktop === false ? 0 : 28}>
          {Array.from({ length: builder }).map((_, i) => (
            <TaskLoader key={i} />
          ))}
          <Skeleton height={12} w={150} mb={8} mt={10} />
        </Box>
      </Stack>
    </Paper>
  );
};

import { useMemo } from 'react';

import { ApiProjectTask } from '@/api/tasks';
import { useAppSelector } from '@/app/store';
import { useFolderData } from '@/hooks/useFolderData';

export const usePerformerData = (task: ApiProjectTask.ITask) => {
  const project = useFolderData(task.ProjectId);

  const performerId = useMemo(
    () =>
      task.PerformerId && project?.UserIds.includes(task.PerformerId)
        ? task.PerformerId
        : null,
    [task.PerformerId, project?.UserIds],
  );

  return useAppSelector((s) =>
    performerId ? s.usersCache.entities[performerId] : null,
  );
};

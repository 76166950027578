import { Flex } from '@mantine/core';
import { IconGripVertical } from '@tabler/icons-react';

export const DragIcon = () => {
  return (
    <Flex
      align={'center'}
      justify={'center'}
      w={36}
      h={36}
      style={{
        flexShrink: 0,
        cursor: 'grab',
      }}
    >
      <IconGripVertical size={18} color="var(--mantine-color-gray-5)" />
    </Flex>
  );
};

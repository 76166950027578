import { ApiCommon } from '@/api/common';
import { Ellipsis } from '@/components/ui/Ellipsis';
import { UserAvatar } from '@/components/ui/UserAvatar';
import { Box, Flex, Indicator } from '@mantine/core';
import { IconX } from '@tabler/icons-react';

import { ParticipantOption } from '../types';

export const UserOption = <USER extends ParticipantOption>({
  user,
}: {
  user: USER;
}) => {
  return (
    <>
      <Flex align={'center'} gap={12} miw={0}>
        <Indicator
          styles={{
            indicator: {
              padding: 0,
            },
          }}
          inline
          size={18}
          label={<IconX size={10} stroke={4} />}
          offset={6}
          withBorder
          color="red.5"
          position="bottom-end"
          disabled={user.Status !== ApiCommon.UserStatus.Banned}
        >
          <UserAvatar size={36} user={user} />
        </Indicator>
        <Box miw={0}>
          <Ellipsis c="light-dark(var(--mantine-color-gray-8), var(--mantine-color-dark-0))">
            {user.Name}
          </Ellipsis>
          {user.Email && (
            <Ellipsis c="gray.6" fz={12}>
              {user.Email}
            </Ellipsis>
          )}
        </Box>
      </Flex>
    </>
  );
};

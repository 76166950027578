import { useTranslation } from 'react-i18next';

import { NoData } from '@/components/ui/NoData/NoData';

export const NoResults = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation();

  return (
    <NoData
      illustration={'noResults'}
      title={t('nodata.noResults.title')}
      description={t('nodata.noResults.description')}
      buttons={[
        {
          buttonText: t('nodata.noResults.action'),
          handleClick: onClick,
        },
      ]}
    />
  );
};

import { PropsWithChildren } from 'react';

import { Text } from '@mantine/core';

import styles from './Shorcut.module.css';

export const Shortcut = ({ children }: PropsWithChildren) => {
  return (
    <Text fw={700} className={styles.shortcut}>
      {children}
    </Text>
  );
};

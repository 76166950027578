import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { projectsApi } from '@/api/projects';
import { usersApi } from '@/api/users';
import { TasksSearchInput } from '@/components/ui/TasksSearchInput/TasksSearchInput';
import { CUSTOMER } from '@/config/constants';
import { AppRoutes } from '@/config/links';
import { ProjectUsers } from '@/features/ProjectUsers';
import { Sprint } from '@/features/Sprint';
import { generateLink } from '@/helpers/generateLink';
import { Grid, Group, Stack } from '@mantine/core';
import { useInputState } from '@mantine/hooks';

import { useFolderData } from '../../../../hooks/useFolderData';
import { useTasksData } from '../../../../hooks/useTasksData';
import { FolderButton } from '../FolderButton';
import { NoFolder } from '../NoFolder';
import { ProjectNoData } from '../ProjectNoData';

type BoardProps = { projectId: number };

export const Board = ({ projectId }: BoardProps) => {
  const { t } = useTranslation();

  const project = useFolderData(projectId);

  const children = project?.Children || [];
  const sprints = project?.Sprints || [];

  const hasData = children.length > 0 || sprints.length > 0;

  usersApi.useGetByIdQuery({ ids: project?.UserIds || [] });
  projectsApi.useGetAvailableStatusesQuery(
    {
      entityType: project?.statusesEntityType || 0,
      entityIds: [project?.statusesEntityId || 0],
    },
    { skip: !project },
  );

  const [performerIds, setPerformerIds] = useState<number[]>([]);
  const [search, setSearch] = useInputState('');

  const { groupedBySprint, isError, isLoading, isFetching } = useTasksData({
    projectId,
    performerIds,
    search,
  });

  // очиста поиска при переходе между папками
  useEffect(() => {
    setSearch('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  if (!project) {
    return <NoFolder />;
  }

  return (
    <>
      <Helmet>
        <title>
          {project.Name} | {t('appName.' + CUSTOMER)}
        </title>
      </Helmet>

      <Stack gap="lg" flex={1} p={12}>
        <Group style={{ gap: '16px 24px' }}>
          <TasksSearchInput
            value={search}
            onChange={setSearch}
            loading={isFetching}
          />
          <ProjectUsers
            selected={performerIds}
            onSelect={setPerformerIds}
            projectId={project.rootProjectId}
          />
        </Group>
        <Stack flex={1} gap={0}>
          {hasData ? (
            <Stack>
              {!!children.length && (
                <Grid>
                  {children.map((folder) => (
                    <Grid.Col span={'auto'} key={folder.Id}>
                      <NavLink
                        to={generateLink(AppRoutes.folder, {
                          folderId: folder.Id,
                        })}
                      >
                        <FolderButton folder={folder} />
                      </NavLink>
                    </Grid.Col>
                  ))}
                </Grid>
              )}

              {sprints.map((sprint) => (
                <Sprint
                  tasks={groupedBySprint[sprint.Id]}
                  key={sprint.Id}
                  sprint={sprint}
                  isError={isError}
                  isLoading={isLoading}
                />
              ))}
            </Stack>
          ) : (
            <ProjectNoData project={project} />
          )}
        </Stack>
      </Stack>
    </>
  );
};

import { memo, useState } from 'react';

import { ApiProjectTask } from '@/api/tasks';
import { Stack } from '@mantine/core';

import { AllTypes } from './components/AllTypes';
import { Comments } from './components/Comments';
import { Filters } from './components/Filters';
import { History } from './components/History';

import styles from './Activity.module.css';

interface IProps {
  task: ApiProjectTask.ITask;
}

export const Activity = memo(({ task }: IProps) => {
  const [filter, setFilter] = useState('comments');

  return (
    <>
      <Stack gap={8} mt={16}>
        <p className={styles.subtitle}>Активность</p>
        <Filters value={filter} onChange={setFilter} />
      </Stack>

      {filter === 'all' && <AllTypes task={task} />}
      {filter === 'comments' && <Comments task={task} />}
      {filter === 'history' && <History task={task} />}
    </>
  );
});

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ApiProjectAttributes, projectsApi } from '@/api/projects';
import { ApiProjectTask } from '@/api/tasks';
import { useFolderData } from '@/hooks/useFolderData';
import { Stack, Title } from '@mantine/core';
import { filter, orderBy, uniqBy } from 'lodash-es';

import { CustomField } from './CustomField';
import { LabelExternal } from './LabelExternal';

import styles from '../../TaskPanel.module.css';

export const CustomFields = ({ task }: { task: ApiProjectTask.ITask }) => {
  const { t } = useTranslation();

  const projectData = useFolderData(task.ProjectId);
  const { data, isLoading } = projectsApi.useGetAttributesQuery({
    projectId: projectData?.rootProjectId ?? 0,
  });

  const preparedData = useMemo<
    (ApiProjectAttributes.IAttribute & { external?: boolean })[]
  >(() => {
    const fromTask = filter(
      uniqBy(task.Attributes, 'AttributeId'),
      'Value',
    ).map((el) => ({ ...el, external: true }));
    const combined = uniqBy([...(data ?? []), ...fromTask], 'AttributeId');

    return orderBy(
      combined,
      ['external', 'ValueType', 'Name'],
      ['desc', 'desc', 'asc'],
    );
  }, [data, task.Attributes]);

  if (!preparedData.length || isLoading) return null;

  return (
    <Stack gap={8} pl={8}>
      <Title order={2} className={styles.subtitle} pl={0}>
        {t('customFields.title')}:
      </Title>
      {preparedData.map((el) => {
        const record = task.Attributes.find(
          (a) => a.AttributeId === el.AttributeId,
        );

        return (
          <CustomField
            key={el.AttributeId}
            name={el.Name}
            type={el.ValueType}
            value={record?.Value}
            taskId={task.Id}
            id={el.AttributeId}
            recordId={record?.Id}
            isExternal={el.external}
          />
        );
      })}
      <LabelExternal data={preparedData} />
    </Stack>
  );
};

import { useMemo } from 'react';

import { ComplexSprintData, projectsApi } from '@/api/projects';
import { orderBy } from 'lodash-es';

export const useStatusesData = (sprint?: ComplexSprintData | null) => {
  const { data: sprintStatuses, ...rest } =
    projectsApi.useGetAvailableStatusesQuery(
      {
        entityType: sprint?.statusesEntityType || 0,
        entityIds: [sprint?.statusesEntityId || 0],
      },
      { skip: !sprint },
    );

  const statuses = useMemo(
    () => orderBy(sprintStatuses, 'Order') || [],
    [sprintStatuses],
  );

  return { ...rest, statuses };
};

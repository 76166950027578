import {
  addMilliseconds,
  endOfDay,
  isAfter,
  isBefore,
  startOfDay,
  subMilliseconds,
} from 'date-fns';

export function isInRange(date: Date, range: [Date, Date]) {
  const _range = [...range].sort((a, b) => a.getTime() - b.getTime());
  return (
    isBefore(subMilliseconds(startOfDay(_range[0]), 1), date) &&
    isAfter(addMilliseconds(endOfDay(_range[1]), 1), date)
  );
}

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface CreateTaskModalState {
  skeletons: Record<
    'topPreloader' | 'navigation' | 'mainContent' | 'tasks',
    boolean
  >;
}

const initialState: CreateTaskModalState = {
  skeletons: {
    topPreloader: false,
    navigation: false,
    mainContent: false,
    tasks: false,
  },
};

export const createDevSlice = createSlice({
  name: 'devSlice',
  initialState,
  reducers: {
    setSkeletons(
      state,
      action: PayloadAction<Partial<CreateTaskModalState['skeletons']>>,
    ) {
      Object.assign(state.skeletons, action.payload);
    },
  },
});

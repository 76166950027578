import { customBaseQuery } from '@/api/customBaseQuery';
import { createApi } from '@reduxjs/toolkit/query/react';
import { uniq } from 'lodash-es';

import { ApiTaskLinks } from './types';

export const tasksLinksApi = createApi({
  reducerPath: 'tasksLinksApi',
  baseQuery: customBaseQuery('/api'),
  tagTypes: ['Link', 'Task'],
  endpoints: (builder) => ({
    createLink: builder.mutation<
      ApiTaskLinks.ICreateResponse,
      ApiTaskLinks.ICreateRequest
    >({
      query: (params) => ({
        url: `/v1/ProjectTaskLinks/Bulk`,
        method: 'POST',
        body: params,
      }),
      invalidatesTags: (response) => {
        if (!response) return [];

        const taskIds = uniq([
          ...response.map((t) => t.FromId),
          ...response.map((t) => t.ToId),
        ]);

        return taskIds.map((id) => ({ type: 'Task', id }));
      },
    }),
    getLinks: builder.query<
      ApiTaskLinks.IGetResponse,
      ApiTaskLinks.IGetRequest
    >({
      query: (params) => ({
        url: `/v1/ProjectTaskLinks/GetLinks`,
        method: 'POST',
        body: params,
      }),
      providesTags: (response, _, args) => {
        if (!response) return [];

        const tags: { type: 'Link' | 'Task'; id: number }[] = args.ids.map(
          (id) => ({ type: 'Task', id }),
        );

        return response.reduce((acc, item) => {
          acc.push(
            { type: 'Task', id: item.FromId },
            { type: 'Task', id: item.ToId },
            { type: 'Link', id: item.Id },
          );

          return acc;
        }, tags);
      },
    }),
    deleteLink: builder.mutation<void, ApiTaskLinks.ILink>({
      query: (params) => ({
        url: `/v1/ProjectTaskLinks?id=${params.Id}`,
        method: 'DELETE',
        body: params,
      }),
      invalidatesTags: (_, __, args) => {
        return [{ type: 'Link', id: args.Id }];
      },
      onQueryStarted: async (args, { dispatch, queryFulfilled, getState }) => {
        const params = tasksLinksApi.util.selectCachedArgsForQuery(
          getState(),
          'getLinks',
        );

        const patchResults = params.map((el) => {
          return dispatch(
            tasksLinksApi.util.updateQueryData('getLinks', el, (draft) => {
              for (let i = 0; i < draft.length; i++) {
                if (draft[i].Id === args.Id) {
                  draft.splice(i, 1);
                  break;
                }
              }
            }),
          );
        });

        try {
          await queryFulfilled;
        } catch {
          patchResults.forEach((el) => el.undo());
        }
      },
    }),
  }),
});

import { ApiProjectTask } from '../tasks';

export namespace ApiTaskLinks {
  export interface ILink {
    Id: number;
    ToId: number;
    FromId: number;
    ProjectId: number;
    Type: LinkType;

    From?: ApiProjectTask.ITask;
    To?: ApiProjectTask.ITask;
  }

  export enum LinkType {
    Relates = 0,
    BlockedBy = 1,
    Blocks = 2,
    Duplicates = 3,
    StartWith = 4,
    FinishWith = 5,
  }

  export type ICreateRequest = {
    ToId: number;
    FromId: number;
    Type: LinkType;
  }[];

  export type ICreateResponse = ILink[];

  export type IGetRequest = { ids: number[] };

  export type IGetResponse = ILink[];
}

import { useMemo } from 'react';

import { ComplexProjectData } from '@/api/projects';
import { usersApi } from '@/api/users';
import { UserAvatar } from '@/components/ui/UserAvatar';
import { useFolderData } from '@/hooks/useFolderData';
import {
  Avatar,
  Checkbox,
  Flex,
  Group,
  Menu,
  Text,
  Tooltip,
  useComputedColorScheme,
} from '@mantine/core';
import { xor } from 'lodash-es';

import { EditUsersModal } from '../EditUsersModal';

import styles from './ProjectUsers.module.css';

const MAX_SHOW_COUNT = 7;

type ComponentProps = {
  project: ComplexProjectData;
  selected?: number[];
  onSelect?: (v: number[]) => void;
};

const Component = ({ project, selected, onSelect }: ComponentProps) => {
  const { data } = usersApi.useGetByIdQuery({
    ids: project.UserIds,
  });

  const isLightTheme =
    useComputedColorScheme('light', {
      getInitialValueInEffect: true,
    }) === 'light';

  const { users, hiddenUsers } = useMemo(() => {
    const users = data?.slice(0, MAX_SHOW_COUNT).filter(Boolean) || [];
    const hiddenUsers =
      data?.slice(MAX_SHOW_COUNT, project.UserIds.length).filter(Boolean) || [];

    return {
      users,
      hiddenUsers,
    };
  }, [project, data]);

  const hasSelectedHiddenUsers = useMemo(
    () => hiddenUsers.some((el) => selected?.includes(el.Id)),
    [hiddenUsers, selected],
  );

  return (
    <Tooltip.Group openDelay={300} closeDelay={100}>
      <Avatar.Group spacing="sm">
        {users.map((user, index) => {
          const isSelected = selected?.includes(user.Id);
          return (
            <Tooltip key={user?.Id ?? index} label={user?.Name} withArrow>
              <UserAvatar
                size={36}
                onClick={() => onSelect?.(xor(selected, [user.Id]))}
                user={user}
                style={
                  isSelected
                    ? {
                        boxShadow: '0 0 0 2px var(--mantine-color-blue-5)',
                        cursor: 'pointer',
                        zIndex: 1,
                      }
                    : { cursor: 'pointer' }
                }
              />
            </Tooltip>
          );
        })}
        {hiddenUsers.length > 0 && (
          <Menu
            closeOnItemClick={false}
            transitionProps={{ transition: 'fade-down', duration: 150 }}
          >
            <Menu.Target>
              <Avatar
                radius="xl"
                color={isLightTheme ? 'dark.5' : 'light.5'}
                style={
                  hasSelectedHiddenUsers
                    ? {
                        boxShadow: '0 0 0 2px var(--mantine-color-blue-5)',
                        cursor: 'pointer',
                        zIndex: 1,
                      }
                    : { cursor: 'pointer' }
                }
              >
                +{hiddenUsers.length}
              </Avatar>
            </Menu.Target>
            <Menu.Dropdown mah={300} style={{ overflow: 'auto' }}>
              {hiddenUsers.map((user, index) => {
                const isSelected = selected?.includes(user.Id);

                return (
                  <Menu.Item key={user?.Id ?? index}>
                    <Checkbox
                      checked={isSelected}
                      classNames={{
                        root: styles.root,
                        labelWrapper: styles.labelWrapper,
                      }}
                      styles={{
                        body: {
                          display: 'flex',
                          alignItems: 'center',
                        },
                      }}
                      label={
                        <Flex>
                          <UserAvatar
                            size={36}
                            user={user}
                            style={{ cursor: 'default' }}
                          />
                          <Text fw={400}> {user?.Name}</Text>
                        </Flex>
                      }
                      onChange={() => onSelect?.(xor(selected, [user.Id]))}
                    />
                  </Menu.Item>
                );
              })}
            </Menu.Dropdown>
          </Menu>
        )}
      </Avatar.Group>
    </Tooltip.Group>
  );
};

type ProjectUsersProps = {
  projectId: number;
  selected?: number[];
  onSelect?: (v: number[]) => void;
};

export const ProjectUsers = ({
  projectId,
  selected,
  onSelect,
}: ProjectUsersProps) => {
  const project = useFolderData(projectId);

  if (!project) return;

  return (
    <Group gap={12} wrap="nowrap">
      <Component project={project} selected={selected} onSelect={onSelect} />
      <EditUsersModal project={project} />
    </Group>
  );
};

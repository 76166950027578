import { Box, Group, Skeleton, Stack } from '@mantine/core';

const CommentsLoading = () => {
  return (
    <Box>
      <Input />
      <Stack gap={16}>
        <Comment />
        <Comment />
      </Stack>
    </Box>
  );
};

const Input = () => (
  <Group gap={8} my={18} wrap="nowrap">
    <Skeleton circle h={32} w={32} style={{ flexShrink: 0 }} />
    <Skeleton h={32} />
  </Group>
);

const Comment = () => (
  <Group gap={8} wrap="nowrap" align="start">
    <Skeleton circle h={32} w={32} style={{ flexShrink: 0 }} />
    <Stack gap={6} w="100%">
      <Group gap={8} mb={6}>
        <Skeleton h={16} w={120} />
        <Skeleton h={16} w={50} />
      </Group>
      <Skeleton h={14} />
      <Skeleton h={14} />
      <Skeleton h={14} w="80%" />
    </Stack>
  </Group>
);

export default Object.assign(CommentsLoading, {
  Input,
  Comment,
});

import { ApiTaskLinks } from '@/api/links';

export const getLinkType = (link: ApiTaskLinks.ILink, taskId: number) => {
  if (link.FromId !== taskId) return oppositeTypes[link.Type];
  return link.Type;
};

const oppositeTypes: Record<ApiTaskLinks.LinkType, ApiTaskLinks.LinkType> = {
  [ApiTaskLinks.LinkType.Relates]: ApiTaskLinks.LinkType.Relates,
  [ApiTaskLinks.LinkType.BlockedBy]: ApiTaskLinks.LinkType.Blocks,
  [ApiTaskLinks.LinkType.Blocks]: ApiTaskLinks.LinkType.BlockedBy,
  [ApiTaskLinks.LinkType.Duplicates]: ApiTaskLinks.LinkType.Duplicates,
  [ApiTaskLinks.LinkType.StartWith]: ApiTaskLinks.LinkType.FinishWith,
  [ApiTaskLinks.LinkType.FinishWith]: ApiTaskLinks.LinkType.StartWith,
};

import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { ApiCommon } from '@/api/common';
import { ComplexSprintData } from '@/api/projects';
import { TaskStatusBadge } from '@/components/ui/badges/TaskStatusBadge/TaskStatusBadge';
import { openCreateTaskModal } from '@/features/CreateTaskModal';
import { useAccess } from '@/hooks/useAccess';
import { ActionIcon, Flex, Stack, Text, Tooltip } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';

import styles from './TasksColumn.module.css';

interface TasksColumnProps {
  count: number;
  status?: ApiCommon.ITaskStatus;
  sprint: ComplexSprintData;
  order?: number;
}

export const TasksColumn = ({
  status,
  count,
  sprint,
  order,
  children,
}: PropsWithChildren<TasksColumnProps>) => {
  const { t } = useTranslation();
  const access = useAccess();

  return (
    <div className={styles.root}>
      <Flex gap={8} py={8} justify={'space-between'}>
        <Flex gap={8} miw={0}>
          <Tooltip label={status?.Name} openDelay={400}>
            <TaskStatusBadge status={status} truncate />
          </Tooltip>
          <Text size="xs" c="dark.2">
            {t('createTask.taskCount', { count })}
          </Text>
        </Flex>
        {access.tasks.create && (
          <ActionIcon
            size={24}
            variant="subtle"
            color="gray.7"
            onClick={() =>
              openCreateTaskModal({
                defaultValues: { sprint, Status: status?.Id, Order: order },
              })
            }
          >
            <IconPlus size={18} />
          </ActionIcon>
        )}
      </Flex>
      <Stack gap={0} mt={6}>
        {children}
      </Stack>
    </div>
  );
};

import { useMemo } from 'react';

import { taskTypesApi } from '@/api/taskTypes';
import { useFoldersListData } from '@/hooks/useFoldersListData';
import { isNil, isNumber, uniq } from 'lodash-es';

export const useTaskTypesData = (
  projectIds: number | number[] | null | undefined,
) => {
  const computedIds = useMemo(() => {
    if (isNil(projectIds)) {
      return [];
    }

    if (isNumber(projectIds)) {
      return [projectIds].filter((v) => !isNaN(v));
    }

    return uniq(projectIds.filter((v) => !isNaN(v)));
  }, [projectIds]);

  const projects = useFoldersListData(computedIds);

  const { data, ...rest } = taskTypesApi.useGetTaskTypesQuery(
    projects.map((el) => el.rootProjectId || 0),
    { skip: !computedIds.length || !projects.length },
  );

  const taskTypes = useMemo(() => data?.list || [], [data]);

  return { ...rest, data, taskTypes };
};

import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ActionIcon,
  ActionIconProps,
  CopyButton as Component,
  MantineColor,
  Tooltip,
  getThemeColor,
  useMantineTheme,
} from '@mantine/core';
import { useHotkeys } from '@mantine/hooks';
import { IconCheck, IconCopy, IconProps } from '@tabler/icons-react';

import { modKey } from '../Shorcut/ShorcutsModal';

interface IProps extends ActionIconProps {
  value: string;
  color?: MantineColor;
  useHotkeys?: boolean;
  iconProps?: IconProps;
}

export const CopyButton = ({
  value,
  color,
  useHotkeys: _useHotkeys,
  iconProps: _iconProps,
  ...props
}: IProps) => {
  const { t } = useTranslation();
  const theme = useMantineTheme();

  const copyRef = useRef<(() => void) | null>(null);

  const iconProps = {
    size: 18,
    color: color ? getThemeColor(color, theme) : undefined,
    ..._iconProps,
  };

  useHotkeys([['mod + I', () => _useHotkeys && copyRef?.current?.()]]);

  return (
    <Component value={value}>
      {({ copied, copy }) => {
        copyRef.current = copy;

        return (
          <Tooltip
            label={t(`copyButton.${copied ? 'copied' : 'copyWithKey'}`, {
              key: `${modKey} + I`,
            })}
            withArrow
            position="top"
            opened={_useHotkeys ? copied || undefined : false}
          >
            <ActionIcon onClick={copy} variant="subtle" color="dark" {...props}>
              {copied ? (
                <IconCheck {...iconProps} />
              ) : (
                <IconCopy {...iconProps} />
              )}
            </ActionIcon>
          </Tooltip>
        );
      }}
    </Component>
  );
};

import React from 'react';

import {
  ProjectIcon,
  ProjectIconType,
} from '@/components/ui/icons/ProjectIcon';
import { NavLink, Text } from '@mantine/core';

import { ProjectType, SprintType } from '../types';
import { SprintItem } from './SprintItem';

interface MenuItemProps {
  project: ProjectType;
  onClick: (s: SprintType) => void;
  currentSprint?: number | null;
}

export const MenuItem: React.FC<MenuItemProps> = ({
  project,
  onClick,
  currentSprint,
}) => {
  return (
    <NavLink
      styles={{
        root: {
          borderRadius: '4px',
        },
      }}
      defaultOpened
      childrenOffset={12}
      component="li"
      leftSection={

        <ProjectIcon
          icon={project.isFolder ? ProjectIconType.folder : project.IconId}
          color={project.Color}
          name={project.Name}
        />

      }
      label={<Text truncate="end">{project.Name}</Text>}
    >
      {project.Children.map((child) => (
        <MenuItem
          key={child.Id}
          project={child}
          onClick={onClick}
          currentSprint={currentSprint}
        />
      ))}
      {project.Sprints.map((sprint) => (
        <SprintItem
          key={sprint.Id}
          sprint={sprint}
          onClick={onClick}
          currentSprint={currentSprint}
        />
      ))}
    </NavLink>
  );
};

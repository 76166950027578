import { PropsWithChildren } from 'react';

import { ApiProjectTask } from '@/api/tasks';
import { useDraggable } from '@dnd-kit/core';
import { Flex } from '@mantine/core';
import { IconGripVertical } from '@tabler/icons-react';

import styles from '../Timeline.module.css';

export const Draggable = ({
  task,
  children,
}: PropsWithChildren<{ task: ApiProjectTask.ITask }>) => {
  const { attributes, listeners, setNodeRef, isDragging } = useDraggable({
    id: task.Id,
    data: task,
  });

  if (isDragging) {
    return <div className={styles.dragSkeleton} />;
  }

  return (
    <Flex ref={setNodeRef} {...attributes}>
      <Flex
        align={'center'}
        justify={'center'}
        w={36}
        h={36}
        style={{
          flexShrink: 0,
          cursor: 'grab',
        }}
        {...listeners}
      >
        <IconGripVertical size={18} color="var(--mantine-color-gray-5)" />
      </Flex>
      {children}
    </Flex>
  );
};

import { RefObject, useEffect } from 'react';

export const FOCUSED_COMMENT_PARAM = 'comment';

export const useFocusScroll = (
  ref: RefObject<HTMLDivElement>,
  commentId: number,
) => {
  useEffect(() => {
    const searchParams = new URLSearchParams(document.location.search);

    const focusedParam = Number(searchParams.get(FOCUSED_COMMENT_PARAM));
    const isFocused = focusedParam === commentId;

    ref.current?.setAttribute('data-focused', `${isFocused}`);

    if (isFocused) {
      ref.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';

const isDev = import.meta.env.DEV;

export const customBaseQuery = (url: string) => {
  const baseQuery = fetchBaseQuery({
    baseUrl: url,
    prepareHeaders: async (headers) => {
      if (isDev) {
        await import('@/api/helpers/getCookieToken')
          .then((module) => {
            const auth = module.getCookieToken();
            if (auth) {
              headers.set('Authorization', ` ${auth}`);
            }
          })
          .catch((err) => {
            console.error('Failed to load the getCookieToken module', err);
          });
      }
      return headers;
    },
  });

  const isUserRequest = (args: string | FetchArgs) => {
    const notRedirect = ['/v1/user', '/v2/auth/login'];

    return (
      typeof args !== 'string' &&
      'url' in args &&
      notRedirect.includes(args.url.toLowerCase())
    );
  };

  const baseQueryWithReauth: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
  > = async (args, api, extraOptions) => {
    const result = await baseQuery(args, api, extraOptions);

    if (!isUserRequest(args) && result.error && result.error.status === 401) {
      window.location.replace('/login');
    }
    return result;
  };

  return baseQueryWithReauth;
};

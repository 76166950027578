import { useState } from 'react';

import { ComplexProjectData, ComplexSprintData } from '@/api/projects';
import { openCreateTaskModal } from '@/features/CreateTaskModal';
import { Kanban } from '@/features/Sprint';
import { ProjectAccessProvider } from '@/hooks/useAccess';
import { useOpenedSprintData } from '@/hooks/useOpenedSprintData';
import { Flex, Stack } from '@mantine/core';
import { useInputState } from '@mantine/hooks';

import { useFolderData } from '../../hooks/useFolderData';
import { useTasksData } from '../../hooks/useTasksData';
import { NoResults } from './components/NoResults';
import { NoSprint } from './components/NoSprint';
import { NoTasks } from './components/NoTasks';
import { SprintHeader } from './components/SprintHeader';
import { useSprintStatuses } from './hooks/useSprintStatuses';

export const KanbanSprintPage = () => {
  const sprintData = useOpenedSprintData();
  const projectData = useFolderData(sprintData?.ProjectId);

  if (!sprintData || !projectData) {
    return <NoSprint />;
  }

  return (
    <ProjectAccessProvider projectId={projectData.Id}>
      <Content sprintData={sprintData} projectData={projectData} />
    </ProjectAccessProvider>
  );
};

const Content = ({
  projectData,
  sprintData,
}: {
  sprintData: ComplexSprintData;
  projectData: ComplexProjectData;
}) => {
  const [performerIds, setPerformerIds] = useState<number[]>([]);
  const [search, setSearch] = useInputState('');

  const { groupedBySprint, isError, isLoading, isFetching, isSearching } =
    useTasksData({
      sprintId: sprintData.Id,
      performerIds,
      search,
    });
  const withFilters = Boolean(performerIds.length) || isSearching;

  useSprintStatuses(projectData);

  return (
    <Stack gap="lg" p={12} flex={1}>
      <SprintHeader
        sprintData={sprintData}
        search={search}
        setSearch={setSearch}
        performerIds={performerIds}
        setPerformerIds={setPerformerIds}
        loading={isFetching}
      />

      <Flex pos="relative" flex={1}>
        <Kanban
          sprint={sprintData}
          isError={isError}
          isLoading={isLoading}
          tasks={groupedBySprint[sprintData.Id]}
          emptyState={
            withFilters ? (
              <NoResults
                onClick={() => {
                  setPerformerIds([]);
                  setSearch('');
                }}
              />
            ) : (
              <NoTasks
                onClick={() =>
                  openCreateTaskModal({
                    defaultValues: {
                      sprint: sprintData,
                    },
                  })
                }
              />
            )
          }
        />
      </Flex>
    </Stack>
  );
};

import { ApiCommon } from '@/api/common';
import { showRequestNotifications } from '@/api/helpers/showNotifications';
import { ApiSprints, ComplexSprintData, projectsApi } from '@/api/projects';
import { ApiProjectTask, projectTasksApi } from '@/api/tasks';
import { store } from '@/app/store';
import { diffStatusesModalSlice } from '@/features/DiffStatusesModal/slice';
import { Text } from '@mantine/core';
import { t } from 'i18next';
import { orderBy } from 'lodash-es';

export const updateTaskSprint = ({
  sprint,
  status,
  task,
}: {
  task: ApiProjectTask.ITask;
  sprint: ApiSprints.ISprint;
  status?: number;
}) => {
  const request = store
    .dispatch(
      projectTasksApi.endpoints.updateTask.initiate({
        ...task,
        ProjectId: sprint.ProjectId,
        SprintId: sprint.Id,
        Status: status || task.Status,
      }),
    )
    .unwrap();

  return showRequestNotifications(request, {
    errorMsg: t('notification.error.moveTask'),
    successMsg: () => ({
      message: (
        <Text
          dangerouslySetInnerHTML={{
            __html: t('notification.success.moveTask', {
              location: sprint.Name,
            }),
          }}
        />
      ),
    }),
  });
};

const getStatuses = async <
  DATA extends {
    entityId: number;
    entityType: ApiCommon.Entity;
  },
>(
  data: DATA,
) => {
  const currentStatusesData = await store
    .dispatch(
      projectsApi.endpoints.getAvailableStatuses.initiate({
        entityType: data.entityType,
        entityIds: [data.entityId],
      }),
    )
    .unwrap();

  return orderBy(currentStatusesData, 'Order');
};

export const moveTask = async ({
  task,
  currentSprint,
  newSprint,
}: {
  task: ApiProjectTask.ITask;
  currentSprint: ComplexSprintData;
  newSprint: ComplexSprintData;
}) => {
  if (task.SprintId === newSprint.Id) return;

  const currentSprintStatuses = await getStatuses({
    entityId: currentSprint.statusesEntityId,
    entityType: currentSprint.statusesEntityType,
  });
  const newSprintStatuses = await getStatuses({
    entityId: newSprint.statusesEntityId,
    entityType: newSprint.statusesEntityType,
  });

  const currentStatus = currentSprintStatuses.find((s) => s.Id === task.Status);

  const newRelatedStatus = newSprintStatuses.find(
    (s) =>
      (s.PatternId !== null &&
        typeof s.PatternId !== 'undefined' &&
        s.PatternId === currentStatus?.PatternId) ||
      s.Id === currentStatus?.Id,
  );

  if (newRelatedStatus) {
    updateTaskSprint({ sprint: newSprint, task, status: newRelatedStatus.Id });
    return;
  }

  store.dispatch(
    diffStatusesModalSlice.actions.open({
      sprintId: newSprint.Id,
      task,
    }),
  );
};

import { ReactNode, memo } from 'react';

import { ColorPickerPopover } from '@/components/ui/ColorPicker/ColorPickerPopover';
import { Group, TextInput } from '@mantine/core';

import { useEntityFormContext } from '../../hooks/useForm';
import { IStatusForm } from '../../types';
import { DeleteAction } from '../DeleteAction';

export type StatusRowProps = {
  status?: IStatusForm;
  leftSection?: ReactNode;
  renderKey: number;
};

export const StatusRow = memo(
  ({ status, leftSection, renderKey }: StatusRowProps) => {
    const form = useEntityFormContext();

    const provide = form.getValues().statuses.provide;
    const pathKey = provide ? 'provided' : 'current';
    const path = `statuses.${pathKey}.${renderKey}`;
    const items = form.getValues().statuses[pathKey];
    const item = items[renderKey];
    const isPattern = !!(status ? status.patternId : item.patternId);

    return (
      <TextInput
        leftSection={
          <Group gap={8} pr={8}>
            {leftSection}

            <ColorPickerPopover
              key={form.key(`${path}.color`)}
              {...(status
                ? { value: status.color }
                : form.getInputProps(`${path}.color`))}
            />
          </Group>
        }
        leftSectionWidth={72}
        rightSection={
          <DeleteAction
            disabled={isPattern}
            onClick={() =>
              form.setFieldValue('statuses.current', (prev) =>
                item.state === 'new'
                  ? prev.filter((el) => el.id !== item.id)
                  : prev.map((el) =>
                      el.id === item.id
                        ? { ...el, state: 'removed' as const }
                        : el,
                    ),
              )
            }
          />
        }
        key={form.key(`${path}.name`)}
        {...(status
          ? { defaultValue: status.name }
          : form.getInputProps(`${path}.name`))}
      />
    );
  },
);

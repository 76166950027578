import { Group, Paper, Skeleton, Stack } from '@mantine/core';

import { StatusLoader } from './StatusLoader';

interface IProps {
  /**
   * taskCount = 4;
   *
   * builder={taskCount}
   */
  builder?: number;
}

export const KanbanTaskGroupLoader = ({ builder = 4 }: IProps) => {
  return (
    <Paper
      bg="light-dark(var(--mantine-color-gray-1), var(--mantine-color-dark-8))"
      maw={400}
      p={6}
      h="100%"
    >
      <Group gap={8} wrap="nowrap" h={42} justify="space-between">
        <Group gap={8} wrap="nowrap">
          <StatusLoader />
          <Skeleton height={20} width={60} />
        </Group>
        <Skeleton height={16} width={16} mx={3} circle />
      </Group>

      <Stack gap={0} mt={6}>
        {Array.from({ length: builder }).map((_, i) => (
          <Skeleton key={i} h={72} my={3} />
        ))}
        <Skeleton height={12} w={150} m={9} />
      </Stack>
    </Paper>
  );
};

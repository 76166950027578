import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { showRequestNotifications } from '@/api/helpers/showNotifications';
import { projectTasksApi } from '@/api/tasks';
import { Gantt } from '@sinups/ds-gantt';
import { isSameDay } from 'date-fns';

import { GanttItem } from '../transformTaskData';

export const useTimeUpdate = () => {
  const { t } = useTranslation();

  const [updateTask] = projectTasksApi.useUpdateTaskMutation();

  const handleUpdate = useCallback(
    async (
      record: Gantt.Record<GanttItem>,
      startDate: string,
      endDate: string,
    ) => {
      if (record.type !== 'task') return true;

      const { _taskData } = record;

      if (
        _taskData.StartDate &&
        isSameDay(startDate, _taskData.StartDate) &&
        _taskData.DueDate &&
        isSameDay(endDate, _taskData.DueDate)
      ) {
        return true;
      }

      return showRequestNotifications(
        updateTask({
          ..._taskData,
          StartDate: new Date(startDate).toISOString(),
          DueDate: new Date(endDate).toISOString(),
        }).unwrap(),
        {
          successMsg: t('notification.success.editTask'),
          errorMsg: t('notification.error.editTask'),
        },
      )
        .then(() => true)
        .catch(() => false);
    },
    [t, updateTask],
  );

  return { handleUpdate };
};

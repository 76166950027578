import { useTranslation } from 'react-i18next';

import { InheritedProps } from '@/types/inheritedProps';
import { ActionIcon, ActionIconProps, Tooltip } from '@mantine/core';
import { IconTrash } from '@tabler/icons-react';

export const DeleteAction = (
  props: InheritedProps<'button', ActionIconProps>,
) => {
  const { t } = useTranslation();

  return (
    <Tooltip label={t('common.action.delete')} disabled={props.disabled}>
      <ActionIcon size="md" variant="subtle" color="red.8" {...props}>
        <IconTrash size={16} />
      </ActionIcon>
    </Tooltip>
  );
};

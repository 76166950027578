import { ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { ApiProjectTask, projectTasksApi } from '@/api/tasks';
import { store, useAppSelector } from '@/app/store';
import { NoData } from '@/components/ui/NoData/NoData';
import { NotFound } from '@/components/ui/NotFoundPage';
import { CUSTOMER } from '@/config/constants';
import { ProjectAccessProvider } from '@/hooks/useAccess';
import { CloseButton, Container } from '@mantine/core';

import { MainBreadcrumbs } from '../MainBreadcrumbs';
import { taskModalSlice } from '../TaskModal/store';
import { TaskAbove } from './components/TaskAbove/TaskAbove';
import { TaskActions } from './components/TaskActions';
import { TaskContent } from './components/TaskContent/TaskContent';
import { TaskLayout } from './components/TaskLayout/TaskLayout';
import { TaskLoading } from './components/TaskLoading';
import { TaskSidebar } from './components/TaskSidebar/TaskSidebar';
import { TaskStatus } from './components/TaskStatus';
import { TaskContextProvider } from './context/ContextProvider';

export const TaskPanel = ({
  taskId,
  actionsRightSection,
  onClose,
  fillSize,
}: {
  taskId: number | string;
  onClose?: () => void;
  actionsRightSection?: ReactNode;
  fillSize?: boolean;
}) => {
  const { t } = useTranslation();

  const { opened } = useAppSelector((s) => s.taskModalSlice);

  const { data, error, isError, isLoading } =
    projectTasksApi.useGetTaskByIdQuery(taskId, { skip: !opened });

  if (isError) {
    if (error && 'status' in error && error.status === 400) {
      return (
        <>
          <Helmet>
            <title>404 | {t('appName.' + CUSTOMER)}</title>
          </Helmet>
          <NotFound />
          <CloseButton pos="absolute" top={16} right={16} onClick={onClose} />
        </>
      );
    } else {
      return (
        <Container p={80}>
          <NoData
            title={t('500.title')}
            description={t('500.description')}
            illustration="500"
            buttons={[
              {
                buttonText: t('500.reload'),
                handleClick: () => window.location.reload(),
              },
            ]}
          />
          <CloseButton pos="absolute" top={16} right={16} onClick={onClose} />
        </Container>
      );
    }
  }

  if (isLoading || !data) {
    return <TaskLoading actionsRightSection={actionsRightSection} />;
  }

  return (
    <ProjectAccessProvider projectId={data.ProjectId}>
      <Content
        fillSize={fillSize}
        data={data}
        onRemove={onClose}
        actionsRightSection={actionsRightSection}
      />
    </ProjectAccessProvider>
  );
};

const close = () => store.dispatch(taskModalSlice.actions.setOpened(false));

const Content = ({
  data,
  actionsRightSection,
  onRemove,
  fillSize,
}: {
  data: ApiProjectTask.ITask;
  onRemove?: (task: ApiProjectTask.ITask) => void;
  actionsRightSection?: ReactNode;
  fillSize?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>
          {data.Title} | {t('appName.' + CUSTOMER)}
        </title>
      </Helmet>
      <TaskContextProvider>
        <TaskLayout
          fillSize={fillSize}
          status={<TaskStatus task={data} />}
          header={
            <MainBreadcrumbs
              folderId={data.ProjectId}
              onItemClick={close}
              task={data}
              style={{ paddingLeft: '4px' }}
            />
          }
          actions={
            <TaskActions
              task={data}
              rightSection={actionsRightSection}
              onRemove={() => onRemove?.(data)}
            />
          }
          above={<TaskAbove task={data} />}
          content={<TaskContent task={data} />}
          sidebar={<TaskSidebar task={data} />}
        />
      </TaskContextProvider>
    </>
  );
};

import { useEffect } from 'react';

import { store, useAppSelector } from '@/app/store';
import { useSwipeDetect } from '@/hooks/useSwipeDetect';
import { Drawer } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

import { layoutSlice } from '../AppLayout/slice';
import { Aside } from './components/Aside';

import styles from './Sidebar.module.css';

const initSidebar = () => store.dispatch(layoutSlice.actions.initSidebar());
const toggle = () => store.dispatch(layoutSlice.actions.toggleSidebar());

export const Sidebar = () => {
  const opened = useAppSelector((s) => s.layoutSlice.sidebarOpened);

  const isMobile = useMediaQuery('(max-width: 1200px)');

  const swipeProps = useSwipeDetect((side) => {
    if (side === 'left') toggle();
  });

  useEffect(() => {
    initSidebar();
  }, [isMobile]);

  return isMobile ? (
    <Drawer
      {...swipeProps}
      classNames={{ body: styles.drawer }}
      withCloseButton={false}
      overlayProps={{ opacity: 0.5 }}
      opened={opened}
      onClose={toggle}
      size={300}
    >
      <Aside />
    </Drawer>
  ) : (
    <Aside />
  );
};

import { ReactNode, forwardRef } from 'react';

import { Button, Flex, useComputedColorScheme } from '@mantine/core';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';

type Props = {
  opened?: boolean;
  error?: boolean;
  children?: ReactNode;
  onClick: () => void;
};

export const FieldValue = forwardRef<HTMLDivElement, Props>(
  ({ opened, error, children, onClick }, ref) => {
    const isLightTheme =
      useComputedColorScheme('light', {
        getInitialValueInEffect: true,
      }) === 'light';

    return (
      <Button
        component="div"
        ref={ref}
        onClick={onClick}
        variant="filled"
        color={isLightTheme ? 'gray.1' : 'dark.5'}
        c={
          'light-dark(var(--mantine-color-gray-8), var(--mantine-color-dark-0))'
        }
        radius={4}
        fw={400}
        size="md"
        w={'100%'}
        justify="flex-start"
        px={12}
        styles={{
          label: {
            flex: 1,
          },
          root: {
            border: error ? '1px solid var(--mantine-color-red-6)' : undefined,
          },
        }}
        rightSection={
          <Flex align={'center'} c={'gray.6'}>
            {opened ? (
              <IconChevronUp size={16} />
            ) : (
              <IconChevronDown size={16} />
            )}
          </Flex>
        }
      >
        {children}
      </Button>
    );
  },
);

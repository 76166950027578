import { useCallback, useMemo, useState } from 'react';

import { ApiAttachments } from '@/api/attachments/types';
import { ApiProjectTask } from '@/api/tasks';
import {
  isGalleryMediaFile,
  isMobileDevice,
  isVideoFile,
} from '@/helpers/fileUtils';
import { IMAGE_MIME_TYPE } from '@mantine/dropzone';

export const useAttachments = (task: ApiProjectTask.ITask) => {
  const [viewType, setViewType] = useState<'list' | 'strip'>('list');
  const [showAttach, setShowAttach] = useState(task.Attachments?.[0]);
  const [opened, setOpened] = useState(false);
  const isMobile = isMobileDevice();

  const images = useMemo(
    () =>
      task.Attachments?.filter((item) =>
        isGalleryMediaFile(item.MimeType, item.Name),
      ) || [],
    [task.Attachments],
  );

  const handleAttachClick = useCallback(
    (attach: ApiAttachments.IAttachment & { fileUrl: string | undefined }) => {
      const isImage = IMAGE_MIME_TYPE.includes(attach.MimeType);
      const isVideo = isMobile
        ? false
        : isVideoFile(attach.MimeType, attach.Name);

      if (isImage || isVideo) {
        setOpened(true);
        setShowAttach(attach);
      } else {
        window.open(attach.fileUrl, '_blank')?.focus();
      }
    },
    [isMobile],
  );

  const effectiveViewType = isMobile ? 'list' : viewType;

  return {
    viewType: effectiveViewType,
    setViewType,
    showAttach,
    setShowAttach,
    opened,
    setOpened,
    images,
    handleAttachClick,
  };
};

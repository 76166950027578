import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ColorPicker } from '@/components/ui/ColorPicker/ColorPicker';
import { ProjectIcon } from '@/components/ui/icons/ProjectIcon';
import { Stack, TextInput } from '@mantine/core';

import { useEntityFormContext } from '../../hooks/useForm';
import { IEntityFormType } from '../../types';
import { ConfirmBoard } from '../ConfirmBoard';
import { StatusesDetails } from '../Details/StatusesDetails';
import { TypesDetails } from '../Details/TypesDetails';

interface IProps {
  isProject: boolean;
}

export const MainForm = ({ isProject }: IProps) => {
  const { t } = useTranslation();

  const form = useEntityFormContext();

  const values = form.getValues();
  const setFormType = useCallback(
    (type: IEntityFormType | null) => form.setFieldValue('formType', type),
    [form],
  );

  const boardItems = useMemo(() => {
    const visibleTypes = values.types.filter((t) => t.state !== 'removed');
    const statuses = values.statuses[
      values.statuses.provide ? 'provided' : 'current'
    ].filter((t) => t.state !== 'removed');
    const items = [
      {
        hide: !isProject,
        label: t('entitySettings.iconSection.title'),
        detail: (
          <ProjectIcon
            color={values.color}
            icon={values.icon}
            name={values.name || 'P'}
          />
        ),
        onClick: () => setFormType('icon'),
      },
      {
        label: t('entitySettings.statusSection.title'),
        detail: <StatusesDetails statuses={statuses} />,
        onClick: () => setFormType('statuses'),
      },
      {
        hide: !isProject,
        label: t('entitySettings.typeSection.title'),
        detail: <TypesDetails types={visibleTypes} />,
        onClick: () => setFormType('taskTypes'),
      },
      {
        hide: !isProject,
        label: t('entitySettings.customFieldSection.title'),
        onClick: () => setFormType('customFields'),
      },
    ];

    return items.filter((el) => !el.hide);
  }, [isProject, values, setFormType, t]);

  return (
    <>
      <Stack gap={16}>
        <TextInput
          data-autofocus
          maxLength={500}
          placeholder={t('entitySettings.fields.title.placeholder')}
          label={t('entitySettings.fields.title.label')}
          size="md"
          {...form.getInputProps('name')}
        />
        {!isProject && (
          <ColorPicker
            value={form.values.color}
            onChange={(c) => form.setFieldValue('color', c)}
          />
        )}
      </Stack>

      <ConfirmBoard items={boardItems} />
    </>
  );
};

import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Checkbox, Menu } from '@mantine/core';
import { IconAdjustmentsHorizontal } from '@tabler/icons-react';
import { xor } from 'lodash-es';

const options = [
  { value: 'name', disabled: true },
  { value: 'status' },
  { value: 'priority' },
  { value: 'performer' },
];

export const ColumnsVisible = memo(
  ({
    value,
    onChange,
  }: {
    value: string[];
    onChange: (v: string[]) => void;
  }) => {
    const { t } = useTranslation();

    const optionsWithLabel = options.map((el) => ({
      ...el,
      label: t(`timelineSettings.columns.${el.value}`),
    }));

    return (
      <Menu closeOnItemClick={false}>
        <Menu.Target>
          <Button
            variant="subtle"
            size="xs"
            c={
              'light-dark(var(--mantine-color-gray-7), var(--mantine-color-gray-3))'
            }
            fw={400}
            leftSection={<IconAdjustmentsHorizontal size={16} />}
          >
            {t('timelineSettings.title')}
          </Button>
        </Menu.Target>
        <Menu.Dropdown>
          {optionsWithLabel.map((opt) => (
            <Menu.Item component="label" key={opt.value}>
              <Checkbox
                checked={value.includes(opt.value)}
                onChange={(e) => onChange(xor(value, [e.currentTarget.value]))}
                value={opt.value}
                label={opt.label}
                disabled={opt.disabled}
              />
            </Menu.Item>
          ))}
        </Menu.Dropdown>
      </Menu>
    );
  },
);

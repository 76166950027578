import { useEffect, useRef } from 'react';

/** Используется для всплывающих окон, чтобы заблокировать скорллинг на всех элементах кроме текущего */
export const useDisableScroll = <T extends HTMLElement>(enable: boolean) => {
  const ref = useRef<T>(null);

  useEffect(() => {
    const handler = (event: WheelEvent) => {
      if (
        event.target &&
        isNode(event.target) &&
        !ref.current?.contains(event.target)
      ) {
        event.preventDefault();
      }
    };

    if (enable) {
      window.addEventListener('wheel', handler, { passive: false });
    }

    return () => window.removeEventListener('wheel', handler);
  }, [enable]);

  return ref;
};

export function isNode(node: object): node is Node {
  return 'nodeType' in node;
}

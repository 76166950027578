import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { ApiCommon } from '@/api/common';
import { useAccess } from '@/hooks/useAccess';
import { Box, Flex, Text } from '@mantine/core';

import { UserRoleSelect } from '../../../../../ui/selects/UserRoleSelect/UserRoleSelect';
import { ParticipantsFormUser } from '../ParticipantsForm';
import { UserOption } from './UserOption';

import styles from './ParticipantsSelect.module.css';

interface UserRowProps {
  user: ParticipantsFormUser;
  onRemove?: () => void;
  onChange?: (v: ApiCommon.AccessType) => void;
  isCurrentUser?: boolean;
}

export const UserRow = memo(
  ({ onChange, user, onRemove, isCurrentUser }: UserRowProps) => {
    const { t } = useTranslation();
    const access = useAccess();

    return (
      <Box mih={56} className={styles.userRow}>
        <UserOption user={user} />

        <Flex align="center" justify="end">
          {user.isOwner ? (
            <Text
              c="light-dark(var(--mantine-color-gray-8), white)"
              size="sm"
              px={4}
            >
              {t('members.owner')}
            </Text>
          ) : (
            <UserRoleSelect
              value={user.accessType}
              onChange={onChange}
              onRemove={onRemove}
              readonly={!access.participants.edit || isCurrentUser}
              buttonProps={{ size: 'xs', px: 4 }}
            />
          )}
        </Flex>
      </Box>
    );
  },
);

import { useTranslation } from 'react-i18next';

import { Text } from '@mantine/core';
import { filter } from 'lodash-es';

export const LabelExternal = <T extends { external?: boolean }>({
  data,
}: {
  data: T[];
}) => {
  const { t } = useTranslation();

  const count = filter(data, 'external').length;

  if (!count) return null;

  return (
    <Text
      fz={12}
      lh={1}
      c="orange.5"
      dangerouslySetInnerHTML={{
        __html: t('taskAttributes.external', { count }),
      }}
    />
  );
};

import { store, useAppSelector } from '@/app/store';
import { ActionIcon, Modal } from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import {
  IconArrowsMaximize,
  IconArrowsMinimize,
  IconX,
} from '@tabler/icons-react';

import { TaskPanel } from '../TaskPanel/TaskPanel';
import { useShowTaskModal } from './hooks/useShowTaskModal';
import { closeTaskModal, taskModalSlice } from './store';

const close = () => store.dispatch(taskModalSlice.actions.setOpened(false));

export const TaskModal = () => {
  const { opened, taskId } = useAppSelector((s) => s.taskModalSlice);

  const [fullScreen, { toggle }] = useDisclosure(false);
  const isMobile = useMediaQuery('(max-width: 992px)');

  useShowTaskModal();

  const fullSize = isMobile ? true : fullScreen;

  return (
    <Modal.Root
      size={'80vw'}
      fullScreen={fullSize}
      opened={opened}
      onClose={closeTaskModal}
      keepMounted={false}
      p={0}
      centered
    >
      <Modal.Overlay />
      <Modal.Content mah={'100dvh'}>
        <Modal.Body p={0}>
          <TaskPanel
            key={taskId}
            fillSize={fullSize}
            taskId={taskId}
            onClose={close}
            actionsRightSection={
              <>
                {!isMobile && (
                  <ActionIcon onClick={toggle} variant="subtle" color="dark">
                    {fullScreen ? (
                      <IconArrowsMinimize size={18} />
                    ) : (
                      <IconArrowsMaximize size={18} />
                    )}
                  </ActionIcon>
                )}
                <ActionIcon
                  onClick={closeTaskModal}
                  variant="subtle"
                  color="dark"
                >
                  <IconX size={18} />
                </ActionIcon>
              </>
            }
          />
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};

export const DEFAULT_VALUES = {
  get TASK_TYPE_COLOR() {
    return this.COLORS[6];
  },
  get TASK_STATUS_COLOR() {
    return this.COLORS[6];
  },
  get FOLDER_COLOR() {
    return this.COLORS[0];
  },
  get PROJECT_COLOR() {
    return this.COLORS[6];
  },
  get SPRINT_COLOR() {
    return this.COLORS[0];
  },
  COLORS: [
    '#ADB5BD',
    '#FFA94D',
    '#FFD43B',
    '#A9E34B',
    '#69DB7C',
    '#3BC9DB',
    '#4DABF7',
    '#748FFC',
    '#9775FA',
    '#DA77F2',
    '#FF8787',
  ],
};

import { projectsApi } from '@/api/projects';
import { store } from '@/app/store';
import { IEntityFormValues } from '@/components/complex/EntityForm/types';

export const getAttributesUpdates = ({
  entityId,
  attributes,
}: {
  entityId: number;
  attributes: IEntityFormValues['custom'];
}) => {
  const updates: Promise<any>[] = [];

  const newAttributes = attributes.filter((el) =>
    ['new', 'updated'].includes(el.state),
  );
  const removedAttributes = attributes.filter((el) => el.state === 'removed');

  if (newAttributes.length) {
    updates.push(
      ...newAttributes.map((el) =>
        store
          .dispatch(
            projectsApi.endpoints.postAttribute.initiate({
              ProjectId: entityId,
              Name: el.name,
              ValueType: el.type,
              ...(el.state === 'updated' ? { AttributeId: el.id } : {}),
            }),
          )
          .unwrap(),
      ),
    );
  }

  if (removedAttributes.length) {
    updates.push(
      ...removedAttributes.map((el) =>
        store
          .dispatch(
            projectsApi.endpoints.deleteAttribute.initiate({
              projectId: entityId,
              attributeId: el.id,
            }),
          )
          .unwrap(),
      ),
    );
  }

  return updates;
};

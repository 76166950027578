import { forwardRef } from 'react';

import userPlaceholder from '@/assets/svg/common/user-placeholder-avatar.svg';
import { Avatar, AvatarProps, Image } from '@mantine/core';
import { useAvatarColor } from '@sinups/agg';

type User = {
  Name: string;
  Id?: number | null;
  FirstName: string | null;
  Surname: string | null;
  Avatar: string | null;
} & AvatarProps;

interface LinkButtonProps
  extends AvatarProps,
    Omit<React.ComponentPropsWithoutRef<'div'>, keyof AvatarProps> {
  user?: User | null;
}

export const UserAvatar = forwardRef<HTMLDivElement, LinkButtonProps>(
  ({ user, size = 24, ...props }, ref) => {
    const AvatarColorById = useAvatarColor(user?.Id?.toString() ?? '');

    return (
      <Avatar
        src={user?.Avatar}
        color={user ? '#fff' : 'var(--mantine-color-gray-6)'}
        tt={'uppercase'}
        size={size}
        {...props}
        style={{
          background: `${user ? AvatarColorById : ' var(--mantine-color-gray-3)'}`,
          lineHeight: 'normal',
          ...props.style,
        }}
        styles={{
          placeholder: {
            fontWeight: '500',
          },
        }}
        ref={ref}
      >
        {user ? (
          <>
            {user.Surname?.at(0)}
            {user.FirstName?.at(0)}
          </>
        ) : (
          <Image
            w={'60%'}
            h={'60%'}
            style={{ flexBasis: '40%' }}
            src={userPlaceholder}
          />
        )}
      </Avatar>
    );
  },
);

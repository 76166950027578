import { PropsWithChildren, useMemo, useState } from 'react';

import { TaskContext } from './taskContext';

export const TaskContextProvider = (props: PropsWithChildren) => {
  const [isDescriptionEdit, setDescriptionEdit] = useState(false);
  const [commentsEdit, setCommentsEdit] = useState<number | null | 'new'>(null);
  const [showLinksForm, setShowLinksForm] = useState(false);

  const value = useMemo(
    () => ({
      isDescriptionEdit,
      setDescriptionEdit,
      commentsEdit,
      setCommentsEdit,
      showLinksForm,
      setShowLinksForm,
    }),
    [commentsEdit, isDescriptionEdit, showLinksForm],
  );

  return (
    <TaskContext.Provider value={value}>{props.children}</TaskContext.Provider>
  );
};

import { store } from '@/app/store';
import { Modal, ModalProps } from '@mantine/core';

import { editEntityModalSlice } from '../slice';

export const closeEntityModal = () =>
  store.dispatch(editEntityModalSlice.actions.close());

export const EntityModal = ({
  opened,
  children,
}: Pick<ModalProps, 'opened' | 'children'>) => {
  return (
    <Modal.Root opened={opened} onClose={closeEntityModal} size={'lg'}>
      <Modal.Overlay />
      <Modal.Content>{children}</Modal.Content>
    </Modal.Root>
  );
};

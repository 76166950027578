import { memo, useMemo } from 'react';

import { ApiCommon } from '@/api/common';
import { ApiProjectTask } from '@/api/tasks';
import { TaskStatusBadge } from '@/components/ui/badges/TaskStatusBadge/TaskStatusBadge';
import { useSprintData } from '@/hooks/useSprintData';
import { useStatusesData } from '@/hooks/useStatusesData';

type TaskStatusProps = {
  task: ApiProjectTask.ITask;
};

export const TaskStatus = memo(({ task }: TaskStatusProps) => {
  const { Status } = task;

  const sprintData = useSprintData(task.SprintId);

  const { statuses: statusesData } = useStatusesData(sprintData);

  const statusValue = useMemo(() => {
    return (
      statusesData?.find((s) => s.Id === Status) ||
      statusesData?.find((s) => s.Type === ApiCommon.ITastStatusType.BeExecuted)
    );
  }, [statusesData, Status]);

  return <TaskStatusBadge maw={250} truncate status={statusValue} />;
});

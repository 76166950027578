import { RouterProvider } from 'react-router-dom';

import { settingsApi } from '@/api/settings';
import { AppError } from '@/components/layout/AppError/AppError';
import { Preloader } from '@/components/ui/Preloader/Preloader';

import { useAppSelector } from '../store';
import { appRoute } from './AppRoute';

export const Router = () => {
  const { isLoading, isSuccess, isError } = settingsApi.useGetQuery();
  const skeleton = useAppSelector((s) => s.devSlice.skeletons.topPreloader);

  if (isError) return <AppError />;

  if (isLoading || skeleton) {
    return <Preloader />;
  }

  if (isSuccess) {
    return <RouterProvider router={appRoute} fallbackElement={<Preloader />} />;
  }

  return null;
};

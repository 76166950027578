import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { AppRoutes } from '@/config/links';
import { generateLink } from '@/helpers/generateLink';
import { BoxProps, Button, Divider, Group } from '@mantine/core';
import {
  IconAlignJustified,
  IconLayoutCards,
  IconList,
  IconProps,
} from '@tabler/icons-react';

import { ISprintView } from '../guards';
import { getSprintView, setSprintView } from '../helpers';

import styles from './SprintViewSelect.module.css';

interface IProps {
  sprintId: number;
}

export const SprintViewSelect = ({ sprintId, ...rest }: IProps & BoxProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const [active, setActive] = useState<ISprintView>(getSprintView(sprintId));

  const list = viewList.map((el) => ({
    ...el,
    title: t(`sprintView.${el.value}`),
  }));

  useEffect(() => {
    setActive(getSprintView(sprintId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <Group gap={4} align="center" wrap="nowrap" {...rest}>
      {list.map(({ value, title, Icon, link }, idx, arr) => {
        return (
          <Fragment key={value}>
            <Button
              className={styles.button}
              variant="subtle"
              radius={4}
              color="dark"
              size="compact-sm"
              leftSection={<Icon size={16} />}
              onClick={() => {
                if (link) {
                  setActive(value);
                  navigate(generateLink(AppRoutes[link], { sprintId }));
                  setSprintView(sprintId, value);
                }
              }}
              data-active={active === value}
            >
              {title}
            </Button>
            {idx < arr.length - 1 && <Divider orientation="vertical" />}
          </Fragment>
        );
      })}
    </Group>
  );
};

interface IViewItem {
  value: ISprintView;
  Icon: React.FC<IconProps>;
  link?: 'sprintList' | 'sprintKanban' | 'sprintTimeline';
}

export const viewList: IViewItem[] = [
  {
    value: 'list',
    Icon: IconList,
    link: 'sprintList',
  },
  {
    value: 'kanban',
    Icon: IconLayoutCards,
    link: 'sprintKanban',
  },
  {
    value: 'timeline',
    Icon: IconAlignJustified,
    link: 'sprintTimeline',
  },
];

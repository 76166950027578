import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { settingsApi } from '@/api/settings';
import { Preloader } from '@/components/ui/Preloader/Preloader';
import { Stack } from '@mantine/core';

import { LoginForm } from './components/LoginForm';

const isMobile = import.meta.env.VITE_MOB;

export const LoginPage = () => {
  const { data, isLoading } = settingsApi.endpoints.get.useQueryState();
  const navigate = useNavigate();

  const strictLogin = window.location.search.includes('login=true');

  useEffect(() => {
    // wait when setting loaded
    if (!data || strictLogin || isMobile) return;

    // redirect to main page if user logged
    if (data.UserId > 0) {
      navigate('/', { replace: true });
      return;
    }

    // redirect to admin login page
    const modules = data.ModuleSettings;
    const loginUrl = modules.ModuleUrls.find((x) => x.Name === 'Admin')?.Url;

    if (!loginUrl) return;
    const returnUrl = encodeURIComponent(window.location.origin);
    window.location.href = `${loginUrl}/login?returnUrl=${returnUrl}`;
  }, [data, navigate, strictLogin]);

  if (isLoading) return <Preloader />;

  if (strictLogin || isMobile) {
    return (
      <Stack
        p={24}
        mih={'100dvh'}
        align="center"
        justify="center"
        bg={'blue.1'}
      >
        <LoginForm />
      </Stack>
    );
  }
};

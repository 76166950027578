import { Dispatch, SetStateAction, createContext, useContext } from 'react';

type TaskContextData = {
  isDescriptionEdit: boolean;
  setDescriptionEdit: Dispatch<SetStateAction<boolean>>;

  commentsEdit: number | 'new' | null;
  setCommentsEdit: Dispatch<SetStateAction<number | 'new' | null>>;

  showLinksForm: boolean;
  setShowLinksForm: Dispatch<SetStateAction<boolean>>;
};

export const TaskContext = createContext(null as unknown as TaskContextData);

export const useTaskContext = () => useContext(TaskContext);

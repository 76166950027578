export namespace ApiCommon {
  export enum Entity {
    Unknown = 0,
    File = 1,
    Document = 2,
    Directory = 3,
    Customer = 4,
    User = 5,
    Favorite = 6,
    Event = 7,
    Call = 8,
    Visitor = 9,
    Reception = 10,
    Birthday = 11,
    Meeting = 12,
    Calendar = 14,
    Contact = 15,
    Application = 16,
    UserGroup = 17,
    ClientApplication = 18,
    OfflineDocument = 30,
    EmailFolder = 31,
    EmailMessage = 32,
    EmailAttachment = 33,
    Role = 34,
    EventParticipant = 35,
    AddressBook = 36,
    ChangeEvent = 37,
    Project = 38,
    ProjectTask = 39,
    ProjectTaskActivity = 40,
    Sprint = 41,
  }

  export enum AccessType {
    LockCopy = -4096 * 4,
    LockPrint = -4096 * 2,
    Deny = -4096,
    OnlyUpload = -1024,
    FreeBusy = -64,

    Read = 0,
    Comment = 1,
    Review = 2,
    Write = 4,
    Delete = 8,
    // Save = 16,
    // EditRights = 32,
    FullInternal = 256,
    Full = 512,

    /** Нельзя скачать, печатать, буфер обмена */
    Lock = 4096,
  }

  export type ITaskStatus = {
    Id: number;
    Name: string;
    EntityId: number | null;
    EntityType: ApiCommon.Entity;
    Color: string | null;
    Type: ITastStatusType;
    PatternId: null | number;
    Order: number;
  };

  export enum ITastStatusType {
    Deleted = -1,
    InActive = 0,
    Active = 1,
    BeExecuted = 2,
    InProcess = 3,
    Done = 4,
    Closed = 5,
    Custom = 6,
  }

  export enum ProjectFlags {
    DEFAULT = 0,
    USE_CUSTOM_STATUSES = 1,
  }

  export enum UserStatus {
    Banned = -2,
    Deleted = -1,
    Active = 1,
  }

  export enum ILinkLifeTime {
    SingleCall = 0,
    FiveMinutes = 1,
    OneHour = 2,
    Day = 3,
    Week = 4,
    Month = 5,
    Year = 6,
    Unlimited = 10,
    Internal = 11,
  }
}

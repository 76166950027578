import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { ComplexSprintData } from '@/api/projects';
import { Ellipsis } from '@/components/ui/Ellipsis';
import { AppRoutes } from '@/config/links';
import { generateLink } from '@/helpers/generateLink';
import { useActiveLink } from '@/hooks/useActiveLink';
import { NavLink, ThemeIcon } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { IconListDetails } from '@tabler/icons-react';

import { ActionsButton } from './ActionsButton';

import styles from '../Navigation.module.css';

export const SprintLink = ({
  sprint,
  onClose,
}: {
  sprint: ComplexSprintData;
  onClose: () => void;
}) => {
  const navigate = useNavigate();
  const isActive = useActiveLink([
    { link: generateLink(AppRoutes.sprintList, { sprintId: sprint.Id }) },
    { link: generateLink(AppRoutes.sprintKanban, { sprintId: sprint.Id }) },
    { link: generateLink(AppRoutes.sprintTimeline, { sprintId: sprint.Id }) },
  ]);

  const isBurger = useMediaQuery('(max-width: 1200px)');

  const handleBodyItemClick = useCallback(
    async (e: React.MouseEvent) => {
      e.stopPropagation();
      navigate(AppRoutes.getSprintRoute(sprint.Id));
      isBurger && onClose();
    },
    [sprint.Id, isBurger, navigate, onClose],
  );

  return (
    <NavLink
      classNames={{
        root: styles.root,
      }}
      onClick={handleBodyItemClick}
      label={<Ellipsis>{sprint.Name}</Ellipsis>}
      active={!!isActive}
      leftSection={
        <ThemeIcon
          size={20}
          ml={8}
          variant="transparent"
          color={sprint.Color || 'gray.5'}
        >
          <IconListDetails size="1rem" stroke={1.5} />
        </ThemeIcon>
      }
      rightSection={
        <ActionsButton onClose={onClose} sprint={sprint} type="sprint" />
      }
    />
  );
};

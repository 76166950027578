import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '@/app/store';
import queryString from 'query-string';

import { SHOW_TASK_MODAL_PARAM, taskModalSlice } from '../store';

export const useShowTaskModal = () => {
  const { opened, taskId } = useAppSelector((s) => s.taskModalSlice);

  const dispatch = useAppDispatch();

  const { search } = useLocation();
  const queryTaskId = queryString.parse(search)[SHOW_TASK_MODAL_PARAM];

  useEffect(() => {
    const id = queryTaskId?.toString();
    if (id) {
      dispatch(taskModalSlice.actions.openTask(id));
    } else {
      dispatch(taskModalSlice.actions.setOpened(false));
    }
  }, [dispatch, queryTaskId]);

  return { opened, taskId };
};

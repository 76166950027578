import { customBaseQuery } from '@/api/customBaseQuery';
import { createApi } from '@reduxjs/toolkit/query/react';
import queryString from 'query-string';

import { ApiAttachments } from './types';

export const attachmentsApi = createApi({
  reducerPath: 'attachmentsApi',
  baseQuery: customBaseQuery('/api'),
  tagTypes: ['Attachments'],
  endpoints: (builder) => ({
    uploadFile: builder.mutation<ApiAttachments.IAttachment, FormData>({
      query: (file) => ({
        url: `/v1/files/upload`,
        method: 'POST',
        body: file,
      }),
    }),
    linkAttach: builder.mutation<
      ApiAttachments.ILinkResponse,
      ApiAttachments.ILinkRequest
    >({
      query: (body) => ({
        url: `/v1/project/linkAttachment?${queryString.stringify(body)}`,
        method: 'PUT',
      }),
    }),
    get: builder.query<string, ApiAttachments.IGetRequest>({
      query: (body) => ({
        url: `/v1/project/attachment?${queryString.stringify(body)}`,
        method: 'GET',
        responseHandler: (response) => response.blob(),
      }),
      transformResponse: (res: ApiAttachments.IGetResponse) => {
        return window.URL.createObjectURL(res);
      },
    }),
    deleteAttach: builder.mutation<void, number[]>({
      query: (Ids) => ({
        url: `/v1/project/attachment`,
        body: { Ids },
        method: 'DELETE',
      }),
    }),
  }),
});

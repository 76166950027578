import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { showRequestNotifications } from '@/api/helpers/showNotifications';
import { ApiTaskLinks, tasksLinksApi } from '@/api/links';
import { ApiProjectTask } from '@/api/tasks';
import { useEvent } from '@/hooks/useEvent';
import { useSprintData } from '@/hooks/useSprintData';
import { Button, Flex, Stack } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { IconPlus } from '@tabler/icons-react';
import { uniq } from 'lodash-es';

import { openCreateTaskModal } from '../../../../../CreateTaskModal';
import { useTaskContext } from '../../../../context/taskContext';
import { getLinkType } from '../../helpers/getLinkType';
import { LinkTypeSelect } from './components/LinkTypeSelect';
import { TaskLinkSearch } from './components/TaskLinkSearch/TaskLinkSearch';

type CreateLinksFormProps = {
  task: ApiProjectTask.ITask;
};

export const CreateLinksForm = ({ task }: CreateLinksFormProps) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width: 768px)');

  const { setShowLinksForm } = useTaskContext();

  const sprint = useSprintData(task.SprintId);

  const { data: linksData } = tasksLinksApi.endpoints.getLinks.useQueryState({
    ids: [task.Id],
  });

  const [linkTasks, { isLoading }] =
    tasksLinksApi.endpoints.createLink.useMutation();

  const [tasks, setTasks] = useState<ApiProjectTask.ITask[]>([]);
  const [type, setType] = useState<ApiTaskLinks.LinkType>(
    ApiTaskLinks.LinkType.Relates,
  );

  const submit = () => {
    const newLinks = tasks
      .map((t) => ({
        FromId: task.Id,
        ToId: t.Id,
        Type: type,
      }))
      // связи фильтруются, чтобы не создать дубликат существующих
      .filter((link) => {
        return !linksData?.some((item) => {
          const allIds = [link.FromId, link.ToId, item.FromId, item.ToId];
          return (
            uniq(allIds).length === 2 &&
            getLinkType(item, task.Id) === link.Type
          );
        });
      });

    if (!newLinks.length) {
      setShowLinksForm(false);
      return;
    }

    showRequestNotifications(linkTasks(newLinks).unwrap(), {
      successMsg: t('notification.success.linkTask'),
      errorMsg: t('notification.error.linkTask'),
    }).then(() => {
      setShowLinksForm(false);
    });
  };

  const afterCreateNewTask = useEvent((task: ApiProjectTask.ITask) => {
    setTasks((prev) => [...prev, task]);
  });

  const excludeIds = useMemo(() => {
    if (!linksData) return [];

    const linkedIds = linksData.filter(
      (link) => getLinkType(link, task.Id) === type,
    );

    return [
      task.Id,
      ...linkedIds.map((link) => {
        const isFrom = link.FromId === task.Id;
        return isFrom ? link.ToId : link.FromId;
      }),
    ];
  }, [linksData, task.Id, type]);

  return (
    <Stack gap={8} data-is-links-form data-cy="links-form">
      <Flex
        gap={8}
        align={'flex-start'}
        component={isMobile ? Stack : undefined}
      >
        <LinkTypeSelect
          value={type}
          onChange={setType}
          w={isMobile ? '100%' : undefined}
        />
        <TaskLinkSearch
          projectId={sprint?.rootProjectId}
          value={tasks}
          onChange={setTasks}
          excludeTaskIds={excludeIds}
        />
      </Flex>

      <Flex justify={'space-between'} gap={12}>
        <Button
          leftSection={<IconPlus size={14} />}
          color="gray.8"
          variant="subtle"
          size="compact-sm"
          fw={400}
          px={4}
          data-cy="create-new-task"
          onClick={() => {
            openCreateTaskModal({
              defaultValues: { sprint },
              afterCreate: afterCreateNewTask,
              allowedProjectIds: sprint ? [sprint.rootProjectId] : [],
            });
          }}
        >
          {t(`createTask.action.${isMobile ? 'create' : 'createLinkTask'}`)}
        </Button>

        <Flex gap={8} justify={'flex-end'}>
          <Button
            variant="subtle"
            onClick={() => setShowLinksForm(false)}
            data-cy="cancel"
          >
            {t('common.action.cancel')}
          </Button>
          <Button
            disabled={!tasks.length}
            onClick={submit}
            loading={isLoading}
            data-cy="link"
          >
            {t('common.action.link')}
          </Button>
        </Flex>
      </Flex>
    </Stack>
  );
};

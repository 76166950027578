import { useTranslation } from 'react-i18next';

import { useTaskContext } from '@/features/TaskPanel/context/taskContext';
import { useAccess } from '@/hooks/useAccess';
import { ActionIcon, Flex } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';

import styles from '../TaskLinks.module.css';

export const Header = () => {
  const { t } = useTranslation();
  const access = useAccess();

  const { showLinksForm, setShowLinksForm } = useTaskContext();

  return (
    <Flex justify={'space-between'}>
      <p className={styles.subtitle}>{t('createTask.linkedTasks')}</p>
      {access.tasks.edit && !showLinksForm && (
        <ActionIcon
          size={24}
          variant="subtle"
          color="gray.6"
          onClick={() => setShowLinksForm(true)}
        >
          <IconPlus size={16} />
        </ActionIcon>
      )}
    </Flex>
  );
};

import { Group, Skeleton, SkeletonProps, Stack } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

import { StatusLoader } from './StatusLoader';

export const TaskLoader = () => {
  const isDesktop = useMediaQuery('(min-width: 992px)', true, {
    getInitialValueInEffect: false,
  });

  return (
    <Stack gap={8} {...(isDesktop ? { px: 8 } : { py: 8 })}>
      {!isDesktop && <NoShrinkSkeleton height={16} my={5} />}

      <Group grow mih={isDesktop ? 40 : 28}>
        <Group wrap="nowrap" gap={8}>
          <NoShrinkSkeleton height={16} width={16} mx={6} />
          <NoShrinkSkeleton height={16} width={50} />
          {isDesktop && <NoShrinkSkeleton height={16} />}
        </Group>
        <Group wrap="nowrap" justify="end">
          <StatusLoader />
          <NoShrinkSkeleton height={16} width={16} />
          <NoShrinkSkeleton height={24} width={24} mx={2} circle />
        </Group>
      </Group>
    </Stack>
  );
};

const NoShrinkSkeleton = (props: SkeletonProps) => (
  <Skeleton style={{ flexShrink: 0 }} {...props} />
);

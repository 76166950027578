import { useMemo } from 'react';

import { ApiCommon } from '@/api/common';
import { Menu } from '@mantine/core';

import { TaskStatusBadge } from '../badges/TaskStatusBadge/TaskStatusBadge';
import { EmptyStatuses } from './EmptyStatuses';

type TaskStatusSelectProps = {
  value?: number | null;
  onChange?: (v: ApiCommon.ITaskStatus) => void;
  statuses: ApiCommon.ITaskStatus[];
  placeholder?: string;
  readonly?: boolean;
  filled?: boolean;
  withLabel?: boolean;
  iconSize?: number;
};

export const TaskStatusSelect = ({
  value,
  onChange,
  statuses,
  placeholder,
  readonly,
  filled,
  withLabel,
  iconSize,
}: TaskStatusSelectProps) => {
  const statusValue = useMemo(
    () => statuses.find((s) => s.Id === value),
    [statuses, value],
  );

  if (readonly) {
    return (
      <TaskStatusBadge
        truncate
        iconSize={iconSize}
        withLabel={withLabel}
        filled={filled}
        placeholder={placeholder}
        status={statusValue}
      />
    );
  }

  return (
    <Menu
      shadow="md"
      withinPortal={true}
      keepMounted={false}
      position="bottom-start"
    >
      <Menu.Target>
        <TaskStatusBadge
          truncate
          iconSize={iconSize}
          withLabel={withLabel}
          filled={filled}
          clickable
          placeholder={placeholder}
          status={statusValue}
        />
      </Menu.Target>
      <Menu.Dropdown
        mah={250}
        style={{ overflow: 'auto' }}
        onClick={(e) => e.stopPropagation()}
      >
        {statuses.length > 0 ? (
          statuses.map((status) => (
            <Menu.Item
              key={status.Id}
              component={'li'}
              onClick={(e) => {
                e.stopPropagation();
                if (value !== status.Id) {
                  onChange?.(status);
                }
              }}
            >
              <TaskStatusBadge status={status} filled={false} />
            </Menu.Item>
          ))
        ) : (
          <EmptyStatuses />
        )}
      </Menu.Dropdown>
    </Menu>
  );
};

import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { showErrorNotification } from '@/api/helpers/showNotifications';
import { tasksLinksApi } from '@/api/links';
import { ApiProjectTask } from '@/api/tasks';
import { Stack } from '@mantine/core';

import { useTaskContext } from '../../context/taskContext';
import { CreateLinksForm } from './components/CreateLinksForm/CreateLinksForm';
import { Header } from './components/Header';
import { LinksList } from './components/LinksList/LinksList';

type TaskLinksProps = {
  task: ApiProjectTask.ITask;
};

export const TaskLinks = ({ task }: TaskLinksProps) => {
  const { t } = useTranslation();

  const { data, isError } = tasksLinksApi.endpoints.getLinks.useQuery({
    ids: [task.Id],
  });

  const { showLinksForm } = useTaskContext();

  useEffect(() => {
    if (isError) {
      showErrorNotification({ message: t('notification.error.getLinks') });
    }
  }, [isError, t]);

  const hasLinks = !!data?.length;

  if (!hasLinks && !showLinksForm) return null;

  return (
    <Stack gap={8} pl={8}>
      {hasLinks && <Header />}
      {showLinksForm && <CreateLinksForm task={task} />}
      {hasLinks && <LinksList task={task} data={data} />}
    </Stack>
  );
};

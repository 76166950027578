import { ChangeEvent, useCallback } from 'react';

import { useAppDispatch, useAppSelector } from '@/app/store';
import { Divider, Paper, Stack, Switch } from '@mantine/core';
import { mapValues } from 'lodash-es';

import { createDevSlice } from './slice';

export const SkeletonsController = () => {
  const values = useAppSelector((s) => s.devSlice.skeletons);
  const dispatch = useAppDispatch();

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const isAll = e.target.name === 'all';
      const result = isAll
        ? mapValues(values, () => e.target.checked)
        : { [e.target.name]: e.target.checked };

      dispatch(createDevSlice.actions.setSkeletons(result));
    },
    [values, dispatch],
  );

  return (
    <Paper pos="absolute" bottom={10} right={10} p={10} withBorder>
      <Stack>
        <Switch
          name="all"
          label="Switch all"
          checked={Object.values(values).every((v) => v)}
          onChange={handleChange}
        />
        <Divider />
        <Switch
          name="topPreloader"
          label="Top preloader"
          checked={values.topPreloader}
          onChange={handleChange}
        />
        <Switch
          name="navigation"
          label="Navigation"
          checked={values.navigation}
          onChange={handleChange}
        />
        <Switch
          name="mainContent"
          label="Main Content"
          checked={values.mainContent}
          onChange={handleChange}
        />
        <Switch
          name="tasks"
          label="Task List"
          checked={values.tasks}
          onChange={handleChange}
        />
      </Stack>
    </Paper>
  );
};

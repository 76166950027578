import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { formatDistance, setDefaultOptions } from 'date-fns';
import { enUS as en, ru } from 'date-fns/locale';

export const useDateFnsLocale = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    setDefaultOptions({ locale: { en, ru }[i18n.language] });
  }, [i18n.language]);
};

export const useFormatDistance = () => {
  const { i18n } = useTranslation();

  return useCallback(
    (timestamp: string) =>
      formatDistance(new Date(timestamp), new Date(), {
        addSuffix: true,
        locale: { en, ru }[i18n.language],
      }),
    [i18n.language],
  );
};

import { ApiProjectTask } from '@/api/tasks';
import { useDndContext, useDroppable } from '@dnd-kit/core';

import styles from '../Timeline.module.css';

export const DroppableLine = ({ task }: { task: ApiProjectTask.ITask }) => {
  const { active } = useDndContext();

  const { isOver, setNodeRef } = useDroppable({
    id: task.Id,
    data: task,
    disabled: active?.id === task.Id,
  });

  return (
    <div data-over={isOver} className={styles.droppableLine} ref={setNodeRef} />
  );
};

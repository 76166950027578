import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { useEntityFormContext } from '../../hooks/useForm';
import { StatusRow, StatusRowProps } from '../StatusRow/StatusRow';
import { DragIcon } from './DragIcon';

export const SortableStatus = (props: StatusRowProps) => {
  const form = useEntityFormContext();
  const statuses = form.getValues().statuses;
  const data = (statuses.provide ? statuses.provided : statuses.current)[
    props.renderKey
  ];

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: data.id, data });

  return (
    <div
      ref={setNodeRef}
      style={{
        transform: CSS.Transform.toString(transform),
        transition,
        opacity: isDragging ? 0.5 : 1,
      }}
      {...attributes}
    >
      <StatusRow
        {...props}
        leftSection={
          <div {...listeners}>{props.leftSection || <DragIcon />}</div>
        }
      />
    </div>
  );
};

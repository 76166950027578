import { useMemo } from 'react';

import { ApiCommon } from '@/api/common';
import { ApiProjectTask } from '@/api/tasks';
import { TaskStatusSelect } from '@/components/ui/selects/TaskStatusSelect';
import { useAccess } from '@/hooks/useAccess';
import { useSprintData } from '@/hooks/useSprintData';
import { useStatusesData } from '@/hooks/useStatusesData';
import { useUpdateTask } from '@/hooks/useUpdateTask';
import { Flex } from '@mantine/core';

export const TaskStatus = ({ task }: { task: ApiProjectTask.ITask }) => {
  const access = useAccess();

  const sprintData = useSprintData(task.SprintId);
  const { statuses } = useStatusesData(sprintData);

  const updateTask = useUpdateTask(task);

  const status = useMemo(() => {
    if (statuses.find((s) => s.Id === task.Status)) {
      return task.Status;
    }

    return statuses.find((s) => s.Type === ApiCommon.ITastStatusType.BeExecuted)
      ?.Id;
  }, [statuses, task.Status]);

  return (
    <Flex miw={0} pl={8}>
      <TaskStatusSelect
        value={status}
        onChange={(s) => updateTask({ Status: s.Id })}
        statuses={statuses}
        readonly={!access.tasks.edit}
      />
    </Flex>
  );
};

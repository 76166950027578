import { useTranslation } from 'react-i18next';

import { ComplexSprintData } from '@/api/projects';
import { TasksSearchInput } from '@/components/ui/TasksSearchInput/TasksSearchInput';
import { openCreateTaskModal } from '@/features/CreateTaskModal';
import { ProjectUsers } from '@/features/ProjectUsers';
import { useAccess } from '@/hooks/useAccess';
import { useFolderData } from '@/hooks/useFolderData';
import { Button, Group } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';

interface IProps {
  sprintData: ComplexSprintData;
  search: string;
  setSearch: (v: string) => void;
  performerIds: number[];
  setPerformerIds: (v: number[]) => void;
  loading?: boolean;
}

export const SprintHeader = ({
  sprintData,
  search,
  setSearch,
  performerIds,
  setPerformerIds,
  loading,
}: IProps) => {
  const { t } = useTranslation();
  const access = useAccess();

  const projectData = useFolderData(sprintData.ProjectId)!;

  return (
    <Group justify="space-between" gap={16} align="flex-start">
      <Group style={{ gap: '16px 24px' }} flex={1}>
        <TasksSearchInput
          value={search}
          onChange={setSearch}
          loading={loading}
        />
        <ProjectUsers
          projectId={projectData.rootProjectId}
          selected={performerIds}
          onSelect={setPerformerIds}
        />
      </Group>
      {access.tasks.create && (
        <Button
          leftSection={<IconPlus size={16} />}
          onClick={() =>
            openCreateTaskModal({ defaultValues: { sprint: sprintData } })
          }
        >
          {t('sidebar.addTask')}
        </Button>
      )}
    </Group>
  );
};

/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ApiCommon } from '@/api/common';
import { ComplexProjectData, projectsApi } from '@/api/projects';
import { shareApi } from '@/api/share';
import { projectTasksApi } from '@/api/tasks';
import { usersApi } from '@/api/users';
import { useAppDispatch, useAppSelector } from '@/app/store';
import {
  IParticipantsFormParams,
  ParticipantsForm,
  ParticipantsFormUser,
} from '@/components/complex/EntityForm/components/ParticipantsForm/ParticipantsForm';
import { openRemoveConfirmModal } from '@/components/ui/confirmModals/RemoveConfirmModal';
import { combineStatuses } from '@/helpers/combineStatuses';
import { ActionIcon, Modal, Text } from '@mantine/core';
import { QueryStatus } from '@reduxjs/toolkit/query';
import { IconPlus } from '@tabler/icons-react';
import { orderBy, uniq } from 'lodash-es';

import { manageUsersModalSlice } from './slice';

type EditUsersModalProps = {
  project: ComplexProjectData;
};

export const EditUsersModal = ({ project }: EditUsersModalProps) => {
  const { t } = useTranslation();

  const opened = useAppSelector((s) => s.manageUsersModal.opened);
  const currentUserId = useAppSelector((s) => s.usersCache.currentUser.Id);
  const dispatch = useAppDispatch();

  const params = useMemo(
    () => ({
      entityId: project?.Id || 0,
      entityType: ApiCommon.Entity.Project,
    }),
    [project?.Id],
  );

  const { data, status: dataStatus } = shareApi.useGetQuery(params, {
    skip: !opened || !project,
  });

  const totalUserIds = useMemo(() => {
    const userIds =
      data?.UserAccessRights.map((user) => user.PrincipalId) || [];
    return uniq([...userIds, project.OwnerId, currentUserId]);
  }, [currentUserId, data?.UserAccessRights, project.OwnerId]);

  const { status: usersStatus } = usersApi.useGetByIdQuery(
    { ids: totalUserIds },
    { skip: dataStatus !== QueryStatus.fulfilled },
  );
  const usersEntities = useAppSelector((s) => s.usersCache.entities);

  const status = useMemo(
    () => combineStatuses([dataStatus, usersStatus]),
    [dataStatus, usersStatus],
  );

  const usersData = useMemo<ParticipantsFormUser[]>(() => {
    if (!data) return [];

    const formData =
      totalUserIds
        .map((userId) => {
          const isOwner = userId === project?.OwnerId;

          const accessType =
            data.UserAccessRights.find((user) => user.PrincipalId === userId)
              ?.Type ?? ApiCommon.AccessType.Read;
          const user = usersEntities[userId];

          if (!user) return null;

          const res: ParticipantsFormUser = {
            Email: user.Email,
            isOwner,
            accessType,
            Avatar: user.Avatar,
            FirstName: user.FirstName,
            Id: user.Id,
            Name: user.Name,
            Surname: user.Surname,
            Status: null, // to do добавить статус пользователя
          };

          return res;
        })
        .filter(Boolean) || [];

    return orderBy(formData, 'Name', 'asc');
  }, [data, project?.OwnerId, totalUserIds, usersEntities]);

  const [giveAccess] = shareApi.useGiveMutation();
  const [removeAccess] = shareApi.useRemoveMutation();

  const handleAdd = useCallback(
    (params: IParticipantsFormParams) => {
      giveAccess({
        entityType: ApiCommon.Entity.Project,
        ids: [project.Id],
        type: params.type,
        userIds: params.userIds,
      })
        .unwrap()
        .then(() => {
          dispatch(projectsApi.util.invalidateTags(['Project']));
        });
    },
    [dispatch, giveAccess, project.Id],
  );

  const handleUpdate = useCallback(
    (params: IParticipantsFormParams) => {
      giveAccess({
        entityType: ApiCommon.Entity.Project,
        ids: [project.Id],
        type: params.type,
        userIds: params.userIds,
      });
    },
    [giveAccess, project.Id],
  );

  const handleRemove = useCallback(
    (user: ParticipantsFormUser) => {
      openRemoveConfirmModal({
        title: t('members.remove.title'),
        children: (
          <Text
            dangerouslySetInnerHTML={{
              __html: t('members.remove.confirm', { user: user.Name }),
            }}
          />
        ),
        onConfirm: () => {
          removeAccess({
            entityId: project.Id,
            entityType: ApiCommon.Entity.Project,
            userId: user.Id,
          })
            .unwrap()
            .then(() => {
              dispatch(projectsApi.util.invalidateTags(['Project']));
              dispatch(
                projectTasksApi.util.invalidateTags([
                  { type: 'Performer', id: user.Id },
                ]),
              );
            });
        },
      });
    },
    [dispatch, project.Id, removeAccess],
  );

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => dispatch(manageUsersModalSlice.actions.close())}
        title={t('members.subtitle')}
        size={'lg'}
      >
        <ParticipantsForm
          usersData={usersData}
          onRemove={handleRemove}
          onUpdate={handleUpdate}
          onAdd={handleAdd}
          status={status}
          projectId={project.Id}
        />
      </Modal>

      <ActionIcon
        size={36}
        variant="light"
        color="dark"
        radius={100}
        onClick={() => dispatch(manageUsersModalSlice.actions.open())}
      >
        <IconPlus size={16} color="var(--mantine-color-gray-6)" />
      </ActionIcon>
    </>
  );
};

import { ApiTaskType } from '@/api/taskTypes';
import { TaskTypeSelect } from '@/components/ui/selects/TaskTypeSelect';
import { openTaskModal } from '@/features/TaskModal/store';
import { useAccess } from '@/hooks/useAccess';
import { useUpdateTask } from '@/hooks/useUpdateTask';
import { Flex } from '@mantine/core';
import { Ellipsis } from '@/components/ui/Ellipsis';
import { GanttTask } from '../transformTaskData';

import styles from '../Timeline.module.css';

export const TaskCell = ({
  task: { _taskData },
  types,
}: {
  task: GanttTask;
  types: ApiTaskType.IType[];
}) => {
  const access = useAccess();
  const updateTask = useUpdateTask(_taskData);

  return (
    <Flex gap={4} miw={0}>
      <TaskTypeSelect
        value={_taskData.TypeId}
        onChange={(TypeId) => updateTask({ TypeId })}
        types={types}
        withLabel={false}
        readonly={!access.tasks.edit}
      />
      <Ellipsis
        className={styles.taskTitle}
        onClick={() => openTaskModal(_taskData.Id)}
      >
        {_taskData.Title}
      </Ellipsis>
    </Flex>
  );
};

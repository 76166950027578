import { memo } from 'react';

import { taskTypesApi } from '@/api/taskTypes';
import { ApiProjectTask } from '@/api/tasks';
import { TaskTypeBadge } from '@/components/ui/badges/TaskTypeBadge';
import { useFolderData } from '@/hooks/useFolderData';

type TaskTypeProps = {
  task: ApiProjectTask.ITask;
};

export const TaskType = memo(({ task }: TaskTypeProps) => {
  const { TypeId } = task;

  const projectData = useFolderData(task.ProjectId);

  const { data } = taskTypesApi.useGetTaskTypesQuery(
    [projectData?.rootProjectId || 0],
    { skip: !projectData },
  );

  const type = data?.mapById[TypeId];

  return <TaskTypeBadge withLabel={false} type={type} />;
});

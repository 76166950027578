import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { ComplexProjectData } from '@/api/projects';
import { openCreateFolderModal } from '@/features/EditEntityModals';
import { ActionIcon, NavLink, Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { IconPlus } from '@tabler/icons-react';

import styles from '../Navigation.module.css';

interface IProps {
  project: ComplexProjectData;
  onClose?: VoidFunction;
}

export const AddFolder = ({ project, onClose }: IProps) => {
  const { t } = useTranslation();
  const isBurger = useMediaQuery('(max-width: 1200px)');

  const handleCreate = useCallback(() => {
    isBurger && onClose?.();
    setTimeout(() => openCreateFolderModal(project), 0);
  }, [isBurger, project, onClose]);

  return (
    <NavLink
      onClick={handleCreate}
      classNames={{
        root: styles.root,
      }}
      h={24}
      childrenOffset={0}
      label={
        <Text size="sm" c={'dark.2'}>
          {t('sidebar.addFolder')}
        </Text>
      }
      leftSection={
        <ActionIcon size={26} ml={8} variant="transparent" color="#808080">
          <IconPlus size="1rem" stroke={1.5} />
        </ActionIcon>
      }
    />
  );
};

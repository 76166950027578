import { appRoute } from '@/app/routes/AppRoute';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import queryString from 'query-string';

interface State {
  opened: boolean;
  taskId: number | string;
}

const initialState: State = {
  opened: false,
  taskId: -1,
};

export const SHOW_TASK_MODAL_PARAM = 'task';

export const openTaskModal = (taskId: number) => {
  const search = appRoute.state.location.search;

  const params = queryString.parse(search);
  params[SHOW_TASK_MODAL_PARAM] = taskId.toString();

  appRoute.navigate({
    search: queryString.stringify(params),
  });
};

export const closeTaskModal = () => {
  const search = appRoute.state.location.search;

  appRoute.navigate({
    search: queryString.exclude(search, [SHOW_TASK_MODAL_PARAM]),
  });
};

export const taskModalSlice = createSlice({
  name: 'taskModalSlice',
  initialState,
  reducers: {
    setOpened: (state, action: PayloadAction<boolean>) => {
      state.opened = action.payload;
    },
    openTask: (state, action: PayloadAction<number | string>) => {
      state.taskId = action.payload;
      state.opened = true;
    },
  },
});

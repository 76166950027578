import { useEffect } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';

import { AppRoutes } from '@/config/links';
import { viewList } from '@/features/SprintViewSelect/components/SprintViewSelect';
import { setSprintView } from '@/features/SprintViewSelect/helpers';
import { generateLink } from '@/helpers/generateLink';
import { useMediaQuery } from '@mantine/hooks';

import { useNumberQueryParam } from './useNumberQueryParam';

export const useRedirectToList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery(`(max-width: 768px)`);
  const sprintId = useNumberQueryParam('sprintId');
  const isSprintList = !!matchPath(AppRoutes.sprintList, location.pathname);
  const selectValue = viewList.find(
    (el) => el.link && matchPath(AppRoutes[el.link], location.pathname),
  )?.value;

  useEffect(() => {
    if (isMobile && sprintId && !isSprintList) {
      setSprintView(sprintId, 'list');
      navigate(generateLink(AppRoutes.sprintList, { sprintId }));
    }
  }, [isMobile, sprintId, isSprintList, navigate]);

  useEffect(() => {
    if (sprintId && selectValue) {
      setSprintView(sprintId, selectValue);
    }
  }, [sprintId, selectValue]);
};

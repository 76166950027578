/**
 * Проверяет, является ли браузер Safari
 * @returns {boolean} - true, если браузер Safari, иначе false
 */
const isSafari = () => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf('safari') !== -1 && ua.indexOf('chrome') === -1;
};
/**
 * Проверяет, является ли браузер Firefox
 * @returns {boolean} - true, если браузер Firefox, иначе false
 */
const isFirefox = () => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf('firefox') !== -1;
};

/**
 * Определяет, является ли устройство мобильным
 * @returns {boolean} - true, если устройство мобильное, иначе false
 */
export const isMobileDevice = () => {
  return /Mobi|Android/i.test(navigator.userAgent);
};

/**
 * Проверяет, является ли файл видеофайлом
 * @param {string} mimeType - MIME тип файла
 * @param {string | null | undefined} filename - имя файла
 * @returns {boolean} - true, если файл является видеофайлом, иначе false
 */
export const isVideoFile = (
  mimeType: string,
  filename: string | null | undefined,
) => {
  if (!filename) return false;
  const extension = getFileExtension(filename);

  // Проверяет, является ли браузер Safari и формат видео WebM
  if ((isSafari() || isFirefox()) && extension === 'webm') {
    return false;
  }

  return (
    mimeType.startsWith('video') ||
    (mimeType === 'application/octet-stream' &&
      (extension === 'mp4' ||
        extension === 'mov' ||
        extension === 'webm' ||
        extension === 'ogv')) ||
    mimeType === 'video/quicktime'
  );
};

/**
 * Проверяет, является ли файл медиафайлом для галереи
 * @param {string} mimeType - MIME тип файла
 * @param {string | null} filename - имя файла
 * @returns {boolean} - true, если файл является медиафайлом для галереи, иначе false
 */
export const isGalleryMediaFile = (
  mimeType: string,
  filename: string | null,
) => {
  if (!filename) return false;
  return mimeType.startsWith('image') || isVideoFile(mimeType, filename);
};

/**
 * Получает правильный MIME тип для файла
 * @param {string} mimeType - исходный MIME тип файла
 * @param {string | null | undefined} filename - имя файла
 * @returns {string} - корректный MIME тип файла
 */
export const getMimeType = (
  mimeType: string,
  filename: string | null | undefined,
) => {
  if (!filename) return mimeType;
  const extension = getFileExtension(filename);
  if (
    mimeType === 'application/octet-stream' ||
    mimeType === 'video/quicktime'
  ) {
    switch (extension) {
      case 'mp4':
        return 'video/mp4';
      case 'mov':
        return 'video/mp4';
      case 'webm':
        return 'video/webm';
      case 'ogv':
        return 'video/ogg';
      default:
        return mimeType;
    }
  }
  return mimeType;
};

/**
 * Получает расширение файла
 * @param {string} filename - имя файла
 * @returns {string | undefined} - расширение файла в нижнем регистре, или undefined, если не найдено
 */
export const getFileExtension = (filename: string) => {
  return filename.split('.').pop()?.toLowerCase();
};

import { useCallback, useEffect, useState } from 'react';

import { usersApi } from '@/api/users';
import { useDebouncedCallback } from '@mantine/hooks';

const PAGE_SIZE = 20;

export const useSearchUsers = (excludeUsers?: number[]) => {
  const [query, setQuery] = useState('');

  const [loadUsers, { data, ...request }] = usersApi.useLazySearchQuery();

  const handleSearch = useCallback(
    (search: string) =>
      loadUsers(
        {
          query: search,
          size: PAGE_SIZE,
          offset: 0,
        },
        true,
      ),
    [loadUsers],
  );

  const debouncedHandleSearch = useDebouncedCallback(handleSearch, 800);

  const handleLoadMore = useCallback(async () => {
    if (!data?.hasMore || request.isFetching) return;

    loadUsers(
      {
        query,
        size: PAGE_SIZE,
        offset: data.data.length,
        prevData: data.data,
      },
      true,
    );
  }, [data, loadUsers, query, request.isFetching]);

  const handleInitLoad = useCallback(() => {
    if ((data && data?.data.length > 0) || request.isFetching || query) return;

    handleSearch('');
  }, [data, handleSearch, query, request.isFetching]);

  useEffect(() => {
    if (
      !data?.data.filter((u) => !excludeUsers?.includes(u.Id) && u.Status === 1)
        .length
    )
      handleLoadMore();
  }, [data?.data, excludeUsers, handleLoadMore]);

  const isLoadingMore = request.isFetching && !!request.originalArgs?.offset;
  const isLoading = request.isFetching && !request.originalArgs?.offset;

  return {
    ...request,
    data,
    query,
    setQuery,
    handleLoadMore,
    handleSearch,
    debouncedHandleSearch,
    handleInitLoad,
    isLoadingMore,
    isLoading,
  };
};

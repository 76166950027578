import { AppProviders } from '@/app/AppProviders';
import { Router } from '@/app/routes/Router';
import { SkeletonsController } from '@/dev/SkeletonsController';
import { isDevtools } from '@/dev/devtools';
import * as Sentry from '@sentry/react';

// Sentry
if (
  process.env.NODE_ENV === 'production' &&
  import.meta.env.VITE_UPLOAD_TO_SENTRY === 'true'
) {
  Sentry.init({
    dsn: 'https://2b3a7976871ce5a8c1f7735157ab82dd@o489084.ingest.us.sentry.io/4506962429149184',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],

    tracesSampleRate: 1.0,

    tracePropagationTargets: [/^https:\/\/projects\.dsd\.md/],

    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

function Main() {
  return (
    <AppProviders>
      <Router />
      {isDevtools() && <SkeletonsController />}
    </AppProviders>
  );
}

export default Main;

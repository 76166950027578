import { ComplexProjectData, ComplexSprintData } from '@/api/projects';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { IEntityFormValues } from '../../components/complex/EntityForm';

type EditEntityType = 'project' | 'folder' | 'sprint';

type EditType = 'create' | 'update';

interface EditEntityModalState {
  opened: boolean;
  defaultValue: IEntityFormValues;
  /** Тип формы: проект, папка или спринт  */
  entityType: EditEntityType;
  /** Какое модальное окно открыть: редактирование или создание  */
  editType: EditType;
  /** Используется для определения чем является родитель и вывода правильно лейбла у наследования статусов */
  parentId?: number | null;
  projectData?: ComplexProjectData;
  sprintData?: ComplexSprintData;
}

const initialState: EditEntityModalState = {
  opened: false,
  entityType: 'project',
  editType: 'create',
  defaultValue: {
    formType: null,
    name: '',
    icon: null,
    color: '',
    statuses: {
      provide: false,
      current: [],
      provided: [],
    },
    types: [],
    custom: [],
  },
};

export const editEntityModalSlice = createSlice({
  name: 'editEntityModal',
  initialState,
  reducers: {
    close(state) {
      state.opened = false;
    },
    open(state, action: PayloadAction<Omit<EditEntityModalState, 'opened'>>) {
      state.opened = true;
      state.entityType = action.payload.entityType;
      state.parentId = action.payload.parentId;
      state.editType = action.payload.editType;
      state.defaultValue = action.payload.defaultValue;
      state.projectData = action.payload.projectData;
      state.sprintData = action.payload.sprintData;
    },
  },
});

import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { ApiProjectTask, ApiTaskActivity } from '@/api/tasks';
import { NoData } from '@/components/ui/NoData/NoData';
import { Stack } from '@mantine/core';

import { useActivityData } from '../hooks/useActivityData';
import { IFilledActivity } from '../types';
import CommentsLoading from './CommentsLoading';
import { HistoryActivity } from './HistoryActivity';
import { HistoryAttach } from './HistoryAttach';
import { HistoryCreate } from './HistoryCreate';

export const History = ({ task }: { task: ApiProjectTask.ITask }) => {
  const { t } = useTranslation();

  const { data, isLoading } = useActivityData(task.Id, [
    ApiTaskActivity.IActivityType.AddTask,
    ApiTaskActivity.IActivityType.ChangeStatus,
    ApiTaskActivity.IActivityType.AssignTask,
    ApiTaskActivity.IActivityType.ChangeTask,
  ]);

  if (isLoading) {
    return (
      <Stack gap={16} mt={12}>
        <CommentsLoading.Comment />
        <CommentsLoading.Comment />
      </Stack>
    );
  }

  if (!data.length) {
    return <NoData description={t('nodata.emptyYet')} p={20} />;
  }

  return (
    <Stack gap={16} mt={12}>
      {data?.map((el) => (
        <Fragment key={el.Id}>{drawActivity(el, task.SprintId)}</Fragment>
      ))}
    </Stack>
  );
};

const drawActivity = (activity: IFilledActivity, sprintId: number) => {
  switch (activity.Type) {
    case ApiTaskActivity.IActivityType.ChangeStatus:
    case ApiTaskActivity.IActivityType.ChangeTask:
    case ApiTaskActivity.IActivityType.AssignTask: {
      return (
        <HistoryActivity
          sprintId={sprintId}
          activity={activity}
          showType={false}
        />
      );
    }
    case ApiTaskActivity.IActivityType.UploadAttachments: {
      return <HistoryAttach activity={activity} showType={false} />;
    }
    case ApiTaskActivity.IActivityType.AddTask: {
      return <HistoryCreate activity={activity} showType={false} />;
    }
  }
};

import { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { formatDate } from '@/helpers/formatDate';
import {
  Button,
  CloseButton,
  Group,
  Menu,
  Stack,
  Text,
  TextInput,
  TextInputProps,
  ThemeIcon,
} from '@mantine/core';
import {
  DatePicker,
  DateValue,
  DatesProvider,
  DatesRangeValue,
} from '@mantine/dates';
import { IconCalendarEvent } from '@tabler/icons-react';
import { endOfDay, isDate, startOfDay } from 'date-fns';

import { useDatesState } from './hooks/useDatesState';

import styles from './DateRangeSelect.module.css';

interface IProps {
  value?: DatesRangeValue;
  onChange?: (v: DatesRangeValue) => void;
  readonly?: boolean;
  minDate?: Date;
  maxDate?: Date;
}

export const DateRangeSelect = ({
  value,
  onChange,
  readonly,
  minDate,
  maxDate,
}: IProps) => {
  const { t } = useTranslation();

  const [opened, setOpened] = useState(false);

  const {
    refs,
    target,
    onDateChange,
    onRootMouseLeave,
    onHoveredDateChange,
    getControlProps,
    setTarget,
  } = useDatesState({
    value: value ?? [null, null],
    onChange,
  });

  if (readonly) {
    return (
      <Text size="sm" pl={10}>
        {formatValue(value, t('dates.rangePlaceholder'))}
      </Text>
    );
  }

  return (
    <Menu
      opened={opened}
      onChange={setOpened}
      shadow="md"
      closeOnClickOutside
      position="bottom-end"
      keepMounted={false}
      withinPortal={true}
      onOpen={() => setTimeout(() => refs.end.current?.focus(), 100)}
    >
      <Menu.Target>
        <Button
          fullWidth
          justify="flex-start"
          variant="subtle"
          color="dark"
          size="compact-md"
        >
          <Text size="sm">
            {formatValue(value, t('dates.rangePlaceholder'))}
          </Text>
        </Button>
      </Menu.Target>
      <Menu.Dropdown>
        <Stack gap={10} align="center">
          <Group gap={8}>
            <Input
              ref={refs.start}
              value={value?.at(0)}
              placeholder={t('dates.startDate')}
              onClear={() => {
                onChange?.([null, value?.[1] ?? null]);
                refs.start.current?.focus();
              }}
              onFocus={() => setTarget('start')}
            />
            <Input
              ref={refs.end}
              value={value?.at(1)}
              placeholder={t('dates.dueDate')}
              w={165}
              onClear={() => {
                onChange?.([value?.[0] ?? null, null]);
                refs.end.current?.focus();
              }}
              onFocus={() => setTarget('end')}
            />
          </Group>
          <DatesProvider settings={{ consistentWeeks: true }}>
            <DatePicker
              locale="ru-ru"
              type="range"
              allowSingleDateInRange
              value={value}
              getDayProps={getControlProps}
              onMouseLeave={onRootMouseLeave}
              excludeDate={(date) => {
                if (target === 'end') return false;
                if (minDate && date < startOfDay(minDate)) return true;
                if (maxDate && date > endOfDay(maxDate)) return true;

                return false;
              }}
              __onDayMouseEnter={(_, date) => onHoveredDateChange(date)}
              __onDayClick={(_, date) => onDateChange(date)}
            />
          </DatesProvider>
        </Stack>
      </Menu.Dropdown>
    </Menu>
  );
};

const Input = forwardRef<
  HTMLInputElement,
  { value?: DateValue; onClear?: VoidFunction } & Omit<TextInputProps, 'value'>
>(({ value, onClear, ...rest }, ref) => {
  return (
    <TextInput
      classNames={{ input: styles.input }}
      ref={ref}
      value={formatDate(value)}
      readOnly
      w={140}
      leftSection={
        <ThemeIcon variant="transparent" color="gray.5" size="xs">
          <IconCalendarEvent />
        </ThemeIcon>
      }
      rightSection={
        value && (
          <CloseButton variant="transparent" size="sm" onClick={onClear} />
        )
      }
      {...rest}
    />
  );
});

const formatValue = (
  value: DatesRangeValue | undefined,
  defaultValue: string,
): string => {
  if (!value || !value.some(isDate)) return defaultValue;

  const formatted = value.filter(isDate).map((date) => formatDate(date));

  return formatted.join(' - ');
};

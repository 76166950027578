import { ApiTaskType, taskTypesApi } from '@/api/taskTypes';
import { store } from '@/app/store';
import { IEntityFormValues } from '@/components/complex/EntityForm';

export const getTypesUpdates = ({
  types,
  entityId,
}: {
  types: IEntityFormValues['types'];
  entityId: number;
}) => {
  const updates: Promise<any>[] = [];

  const newTypes = types.filter((el) => el.state === 'new');
  const updatedTypes = types.filter((el) => el.state === 'updated');
  const removedTypes = types.filter((el) => el.state === 'removed');

  if (newTypes.length) {
    updates.push(
      store
        .dispatch(
          taskTypesApi.endpoints.save.initiate(
            newTypes.map((t) => ({
              Color: t.color,
              IconId: t.icon,
              Name: t.name,
              ProjectId: entityId,
              Type: ApiTaskType.TaskType.Custom,
            })),
          ),
        )
        .unwrap(),
    );
  }

  if (updatedTypes.length) {
    updates.push(
      store
        .dispatch(
          taskTypesApi.endpoints.save.initiate(
            updatedTypes.map((t) => ({
              Id: t.id,
              Color: t.color,
              IconId: t.icon,
              Name: t.name,
              ProjectId: entityId,
              Type: ApiTaskType.TaskType.Custom,
            })),
          ),
        )
        .unwrap(),
    );
  }

  if (removedTypes.length) {
    updates.push(
      store
        .dispatch(
          taskTypesApi.endpoints.remove.initiate({
            ids: removedTypes.map((el) => el.id),
            projectId: entityId,
          }),
        )
        .unwrap(),
    );
  }

  return updates;
};

import { SyntheticEvent, memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { attachmentsApi } from '@/api/attachments/slice';
import { ApiAttachments } from '@/api/attachments/types';
import { downloadURL } from '@/helpers/download';
import { getMimeType, isVideoFile } from '@/helpers/fileUtils';
import { formatBytes } from '@/helpers/formatBytes';
import {
  ActionIcon,
  Flex,
  Image,
  Loader,
  Modal,
  Stack,
  Text,
  Tooltip,
} from '@mantine/core';
import {
  IconChevronLeft,
  IconChevronRight,
  IconDownload,
  IconPhoto,
  IconVideo,
  IconX,
} from '@tabler/icons-react';

import styles from './AttachmentsGalleryModal.module.css';

type AttachmentsGalleryModalProps = {
  opened: boolean;
  onOpenChange: (v: boolean) => void;
  showAttach?: ApiAttachments.IAttachment | null;
  setShowAttach?: (v: ApiAttachments.IAttachment) => void;
  attachments: ApiAttachments.IAttachment[];
};

export const AttachmentsGalleryModal = memo(
  ({
    attachments,
    onOpenChange,
    opened,
    setShowAttach,
    showAttach,
  }: AttachmentsGalleryModalProps) => {
    const { isFirst, isLast, currentIndex } = useMemo(
      () => ({
        isFirst: attachments[0]?.Id === showAttach?.Id,
        isLast: attachments[attachments.length - 1]?.Id === showAttach?.Id,
        currentIndex: attachments.findIndex((el) => el.Id === showAttach?.Id),
      }),
      [attachments, showAttach?.Id],
    );

    if (!attachments.length || !showAttach) return null;

    return (
      <Modal
        transitionProps={{
          duration: 0,
        }}
        styles={{
          content: {
            backgroundColor: 'var(--mantine-color-dark-8)',
          },
          body: {
            height: '100%',
          },
        }}
        withCloseButton={false}
        opened={opened}
        onClose={() => onOpenChange?.(false)}
        fullScreen
      >
        <Flex
          align={'center'}
          styles={{
            root: {
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            },
          }}
        >
          {showAttach && (
            <AttachImage
              attachment={showAttach}
              onClose={() => onOpenChange(false)}
            />
          )}
          {!isFirst && (
            <ActionIcon
              variant="white"
              color="dark"
              autoContrast
              radius={'xl'}
              size={36}
              pos={'absolute'}
              top={'calc(50% - 18px)'}
              left={'16px'}
              onClick={() => setShowAttach?.(attachments[currentIndex - 1])}
            >
              <IconChevronLeft />
            </ActionIcon>
          )}
          {!isLast && (
            <ActionIcon
              variant="white"
              color="dark"
              autoContrast
              radius={'xl'}
              size={36}
              pos={'absolute'}
              top={'calc(50% - 18px)'}
              right={'16px'}
              onClick={() => setShowAttach?.(attachments[currentIndex + 1])}
            >
              <IconChevronRight />
            </ActionIcon>
          )}
        </Flex>
      </Modal>
    );
  },
);

const AttachImage = ({
  attachment,
  onClose,
}: {
  attachment: ApiAttachments.IAttachment;
  onClose?: () => void;
}) => {
  const { t } = useTranslation();

  const { data: fileUrl, isFetching } = attachmentsApi.useGetQuery({
    id: attachment.Id,
  });

  const handleDownload = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation();
      if (fileUrl) {
        downloadURL(fileUrl, attachment.Name || 'file');
      }
    },
    [attachment.Name, fileUrl],
  );

  const isVideo = isVideoFile(attachment.MimeType, attachment.Name);
  const correctMimeType = getMimeType(attachment.MimeType, attachment.Name);

  const renderContent = () => {
    if (isFetching) {
      return <Loader size="lg" color="blue" />;
    }

    if (!fileUrl) {
      return <Text c="red">{t('notification.error.getFile')}</Text>;
    }

    if (isVideo) {
      return (
        <video controls muted className={styles.html5PlayerOverlay}>
          <source src={fileUrl} type={correctMimeType} />
          Your browser does not support the video tag.
        </video>
      );
    }

    return (
      <Image mah="100dvh" fit="contain" maw="100%" w="initial" src={fileUrl} />
    );
  };

  return (
    <>
      <Flex
        justify="space-between"
        align="center"
        pos="absolute"
        top={0}
        left={0}
        right={0}
        gap={8}
        py={16}
        px={24}
        style={{
          background:
            'linear-gradient(rgb(16, 18, 20), rgba(14, 22, 36, 0)) 0px center no-repeat',
          zIndex: 99,
        }}
      >
        <Flex align="center" gap={12} miw={0}>
          <Flex
            justify="center"
            align="center"
            gap={8}
            style={{
              flexShrink: 0,
              width: '32px',
              height: '32px',
              borderRadius: '4px',
              backgroundColor: 'var(--mantine-color-blue-5)',
              color: 'var(--mantine-color-white)',
            }}
          >
            {isVideo ? <IconVideo size={18} /> : <IconPhoto size={18} />}
          </Flex>
          <Stack gap={0} miw={0}>
            <Text size="md" c="white" truncate="end">
              {attachment.Name}
            </Text>
            <Text size="sm" c="dark.1" truncate="end">
              {attachment.MimeType} / {formatBytes(attachment.Size)}
            </Text>
          </Stack>
        </Flex>

        <Flex gap={4}>
          <Tooltip
            label={t('common.action.download')}
            openDelay={200}
            keepMounted={false}
          >
            <ActionIcon
              size="lg"
              variant="subtle"
              color="gray.0"
              onClick={handleDownload}
            >
              <IconDownload />
            </ActionIcon>
          </Tooltip>
          <Tooltip
            label={t('common.action.close')}
            openDelay={200}
            keepMounted={false}
          >
            <ActionIcon
              size="lg"
              variant="subtle"
              color="gray.0"
              onClick={onClose}
            >
              <IconX />
            </ActionIcon>
          </Tooltip>
        </Flex>
      </Flex>

      <Flex
        justify="center"
        align="center"
        styles={{
          root: {
            width: '100%',
            height: '100%',
            minHeight: 320,
          },
        }}
      >
        {renderContent()}
      </Flex>
    </>
  );
};

export default AttachImage;

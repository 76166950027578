import { useCallback, useState } from 'react';

import { UserAvatar } from '@/components/ui/UserAvatar';
import { ActionIcon, Box, Button, Combobox, useCombobox } from '@mantine/core';
import { isEqual } from 'lodash-es';

import { UserOption } from '../../components/ui/UserOption';
import { PerformerSelectDropdown } from './PerformerSelectDropdown';
import { PerformerOption, PerformerSelectProps } from './types';

export const PerformerSelect = <OPTION extends PerformerOption>({
  value,
  onChange,
  withLabel = true,
  projectId,
  readonly,
  offset,
}: PerformerSelectProps<OPTION>) => {
  const [opened, onOpenedChange] = useState(false);
  const combobox = useCombobox({
    opened,
    onOpenedChange,
  });

  const handleClick = useCallback(() => {
    combobox.openDropdown();
  }, [combobox]);

  if (readonly) {
    return (
      <Box pl={10}>
        {withLabel ? (
          <UserOption user={value} />
        ) : (
          <UserAvatar size={24} user={value} />
        )}
      </Box>
    );
  }

  return (
    <Combobox
      keepMounted={false}
      store={combobox}
      position="bottom-end"
      offset={offset}
      shadow="md"
    >
      <Combobox.Target>
        {withLabel ? (
          <Button
            fullWidth
            justify="flex-start"
            variant="subtle"
            size="compact-md"
            color="dark"
            onClick={handleClick}
          >
            <UserOption user={value} />
          </Button>
        ) : (
          <ActionIcon
            onClick={handleClick}
            variant="subtle"
            color={'dark'}
            size="md"
          >
            <UserAvatar size={24} user={value} />
          </ActionIcon>
        )}
      </Combobox.Target>

      <Combobox.Dropdown miw={300} style={{ overflow: 'hidden' }}>
        <PerformerSelectDropdown<OPTION>
          value={value}
          projectId={projectId}
          onChange={(opt) => {
            if (!isEqual(value, opt)) onChange?.(opt);
            combobox.closeDropdown();
          }}
        />
      </Combobox.Dropdown>
    </Combobox>
  );
};

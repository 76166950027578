import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ApiCommon } from '@/api/common';
import { showRequestNotifications } from '@/api/helpers/showNotifications';
import { projectsApi } from '@/api/projects';
import { useAppSelector } from '@/app/store';
import {
  IEntityFormValues,
  NewEntityForm,
} from '@/components/complex/EntityForm';
import { redirectToSprint } from '@/helpers/redirect';
import { useFolderData } from '@/hooks/useFolderData';
import { QueryStatus } from '@reduxjs/toolkit/query';
import { isNull } from 'lodash-es';

import { EntityModal, closeEntityModal } from './EntityModal';

export const CreateSprintModal = () => {
  const { t } = useTranslation();

  const [create, { isLoading }] = projectsApi.useCreateSprintMutation();

  const { opened, defaultValue, entityType, parentId, editType } =
    useAppSelector((s) => s.editEntityModal);

  const parentProjectData = useFolderData(parentId);
  const parentIsFolder = !!parentProjectData?.ParentId;

  const { status } = projectsApi.endpoints.getProjects.useQueryState();

  const [needRedirect, setNeedRedirect] = useState<number | null>(null);

  useEffect(() => {
    if (!isNull(needRedirect) && status === QueryStatus.fulfilled) {
      redirectToSprint(needRedirect);
      setNeedRedirect(null);
    }
  }, [status, needRedirect]);

  const handleCreate = (values: IEntityFormValues) => {
    if (!parentId) return;

    showRequestNotifications(
      create({
        Name: values.name || '',
        Color: values.color,
        ProjectId: parentId,
        Flags: values.statuses.provide
          ? ApiCommon.ProjectFlags.DEFAULT
          : ApiCommon.ProjectFlags.USE_CUSTOM_STATUSES,
        TaskStatuses: values.statuses.current.map((s) => ({
          Color: s.color,
          Name: s.name,
          PatternId: s.patternId,
          Type: s.type,
          Order: s.order,
        })),
      }).unwrap(),
      {
        successMsg: t('notification.success.createSprint'),
        errorMsg: t('notification.error.createSprint'),
      },
    ).then((response) => {
      closeEntityModal();
      setNeedRedirect(response.Id);
    });
  };

  return (
    <EntityModal
      opened={opened && entityType === 'sprint' && editType === 'create'}
    >
      <NewEntityForm
        title={t(`entitySettings.title.create.${entityType}`)}
        entityType={entityType}
        onConfirm={handleCreate}
        onClose={closeEntityModal}
        confirmLabel={t('common.action.create')}
        defaultValue={defaultValue}
        isLoading={isLoading}
        parentIsFolder={parentIsFolder}
      />
    </EntityModal>
  );
};

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ApiProjectTask } from '@/api/tasks';
import { usersApi } from '@/api/users';
import { useAppSelector } from '@/app/store';
import { DateRangeSelect } from '@/components/ui/DateRangeSelect';
import { UserOption } from '@/components/ui/UserOption';
import { TaskPrioritySelect } from '@/components/ui/selects/TaskPrioritySelect';
import { PerformerSelect } from '@/features/PerformerSelect';
import { formatDate } from '@/helpers/formatDate';
import { useAccess } from '@/hooks/useAccess';
import { usePerformerData } from '@/hooks/usePerformerData';
import { useUpdateTask } from '@/hooks/useUpdateTask';
import {
  Accordion,
  Box,
  Button,
  Stack,
  Text,
  Title,
  Tooltip,
} from '@mantine/core';
import { DatesRangeValue } from '@mantine/dates';
import { useElementSize, useHotkeys, useMediaQuery } from '@mantine/hooks';
import { IconChevronDown } from '@tabler/icons-react';
import { isDate } from 'date-fns';

// import { DateSelect } from './components/DateSelect';
import styles from './TaskSidebar.module.css';

export const TaskSidebar = ({ task }: { task: ApiProjectTask.ITask }) => {
  const { t } = useTranslation();
  const access = useAccess();

  const currentUser = useAppSelector((s) => s.usersCache.currentUser);

  usersApi.useGetByIdQuery({
    ids: [task.PerformerId, task.AuthorId].filter(Boolean),
  });

  const usersData = useAppSelector((s) => s.usersCache.entities);

  const performerData = usePerformerData(task);
  const authorData = task.AuthorId ? usersData[task.AuthorId] : null;

  const { ref, width } = useElementSize();
  const isMobile = useMediaQuery('(max-width: 992px)');
  const [accordion, setAccordion] = useState<string | null>(
    !isMobile ? 'details' : null,
  );

  const extendView = width >= 330;

  const updateTask = useUpdateTask(task);

  const dateRange = [task.StartDate, task.DueDate].map((el) =>
    el ? new Date(el) : null,
  ) as DatesRangeValue;
  const createdDate = task.Timestamp && new Date(task.Timestamp);
  const updatedDate = (task.Updated && new Date(task.Updated)) || createdDate;

  useEffect(() => {
    setAccordion(!isMobile ? 'details' : null);
  }, [isMobile]);

  useHotkeys([['M', () => updateTask({ PerformerId: currentUser.Id })]]);

  return (
    <Accordion
      value={accordion}
      onChange={setAccordion}
      classNames={{
        root: styles.accordion,
        item: styles.item,
        control: styles.control,
        label: styles.label,
        content: styles.content,
      }}
      chevron={<IconChevronDown size={30} />}
    >
      <Accordion.Item value="details">
        <Accordion.Control>
          <Title
            order={2}
            fz={14}
            c="light-dark(var(--mantine-color-gray-8), var(--mantine-color-dark-0))"
          >
            {t('taskDetails.title')}
          </Title>
        </Accordion.Control>
        <Accordion.Panel>
          <Stack
            gap={8}
            ref={ref}
            className={styles.detailsRoot}
            data-extend-view={extendView}
          >
            <div className={styles.taskDetailsRow}>
              <Text className={styles.taskDetailsLabel}>
                {t('taskDetails.performer')}
              </Text>
              <div className={styles.taskDetailsData}>
                <PerformerSelect
                  projectId={task.ProjectId}
                  value={performerData}
                  onChange={(opt) =>
                    updateTask({ PerformerId: opt?.Id || null })
                  }
                  readonly={!access.tasks.edit}
                />
                {!task.PerformerId && access.tasks.edit && (
                  <Tooltip
                    label={`${t('common.action.assign')} (M)`}
                    withArrow
                    position="top"
                  >
                    <Button
                      variant="subtle"
                      color="blue"
                      px={10}
                      size="compact-xs"
                      onClick={() =>
                        updateTask({ PerformerId: currentUser.Id })
                      }
                    >
                      {t('createTask.fields.performer.description')}
                    </Button>
                  </Tooltip>
                )}
              </div>
            </div>
            <div className={styles.taskDetailsRow}>
              <Text className={styles.taskDetailsLabel}>
                {t('taskDetails.author')}
              </Text>
              <Box pl={11} w="100%" style={{ overflow: 'hidden' }}>
                <UserOption user={authorData} />
              </Box>
            </div>
            <div className={styles.taskDetailsRow}>
              <Text className={styles.taskDetailsLabel}>
                {t('taskDetails.priority')}
              </Text>
              <div className={styles.taskDetailsData}>
                <TaskPrioritySelect
                  withLabel
                  priority={task.Priority}
                  onChange={(Priority) => updateTask({ Priority })}
                  readonly={!access.tasks.edit}
                />
              </div>
            </div>
            <div className={styles.taskDetailsRow}>
              <Text className={styles.taskDetailsLabel}>
                {dateRange.every(isDate)
                  ? t('dates.title')
                  : isDate(dateRange.at(0))
                    ? t('dates.startDate')
                    : isDate(dateRange.at(1))
                      ? t('dates.dueDate')
                      : t('dates.title')}
              </Text>
              <Stack gap={16} className={styles.taskDetailsData}>
                <DateRangeSelect
                  value={dateRange}
                  onChange={([start, end]) =>
                    updateTask({
                      StartDate: start?.toISOString() || null,
                      DueDate: end?.toISOString() || null,
                    })
                  }
                  readonly={!access.tasks.edit}
                />
              </Stack>
            </div>
            {/*<div className={styles.taskDetailsRow}>
              <Text className={styles.taskDetailsLabel}>Дата окончания</Text>
              <Stack gap={16} className={styles.taskDetailsData}>
                <DateSelect
                  value={task.EndDate ? new Date(task.EndDate) : null}
                  onChange={(EndDate) =>
                    updateTask({ EndDate: EndDate?.toISOString() || null })
                  }
                  minDate={
                    task.StartDate ? new Date(task.StartDate) : undefined
                  }
                  readonly={!access.tasks.edit}
                />
              </Stack>
            </div>*/}
            <Stack gap={4} className={styles.taskDetailsFooter} mt={'lg'}>
              <Text size={'xs'} className={styles.subLabel}>
                {`${t('dates.created')}: `}
                {formatDate(createdDate, {
                  format: 'longWithTime',
                  invalidValue: '-',
                })}
              </Text>
              <Text size={'xs'} className={styles.subLabel}>
                {`${t('dates.updated')}: `}
                {formatDate(updatedDate, {
                  format: 'longWithTime',
                  invalidValue: '-',
                })}
              </Text>
            </Stack>
          </Stack>
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
};

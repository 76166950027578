import { useTranslation } from 'react-i18next';

import { ComplexSprintData } from '@/api/projects';
import { openUpdateSprintModal } from '@/features/EditEntityModals';
import { BoxProps, Button } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';

interface IProps {
  sprint: ComplexSprintData;
}

export const CreateStatus = ({ sprint, ...props }: IProps & BoxProps) => {
  const { t } = useTranslation();

  return (
    <Button
      fullWidth
      leftSection={<IconPlus size={12} />}
      size="xs"
      variant="subtle"
      color="gray.8"
      justify="flex-start"
      onClick={() => openUpdateSprintModal(sprint, 'statuses')}
      {...props}
    >
      {t('entitySettings.statusSection.addStatus')}
    </Button>
  );
};

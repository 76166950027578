import { ApiCommon } from '@/api/common';
import { ComplexSprintData } from '@/api/projects';
import { ApiProjectTask } from '@/api/tasks';
import { TasksGroup } from '@/hooks/useGroupTasks';

type GanttData = {
  id: string;
  name: string;
  startDate: string | undefined;
  endDate: string | undefined;
  progress?: number;
  dependencies?: string[];
  color: string | null;
  group?: boolean;
  collapsed?: boolean;
  isFirst?: boolean;
};

export type GanttTask = GanttData & {
  type: 'task';
  _taskData: ApiProjectTask.ITask;
};

export type GanttStatus = GanttData & {
  type: 'status';
  _statusData: ApiCommon.ITaskStatus;
  children: GanttTask[];
};

export type GanttSprint = GanttData & {
  type: 'sprint';
  _sprintData: ComplexSprintData;
  children: GanttTask[];
};

export type GanttItem = GanttTask | GanttStatus | GanttSprint;

export const ganttPrepareTasks = (tasks?: ApiProjectTask.ITask[]) => {
  return (
    tasks?.map<GanttTask>((task, index) => ({
      id: task.Id.toString(),
      name: task.Title,
      startDate: task.StartDate || undefined,
      endDate: task.DueDate || undefined,
      progress: 40,
      dependencies: [],
      color: 'blue',
      _taskData: task,
      type: 'task',
      isFirst: index === 0,
    })) || []
  );
};

export const ganttPrepareStatuses = (
  groups: TasksGroup[],
  expand: Record<number, boolean>,
) => {
  return groups.map<GanttStatus>(({ status, tasks }, index) => ({
    id: status.Id.toString(),
    name: status.Name,
    startDate: undefined,
    endDate: undefined,
    progress: 0,
    dependencies: [],
    color: status?.Color,
    type: 'status',
    _statusData: status,
    group: true,
    collapsed: !!expand[status.Id],
    children: ganttPrepareTasks(tasks),
    isFirst: index === 0,
  }));
};

export const ganttPrepareSprint = (
  sprint: ComplexSprintData,
  tasks: ApiProjectTask.ITask[],
  expand: Record<number, boolean>,
): GanttSprint => {
  return {
    id: sprint.Id.toString(),
    name: sprint.Name,
    startDate: undefined,
    endDate: undefined,
    progress: 0,
    dependencies: [],
    color: sprint?.Color,
    type: 'sprint',
    _sprintData: sprint,
    group: true,
    collapsed: !!expand[sprint.Id],
    children: ganttPrepareTasks(tasks),
  };
};

import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { ApiProjectTask } from '@/api/tasks';
import { CopyButton } from '@/components/ui/CopyButton';
import { AppRoutes } from '@/config/links';
import { removeTask } from '@/helpers/removeTask';
import { useAccess } from '@/hooks/useAccess';
import { ActionIcon, Group, Menu } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconDotsVertical, IconTrash } from '@tabler/icons-react';

import { MoveTaskButton } from '../../MoveTaskButton/MoveTaskButton';

export const TaskActions = ({
  task,
  rightSection,
  onRemove,
}: {
  task: ApiProjectTask.ITask;
  rightSection?: ReactNode;
  onRemove?: () => void;
}) => {
  const { t } = useTranslation();
  const access = useAccess();

  const [opened, { open, close }] = useDisclosure();

  return (
    <Group gap={4} justify="flex-end">
      <CopyButton
        value={AppRoutes.getTaskLink({
          sprintId: task.SprintId,
          taskId: task.Id,
        })}
        useHotkeys
      />
      {(access.tasks.edit || access.tasks.delete) && (
        <Menu
          position="bottom-end"
          closeOnItemClick={false}
          opened={opened}
          onOpen={open}
          onClose={close}
        >
          <Menu.Target>
            <ActionIcon variant="subtle" color="dark">
              <IconDotsVertical size={18} />
            </ActionIcon>
          </Menu.Target>
          <Menu.Dropdown onClick={(e) => e.stopPropagation()}>
            {access.tasks.edit && (
              <MoveTaskButton task={task} onClose={close} />
            )}
            {access.tasks.delete && (
              <Menu.Item
                color="red"
                onClick={() => {
                  removeTask(task, onRemove);
                  close();
                }}
                leftSection={<IconTrash size={18} />}
              >
                {t('common.action.delete')}
              </Menu.Item>
            )}
          </Menu.Dropdown>
        </Menu>
      )}

      {rightSection}
    </Group>
  );
};

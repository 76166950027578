import { KanbanTaskGroupLoader } from './KanbanTaskGroupLoader';

import styles from '@/features/Sprint/Kanban/Kanban.module.css';

interface IProps {
  /**
   * taskCount = 4;
   *
   * groupCount = [taskCount, taskCount, taskCount];
   *
   * builder={groupCount}
   */
  builder?: number[];
}

export const KanbanLoader = ({ builder = [5, 2, 3] }: IProps) => {
  return (
    <div
      className={styles.root}
      style={{
        gridTemplateColumns: `repeat(${builder.length}, minmax(250px, 400px))`,
        height: '100%',
      }}
    >
      {builder.map((el, i) => (
        <KanbanTaskGroupLoader key={i} builder={el} />
      ))}
    </div>
  );
};

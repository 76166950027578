import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { attachmentsApi } from '@/api/attachments/slice';
import { ApiCommon } from '@/api/common';
import { ApiProjectTask, projectTasksApi } from '@/api/tasks';
import { store } from '@/app/store';
import { Stack, Text } from '@mantine/core';
import { Dropzone, FileRejection, FileWithPath } from '@mantine/dropzone';
import { showNotification } from '@mantine/notifications';
import {
  IconCheck,
  IconCircleXFilled,
  IconDownload,
} from '@tabler/icons-react';

import { MAX_FILES } from '../../../constants';

import '@mantine/dropzone/styles.css';

export const TaskFilesUpload = ({
  task,
  onUpload,
}: {
  task: ApiProjectTask.ITask;
  onUpload?: () => void;
}) => {
  const { t } = useTranslation();

  const [uploadFile, { isLoading: uploadLoading }] =
    attachmentsApi.useUploadFileMutation();
  const [linkAttach, { isLoading: linkLoading }] =
    attachmentsApi.useLinkAttachMutation();

  const handleReject = useCallback(
    (files: FileRejection[]) => {
      const invalidFiles = files.filter((el) =>
        el.errors.some((er) => er.code === 'file-invalid-type'),
      );

      if (invalidFiles.length > 0) {
        showNotification({
          color: 'blue',
          message: (
            <>
              {t('attachments.invalid')}{' '}
              <b>{invalidFiles.map((el) => el.file.name).join(', ')}</b>
            </>
          ),
        });
        return;
      }

      // if (
      //   files.some((el) => el.errors.some((er) => er.code === 'file-too-large'))
      // ) {
      //   showNotification({
      //     title: 'Ошибка',
      //     message: 'Размер файла не должен превышать 5 MB',
      //     color: 'orange',
      //     icon: <IconAlertCircleFilled size={16} />,
      //   });
      //   return;
      // }

      const tooManyFiles = files.filter((el) =>
        el.errors.some((er) => er.code === 'too-many-files'),
      );

      if (tooManyFiles.length > 0) {
        showNotification({
          message: <>{t('attachments.maxFiles', { count: MAX_FILES })}</>,
          color: 'blue',
        });
        return;
      }
    },
    [t],
  );

  const handleDrop = useCallback(
    (files: FileWithPath[]) => {
      const filteredFiles = files.filter((file) => {
        if (file.size <= 100 * 1024 * 1024) {
          return true;
        } else {
          showNotification({
            title: t('notification.error.upload'),
            color: 'red',
            icon: <IconCircleXFilled size={30} />,
            message: (
              <>
                {t('attachments.maxSize')} <b>{file.name}</b>.
              </>
            ),
          });
          return false;
        }
      });

      if (filteredFiles.length === 0) return;

      const uploadAndLinkFile = async (file: FileWithPath) => {
        const formData = new FormData();
        formData.append('file', file, file.name);

        const uploadResponse = await uploadFile(formData).unwrap();
        const linkResponse = await linkAttach({
          entityId: task.Id,
          entityType: ApiCommon.Entity.ProjectTask,
          fileId: uploadResponse.Id,
          name: uploadResponse.Name,
        });

        return {
          uploadResponse,
          linkResponse,
        };
      };

      const uploadAndAttachAllFiles = async () => {
        const uploads = filteredFiles.map(async (file) => {
          return uploadAndLinkFile(file).catch((err) => {
            showNotification({
              title: t('notification.error.upload'),
              color: 'red',
              icon: <IconCircleXFilled size={30} />,
              message:
                err.originalStatus === 413 || err.status === 413 ? (
                  <>
                    {t('attachments.maxSize')} <b>{file.name}</b>.
                  </>
                ) : (
                  <>
                    {t('notification.error.getFile')}: <b>{file.name}</b>.
                  </>
                ),
            });
            return 'error';
          });
        });

        return Promise.all(uploads);
      };

      uploadAndAttachAllFiles().then((data) => {
        const hasErrors = data.some((el) => el === 'error');

        if (!hasErrors) {
          showNotification({
            color: 'blue',
            icon: <IconCheck size={30} />,
            message: t('notification.success.addFile', { count: data.length }),
          });
        }

        store.dispatch(
          projectTasksApi.util.invalidateTags([
            {
              type: 'Task',
              id: task.Id,
            },
          ]),
        );

        onUpload?.();
      });
    },
    [linkAttach, task.Id, uploadFile, onUpload, t],
  );

  return (
    <Dropzone
      loading={uploadLoading || linkLoading}
      onDrop={handleDrop}
      onReject={handleReject}
      maxFiles={MAX_FILES}
    >
      <Stack
        justify="center"
        gap={8}
        align={'center'}
        mih={200}
        style={{ pointerEvents: 'none' }}
      >
        <Text c="gray.6">
          <IconDownload size={80} strokeWidth={1.5} />
        </Text>

        <Stack
          gap={2}
          align="center"
          c="light-dark(var(--mantine-color-gray-8), var(--mantine-color-gray-6))"
        >
          <Text fz="lg" ta="center">
            {t('attachments.description')}
          </Text>
          <Text c="dimmed" ta="center">
            {t('attachments.hint')}
          </Text>
        </Stack>
      </Stack>
    </Dropzone>
  );
};

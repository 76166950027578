import { useTranslation } from 'react-i18next';

import { Container } from '@mantine/core';

import { NoData } from '../NoData/NoData';

import classes from './NotFound.module.css';

export const NotFound = () => {
  const { t } = useTranslation();

  return (
    <Container className={classes.root}>
      <div className={classes.inner}>
        <NoData
          title={t('404.title')}
          description={t('404.description', { entity: t('404.entities.task') })}
          illustration="404"
        />
      </div>
    </Container>
  );
};

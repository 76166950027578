import { showRequestNotifications } from '@/api/helpers/showNotifications';
import { ComplexSprintData, projectsApi } from '@/api/projects';
import { store } from '@/app/store';
import { openRemoveConfirmModal } from '@/components/ui/confirmModals/RemoveConfirmModal';
import { redirectToProject } from '@/helpers/redirect';
import { Text } from '@mantine/core';
import { t } from 'i18next';

type RemoveSprintModalProps = {
  sprint: ComplexSprintData;
};

export const openRemoveSprintModal = ({ sprint }: RemoveSprintModalProps) =>
  openRemoveConfirmModal({
    title: t('removeSprint.title'),
    children: (
      <Text
        dangerouslySetInnerHTML={{
          __html: t('removeSprint.confirm', { sprint: sprint.Name }),
        }}
      />
    ),
    onConfirm: () => {
      const subscribe = store
        .dispatch(projectsApi.endpoints.deleteSprint.initiate(sprint.Id))
        .unwrap();

      showRequestNotifications(subscribe, {
        successMsg: t('notification.success.deleteSprint'),
        errorMsg: t('notification.error.deleteSprint'),
      }).then(() => {
        redirectToProject(sprint.rootProjectId);
      });
    },
  });

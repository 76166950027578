import { ReactNode } from 'react';

import anySrc from '@/assets/svg/fileTypes/any.svg';
import archiveSrc from '@/assets/svg/fileTypes/archive.svg';
import docSrc from '@/assets/svg/fileTypes/doc.svg';
import graphicsSrc from '@/assets/svg/fileTypes/graphics.svg';
import mediaSrc from '@/assets/svg/fileTypes/media.svg';
import musicSrc from '@/assets/svg/fileTypes/music.svg';
import pdfSrc from '@/assets/svg/fileTypes/pdf.svg';
import photoSrc from '@/assets/svg/fileTypes/photo.svg';
import presentationSrc from '@/assets/svg/fileTypes/presentation.svg';
import sheetSrc from '@/assets/svg/fileTypes/sheet.svg';
import wavSrc from '@/assets/svg/fileTypes/wav.svg';
import { Image } from '@mantine/core';

import { FileGroupType } from '../../../constants';

export const attachIcons: Record<FileGroupType, ReactNode> = {
  doc: <Image w={20} h={22} src={docSrc} />,
  sheet: <Image w={20} h={22} src={sheetSrc} />,
  presentation: <Image w={20} h={22} src={presentationSrc} />,
  photo: <Image w={20} h={22} src={photoSrc} />,
  media: <Image w={20} h={22} src={mediaSrc} />,
  pdf: <Image w={20} h={22} src={pdfSrc} />,
  music: <Image w={20} h={22} src={musicSrc} />,
  wav: <Image w={20} h={22} src={wavSrc} />,
  archive: <Image w={20} h={22} src={archiveSrc} />,
  graphics: <Image w={20} h={22} src={graphicsSrc} />,
};

export const anyAttachIcon = <Image w={20} h={22} src={anySrc} />;

import { Ref, forwardRef } from 'react';

import { ApiTaskType } from '@/api/taskTypes';
import { Flex, FlexProps, Text, TextProps } from '@mantine/core';

import { ProjectIcon } from '../icons/ProjectIcon';

type TaskTypeBadgeProps<
  T extends Pick<ApiTaskType.IType, 'Color' | 'Type' | 'Name' | 'IconId'>,
> = {
  type?: T;
  withLabel?: boolean;
  iconSize?: number;
  placeholder?: string;
  textSize?: TextProps['size'];
} & FlexProps;

const Badge = <
  T extends Pick<ApiTaskType.IType, 'Color' | 'Type' | 'Name' | 'IconId'>,
>(
  {
    type,
    withLabel = true,
    iconSize = 20,
    placeholder,
    textSize = 'sm',
    ...props
  }: TaskTypeBadgeProps<T>,
  ref: Ref<HTMLDivElement>,
) => {
  return (
    <Flex
      gap={12}
      ref={ref}
      c={'light-dark(var(--mantine-color-gray-8), var(--mantine-color-dark-0))'}
      {...props}
    >
      <ProjectIcon
        size={iconSize}
        name={type?.Name}
        color={type?.Color}
        icon={type?.IconId}
      />
      {withLabel && <Text size={textSize}>{type?.Name || placeholder}</Text>}
    </Flex>
  );
};

export const TaskTypeBadge = forwardRef(Badge);

import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ComplexSprintData } from '@/api/projects';
import { ApiProjectTask, projectTasksApi } from '@/api/tasks';
import { useAppSelector } from '@/app/store';
import { TaskPrioritySelect } from '@/components/ui/selects/TaskPrioritySelect';
import { TaskStatusSelect } from '@/components/ui/selects/TaskStatusSelect';
import { PerformerSelect } from '@/features/PerformerSelect';
import { useAccess } from '@/hooks/useAccess';
import { useStatusesData } from '@/hooks/useStatusesData';
import { useTaskTypesData } from '@/hooks/useTaskTypesData';
import { Flex } from '@mantine/core';
import { Gantt } from '@sinups/ds-gantt';

import { Draggable } from '../components/Draggable';
import { DroppableLine } from '../components/DroppableLine';
import { SprintCell } from '../components/SprintCell';
import { StatusCell } from '../components/StatusCell';
import { TaskCell } from '../components/TaskCell';
import { GanttSprint, GanttStatus, GanttTask } from '../transformTaskData';

import styles from '../Timeline.module.css';

export const useColumns = ({
  dragItem,
  sprint,
}: {
  dragItem: ApiProjectTask.ITask | null;
  sprint: ComplexSprintData;
}) => {
  const { t } = useTranslation();
  const access = useAccess();

  const [updateTask] = projectTasksApi.useUpdateTaskMutation();

  const users = useAppSelector((s) => s.usersCache.entities);

  const { statuses } = useStatusesData(sprint);
  const { taskTypes } = useTaskTypesData([sprint.pathById[0]]);

  const [showColumns, setShowColumns] = useState<string[]>([
    'name',
    // 'status',
    // 'priority',
    // 'performer',
  ]);

  const columns = useMemo(() => {
    const allColumns: Gantt.Column[] = [
      {
        flex: 4,
        minWidth: 200,
        name: 'name',
        label: t('timelineSettings.columns.name'),
        render: (record) => {
          if (record.type === 'task') {
            return access.tasks.edit ? (
              <>
                {record.isFirst && (
                  <DroppableLine
                    task={{
                      ...record._taskData,
                      Id: 'firstPosition',
                      Order: record._taskData.Order - 1,
                    }}
                  />
                )}
                <Draggable task={record._taskData}>
                  <TaskCell task={record as GanttTask} types={taskTypes} />
                </Draggable>
                <DroppableLine task={record._taskData} />
              </>
            ) : (
              <TaskCell task={record as GanttTask} types={taskTypes} />
            );
          }

          if (record.type === 'status') {
            return <StatusCell status={record as GanttStatus} />;
          }

          if (record.type === 'sprint') {
            return <SprintCell sprint={record as GanttSprint} />;
          }

          return null;
        },
      },
      {
        minWidth: 70,
        flex: 2,
        name: 'status',
        label: t('timelineSettings.columns.status'),
        render: (record) => {
          if (record.type === 'task') {
            if (dragItem?.Id === record._taskData.Id) {
              return <div className={styles.dragSkeleton} />;
            }

            return (
              <div
                className={styles.taskStatusSelect}
                data-clickable={access.tasks.edit}
              >
                <TaskStatusSelect
                  readonly={!access.tasks.edit}
                  iconSize={14}
                  filled={false}
                  statuses={statuses}
                  value={record._taskData.Status}
                  onChange={(opt) =>
                    updateTask({
                      ...record._taskData,
                      Status: opt.Id,
                    })
                  }
                />
              </div>
            );
          }

          return null;
        },
      },
      {
        minWidth: 60,
        name: 'priority',
        label: t('timelineSettings.columns.priority'),
        render: (record) => {
          if (record.type === 'task') {
            if (dragItem?.Id === record._taskData.Id) {
              return <div className={styles.dragSkeleton} />;
            }

            return (
              <Flex justify="center">
                <TaskPrioritySelect
                  readonly={!access.tasks.edit}
                  priority={record._taskData.Priority}
                  onChange={(Priority) =>
                    updateTask({
                      ...record._taskData,
                      Priority,
                    })
                  }
                />
              </Flex>
            );
          }

          return null;
        },
      },
      {
        minWidth: 60,
        name: 'performer',
        label: t('timelineSettings.columns.performer'),
        render: (record) => {
          if (record.type === 'task') {
            if (dragItem?.Id === record._taskData.Id) {
              return <div className={styles.dragSkeleton} />;
            }

            return (
              <Flex justify="center">
                <PerformerSelect
                  readonly={!access.tasks.edit}
                  withLabel={false}
                  value={users[record._taskData.PerformerId]}
                  projectId={record._taskData.ProjectId}
                  onChange={(opt) =>
                    updateTask({
                      ...record._taskData,
                      PerformerId: opt?.Id,
                    })
                  }
                />
              </Flex>
            );
          }

          return null;
        },
      },
    ];

    return allColumns.filter((c) => showColumns.includes(c.name));
  }, [
    access.tasks.edit,
    dragItem?.Id,
    showColumns,
    statuses,
    taskTypes,
    users,
    t,
    updateTask,
  ]);

  return { columns, showColumns, setShowColumns };
};

import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { authApi } from '@/api/auth';
import { settingsApi } from '@/api/settings';
import { useAppSelector } from '@/app/store';
import ModalShortcut from '@/components/ui/Shorcut/ShorcutsModal';
import { AppRoutes } from '@/config/links';
import {
  Divider,
  Group,
  Menu,
  Text,
  useComputedColorScheme,
  useMantineColorScheme,
} from '@mantine/core';
import { useHotkeys } from '@mantine/hooks';
import {
  IconBug,
  IconCertificate,
  IconCommand,
  IconDeviceLaptop,
  IconLogout,
  IconSmartHome,
  IconUserCircle,
} from '@tabler/icons-react';

import { UserAvatar } from '../UserAvatar';

export const UserMenu = () => {
  const { t } = useTranslation();

  const currentUser = useAppSelector((s) => s.usersCache.currentUser);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { data } = settingsApi.endpoints.get.useQueryState();
  const [logout] = authApi.useLogoutMutation();
  // TODO Change hasAdminAccess Admin to Projects on MVP version
  const hasAdminAccess =
    data?.ModuleSettings.ModulesAccess.includes('Projects');
  const adminUrl = data?.ModuleSettings.ModuleUrls.find(
    (el) => el.Name === 'Admin',
  )?.Url;

  const { setColorScheme } = useMantineColorScheme();
  const computedColorScheme = useComputedColorScheme('light', {
    getInitialValueInEffect: true,
  });

  const handleLogout = async () => {
    logout()
      .unwrap()
      .then(() => {
        window.location.href = AppRoutes.login;
      });
  };

  const handleHotKeys = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useHotkeys([
    [
      'mod+E',
      () => setColorScheme(computedColorScheme === 'light' ? 'dark' : 'light'),
    ],
  ]);

  return (
    <>
      <Menu
        shadow="md"
        position="bottom-end"
        keepMounted={false}
        transitionProps={{ transition: 'pop-top-right', duration: 150 }}
      >
        <Menu.Target>
          <UserAvatar
            user={currentUser}
            size={36}
            style={{
              cursor: 'pointer',
            }}
          />
        </Menu.Target>
        <Menu.Dropdown>
          <Group px="sm" pt={6} maw={'100dwh'} w={300}>
            <UserAvatar user={currentUser} size={50} />
            <div style={{ flex: 1, minWidth: 0 }}>
              <Text fz={18} truncate="end">
                {currentUser.Surname ?? '-'} {currentUser.FirstName ?? '-'}
              </Text>
              <Text fz={14} c="dimmed" truncate="end">
                {currentUser.Email}
              </Text>
            </div>
          </Group>
          <Divider mt="xs" mb={6} />
          <Menu.Item
            component="a"
            href={`${adminUrl}/dashboard`}
            leftSection={<IconSmartHome stroke={1.5} size={20} />}
            px="md"
            fz={14}
          >
            {t('userMenu.main')}
          </Menu.Item>
          {hasAdminAccess && (
            <Menu.Item
              component="a"
              href={`${adminUrl}/profile`}
              leftSection={<IconUserCircle stroke={1.5} size={20} />}
              px="md"
              fz={14}
            >
              {t('userMenu.profile')}
            </Menu.Item>
          )}
          {data?.ModuleSettings.ModulesAccess.includes('Payments') && (
            <Menu.Item
              component="a"
              target="_blank"
              href={'https://payments.' + location.host}
              leftSection={<IconCertificate stroke={1.5} size={20} />}
              px="md"
              fz={14}
            >
              {t('userMenu.subscription')}
            </Menu.Item>
          )}
          <Menu.Item
            component="a"
            target="_blank"
            href={'https://preview.' + location.host}
            leftSection={<IconDeviceLaptop stroke={1.5} size={20} />}
            px="md"
            fz={14}
          >
            {t('userMenu.download')}
          </Menu.Item>
          <Menu.Item
            component="a"
            target="_blank"
            href={location.origin + '/feedback?from=Projects'}
            leftSection={<IconBug stroke={1.5} size={20} />}
            px="md"
            fz={14}
          >
            {t('userMenu.feedback')}
          </Menu.Item>
          <Menu.Item
            onClick={handleHotKeys}
            leftSection={<IconCommand stroke={1.5} size={20} />}
            px="md"
            fz={14}
          >
            {t('userMenu.shortcuts')}
          </Menu.Item>
          <Menu.Item
            onClick={handleLogout}
            leftSection={<IconLogout stroke={1.5} size={20} />}
            px="md"
            fz={14}
          >
            {t('userMenu.logout')}
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
      {isModalOpen && (
        <ModalShortcut opened={isModalOpen} onClose={closeModal} />
      )}
    </>
  );
};

import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { ApiCommon } from '@/api/common';
import { showRequestNotifications } from '@/api/helpers/showNotifications';
import { ApiProjects, projectsApi } from '@/api/projects';
import { useAppSelector } from '@/app/store';
import {
  IEntityFormValues,
  NewEntityForm,
} from '@/components/complex/EntityForm';
import { isEqual } from 'lodash-es';

import { getAttributesUpdates } from '../helpers/getAttributesUpdates';
import { getStatusesUpdates } from '../helpers/getStatusesUpdates';
import { getTypesUpdates } from '../helpers/getTypesUpdates';
import { EntityModal, closeEntityModal } from './EntityModal';

const Content = memo(() => {
  const { t } = useTranslation();

  const [update, { isLoading }] = projectsApi.useUpdateFolderMutation();

  const { entityType, projectData, defaultValue } = useAppSelector(
    (s) => s.editEntityModal,
  );

  const handleUpdate = (values: IEntityFormValues) => {
    if (!projectData) return;

    // MAIN DATA
    const oldData: Partial<ApiProjects.IProject> = {
      Name: projectData.Name,
      Color: projectData.Color,
      Description: projectData.Description,
      IconId: projectData.IconId,
    };

    const newData: Partial<ApiProjects.IProject> = {
      Name: values.name,
      Color: values.color,
      IconId: values.icon,
    };

    const updates: Promise<any>[] = isEqual(oldData, newData)
      ? []
      : [update({ ...projectData, ...newData }).unwrap()];
    // MAIN DATA - END

    showRequestNotifications(
      Promise.all([
        ...updates,
        ...getStatusesUpdates({
          entityId: projectData.Id,
          entityType: ApiCommon.Entity.Project,
          statuses: values.statuses.provide ? [] : values.statuses.current,
        }),
        ...getTypesUpdates({
          entityId: projectData.Id,
          types: values.types,
        }),
        ...getAttributesUpdates({
          entityId: projectData.Id,
          attributes: values.custom,
        }),
      ]),
      {
        successMsg: t('notification.success.editProject'),
        errorMsg: t('notification.error.editProject'),
      },
    ).then(() => closeEntityModal());
  };

  return (
    <NewEntityForm
      title={t(`entitySettings.title.edit.${entityType}`)}
      entityType={entityType}
      onConfirm={handleUpdate}
      onClose={closeEntityModal}
      confirmLabel={t('common.action.save')}
      defaultValue={defaultValue}
      isLoading={isLoading}
    />
  );
});

export const UpdateProjectModal = () => {
  const { opened, entityType, editType } = useAppSelector(
    (s) => s.editEntityModal,
  );

  return (
    <EntityModal
      opened={opened && entityType === 'project' && editType === 'update'}
    >
      <Content />
    </EntityModal>
  );
};

import { useCallback, useEffect, useRef, useState } from 'react';

import { Text, Textarea } from '@mantine/core';
import { getHotkeyHandler } from '@mantine/hooks';

interface IProps {
  value: string;
  isEdit?: boolean;
  onChange?: (v: string) => void;
}

export const TaskTitle = ({ value, isEdit, onChange }: IProps) => {
  const [innerValue, setInnerValue] = useState(value);
  const ref = useRef<HTMLTextAreaElement>(null);

  const handleChange = useCallback(
    () => onChange?.(innerValue.trim()),
    [innerValue, onChange],
  );

  useEffect(() => {
    if (!ref.current || !isEdit) return;

    setInnerValue(value);
    ref.current.selectionStart = innerValue?.toString().length ?? 0;
    ref.current.scrollTop = ref.current.scrollHeight;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

  if (!isEdit) {
    return (
      <Text
        size="sm"
        lh="16px"
        fw={500}
        c="light-dark(var(--mantine-color-gray-7), var(--mantine-color-gray-1))"
      >
        {value}
      </Text>
    );
  }

  return (
    <Textarea
      ref={ref}
      autoFocus
      onClick={(e) => e.stopPropagation()}
      onBlur={handleChange}
      onChange={(e) => setInnerValue(e.target.value)}
      onKeyDown={getHotkeyHandler([
        ['enter', handleChange],
        ['escape', handleChange],
      ])}
      value={innerValue}
      variant="unstyled"
      w="100%"
      maxLength={500}
      autosize
      size="compact-xs"
      styles={{
        input: {
          padding: 0,
          lineHeight: '16px',
          border: 'none',
          fontWeight: 500,
          color:
            'light-dark(var(--mantine-color-gray-7), var(--mantine-color-gray-1))',
        },
      }}
    />
  );
};

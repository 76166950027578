import { FOCUSED_COMMENT_PARAM } from '@/components/ui/Comment/hooks/useFocusScroll';
import { ISprintView, getSprintView } from '@/features/SprintViewSelect';
import { SHOW_TASK_MODAL_PARAM } from '@/features/TaskModal/store';
import { generateLink } from '@/helpers/generateLink';
import queryString from 'query-string';

export namespace AppRoutes {
  export const login = '/login';
  export const folder = '/folders/:folderId';
  export const sprintList = '/sprints/list/:sprintId';
  export const sprintKanban = '/sprints/kanban/:sprintId';
  export const sprintTimeline = '/sprints/timeline/:sprintId';
  export const task = '/issues/:taskId';
  export const myIssues = '/my-issues';
  export const updates = '/updates';
  export const invite = '/invite';

  export const getSprintRoute = (sprintId: string | number | null) => {
    const sprintView = sprintId ? getSprintView(Number(sprintId)) : 'list';

    const dictionary: Record<ISprintView, string> = {
      list: sprintList,
      kanban: sprintKanban,
      timeline: sprintTimeline,
    };

    return generateLink(dictionary[sprintView], { sprintId });
  };

  export const getSprintLink = (sprintId: string | number | null) => {
    return window.location.origin + getSprintRoute(sprintId);
  };

  export const getTaskLink = ({
    sprintId,
    taskId,
  }: {
    sprintId: number;
    taskId: number;
  }) => {
    return queryString.stringifyUrl({
      url: getSprintLink(sprintId),
      query: {
        [SHOW_TASK_MODAL_PARAM]: taskId,
      },
    });
  };

  export const getCommentLink = ({
    sprintId,
    taskId,
    activityId,
  }: {
    sprintId: number;
    taskId: number;
    activityId: number;
  }) => {
    return queryString.stringifyUrl({
      url: getSprintLink(sprintId),
      query: {
        [SHOW_TASK_MODAL_PARAM]: taskId,
        [FOCUSED_COMMENT_PARAM]: activityId,
      },
    });
  };
}

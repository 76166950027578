import { ApiProjectTask } from '@/api/tasks';
import { modals } from '@mantine/modals';
import { t } from 'i18next';

import { TaskFilesUpload } from './TaskFilesUpload';

const MODAL_ID = 'dropFileModal';
const handleUpload = () => modals.close(MODAL_ID);

export const openDropFilesModal = (task: ApiProjectTask.ITask) =>
  modals.open({
    modalId: MODAL_ID,
    size: 'lg',
    title: t('common.action.upload'),
    children: <TaskFilesUpload task={task} onUpload={handleUpload} />,
  });

import { createPortal } from 'react-dom';

import { DragOverlay, useDndContext } from '@dnd-kit/core';
import { Paper } from '@mantine/core';

import { IStatusForm } from '../../types';
import { StatusRow } from '../StatusRow/StatusRow';
import { DragIcon } from './DragIcon';

export const StatusOverlay = () => {
  const { active } = useDndContext();

  return createPortal(
    <DragOverlay>
      {active ? (
        <Paper shadow="md" px={0} py={0} withBorder>
          <StatusRow
            renderKey={0}
            leftSection={<DragIcon />}
            status={active.data.current as IStatusForm}
          />
        </Paper>
      ) : null}
    </DragOverlay>,
    document.body,
  );
};

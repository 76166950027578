import { ReactNode } from 'react';

import { Box, Group, Skeleton, Stack } from '@mantine/core';

import CommentsLoading from './Activity/components/CommentsLoading';
import { TaskLayout } from './TaskLayout/TaskLayout';

export const TaskLoading = ({
  actionsRightSection,
}: {
  actionsRightSection?: ReactNode;
}) => {
  return (
    <TaskLayout
      status={<Skeleton h={22} w={100} />}
      header={<Skeleton h={24} w={100} />}
      actions={<Group gap={4}>{actionsRightSection}</Group>}
      above={
        <Stack gap={16}>
          <Skeleton h={20} my={10} w="70%" data-is="title" />
          <Group gap={8} data-is="actions">
            <Skeleton h={30} w={30} />
            <Skeleton h={30} w={30} />
            <Skeleton h={30} w={30} />
          </Group>
        </Stack>
      }
      content={
        <>
          <Stack gap={16}>
            <Stack gap={16} data-is="description">
              <Skeleton h={14} w={120} />
              <Stack gap={6}>
                <Skeleton h={16} />
                <Skeleton h={16} />
                <Skeleton h={16} w="80%" />
              </Stack>
            </Stack>
            <Stack gap={12} mt={30} data-is="attachments">
              <Skeleton h={14} w={120} />
              <Skeleton h={100} w={150} />
            </Stack>
          </Stack>
          <Box mt={50} data-is="activities">
            <Stack gap={8}>
              <Skeleton h={14} w={120} />
              <Group gap={8}>
                <Skeleton h={14} w={60} />
                <Skeleton h={30} w={30} />
                <Skeleton h={30} w={30} />
                <Skeleton h={30} w={30} />
              </Group>
            </Stack>
            <CommentsLoading />
          </Box>
        </>
      }
      sidebar={
        <Stack gap={24}>
          <Stack gap={8}>
            <Skeleton h={16} w={100} />
            <Group gap={8} wrap="nowrap">
              <Skeleton h={24} w={24} circle style={{ flexShrink: 0 }} />
              <Skeleton h={16} />
            </Group>
          </Stack>
          <Stack gap={8}>
            <Skeleton h={16} w={100} />
            <Group gap={8} wrap="nowrap">
              <Skeleton h={24} w={24} style={{ flexShrink: 0 }} />
              <Skeleton h={16} />
            </Group>
          </Stack>
          <Stack gap={8}>
            <Skeleton h={16} w={100} />
            <Skeleton h={16} />
          </Stack>
          <Stack gap={8}>
            <Skeleton h={16} w={100} />
            <Skeleton h={16} />
          </Stack>
          <Stack gap={8}>
            <Skeleton h={16} w={100} />
            <Skeleton h={16} />
          </Stack>
          <Stack gap={8}>
            <Skeleton h={16} />
            <Skeleton h={16} />
          </Stack>
        </Stack>
      }
    />
  );
};

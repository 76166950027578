import { useTranslation } from 'react-i18next';

import { Box, Text, TextInput } from '@mantine/core';
import { useInputState } from '@mantine/hooks';
import { IconSearch } from '@tabler/icons-react';

import { MenuItem } from './components/MenuItem';
import { useProjectSprints } from './hooks/useProjectSprints';
import { ProjectType } from './types';

type ProjectSprintsProps<P extends ProjectType> = {
  projects?: P[];
  currentSprint?: number | null;
  onClick: (s: P['Sprints'][number]) => void;
};

export const ProjectSprints = <P extends ProjectType>({
  projects,
  onClick,
  currentSprint,
}: ProjectSprintsProps<P>) => {
  const { t } = useTranslation();

  const [value, onChange] = useInputState('');

  const { filteredProjects, hasResults } = useProjectSprints({
    projects,
    searchString: value,
  });

  return (
    <Box>
      <TextInput
        value={value}
        onChange={onChange}
        radius={0}
        size="md"
        styles={{
          input: {
            border: 'none',
            borderBottom: '1px solid',
            borderColor:
              'light-dark(var(--mantine-color-gray-3),var(--mantine-color-gray-7))',
          },
        }}
        variant="default"
        placeholder={t('common.action.search')}
        leftSection={
          <IconSearch color={'var(--mantine-color-gray-6)'} size={16} />
        }
      />

      <Box p={6} mah={300} style={{ overflow: 'auto' }}>
        {hasResults ? (
          <>
            {filteredProjects?.map((p) => (
              <MenuItem
                key={p.Id}
                project={p}
                onClick={onClick}
                currentSprint={currentSprint}
              />
            ))}
          </>
        ) : (
          <Text ta={'center'} c={'gray.7'} py={12}>
            {t('nodata.noSprints.title')}
          </Text>
        )}
      </Box>
    </Box>
  );
};

import ReactDOM from 'react-dom/client';

import '@/app/lang/index';
import Main from '@/app/main';
import { appFeature } from '@/helpers/feature';

import { appDevtools } from './dev/devtools';
import AppVersion from './preval';

import '@/assets/styles/fonts.css';
import '@/assets/styles/global.css';
import '@mantine/core/styles.layer.css';

const element = document.getElementById('root') as HTMLElement;

(window as any).appVersion = AppVersion;
(window as any).appFeature = appFeature;
(window as any).appDevtools = appDevtools;

ReactDOM.createRoot(element).render(
  // <React.StrictMode>
  <Main />,
  // </React.StrictMode>,
);

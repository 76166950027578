import { useTranslation } from 'react-i18next';

import { NoData } from '@/components/ui/NoData/NoData';
import { useAccess } from '@/hooks/useAccess';

export const NoTasks = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation();
  const access = useAccess();

  return (
    <NoData
      illustration={'noTasks'}
      title={t('nodata.noTasks2.title')}
      description={t('nodata.noTasks2.description')}
      buttons={
        access.tasks.create
          ? [
              {
                buttonText: t('nodata.noTasks2.action'),
                handleClick: onClick,
              },
            ]
          : []
      }
    />
  );
};

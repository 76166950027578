import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TaskStatusBadge } from '@/components/ui/badges/TaskStatusBadge/TaskStatusBadge';
import { EmptyStatuses } from '@/components/ui/selects/EmptyStatuses';
import { CreateTaskFormData, emptyNumberValue } from '@/hooks/useCreateTask';
import { useStatusesData } from '@/hooks/useStatusesData';
import { Combobox, Text } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';

import { SelectField } from './SelectField';

type SelectProps = {
  form: UseFormReturnType<CreateTaskFormData>;
};

export const StatusSelectField = ({ form }: SelectProps) => {
  const { t } = useTranslation();

  const { statuses, isFetching } = useStatusesData(form.values.sprint);

  useEffect(() => {
    // reset status after change sprint
    if (isFetching) return;
    if (form.values.Status === emptyNumberValue && statuses.length > 0) {
      const id = statuses.at(0)?.Id;
      id && form.setFieldValue('Status', id);
    }
  }, [form, statuses, isFetching]);

  const value = form.values.Status;

  const statusValue = useMemo(
    () => statuses.find((s) => s.Id === value),
    [statuses, value],
  );

  const options = useMemo(
    () =>
      statuses.length > 0 ? (
        statuses.map((status) => (
          <Combobox.Option
            key={status.Id}
            value={status.Id.toString()}
            style={{ display: 'flex' }}
          >
            <TaskStatusBadge status={status} />
          </Combobox.Option>
        ))
      ) : (
        <EmptyStatuses
          message={
            !form.values.sprint
              ? t('createTask.fields.status.description')
              : undefined
          }
        />
      ),
    [form.values.sprint, statuses, t],
  );

  const error = form.errors['Status'];
  const hasError = !!error;

  return (
    <SelectField
      loading={isFetching}
      value={
        value && value !== emptyNumberValue ? (
          <TaskStatusBadge status={statusValue} />
        ) : (
          <Text
            c={
              hasError
                ? 'red.6'
                : 'light-dark(var(--mantine-color-gray-5), var(--mantine-color-dark-3))'
            }
          >
            {t('createTask.fields.status.placeholder')}
          </Text>
        )
      }
      required
      label={t('createTask.fields.status.label')}
      error={error}
      options={options}
      onChange={(val) => form.setFieldValue('Status', Number(val))}
    />
  );
};

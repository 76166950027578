import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { ApiCommon } from '@/api/common';
import { TaskStatusBadge } from '@/components/ui/badges/TaskStatusBadge/TaskStatusBadge';
import { ActionIcon, Badge, Flex, Stack } from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { IconChevronDown, IconChevronRight } from '@tabler/icons-react';

import styles from './Sprint.module.css';

interface ITasksGroupCardProps {
  status?: ApiCommon.ITaskStatus;
  count: number;
}

export const TasksGroupCard = ({
  status,
  count,
  children,
}: PropsWithChildren<ITasksGroupCardProps>) => {
  const { t } = useTranslation();
  const isDesktop = useMediaQuery('(min-width: 992px)', true, {
    getInitialValueInEffect: false,
  });

  const [opened, { toggle }] = useDisclosure(true);

  return (
    <Stack
      gap={12}
      style={
        !isDesktop
          ? {
              border:
                '1px solid light-dark(var(--mantine-color-gray-2),var(--mantine-color-gray-8))',
              padding: '12px',
              borderRadius: '6px',
            }
          : undefined
      }
    >
      <Flex gap={8}>
        <ActionIcon size="sm" onClick={toggle} color="dark" variant="subtle">
          {opened ? (
            <IconChevronDown size={14} />
          ) : (
            <IconChevronRight size={14} />
          )}
        </ActionIcon>
        <TaskStatusBadge truncate status={status} />
        <Badge className={styles.sumBadge} variant="transparent" radius="lg">
          {t('createTask.taskCount', { count })}
        </Badge>
      </Flex>
      {opened && (
        <Stack gap={0} pl={isDesktop ? 28 : 0}>
          {children}
        </Stack>
      )}
    </Stack>
  );
};

import { useTranslation } from 'react-i18next';

import { ApiCommon } from '@/api/common';
import { Box, Button, ButtonProps, Menu, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
  IconCheck,
  IconChevronDown,
  IconChevronUp,
  IconTrash,
} from '@tabler/icons-react';

const options = [
  { value: ApiCommon.AccessType.Read },
  { value: ApiCommon.AccessType.Write },
  { value: ApiCommon.AccessType.Full },
];

interface UserRoleSelectProps {
  value: ApiCommon.AccessType;
  onChange?: (value: ApiCommon.AccessType) => void;
  onRemove?: () => void;
  readonly?: boolean;
  buttonProps?: ButtonProps;
}

export const UserRoleSelect: React.FC<UserRoleSelectProps> = ({
  value,
  onChange,
  onRemove,
  readonly,
  buttonProps,
}) => {
  const { t } = useTranslation();

  const [opened, { toggle }] = useDisclosure();

  if (readonly) {
    return (
      <Text c="light-dark(var(--mantine-color-gray-8), white)" size="sm" px={4}>
        {t('userRoles.' + value)}
      </Text>
    );
  }

  return (
    <Menu
      shadow="md"
      opened={opened}
      onChange={toggle}
      position="bottom-end"
      middlewares={{ shift: false, flip: true }}
      withinPortal
      zIndex={1000}
    >
      <Menu.Target>
        <Button
          size="md"
          variant="subtle"
          color="dark"
          {...buttonProps}
          style={{ flexShrink: 0, ...buttonProps?.style }}
        >
          <Text
            size="sm"
            mr={8}
            c="light-dark(var(--mantine-color-gray-8), white)"
          >
            {t('userRoles.' + value)}
          </Text>
          {opened ? <IconChevronUp size={16} /> : <IconChevronDown size={16} />}
        </Button>
      </Menu.Target>
      <Menu.Dropdown miw={150}>
        {options.map((role) => (
          <Menu.Item
            key={role.value}
            leftSection={
              value === role.value ? (
                <IconCheck size={20} stroke={1.5} />
              ) : (
                <Box w={20} />
              )
            }
            onClick={() => value !== role.value && onChange?.(role.value)}
          >
            {t('userRoles.' + role.value)}
          </Menu.Item>
        ))}
        {onRemove && (
          <>
            <Menu.Divider />
            <Menu.Item
              color="red"
              leftSection={<IconTrash size={20} stroke={1.5} color="red" />}
              onClick={onRemove}
            >
              {t('common.action.delete')}
            </Menu.Item>
          </>
        )}
      </Menu.Dropdown>
    </Menu>
  );
};

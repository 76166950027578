import { ReactNode } from 'react';

import { useIsDarkTheme } from '@/helpers/themeUtils';
import { Group, Text, ThemeIcon } from '@mantine/core';
import { IconArrowRight } from '@tabler/icons-react';
import { formatDistance } from 'date-fns';

interface IProps {
  name: string | undefined;
  action: string;
  predicate: string;
  timestamp: string | null;
  nextState?: ReactNode;
}

export const HistoryComponent = ({
  name = 'Он(а)',
  action,
  predicate,
  timestamp,
  nextState,
}: IProps) => {
  const isDark = useIsDarkTheme();
  const primaryColor = isDark ? 'white' : 'dark.6';
  const secondColor = isDark ? 'gray.6' : 'gray.7';

  return (
    <Group justify="space-between" px={8}>
      <Group gap={4} c={primaryColor}>
        <Text fz="xs">{name}</Text>
        <Text fz="xs" c={secondColor}>
          {action}
        </Text>
        <Text fz="xs" fw={500}>
          {predicate}
        </Text>
        {nextState && (
          <ThemeIcon variant="transparent" size={16} c={secondColor}>
            <IconArrowRight style={{ width: '80%', height: '80%' }} />
          </ThemeIcon>
        )}
        {nextState}
      </Group>
      <Text fz="xs" c={secondColor}>
        {timestamp &&
          formatDistance(new Date(timestamp), new Date(), { addSuffix: true })}
      </Text>
    </Group>
  );
};

import { useMemo } from 'react';

import { projectsApi } from '@/api/projects';
import { isNumberValue } from '@/hooks/useNumberQueryParam';

export const useSprintData = (sprintId?: number | null) => {
  const { data } = projectsApi.endpoints.getProjects.useQueryState();

  return useMemo(
    () =>
      isNumberValue(sprintId) ? data?.sprintEntities[sprintId] || null : null,
    [data?.sprintEntities, sprintId],
  );
};

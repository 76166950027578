import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { showRequestNotifications } from '@/api/helpers/showNotifications';
import { tasksLinksApi } from '@/api/links';
import { ApiProjectTask, projectTasksApi } from '@/api/tasks';
import { store } from '@/app/store';

export const useUpdateTask = (
  task: ApiProjectTask.IUpdateRequest | null | undefined,
) => {
  const { t } = useTranslation();

  const [updateTask] = projectTasksApi.useUpdateTaskMutation();

  return useCallback(
    (props: Partial<ApiProjectTask.IUpdateRequest>) => {
      if (!task) return;
      return showRequestNotifications(
        updateTask({ ...task, ...props }).unwrap(),
        { successMsg: t('notification.success.editTask') },
      ).then(() => {
        store.dispatch(
          tasksLinksApi.util.invalidateTags([
            {
              type: 'Task',
              id: task.Id,
            },
          ]),
        );
      });
    },
    [task, updateTask, t],
  );
};

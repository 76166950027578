import { useTranslation } from 'react-i18next';

import { ApiTaskActivity } from '@/api/tasks';
import { ApiUsersGet } from '@/api/users';
import { ActivityHeader } from '@/components/ui/Comment/components/ActivityHeader';
import { UserAvatar } from '@/components/ui/UserAvatar';
import { Group, Stack, Text } from '@mantine/core';

interface IProps {
  activity: ApiTaskActivity.IAttachActivity & {
    Author: ApiUsersGet.IUser | undefined;
  };
  showType?: boolean;
}

export const HistoryAttach = ({ activity, showType = true }: IProps) => {
  const { t } = useTranslation();

  const isAdd = activity.Description.startsWith('Add');

  return (
    <Group wrap="nowrap" align="start" py={4} px={8}>
      <UserAvatar size={32} user={activity.Author} />
      <Stack gap={6} w="100%">
        <ActivityHeader
          activity={activity}
          description={{
            action: t(`taskAction.${isAdd ? 'add' : 'remove'}`),
            predicate: t('activities.typeOptions.attach'),
            type: showType ? t('activities.typeOptions.history') : undefined,
          }}
        />
        <Text>{activity.Json.Name}</Text>
      </Stack>
    </Group>
  );
};

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TaskPriorityBadge } from '@/components/ui/badges/TaskPriorityBadge';
import { priorityList } from '@/components/ui/selects/TaskPrioritySelect';
import { CreateTaskFormData } from '@/hooks/useCreateTask';
import { Combobox } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';

import { SelectField } from './SelectField';

type SelectProps = {
  form: UseFormReturnType<CreateTaskFormData>;
};

export const PrioritySelectField = ({ form }: SelectProps) => {
  const { t } = useTranslation();

  const value = form.values.Priority;
  const options = useMemo(
    () =>
      priorityList.map((p) => (
        <Combobox.Option key={p} value={p.toString()}>
          <TaskPriorityBadge priority={p} />
        </Combobox.Option>
      )),
    [],
  );

  return (
    <SelectField
      value={
        value ? (
          <TaskPriorityBadge priority={value} />
        ) : (
          t('createTask.fields.priority.placeholder')
        )
      }
      required
      label={t('createTask.fields.priority.label')}
      error={form.errors['Priority']}
      options={options}
      onChange={(val) => form.setFieldValue('Priority', Number(val))}
    />
  );
};

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { showRequestNotifications } from '@/api/helpers/showNotifications';
import { projectsApi } from '@/api/projects';
import { ApiTaskType } from '@/api/taskTypes';
import { useAppSelector } from '@/app/store';
import {
  IEntityFormValues,
  NewEntityForm,
} from '@/components/complex/EntityForm';
import { redirectToProject } from '@/helpers/redirect';
import { QueryStatus } from '@reduxjs/toolkit/query';
import { isNull } from 'lodash-es';

import { EntityModal, closeEntityModal } from './EntityModal';

export const CreateProjectModal = () => {
  const { t } = useTranslation();

  const [create, { isLoading }] = projectsApi.useCreateFolderMutation();
  const [postAttribute] = projectsApi.usePostAttributeMutation();

  const { opened, defaultValue, entityType, editType } = useAppSelector(
    (s) => s.editEntityModal,
  );

  const { status } = projectsApi.endpoints.getProjects.useQueryState();

  const [needRedirect, setNeedRedirect] = useState<number | null>(null);

  useEffect(() => {
    if (!isNull(needRedirect) && status === QueryStatus.fulfilled) {
      redirectToProject(needRedirect);
      setNeedRedirect(null);
    }
  }, [status, needRedirect]);

  const handleCreate = (values: IEntityFormValues) => {
    showRequestNotifications(
      create({
        Name: values.name || '',
        Color: values.color,
        IconId: values.icon,
        TaskStatuses: values.statuses.current.map((s) => ({
          Color: s.color,
          Name: s.name,
          Type: s.type,
          PatternId: s.patternId,
          Order: s.order,
        })),
        TaskTypes: values.types.map((t) => ({
          Color: t.color,
          IconId: t.icon,
          Name: t.name,
          Type: ApiTaskType.TaskType.Custom,
        })),
      }).unwrap(),
      {
        successMsg: t('notification.success.createProject'),
        errorMsg: t('notification.error.createProject'),
      },
    )
      .then(async (res) => {
        await Promise.all(
          values.custom.map((el) =>
            postAttribute({
              ProjectId: res.Id,
              Name: el.name,
              ValueType: el.type,
            }).unwrap(),
          ),
        );
        return res;
      })
      .then((response) => {
        closeEntityModal();
        setNeedRedirect(response.Id);
      });
  };

  return (
    <EntityModal
      opened={opened && entityType === 'project' && editType === 'create'}
    >
      <NewEntityForm
        title={t(`entitySettings.title.create.${entityType}`)}
        entityType={entityType}
        onConfirm={handleCreate}
        onClose={closeEntityModal}
        confirmLabel={t('common.action.create')}
        defaultValue={defaultValue}
        isLoading={isLoading}
      />
    </EntityModal>
  );
};

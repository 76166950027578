import { ApiCommon } from '@/api/common';
import { projectsApi } from '@/api/projects';
import { store } from '@/app/store';
import { IStatusForm } from '@/components/complex/EntityForm';

export const getStatusesUpdates = ({
  statuses,
  entityId,
  entityType,
}: {
  statuses: IStatusForm[];
  entityId: number;
  entityType: ApiCommon.Entity;
}) => {
  const updates: Promise<any>[] = [];

  const newStatuses = statuses.filter((el) => el.state === 'new');
  const updatedStatuses = statuses.filter((el) => el.state === 'updated');
  const removedStatuses = statuses.filter((el) => el.state === 'removed');

  const remove = () => {
    return store
      .dispatch(
        projectsApi.endpoints.removeStatuses.initiate({
          newId: statuses.find(
            (s) => s.type === ApiCommon.ITastStatusType.BeExecuted,
          )?.id,
          ids: removedStatuses.map((el) => el.id),
          entityId: entityId,
          entityType: entityType,
        }),
      )
      .unwrap();
  };
  const update = () => {
    return store
      .dispatch(
        projectsApi.endpoints.saveStatuses.initiate(
          updatedStatuses.map((s) => ({
            Id: s.id,
            Name: s.name,
            Color: s.color,
            EntityId: entityId,
            EntityType: entityType,
            Type: s.type,
            PatternId: s.patternId,
            Order: s.order,
          })),
        ),
      )
      .unwrap();
  };
  const add = () => {
    return store
      .dispatch(
        projectsApi.endpoints.saveStatuses.initiate(
          newStatuses.map((s) => ({
            Name: s.name,
            Color: s.color,
            EntityId: entityId,
            EntityType: entityType,
            Type: s.type,
            PatternId: s.patternId,
            Order: s.order,
          })),
        ),
      )
      .unwrap();
  };

  if (removedStatuses.length) {
    updates.push(
      remove()
        .then(async () => {
          if (updatedStatuses.length) await update();
        })
        .then(() => {
          if (newStatuses.length) add();
        }),
    );
  } else if (updatedStatuses.length) {
    updates.push(
      update().then(() => {
        if (newStatuses.length) add();
      }),
    );
  } else if (newStatuses.length) updates.push(add());

  return updates;
};

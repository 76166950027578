import { memo, useState } from 'react';

import { ApiCommon } from '@/api/common';
import { ApiTaskType } from '@/api/taskTypes';
import { ApiProjectTask } from '@/api/tasks';
import { TaskPrioritySelect } from '@/components/ui/selects/TaskPrioritySelect';
import { TaskTypeSelect } from '@/components/ui/selects/TaskTypeSelect';
import { formatDate } from '@/helpers/formatDate';
import { useAccess } from '@/hooks/useAccess';
import { Box, BoxProps, Flex, Group, Text, ThemeIcon } from '@mantine/core';
import { useDisclosure, useHover } from '@mantine/hooks';
import { IconCalendarEvent } from '@tabler/icons-react';

import { usePerformerData } from '../../../../../hooks/usePerformerData';
import { useUpdateTask } from '../../../../../hooks/useUpdateTask';
import { PerformerSelect } from '../../../../PerformerSelect';
import { openTaskModal } from '../../../../TaskModal/store';
import { TaskActions } from './TaskActions';
import { TaskTitle } from './TaskTitle';

import styles from './TaskCard.module.css';

type TaskCardProps = {
  task: ApiProjectTask.ITask;
  statuses: ApiCommon.ITaskStatus[];
  types: ApiTaskType.IType[];
} & BoxProps;

export const TaskCard = memo(({ task, types, ...props }: TaskCardProps) => {
  const access = useAccess();
  const { Key, TypeId, ProjectId } = task;

  const [isEdit, { open, close }] = useDisclosure(false);
  const [actionsOpened, setActionsOpened] = useState(false);
  const { hovered, ref } = useHover();

  const performerData = usePerformerData(task);
  const updateTask = useUpdateTask(task);

  const handleTaskClick = () => {
    openTaskModal(task.Id);
  };

  return (
    <Box ref={ref} onClick={handleTaskClick} className={styles.root} {...props}>
      <TaskActions
        opened={actionsOpened}
        onOpenChange={setActionsOpened}
        task={task}
        onRename={open}
        hidden={(!actionsOpened && !hovered) || isEdit}
      />
      <TaskTitle
        value={task.Title}
        isEdit={isEdit}
        onChange={(Title) => {
          close();
          if (task.Title !== Title) updateTask({ Title });
        }}
      />
      {task.DueDate && (
        <Group gap={4}>
          <ThemeIcon
            variant="transparent"
            size={16}
            color="light-dark(var(--mantine-color-gray-6), var(--mantine-color-gray-1))"
          >
            <IconCalendarEvent size={12} />
          </ThemeIcon>
          <Text
            fz={12}
            c="light-dark(var(--mantine-color-gray-6), var(--mantine-color-gray-3))"
          >{`до ${formatDate(task.DueDate)}`}</Text>
        </Group>
      )}
      <Flex justify={'space-between'}>
        <Flex gap={8} onClick={(e) => e.stopPropagation()} ml={-4}>
          <TaskTypeSelect
            value={TypeId}
            onChange={(TypeId) => updateTask({ TypeId })}
            types={types}
            withLabel={false}
            readonly={!access.tasks.edit}
            iconSize={18}
          />
          <Text
            size={'sm'}
            c="light-dark(var(--mantine-color-gray-7), var(--mantine-color-gray-3))"
          >
            {Key}
          </Text>
        </Flex>
        <Flex gap={4} align="center" onClick={(e) => e.stopPropagation()}>
          <TaskPrioritySelect
            priority={task.Priority}
            onChange={(Priority) => updateTask({ Priority })}
            withLabel={false}
            readonly={!access.tasks.edit}
          />
          <PerformerSelect
            projectId={ProjectId}
            value={performerData}
            onChange={(opt) => updateTask({ PerformerId: opt?.Id || null })}
            withLabel={false}
            readonly={!access.tasks.edit}
            offset={{ mainAxis: 8, alignmentAxis: -12 }}
          />
        </Flex>
      </Flex>
    </Box>
  );
});

import { Suspense, SyntheticEvent, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { attachmentsApi } from '@/api/attachments/slice';
import { showErrorNotification } from '@/api/helpers/showNotifications';
import { ApiProjectTask } from '@/api/tasks';
import { useTaskContext } from '@/features/TaskPanel/context/taskContext';
import { useAccess } from '@/hooks/useAccess';
import { useUpdateTask } from '@/hooks/useUpdateTask';
import { Box, Button, Flex, Stack, Text } from '@mantine/core';
// Todo Fix ts
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import { DocSpaceStylesProvider, Editor } from '@sinups/editor-dsd';
import { isEqual } from 'lodash-es';

import styles from '../../../TaskPanel.module.css';

interface IProps {
  task: ApiProjectTask.ITask;
}

export const Description = memo(({ task }: IProps) => {
  const { t } = useTranslation();
  const access = useAccess();

  const {
    isDescriptionEdit: isEdit,
    setDescriptionEdit,
    setCommentsEdit,
  } = useTaskContext();

  const setEdit = (v: boolean) => {
    if (v) setCommentsEdit(null);
    setDescriptionEdit(v);
  };

  const [uploadFile] = attachmentsApi.useUploadFileMutation();

  const [value, setValue] = useState('');
  const updateTask = useUpdateTask(task);

  const handleEdit = () => {
    if (isEqual(task.Description, value)) {
      setEdit(false);
    } else {
      updateTask({ Description: value })?.then(() => setEdit(false));
    }
  };

  const handleStartEdit = (e: SyntheticEvent<HTMLElement>) => {
    if (e.target instanceof HTMLElement) {
      const link = e.target.closest('a');
      if (link) {
        e.stopPropagation();
        return;
      }
    }

    setValue(value || task.Description || '');
    setEdit(true);
    e.currentTarget.scrollIntoView();
  };

  const fetchUploadImage = async (
    file: File,
    success: (ulr: string, id: string) => void,
    error?: (error?: Error) => void,
  ) => {
    const formData = new FormData();
    formData.append('File', file);
    formData.append('FileAccessModifier', '0');

    return uploadFile(formData)
      .then((res) => {
        if (!res.data) throw new Error('File upload failed');
        success(res.data.Url, res.data.Id.toString());
      })
      .catch((err) => {
        if (error) {
          if (err instanceof Error) {
            error(err);
          } else {
            error(new Error('An unknown error occurred'));
          }
        }
      });
  };

  return (
    <Stack gap={8}>
      <p className={styles.subtitle}>
        {t('createTask.fields.description.title')}
      </p>
      <Stack mih={'208px'}>
        {isEdit ? (
          <Stack gap={8}>
            <Box ml={'8px'}>
              <Suspense fallback={<div>{t('common.loading')}</div>}>
                <Editor
                  maxImageSizeError={() =>
                    showErrorNotification({ message: t('createTask.maxSize') })
                  }
                  maxImageSize={25}
                  fetchUploadImage={fetchUploadImage}
                  onChange={(v: string) => setValue(v)}
                  outputFormat={'html'}
                  initialContent={value}
                  mode={undefined}
                />
              </Suspense>
            </Box>
            <Flex gap={8} justify={'flex-end'}>
              <Button
                variant="subtle"
                color="dark"
                radius="md"
                onClick={() => {
                  setEdit(false);
                  setValue('');
                }}
              >
                {t('common.action.cancel')}
              </Button>
              <Button radius={'md'} onClick={handleEdit}>
                {t('common.action.save')}
              </Button>
            </Flex>
          </Stack>
        ) : (
          <Stack
            gap={8}
            className={styles.editableSection}
            data-editable={access.tasks.edit}
            onClick={access.tasks.edit ? handleStartEdit : undefined}
            align="flex-start"
            justify="flex-start"
          >
            {task.Description ? (
              <DocSpaceStylesProvider>
                <div
                  className={styles.descWrapper}
                  dangerouslySetInnerHTML={{ __html: task.Description || '' }}
                />
              </DocSpaceStylesProvider>
            ) : (
              <Text c="gray.6" fz={14} className={styles.placeholder}>
                {t('createTask.fields.description.placeholder')}
              </Text>
            )}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
});

import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ApiCommon } from '@/api/common';
import { showRequestNotifications } from '@/api/helpers/showNotifications';
import {
  ComplexProjectData,
  ComplexSprintData,
  projectsApi,
} from '@/api/projects';
import { shareApi } from '@/api/share';
import { store, useAppDispatch, useAppSelector } from '@/app/store';
import { openRemoveConfirmModal } from '@/components/ui/confirmModals/RemoveConfirmModal';
import {
  openCreateFolderModal,
  openCreateSprintModal,
  openUpdateFolderModal,
  openUpdateProjectModal,
  openUpdateSprintModal,
} from '@/features/EditEntityModals';
import { manageUsersModalSlice } from '@/features/EditUsersModal/slice';
import { openRemoveFolderModal } from '@/features/FolderModals';
import { openRemoveProjectModal } from '@/features/RemoveProjectModal';
import { openRemoveSprintModal } from '@/features/SprintModals';
import { useAccess } from '@/hooks/useAccess';
import { ActionIcon, Menu, Text, Tooltip } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import {
  IconDots,
  IconFolder,
  IconListDetails,
  IconLogout,
  IconPencil,
  IconTrash,
  IconUsersPlus,
} from '@tabler/icons-react';

type ActionsButtonProps = {
  project?: ComplexProjectData;
  sprint?: ComplexSprintData;
  canCreateFolder?: boolean;
  type: 'folder' | 'sprint' | 'project';
  onClose: () => void;
  redirect?: (e: React.MouseEvent) => void;
};

export const ActionsButton = ({
  canCreateFolder,
  type,
  project,
  sprint,
  onClose,
  redirect,
}: ActionsButtonProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const access = useAccess();

  const currentUserId = useAppSelector((s) => s.usersCache.currentUser.Id);
  const [leaveProject] = shareApi.useLeaveMutation();
  const dispatch = useAppDispatch();
  const [opened, setOpened] = useState(false);

  const isBurger = useMediaQuery('(max-width: 1200px)');

  const handleIconClick = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    setOpened((o) => !o);
  }, []);
  const handleAction = useCallback(
    (e: React.MouseEvent, action: VoidFunction) => {
      e.stopPropagation();
      isBurger && onClose();
      setTimeout(action, 0);
    },
    [isBurger, onClose],
  );
  const handleLeave = useCallback(
    (e: React.MouseEvent) => {
      if (!project) return;

      openRemoveConfirmModal({
        title: t('members.leave.title'),
        labels: {
          confirm: t('common.action.leave'),
          cancel: t('common.action.cancel'),
        },
        children: (
          <Text
            dangerouslySetInnerHTML={{
              __html: t('members.leave.description', { project: project.Name }),
            }}
          />
        ),
        onConfirm: () => {
          handleAction(e, () => {
            const subscribe = leaveProject({
              entityId: project.Id,
              entityType: ApiCommon.Entity.Project,
            }).unwrap();

            showRequestNotifications(subscribe, {
              successMsg: t('notification.success.leaveProject'),
              errorMsg: t('notification.error.leaveProject'),
            })
              .then(async () => {
                await store
                  .dispatch(
                    projectsApi.endpoints.getProjects.initiate(undefined, {
                      forceRefetch: true,
                    }),
                  )
                  .unwrap();
              })
              .then(() => navigate('/'));
          });
        },
      });
    },
    [project, handleAction, leaveProject, navigate, t],
  );

  const isOwner = currentUserId === project?.OwnerId;
  const {
    createFolderAccess,
    createSprintAccess,
    editAccess,
    removeAccess,
    hasActions,
  } = useMemo(() => {
    const { folders, project, sprints } = access;

    const isParent = type === 'folder' || type === 'project';

    const createFolderAccess =
      folders.create && isParent && project && canCreateFolder;

    const createSprintAccess = sprints.create && isParent && project;

    const editAccess =
      (type === 'folder' && folders.edit) ||
      (type === 'sprint' && sprints.edit) ||
      (type === 'project' && project.edit);

    const removeAccess =
      (type === 'folder' && folders.delete) ||
      (type === 'sprint' && sprints.delete) ||
      (type === 'project' && project.delete);

    return {
      createFolderAccess,
      createSprintAccess,
      editAccess,
      removeAccess,
      hasActions: [
        createFolderAccess,
        createSprintAccess,
        editAccess,
        removeAccess,
      ].some(Boolean),
    };
  }, [access, canCreateFolder, type]);

  if (!hasActions && type !== 'project') return null;

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Menu opened={opened} onChange={setOpened}>
        <Menu.Target>
          <Tooltip label={t('common.actionsLabel')} withArrow position="right">
            <ActionIcon
              mr={8}
              variant="subtle"
              color="dark.1"
              onClick={handleIconClick}
            >
              <IconDots size={18} color="var(--mantine-color-gray-5)" />
            </ActionIcon>
          </Tooltip>
        </Menu.Target>
        <Menu.Dropdown fw={400}>
          {project && createFolderAccess && (
            <Menu.Item
              leftSection={<IconFolder size={18} />}
              onClick={(e) => {
                handleAction(e, () => openCreateFolderModal(project));
              }}
            >
              {t('sidebar.addFolder')}
            </Menu.Item>
          )}

          {project && createSprintAccess && (
            <Menu.Item
              leftSection={<IconListDetails size={18} />}
              onClick={(e) => {
                handleAction(e, () => openCreateSprintModal(project));
              }}
            >
              {t('sidebar.addSprint')}
            </Menu.Item>
          )}

          {(createFolderAccess || createSprintAccess) && <Menu.Divider />}

          {project && type === 'project' && redirect && (
            <Menu.Item
              leftSection={<IconUsersPlus size={18} />}
              onClick={(e) => {
                redirect(e);
                dispatch(manageUsersModalSlice.actions.open());
              }}
            >
              {t('members.title')}
            </Menu.Item>
          )}

          {editAccess && (
            <Menu.Item
              leftSection={<IconPencil size={18} />}
              onClick={(e) => {
                handleAction(e, () => {
                  if (type === 'project' && project) {
                    openUpdateProjectModal(project);
                    return;
                  }
                  if (type === 'folder' && project) {
                    openUpdateFolderModal(project);
                    return;
                  }
                  if (type === 'sprint' && sprint) {
                    openUpdateSprintModal(sprint);
                  }
                });
              }}
            >
              {t('common.action.edit')}
            </Menu.Item>
          )}

          {project && type === 'project' && !isOwner && (
            <Menu.Item
              leftSection={<IconLogout size={18} />}
              color="red.8"
              onClick={handleLeave}
            >
              {t('common.action.leave')}
            </Menu.Item>
          )}

          {removeAccess && (
            <Menu.Item
              leftSection={<IconTrash size={18} />}
              color="red.8"
              onClick={(e) => {
                handleAction(e, () => {
                  if (type === 'project' && project) {
                    openRemoveProjectModal({
                      projectId: project.Id,
                      name: project.Name,
                    });
                    return;
                  }
                  if (type === 'folder' && project) {
                    openRemoveFolderModal({ folder: project });
                    return;
                  }
                  if (type === 'sprint' && sprint) {
                    openRemoveSprintModal({ sprint });
                  }
                });
              }}
            >
              {t('common.action.delete')}
            </Menu.Item>
          )}
        </Menu.Dropdown>
      </Menu>
    </div>
  );
};

import { ComponentPropsWithoutRef, memo } from 'react';

import { ApiProjectTask } from '@/api/tasks';
import { UserAvatar } from '@/components/ui/UserAvatar';

import { usePerformerData } from '../../../hooks/usePerformerData';

type TaskPerformerProps = {
  task: ApiProjectTask.ITask;
} & ComponentPropsWithoutRef<'div'>;

export const TaskPerformer = memo(({ task }: TaskPerformerProps) => {
  const performerData = usePerformerData(task);

  return <UserAvatar size={24} user={performerData} />;
});

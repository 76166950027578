import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ApiTaskLinks } from '@/api/links';
import { ApiProjectTask } from '@/api/tasks';
import { TaskCard } from '@/features/TaskCard';
import { openTaskModal } from '@/features/TaskModal/store';
import { useAccess } from '@/hooks/useAccess';
import { Button, Collapse, Stack } from '@mantine/core';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';

import { getLinkType } from '../../helpers/getLinkType';
import { RemoveLinkButton } from '../RemoveLinkButton';

import styles from './Section.module.css';

export const Section = ({
  links,
  task,
}: {
  links: ApiTaskLinks.ILink[];
  task: ApiProjectTask.ITask;
}) => {
  const { t } = useTranslation();
  const access = useAccess();

  const [opened, setOpened] = useState(true);

  return (
    <Stack gap={4}>
      <Button
        ml={-8}
        mr={-5}
        w={`calc(100% + ${8 + 5}px)`}
        px={8}
        color="gray.6"
        size={'compact-md'}
        fullWidth
        justify="space-between"
        onClick={() => setOpened((p) => !p)}
        variant="subtle"
        rightSection={
          opened ? <IconChevronUp size={16} /> : <IconChevronDown size={16} />
        }
        fz={14}
        radius={6}
        c="gray.6"
      >
        {t('taskLinkTypes.' + getLinkType(links[0], task.Id))}
      </Button>
      <Collapse in={opened}>
        {links.map((link) => {
          const isFrom = link.FromId === task.Id;
          const linkedTask = isFrom ? link.To : link.From;

          if (!linkedTask) return null;

          return (
            <div className={styles.item} key={link.Id}>
              <TaskCard
                task={linkedTask}
                showPriority={false}
                showStatus={false}
                border
                onClick={() => openTaskModal(linkedTask.Id)}
                rightSection={
                  access.tasks.edit && <RemoveLinkButton link={link} />
                }
              />
            </div>
          );
        })}
      </Collapse>
    </Stack>
  );
};

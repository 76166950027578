import { SyntheticEvent, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { showRequestNotifications } from '@/api/helpers/showNotifications';
import { projectsApi } from '@/api/projects';
import { NoData } from '@/components/ui/NoData/NoData';
import { redirectAfterProjectDeletion } from '@/helpers/redirect';
import { Button, Flex, Stack, Text, TextInput } from '@mantine/core';
import { useInputState } from '@mantine/hooks';
import { modals, openModal } from '@mantine/modals';
import { t } from 'i18next';
import { map } from 'lodash-es';

import styles from './RemoveProjectModal.module.css';

export type RemoveProjectModalProps = {
  projectId: number;
  name: string;
};

const MODAL_ID = 'removeProjectModal';

const Modal = memo(({ projectId, name }: RemoveProjectModalProps) => {
  const { t } = useTranslation();

  const { data: projects } = projectsApi.endpoints.getProjects.useQueryState();
  const [deleteFolder, { isLoading }] =
    projectsApi.useDeleteFolderByIdMutation();

  const [value, setValue] = useInputState('');

  const handleSubmit = useCallback(
    async (e: SyntheticEvent) => {
      e.preventDefault();

      showRequestNotifications(deleteFolder(projectId).unwrap(), {
        successMsg: t('notification.success.deleteProject', { project: name }),
        errorMsg: t('notification.error.deleteProject', { project: name }),
      }).then(() => {
        modals.close(MODAL_ID);

        redirectAfterProjectDeletion(map(projects?.list, 'Id'), projectId);
      });
    },
    [deleteFolder, name, projectId, projects?.list, t],
  );

  return (
    <>
      <Stack gap={'md'}>
        <NoData mt={'md'} illustration={'trash'} size={160} />
        <Text c={'gray.6'}>{t('removeProject.description')}</Text>

        <form onSubmit={handleSubmit}>
          <Stack gap={24}>
            <TextInput
              data-autofocus
              value={value}
              onChange={setValue}
              placeholder={name}
              label={t('removeProject.confirm', { project: name.trim() })}
            />

            <Flex gap={'md'}>
              <Button
                variant="subtle"
                color={'dark'}
                fullWidth
                radius={'md'}
                size={'md'}
                onClick={() => modals.close(MODAL_ID)}
              >
                {t('common.action.cancel')}
              </Button>
              <Button
                loading={isLoading}
                className={styles.removeButton}
                variant="filled"
                color={'red'}
                disabled={
                  value.trim().toLocaleLowerCase() !==
                  name.trim().toLocaleLowerCase()
                }
                radius={'md'}
                size={'md'}
                type="submit"
                fullWidth
              >
                {t('common.action.delete')}
              </Button>
            </Flex>
          </Stack>
        </form>
      </Stack>
    </>
  );
});

export const openRemoveProjectModal = (props: RemoveProjectModalProps) => {
  openModal({
    modalId: MODAL_ID,
    title: t('removeProject.title'),
    children: <Modal {...props} />,
  });
};

import { customBaseQuery } from '@/api/customBaseQuery';
import { createApi } from '@reduxjs/toolkit/query/react';
import { t } from 'i18next';
import { flatMap, uniqBy } from 'lodash-es';

import { showErrorNotification } from '../helpers/showNotifications';
import { ApiTaskType } from './types';

type GetTypesResponse = {
  list: ApiTaskType.IType[];
  mapById: Record<number, ApiTaskType.IType>;
};

export const taskTypesApi = createApi({
  reducerPath: 'taskTypesApi',
  baseQuery: customBaseQuery('/api'),
  tagTypes: ['Project'],
  endpoints: (builder) => ({
    getTaskTypes: builder.query<GetTypesResponse, ApiTaskType.IGetRequest>({
      query: (ProjectIds) => ({
        url: `/v1/ProjectTask/Types`,
        method: 'POST',
        body: { ProjectIds },
      }),
      transformResponse: (res: ApiTaskType.IGetResponse) => {
        if (res.length < 1) {
          throw Error('Empty task types');
        }

        const flatten = uniqBy(flatMap(res, 'Types'), 'Id');
        const mapById = flatten.reduce(
          (acc, val) => {
            acc[val.Id] = val;
            return acc;
          },
          {} as Record<number, ApiTaskType.IType>,
        );

        return {
          list: flatten,
          mapById,
        };
      },
      providesTags: (_, __, ids) => ids.map((id) => ({ type: 'Project', id })),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          showErrorNotification({
            message: t('notification.error.getTaskTypes'),
          });
        }
      },
    }),

    save: builder.mutation<ApiTaskType.ISaveResponse, ApiTaskType.ISaveRequest>(
      {
        query: (body) => ({
          url: `/v1/ProjectTask/SaveType`,
          method: 'POST',
          body,
        }),
        invalidatesTags: (res) =>
          res?.length ? [{ type: 'Project', id: res.at(0)?.ProjectId }] : [],
      },
    ),

    remove: builder.mutation<void, { ids: number[]; projectId: number }>({
      query: ({ ids }) => ({
        url: `/v1/ProjectTask/Type`,
        method: 'DELETE',
        body: {
          ids,
        },
      }),
      invalidatesTags: (_, __, { projectId }) => [
        { type: 'Project', id: projectId },
      ],
    }),
  }),
});

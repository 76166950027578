import { ColorPicker } from '@/components/ui/ColorPicker/ColorPicker';
import { Flex, Tooltip } from '@mantine/core';

import { IStatusForm } from '../../types';

export const StatusesDetails = ({ statuses }: { statuses: IStatusForm[] }) => {
  return (
    <Flex gap={8} wrap={'wrap'} justify={'flex-end'}>
      {statuses.map((s) => (
        <Tooltip label={s.name} key={s.id}>
          <ColorPicker.ColorCircle value={s.color} radius={4} size={20} />
        </Tooltip>
      ))}
    </Flex>
  );
};

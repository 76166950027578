import { PropsWithChildren, createContext, useContext, useMemo } from 'react';

import { ApiCommon } from '@/api/common';
import { useAppSelector } from '@/app/store';
import { useFolderData } from '@/hooks/useFolderData';

type CrudAccess = {
  create: boolean;
  edit: boolean;
  delete: boolean;
};

type PermissionsType = {
  project: {
    edit: boolean;
    delete: boolean;
  };
  folders: CrudAccess;
  tasks: CrudAccess;
  sprints: CrudAccess;
  comments: CrudAccess;
  participants: CrudAccess;
};

const getView = (): CrudAccess => ({
  create: false,
  delete: false,
  edit: false,
});

const getEdit = (): CrudAccess => ({
  create: true,
  delete: true,
  edit: true,
});

export const viewAccess: PermissionsType = {
  project: {
    edit: false,
    delete: false,
  },
  participants: getView(),
  folders: getView(),
  sprints: getView(),
  tasks: getView(),
  comments: getView(),
};

export const editAccess: PermissionsType = {
  project: {
    edit: true,
    delete: false,
  },
  participants: getView(),
  folders: getEdit(),
  sprints: getEdit(),
  tasks: getEdit(),
  comments: getEdit(),
};

export const fullAccess: PermissionsType = {
  project: {
    edit: true,
    delete: true,
  },
  participants: getEdit(),
  folders: getEdit(),
  sprints: getEdit(),
  tasks: getEdit(),
  comments: getEdit(),
};

const AccessContext = createContext(null as unknown as PermissionsType);

export const useAccess = () => useContext(AccessContext);

export const ProjectAccessProvider = ({
  projectId,
  children,
}: PropsWithChildren<{ projectId?: number }>) => {
  const currentUserId = useAppSelector((s) => s.usersCache.currentUser.Id);
  const project = useFolderData(projectId);

  const access = useMemo(() => {
    if (project?.OwnerId === currentUserId) {
      return fullAccess;
    }

    if (project?.AccessType === ApiCommon.AccessType.Read) {
      return viewAccess;
    }

    if (project?.AccessType === ApiCommon.AccessType.Write) {
      return editAccess;
    }

    if (project?.AccessType === ApiCommon.AccessType.Full) {
      return fullAccess;
    }

    return viewAccess;
  }, [currentUserId, project?.AccessType, project?.OwnerId]);

  return (
    <AccessContext.Provider value={access}>{children}</AccessContext.Provider>
  );
};

import { ReactNode, useEffect, useRef } from 'react';

import { useAppSelector } from '@/app/store';
import { Flex, Space, Stack } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

import styles from './TaskLayout.module.css';

export const TaskLayout = ({
  header,
  above,
  content,
  sidebar,
  actions,
  status,
  fillSize,
}: {
  header: ReactNode;
  actions: ReactNode;
  above: ReactNode;
  content: ReactNode;
  sidebar: ReactNode;
  status: ReactNode;
  fillSize?: boolean;
}) => {
  const isMobile = useMediaQuery('(max-width: 992px)');

  const openedTaskId = useAppSelector((s) => s.taskModalSlice.taskId);
  const leftColumnRef = useRef<HTMLDivElement>(null);
  const rightColumnRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    leftColumnRef.current?.scrollTo(0, 0);
    rightColumnRef.current?.scrollTo(0, 0);
  }, [openedTaskId]);

  return (
    <>
      {isMobile ? (
        <>
          <Flex p={8} pb={0} justify={'flex-end'}>
            {actions}
          </Flex>
          <Flex p={8} pt={0}>
            {header}
          </Flex>
          <Flex p={8} pt={0}>
            {status}
          </Flex>
        </>
      ) : (
        <Flex p={16} justify={'space-between'}>
          {header}
          {actions}
        </Flex>
      )}

      <div className={styles.layout}>
        <div
          ref={leftColumnRef}
          className={styles.column}
          data-full-size={fillSize}
          style={{ scrollPaddingTop: '120px' }}
        >
          <Stack gap={isMobile ? 8 : 16}>
            {above}
            {isMobile && sidebar}
            {content}
          </Stack>
          <Space h={16} />
        </div>
        {!isMobile && (
          <div
            ref={rightColumnRef}
            className={styles.column}
            data-full-size={fillSize}
          >
            <Stack gap={16}>
              {status}
              {sidebar}
            </Stack>
            <Space h={16} />
          </div>
        )}
      </div>
    </>
  );
};

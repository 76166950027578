import { TaskStatusBadge } from '@/components/ui/badges/TaskStatusBadge/TaskStatusBadge';

import { GanttStatus } from '../transformTaskData';

export const StatusCell = ({
  status: { _statusData },
}: {
  status: GanttStatus;
}) => {
  return <TaskStatusBadge w="min-content" status={_statusData} />;
};

import { useMemo } from 'react';

import { ApiTaskLinks } from '@/api/links';
import { ApiProjectTask } from '@/api/tasks';

import { getLinkType } from '../../helpers/getLinkType';
import { Section } from '../Section/Section';

type LinksListProps = {
  task: ApiProjectTask.ITask;
  data: ApiTaskLinks.IGetResponse;
};

export const LinksList = ({ data, task }: LinksListProps) => {
  const grouped = useMemo(() => {
    if (!data) return [];

    const groups = data.reduce<Record<number, ApiTaskLinks.ILink[]>>(
      (acc, item) => {
        const type = getLinkType(item, task.Id);

        if (!acc[type]) {
          acc[type] = [];
        }

        acc[type].push(item);

        return acc;
      },
      {},
    );

    return Object.values(groups);
  }, [data, task.Id]);

  return grouped.map((item) => (
    <Section key={getLinkType(item[0], task.Id)} task={task} links={item} />
  ));
};

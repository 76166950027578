import { memo, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { projectsApi } from '@/api/projects';
import { ApiProjectTask } from '@/api/tasks';
import { Ellipsis } from '@/components/ui/Ellipsis';
import {
  ProjectIcon,
  ProjectIconType,
} from '@/components/ui/icons/ProjectIcon';
import { TaskTypeSelect } from '@/components/ui/selects/TaskTypeSelect';
import { AppRoutes } from '@/config/links';
import { generateLink } from '@/helpers/generateLink';
import { useAccess } from '@/hooks/useAccess';
import { useSprintData } from '@/hooks/useSprintData';
import { useTaskTypesData } from '@/hooks/useTaskTypesData';
import { useUpdateTask } from '@/hooks/useUpdateTask';
import {
  Breadcrumbs,
  Button,
  CopyButton,
  Flex,
  MantineStyleProp,
  Text,
  Tooltip,
} from '@mantine/core';
import { useHotkeys } from '@mantine/hooks';
import { IconListDetails } from '@tabler/icons-react';

import { useFolderData } from '../../hooks/useFolderData';

type MainBreadcrumbsProps = {
  folderId: number;
  sprintId?: number;
  task?: ApiProjectTask.ITask;
  style?: MantineStyleProp;
  onItemClick?: () => void;
};

export const MainBreadcrumbs = memo(
  ({ folderId, sprintId, task, style, onItemClick }: MainBreadcrumbsProps) => {
    const { t } = useTranslation();
    const access = useAccess();

    const lastFolder = useFolderData(folderId);
    const { taskTypes } = useTaskTypesData(folderId);
    const updateTask = useUpdateTask(task);

    const copyRef = useRef<(() => void) | null>(null);

    const sprint = useSprintData(sprintId || task?.SprintId);

    const { data } = projectsApi.endpoints.getProjects.useQueryState();

    const items = useMemo(() => {
      return (
        lastFolder?.pathById
          .map((id) => data?.folderEntities[id])
          .filter(Boolean) || []
      );
    }, [data?.folderEntities, lastFolder?.pathById]);

    useHotkeys([['Q', () => copyRef?.current?.()]]);

    return (
      <Breadcrumbs style={{ flexWrap: 'wrap', ...style }} separatorMargin="3px">
        {items.map((folder) => (
          <Button
            key={folder.Id}
            component={NavLink}
            to={generateLink(AppRoutes.folder, { folderId: folder.Id })}
            onClick={onItemClick}
            size="compact-sm"
            variant="subtle"
            color="dark"
            leftSection={
              <ProjectIcon
                size={16}
                color={folder.Color}
                name={folder.Name}
                icon={folder.isFolder ? ProjectIconType.folder : folder.IconId}
              />
            }
          >
            <Ellipsis check="x" style={{ font: 'inherit' }} maw={100}>
              {folder.Name}
            </Ellipsis>
          </Button>
        ))}
        {sprint && (
          <Button
            component={NavLink}
            to={AppRoutes.getSprintRoute(sprint.Id)}
            onClick={onItemClick}
            size="compact-sm"
            variant="subtle"
            color="dark"
            leftSection={<IconListDetails size={16} />}
          >
            <Ellipsis check="x" style={{ font: 'inherit' }} maw={100}>
              {sprint.Name}
            </Ellipsis>
          </Button>
        )}
        {task && (
          <Flex gap={4}>
            <TaskTypeSelect
              iconSize={16}
              value={task?.TypeId}
              onChange={(TypeId) => updateTask({ TypeId })}
              types={taskTypes}
              withLabel={false}
              menuProps={{
                withinPortal: true,
              }}
              readonly={!access.tasks.edit}
            />

            <CopyButton value="task.Key">
              {({ copied, copy }) => {
                copyRef.current = copy;

                return (
                  <Tooltip
                    label={
                      copied
                        ? t('copyButton.keyCopied')
                        : t('copyButton.copyWithKey', { key: 'Q' })
                    }
                    withArrow
                    position="top"
                    opened={copied || undefined}
                  >
                    <Text
                      truncate={'end'}
                      style={{ font: 'inherit' }}
                      fz={14}
                      fw={'bold'}
                      maw={100}
                    >
                      {task.Key}
                    </Text>
                  </Tooltip>
                );
              }}
            </CopyButton>
          </Flex>
        )}
      </Breadcrumbs>
    );
  },
);

import { Link } from 'react-router-dom';

import DudocLogo from '@/assets/svg/crudoc-logo-full.svg?react';
import R7Logo from '@/assets/svg/logo.svg?react';
import R7LogoWhite from '@/assets/svg/whiteLogo.svg?react';
import { CUSTOMER } from '@/config/constants';
import { useIsDarkTheme } from '@/helpers/themeUtils';
import { Flex, FlexProps } from '@mantine/core';

export const Logotype = (props: FlexProps) => {
  const isDark = useIsDarkTheme();

  return (
    <Link to={'/'}>
      <Flex
        w={CUSTOMER === 'R7' ? 150 : 100}
        c={isDark ? 'white' : 'black'}
        {...props}
      >
        {CUSTOMER === 'R7' ? (
          isDark ? (
            <R7LogoWhite />
          ) : (
            <R7Logo />
          )
        ) : (
          <DudocLogo />
        )}
      </Flex>
    </Link>
  );
};

import { ProjectIcon } from '@/components/ui/icons/ProjectIcon';
import { Flex, Tooltip } from '@mantine/core';

import { ITypeForm } from '../../types';

export const TypesDetails = ({ types }: { types: ITypeForm[] }) => {
  return (
    <Flex gap={8} wrap={'wrap'} justify={'flex-end'}>
      {types.map((s) => (
        <Tooltip label={s.name} key={s.id}>
          <ProjectIcon color={s.color} name={s.name} icon={s.icon} />
        </Tooltip>
      ))}
    </Flex>
  );
};

import { useTranslation } from 'react-i18next';

import { ApiProjectTask } from '@/api/tasks';
import { ProjectSprints } from '@/components/ui/ProjectSprints/ProjectSprints';
import { useFolderData } from '@/hooks/useFolderData';
import { useSprintData } from '@/hooks/useSprintData';
import { Menu, Popover } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconLogout } from '@tabler/icons-react';

import { moveTask } from '../../helpers/moveTask';

export const MoveTaskButton = ({
  task,
  onClose,
}: {
  task: ApiProjectTask.ITask;
  onClose?: () => void;
}) => {
  const { t } = useTranslation();

  const [opened, { open, close }] = useDisclosure();
  const folder = useFolderData(task.ProjectId);
  const currentSprint = useSprintData(task.SprintId);
  const project = useFolderData(folder?.rootProjectId);

  if (!project) return null;

  return (
    <Popover
      withinPortal={false}
      keepMounted={false}
      opened={opened}
      onOpen={open}
      onClose={close}
    >
      <Popover.Target>
        <Menu.Item leftSection={<IconLogout size={18} />} onClick={open}>
          {t('common.action.move')}
        </Menu.Item>
      </Popover.Target>
      <Popover.Dropdown p={0} style={{ overflow: 'hidden' }}>
        <ProjectSprints
          currentSprint={task.SprintId}
          projects={[project]}
          onClick={(newSprint) => {
            close();
            onClose?.();
            if (!currentSprint) return;
            moveTask({ task, currentSprint, newSprint });
          }}
        />
      </Popover.Dropdown>
    </Popover>
  );
};

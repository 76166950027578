import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ComplexProjectData } from '@/api/projects';
import { NoData } from '@/components/ui/NoData/NoData';
import {
  openCreateFolderModal,
  openCreateSprintModal,
} from '@/features/EditEntityModals';
import { useAccess } from '@/hooks/useAccess';

export const ProjectNoData = memo(
  ({ project }: { project: ComplexProjectData }) => {
    const { t } = useTranslation();
    const access = useAccess();

    const buttons = useMemo(() => {
      const res = [];

      if (access.folders.create && project.pathById.length < 3) {
        res.push({
          buttonText: t('nodata.noFoldersSprints.action', {
            context: 'folder',
          }),
          handleClick: () => openCreateFolderModal(project),
        });
      }

      if (access.sprints.create) {
        res.push({
          buttonText: t('nodata.noFoldersSprints.action', {
            context: 'sprint',
          }),
          handleClick: () => openCreateSprintModal(project),
        });
      }

      return res;
    }, [access.folders.create, access.sprints.create, project, t]);

    return (
      <NoData
        illustration="emptyFolder"
        title={
          project.isFolder ? t('nodata.emptyFolder') : t('nodata.emptyProject')
        }
        description={
          project.pathById.length < 3
            ? t('nodata.noFoldersSprints.description', { context: 'both' })
            : t('nodata.noFoldersSprints.description', { context: 'sprint' })
        }
        buttons={buttons}
      />
    );
  },
);

import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ComplexSprintData } from '@/api/projects';
import { ApiTaskType } from '@/api/taskTypes';
import { TaskTypeSelect } from '@/components/ui/selects/TaskTypeSelect';
import { Button, Flex, Loader, LoadingOverlay, TextInput } from '@mantine/core';
import { useClickOutside, useFocusTrap, useHotkeys } from '@mantine/hooks';
import { IconCornerDownLeft } from '@tabler/icons-react';

import {
  emptyNumberValue,
  useCreateTask,
} from '../../../../../hooks/useCreateTask';

import styles from './CreateForm.module.css';

type CreateFormProps = {
  sprint: ComplexSprintData;
  types: ApiTaskType.IType[];
  status?: number;
  prevOrder?: number;
  afterCreate: () => void;
  onCancel: () => void;
};

export const CreateForm = ({
  sprint,
  types,
  status,
  afterCreate,
  onCancel,
  prevOrder,
}: CreateFormProps) => {
  const { t } = useTranslation();

  const [wrapperRef, setWrapperRef] = useState<HTMLDivElement | null>(null);
  const [typeSelectRef, setTypeSelectRef] = useState<HTMLDivElement | null>(
    null,
  );
  const formRef = useRef<HTMLFormElement>(null);

  useClickOutside(onCancel, null, [wrapperRef, typeSelectRef].filter(Boolean));

  const inputRef = useFocusTrap();

  const { handleAdd, isLoading, form } = useCreateTask({
    afterCreate,
    defaultValues: {
      sprint,
      Status: status,
      Order: prevOrder ? prevOrder + 1 : undefined,
    },
  });

  useEffect(() => {
    if (
      form.values.TypeId === emptyNumberValue ||
      !types.some((t) => t.Id === form.values.TypeId)
    ) {
      form.setFieldValue('TypeId', types[0]?.Id || -1);
    }
  }, [form, types]);

  useHotkeys([['mod + Enter', () => formRef?.current?.requestSubmit()]], []);

  return (
    <div ref={setWrapperRef} className={styles.root}>
      <LoadingOverlay
        visible={isLoading}
        loaderProps={{ children: <Loader color="blue" size="sm" /> }}
      />
      <form ref={formRef} onSubmit={form.onSubmit(handleAdd)}>
        <Flex align={'center'} gap={8}>
          <TaskTypeSelect
            ref={setTypeSelectRef}
            value={form.values.TypeId}
            onChange={(typeId) => form.setFieldValue('TypeId', typeId)}
            types={types}
            withLabel={false}
          />
          <TextInput
            data-autofocus
            ref={inputRef}
            variant="filled"
            w={'100%'}
            size="md"
            placeholder={t('createTask.fields.title.placeholder')}
            maxLength={500}
            {...form.getInputProps('Title')}
            error={!!form.errors['Title']}
          />
          <Flex gap={4} align={'center'}>
            <Button
              variant="filled"
              color="dark.1"
              size="compact-sm"
              onClick={onCancel}
            >
              {t('common.action.cancel')}
            </Button>
            <Button
              variant="filled"
              color="blue"
              type="submit"
              size="compact-sm"
              rightSection={<IconCornerDownLeft size={16} />}
            >
              {t('common.action.add')}
            </Button>
          </Flex>
        </Flex>
      </form>
    </div>
  );
};

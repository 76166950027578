import { ComplexSprintData } from '@/api/projects';
import { Flex } from '@mantine/core';
import { IconListDetails } from '@tabler/icons-react';
import { Ellipsis } from '@/components/ui/Ellipsis';

export const SprintOption = ({ sprint }: { sprint: ComplexSprintData }) => {
  return (
    <Flex
      gap={10}
      align={'center'}
      c={'light-dark(var(--mantine-color-gray-8), var(--mantine-color-dark-0))'}
    >
      <Flex align={'center'} c={sprint.Color || 'gray.7'}>
        <IconListDetails size={16} stroke={1.5} style={{ zIndex: 1 }} />
      </Flex>
      <Ellipsis>{sprint.Name}</Ellipsis>
    </Flex>
  );
};

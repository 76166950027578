import { SyntheticEvent, useState } from 'react';

import { useClickOutside } from '@mantine/hooks';

type UseEditTextValueProps = {
  originalValue: string;
  onUpdate?: (v: string) => void;
  allowEqual?: boolean;
  updateOnBlur?: boolean;
  defaultEdit?: boolean;
};

export const useEditTextValue = ({
  originalValue,
  onUpdate,
  allowEqual,
  updateOnBlur,
  defaultEdit = false,
}: UseEditTextValueProps) => {
  const [value, setValue] = useState('');
  const [isEdit, setIsEdit] = useState(defaultEdit);

  const handleEdit = () => {
    setIsEdit(true);
    setValue(originalValue);
  };

  const handleUpdate = (e?: SyntheticEvent) => {
    e?.preventDefault();
    const trimValue = value.trim();

    if (!trimValue) return;
    if (allowEqual || trimValue !== originalValue) onUpdate?.(trimValue);
    setIsEdit(false);
  };

  const ref = useClickOutside(() =>
    updateOnBlur ? handleUpdate() : setIsEdit(false),
  );

  return {
    value,
    setValue,
    handleEdit,
    handleUpdate,
    ref,
    isEdit,
    setIsEdit,
    valueEmpty: !value.trim(),
  };
};

import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CopyButton } from '@/components/ui/CopyButton';
import { UserRoleSelect } from '@/components/ui/selects/UserRoleSelect/UserRoleSelect';
import {
  Button,
  Group,
  Skeleton,
  Stack,
  Switch,
  Text,
  TextInput,
} from '@mantine/core';
import { IconRefresh } from '@tabler/icons-react';

import { useInvite } from '../hooks/useInvite';

export const InviteLink = ({ projectId }: { projectId: number }) => {
  const { t } = useTranslation();

  const ref = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);

  const { isLoading, link, enabled, role, setRole, onToggle, onUpdate } =
    useInvite(projectId);

  useEffect(() => {
    setWidth(ref.current?.getBoundingClientRect().width ?? 0);
  }, [enabled, role]);

  return (
    <Stack gap={16}>
      <Group justify="space-between">
        <Text
          size="sm"
          c="light-dark(var(--mantine-color-gray-8), white)"
          fw={500}
        >
          {t('members.inviteLink')}
        </Text>

        <Group gap={8} mih={30}>
          {enabled && (
            <Button
              size="xs"
              variant="transparent"
              leftSection={<IconRefresh size={16} />}
              onClick={onUpdate}
            >
              {t('common.action.update')}
            </Button>
          )}
          <Switch checked={enabled} onChange={onToggle} />
        </Group>
      </Group>

      {enabled && (
        <Group>
          {isLoading ? (
            <Skeleton h={42} flex={1} />
          ) : (
            <TextInput
              value={link}
              variant="default"
              readOnly
              size="md"
              rightSection={
                <div ref={ref}>
                  <UserRoleSelect
                    value={role}
                    onChange={setRole}
                    buttonProps={{
                      variant: 'default',
                      style: {
                        border: 'none',
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                      },
                    }}
                  />
                </div>
              }
              flex={1}
              styles={{
                input: {
                  paddingRight: width + 14,
                  backgroundColor:
                    'light-dark(var(--mantine-color-gray-1), var(--mantine-color-dark-7))',
                },
                section: {
                  width: 'auto',
                  overflow: 'hidden',
                  borderLeft:
                    '1px solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-5))',
                  borderRadius: 0,
                },
              }}
            />
          )}

          <CopyButton
            disabled={isLoading}
            value={link}
            variant="default"
            size={42}
            iconProps={{ size: 22 }}
          />
        </Group>
      )}
    </Stack>
  );
};

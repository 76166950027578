import { Popover } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

import { ColorPicker } from './ColorPicker';

interface ColorPickerPopoverProps {
  value?: string | null;
  onChange?: (v: string) => void;
  defaultOpened?: boolean;
}

export const ColorPickerPopover = ({
  value = 'var(--mantine-color-gray-5)',
  onChange,
  defaultOpened,
}: ColorPickerPopoverProps) => {
  const [opened, { close, open, toggle }] = useDisclosure(defaultOpened);
  return (
    <Popover
      opened={opened}
      onClose={close}
      onOpen={open}
      position="bottom-start"
      // withinPortal={false}
      withArrow
    >
      <Popover.Target>
        <ColorPicker.ColorCircle
          onClick={toggle}
          value={value}
          radius={4}
          size={20}
        />
      </Popover.Target>
      <Popover.Dropdown maw={'270px'}>
        <div onClick={close}>
          <ColorPicker
            {...(onChange
              ? { value, onChange }
              : { defaultValue: value ?? undefined })}
          />
        </div>
      </Popover.Dropdown>
    </Popover>
  );
};

import { ReactNode, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { ApiProjectAttributes } from '@/api/projects';
import { NoData } from '@/components/ui/NoData/NoData';
import { Button, Menu, Stack, TextInput, ThemeIcon } from '@mantine/core';
import {
  IconLetterCase,
  IconPlus,
  IconQuestionMark,
  IconWorld,
} from '@tabler/icons-react';
import { map, max } from 'lodash-es';

import { useEntityFormContext } from '../../hooks/useForm';
import { ICustomField } from '../../types';
import { DeleteAction } from '../DeleteAction';

export const CustomFieldsForm = () => {
  const { t } = useTranslation();

  const ref = useRef<HTMLDivElement>(null);
  const form = useEntityFormContext();

  const value = form.getValues().custom;

  const handleAdd = useCallback(
    (type: ICustomField['type']) => {
      form.setFieldValue('custom', (prev) => [
        ...prev,
        {
          id: (max(map(prev, 'id')) || 0) + 1,
          name: '',
          type,
          state: 'new' as const,
        },
      ]);
      setTimeout(() => {
        const inputs = ref.current?.querySelectorAll('input');
        if (!inputs) return;
        inputs[inputs.length - 1].focus();
      });
    },
    [form],
  );

  if (!value.filter((el) => el.state !== 'removed').length)
    return (
      <Stack>
        <NoData
          illustration="emptyBoard"
          description={t('entitySettings.customFieldSection.empty')}
          descriptionProps={{ maw: 300 }}
        >
          <AddButton onAdd={handleAdd} />
        </NoData>
      </Stack>
    );

  return (
    <Stack ref={ref} gap={8}>
      {value.map((el, idx) => {
        if (el.state === 'removed') return null;

        const thisKey = `custom.${idx}.name`;

        return (
          <TextInput
            key={form.key(thisKey)}
            {...form.getInputProps(thisKey)}
            onChange={(e) => {
              if (el.state !== 'new')
                form.setFieldValue(`custom.${idx}`, {
                  ...el,
                  state: 'updated',
                });
              form.getInputProps(thisKey).onChange(e);

              const thisError = form.errors[thisKey];
              if (thisError) {
                Object.entries(form.errors).forEach(([key, value]) => {
                  if (key !== thisKey && value === thisError)
                    form.validateField(key);
                });
              }
            }}
            leftSection={
              <ThemeIcon
                variant="transparent"
                color="light-dark(var(--mantine-color-gray-8), var(--mantine-color-gray-6))"
              >
                {iconByType[el.type]}
              </ThemeIcon>
            }
            rightSection={
              <DeleteAction
                onClick={() => {
                  form.setFieldValue('custom', (prev) =>
                    el.state === 'new'
                      ? prev.filter((_, ind) => ind !== idx)
                      : prev.map((el, ind) =>
                          ind === idx
                            ? { ...el, state: 'removed' as const }
                            : el,
                        ),
                  );
                  form.validate();
                }}
              />
            }
          />
        );
      })}

      <AddButton small onAdd={handleAdd} />
    </Stack>
  );
};

const AddButton = ({
  small,
  onAdd,
}: {
  small?: boolean;
  onAdd: (type: ICustomField['type']) => void;
}) => {
  const { t } = useTranslation();

  return (
    <Menu width={256} offset={0} position="bottom-start" shadow="sm">
      <Menu.Target>
        <Button
          {...(small ? { size: 'compact-sm', w: 'fit-content' } : {})}
          leftSection={<IconPlus size={15} />}
        >
          {t('entitySettings.customFieldSection.addField')}
        </Button>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item
          leftSection={iconByType[ApiProjectAttributes.IValueType.String]}
          onClick={() => onAdd(ApiProjectAttributes.IValueType.String)}
        >
          {t('entitySettings.customFieldSection.type.text')}
        </Menu.Item>
        <Menu.Item
          leftSection={iconByType[ApiProjectAttributes.IValueType.Link]}
          onClick={() => onAdd(ApiProjectAttributes.IValueType.Link)}
        >
          {t('entitySettings.customFieldSection.type.link')}
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

export const iconByType: Record<ICustomField['type'], ReactNode> = {
  [ApiProjectAttributes.IValueType.Int]: <IconQuestionMark size={18} />,
  [ApiProjectAttributes.IValueType.Long]: <IconQuestionMark size={18} />,
  [ApiProjectAttributes.IValueType.String]: <IconLetterCase size={18} />,
  [ApiProjectAttributes.IValueType.Double]: <IconQuestionMark size={18} />,
  [ApiProjectAttributes.IValueType.Date]: <IconQuestionMark size={18} />,
  [ApiProjectAttributes.IValueType.Array]: <IconQuestionMark size={18} />,
  [ApiProjectAttributes.IValueType.Link]: <IconWorld size={18} />,
};

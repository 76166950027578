import { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { projectsApi } from '@/api/projects';
import { useAppSelector } from '@/app/store';
import { FolderLoader } from '@/components/shared/SkeletonLoaders/FolderLoader';
import { NoData } from '@/components/ui/NoData/NoData';
import { TasksSearchInput } from '@/components/ui/TasksSearchInput/TasksSearchInput';
import { Sprint } from '@/features/Sprint';
import { ProjectAccessProvider } from '@/hooks/useAccess';
import { Stack } from '@mantine/core';
import { useInputState } from '@mantine/hooks';
import { map, sortBy, uniq } from 'lodash-es';

import { useTasksData } from '../../hooks/useTasksData';

export const MyTasksPage = () => {
  const { t } = useTranslation();

  const currentUserId = useAppSelector((s) => s.usersCache.currentUser.Id);
  const { data: projectsData } =
    projectsApi.endpoints.getProjects.useQueryState();

  const [search, setSearch] = useInputState('');

  const { groupedBySprint, isError, isLoading, isFetching, data, isSearching } =
    useTasksData({
      performerIds: [currentUserId],
      search,
    });

  const sprints = useMemo(() => {
    const sprintsIds = uniq(map(data, 'SprintId'));

    const sprints = sprintsIds
      .map((id) => projectsData?.sprintEntities[id])
      .filter(Boolean);

    return sortBy(sprints, ['ProjectId', 'Id']);
  }, [data, projectsData?.sprintEntities]);

  const hasData = sprints.length > 0;

  return (
    <>
      <Helmet>
        <title>
          {t('common.myTasks')} | {t('common.title')}
        </title>
      </Helmet>
      <ProjectAccessProvider>
        <Stack gap="lg" flex={1} p={12}>
          <TasksSearchInput
            value={search}
            onChange={setSearch}
            loading={isFetching}
          />
          <Stack flex={1} gap={0}>
            {isError ? (
              <NoData
                title={t('500.title')}
                description={t('500.description')}
                illustration="500"
                buttons={[
                  {
                    buttonText: t('500.reload'),
                    handleClick: () => window.location.reload(),
                  },
                ]}
              />
            ) : isLoading ? (
              <FolderLoader builder={[[3], [1, 2], [2, 3]]} />
            ) : hasData ? (
              <Stack>
                {sprints.map((sprint) => (
                  <Sprint
                    tasks={groupedBySprint[sprint.Id]}
                    key={sprint.Id}
                    sprint={sprint}
                    isError={isError}
                    isLoading={isLoading}
                  />
                ))}
              </Stack>
            ) : isSearching ? (
              <NoData
                title={t('nodata.noResults.title')}
                description={t('nodata.noResults.description')}
                illustration="noResults"
              />
            ) : (
              <NoData
                title={t('nodata.noTasks.title')}
                description={t('nodata.noTasks.description')}
                illustration="noTasks"
              />
            )}
          </Stack>
        </Stack>
      </ProjectAccessProvider>
    </>
  );
};

import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import { projectsApi } from '@/api/projects';
import { useAppSelector } from '@/app/store';
import { Header } from '@/components/layout/Header/Header';
import { Sidebar } from '@/components/layout/Sidebar/Sidebar';
import { MainContentLoader } from '@/components/shared/SkeletonLoaders/MainContentLoader';
import { NoData } from '@/components/ui/NoData/NoData';
import { CreateTaskModal } from '@/features/CreateTaskModal/CreateTaskModal';
import { DiffStatusesModal } from '@/features/DiffStatusesModal';
import { CreateFolderModal } from '@/features/EditEntityModals/modals/CreateFolderModal';
import { CreateProjectModal } from '@/features/EditEntityModals/modals/CreateProjectModal';
import { CreateSprintModal } from '@/features/EditEntityModals/modals/CreateSprintModal';
import { UpdateFolderModal } from '@/features/EditEntityModals/modals/UpdateFolderModal';
import { UpdateProjectModal } from '@/features/EditEntityModals/modals/UpdateProjectModal';
import { UpdateSprintModal } from '@/features/EditEntityModals/modals/UpdateSprintModal';
import { TaskModal } from '@/features/TaskModal/TaskModal';
import { AppShell } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

export const AppLayout = ({ children }: { children?: React.ReactNode }) => {
  const { t } = useTranslation();

  const { isLoading, isError } = projectsApi.useGetProjectsQuery();
  const skeleton = useAppSelector((s) => s.devSlice.skeletons.mainContent);

  const opened = useAppSelector((s) => s.layoutSlice.sidebarOpened);
  const disabled = useAppSelector((s) => s.layoutSlice.withoutSidebar);

  const isMobile = useMediaQuery(`(max-width: 576px)`);

  return (
    <>
      <AppShell
        withBorder={false}
        layout="alt"
        header={{ height: 65 }}
        navbar={{
          width: 300,
          breakpoint: 'lg',
          collapsed: {
            mobile: disabled || (isMobile ? !opened : undefined),
            desktop: disabled || (!isMobile ? !opened : undefined),
          },
        }}
      >
        <AppShell.Header>
          <Header />
        </AppShell.Header>

        <Sidebar />

        <AppShell.Main
          style={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100dvh',
          }}
        >
          {isLoading || skeleton ? (
            <MainContentLoader />
          ) : isError ? (
            <NoData
              title={t('500.title')}
              description={t('500.description')}
              illustration="500"
              buttons={[
                {
                  buttonText: t('500.reload'),
                  handleClick: () => window.location.reload(),
                },
              ]}
            />
          ) : (
            <>{children || <Outlet />}</>
          )}
        </AppShell.Main>
      </AppShell>

      <TaskModal />

      <CreateProjectModal />
      <UpdateProjectModal />

      <CreateFolderModal />
      <UpdateFolderModal />

      <CreateSprintModal />
      <UpdateSprintModal />

      <DiffStatusesModal />
      <CreateTaskModal />
    </>
  );
};

import { useTranslation } from 'react-i18next';

import { ActionIcon, Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconSearch } from '@tabler/icons-react';

import { ModalContent } from './components/ModalContent';

export type TasksSearchProps = {
  placeholder: string;
};

const SearchModal = ({
  onClose,
  opened,
  placeholder,
}: TasksSearchProps & { opened: boolean; onClose: () => void }) => {
  return (
    <Modal
      opened={opened}
      onClose={onClose}
      closeOnEscape
      closeOnClickOutside
      keepMounted={false}
      title={placeholder}
      shadow="xl"
      size={'xl'}
    >
      <ModalContent onClose={onClose} />
    </Modal>
  );
};

export const GlobalTaskSearch = () => {
  const { t } = useTranslation();

  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
      <ActionIcon
        onClick={open}
        variant="subtle"
        color="gray.6"
        aria-label="Search"
        size={'lg'}
      >
        <IconSearch size={22} />
      </ActionIcon>

      <SearchModal
        placeholder={t('common.action.searchTask')}
        opened={opened}
        onClose={close}
      />
    </>
  );
};

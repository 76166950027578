import { useTranslation } from 'react-i18next';

import { NoData } from '@/components/ui/NoData/NoData';
import { openCreateProjectModal } from '@/features/EditEntityModals';

export const EmptyState = () => {
  const { t } = useTranslation();

  return (
    <NoData
      title={t('nodata.noProjects.title')}
      description={t('nodata.noProjects.description')}
      buttons={[
        {
          buttonText: t('nodata.noProjects.action'),
          handleClick: openCreateProjectModal,
        },
      ]}
    />
  );
};

import { Group, Paper, Skeleton, Stack } from '@mantine/core';

import { SprintLoader } from './SprintLoader';

interface IProps {
  /**
   * taskCount = 4;
   *
   * groupCount = [taskCount, taskCount, taskCount];
   *
   * sprintCount = [groupCount, groupCount];
   *
   * builder={sprintCount}
   */
  builder?: number[][];
}

export const FolderLoader = ({ builder = [[4, 4, 4, 4, 4, 4]] }: IProps) => {
  return (
    <Stack>
      {builder.map((el, i) => (
        <Paper key={i} p={16} withBorder radius={8}>
          <Group gap={8} wrap="nowrap" h={26} mb={12}>
            <Skeleton height={16} width={16} mx={3} circle />
            <Skeleton height={20} width={150} />
            <Skeleton height={20} width={60} />
          </Group>

          <SprintLoader builder={el} />
        </Paper>
      ))}
    </Stack>
  );
};

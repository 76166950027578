import { customBaseQuery } from '@/api/customBaseQuery';
import { createApi } from '@reduxjs/toolkit/query/react';

import { ApiAuth } from './types';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: customBaseQuery('/api'),
  tagTypes: ['Users'],
  endpoints: (builder) => ({
    login: builder.mutation<ApiAuth.ILoginResponse, ApiAuth.ILoginRequest>({
      query: (body) => ({
        url: '/v2/auth/login',
        method: 'POST',
        body,
      }),
    }),
    logout: builder.mutation<void, void>({
      query: () => ({
        url: '/v2/auth/logout',
        method: 'GET',
      }),
    }),
  }),
});

import { memo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { ApiProjectTask } from '@/api/tasks';
import { IFilledActivity } from '@/features/TaskPanel/components/Activity/types';
import { Button, Group, Stack } from '@mantine/core';

import { EditCommentForm } from '../EditCommentForm/EditCommentForm';
import { UserAvatar } from '../UserAvatar';
import { CommentHeader } from './components/CommentHeader';
import { useFocusScroll } from './hooks/useFocusScroll';

import styles from './Comment.module.css';

interface IProps {
  currentUserId?: number;
  task: ApiProjectTask.ITask;
  activity: Pick<
    IFilledActivity,
    'Id' | 'Author' | 'Description' | 'Timestamp'
  >;
  loading?: boolean;
  editing?: boolean;
  onDelete?: (id: number) => void;
  onChange?: (id: number, value: string) => void;
  onCancel?: (id: null) => void;
  onEdit?: (id: number) => void;
}

export const Comment = memo(
  ({
    currentUserId,
    task,
    activity,
    loading,
    editing,
    onDelete,
    onChange,
    onCancel,
    onEdit,
  }: IProps) => {
    const { t } = useTranslation();

    const ref = useRef<HTMLDivElement>(null);

    useFocusScroll(ref, activity.Id);

    const content = highlightLinks(activity.Description);

    return (
      <Group ref={ref} className={styles.comment} wrap="nowrap" gap={4}>
        <UserAvatar size={36} user={activity.Author} />
        <Stack gap={2} w="100%">
          <CommentHeader activity={activity} task={task} />

          {editing ? (
            <EditCommentForm
              anchor={ref}
              onEditing={(v) => {
                if (!v) onCancel?.(null);
              }}
              loading={loading}
              onChange={(value) => onChange?.(activity.Id, value)}
              defaultValue={activity.Description}
            />
          ) : (
            <>
              <p
                className={styles.message}
                dangerouslySetInnerHTML={{ __html: content }}
              />
              {currentUserId === activity.Author?.Id && (
                <Group gap={4} pos="relative" left={-7}>
                  {onChange && (
                    <Button
                      variant="subtle"
                      color="dark.2"
                      size="compact-xs"
                      onClick={() => onEdit?.(activity.Id)}
                    >
                      {t('common.action.change')}
                    </Button>
                  )}
                  {onDelete && (
                    <Button
                      variant="subtle"
                      color="dark.2"
                      size="compact-xs"
                      onClick={() => onDelete(activity.Id)}
                    >
                      {t('common.action.delete')}
                    </Button>
                  )}
                </Group>
              )}
            </>
          )}
        </Stack>
      </Group>
    );
  },
);

function highlightLinks(message: string) {
  const urlPattern = /((https?:\/\/)?((www\.)?[^\s]+\.[^\s]{2,}))/gi;

  return message.replace(urlPattern, (_match, full, protocol) => {
    let url = full;
    if (!protocol) {
      url = 'https://' + full;
    }
    return `<a href="${url}" target="_blank">${full}</a>`;
  });
}

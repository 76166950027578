import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ComplexProjectData } from '@/api/projects';
import { store } from '@/app/store';
import { layoutSlice } from '@/components/layout/AppLayout/slice';
import { Ellipsis } from '@/components/ui/Ellipsis';
import { AppRoutes } from '@/config/links';
import { generateLink } from '@/helpers/generateLink';
import { useAccess } from '@/hooks/useAccess';
import { useActiveLink } from '@/hooks/useActiveLink';
import { NavLink, Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { flatMap, xor } from 'lodash-es';

import { ActionsButton } from './ActionsButton';
import { AddFolder } from './AddFolder';
import { AddSprint } from './AddSprint';
import { SprintLink } from './SprintLink';
import { TreeProjectIcon } from './TreeProjectIcon';

import styles from '../Navigation.module.css';

const close = () => store.dispatch(layoutSlice.actions.closeSidebar());

interface RecursiveNavLinkProps {
  project: ComplexProjectData;
  depth: number;
  opened: number[];
  setOpened: Dispatch<SetStateAction<number[]>>;
  type: 'project' | 'folder';
}

export const RecursiveLink: React.FC<RecursiveNavLinkProps> = ({
  project,
  depth,
  opened,
  setOpened,
  type,
}) => {
  const { t } = useTranslation();
  const access = useAccess();
  const navigate = useNavigate();

  const children = project.Children || [];
  const sprints = project.Sprints || [];

  const isOpen = opened.includes(project.Id);
  const canCreateFolder = depth! <= 1;
  const hasChildren = project.Children.length || project.Sprints.length;
  const createFolderAccess = access.folders.create && canCreateFolder;
  const createSprintAccess = !project.Sprints.length && access.sprints.create;

  const isActive = useActiveLink(getLinks(project, isOpen));
  const isBurger = useMediaQuery('(max-width: 1200px)');

  const handleBodyItemClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      navigate(generateLink(AppRoutes.folder, { folderId: project.Id }));
      isBurger && close();
    },
    [isBurger, project.Id, navigate],
  );

  const handleChange = (e: React.MouseEvent) => {
    e.stopPropagation();
    setOpened((opened) => xor(opened, [project.Id]));
  };

  return (
    <NavLink
      classNames={{
        root: styles.root,
      }}
      onClick={handleBodyItemClick}
      label={<Ellipsis>{project.Name}</Ellipsis>}
      opened={isOpen}
      leftSection={
        <TreeProjectIcon
          onClick={handleChange}
          depth={depth}
          opened={isOpen}
          project={project}
        />
      }
      rightSection={
        <ActionsButton
          project={project}
          canCreateFolder={canCreateFolder}
          onClose={close}
          type={type}
          redirect={handleBodyItemClick}
        />
      }
      childrenOffset={20}
      disableRightSectionRotation
      noWrap
      active={!!isActive}
    >
      {children?.map((child) => (
        <RecursiveLink
          key={child.Id}
          type="folder"
          project={child}
          depth={depth + 1}
          opened={opened}
          setOpened={setOpened}
        />
      ))}

      {sprints?.map((sprint) => (
        <SprintLink key={sprint.Id} sprint={sprint} onClose={close} />
      ))}

      {!project.Children.length && createFolderAccess && (
        <AddFolder project={project} onClose={close} />
      )}
      {!project.Sprints.length && createSprintAccess && (
        <AddSprint project={project} onClose={close} />
      )}

      {!hasChildren && !createFolderAccess && !createSprintAccess && (
        <Text fz={14} c={'gray.6'} py={6}>
          {type === 'project' && t('nodata.emptyProject')}
          {type === 'folder' && t('nodata.emptyFolder')}
        </Text>
      )}
    </NavLink>
  );
};

const getLinks = (
  project: ComplexProjectData,
  isOpen?: boolean,
): { link: string }[] => {
  if (isOpen)
    return [{ link: generateLink(AppRoutes.folder, { folderId: project.Id }) }];

  return [
    { link: generateLink(AppRoutes.folder, { folderId: project.Id }) },
    ...flatMap(project.Sprints, ({ Id }) => [
      { link: generateLink(AppRoutes.sprintList, { sprintId: Id }) },
      { link: generateLink(AppRoutes.sprintKanban, { sprintId: Id }) },
    ]),
    ...flatMap(project.Children, (el) => getLinks(el)),
  ];
};

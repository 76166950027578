import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ApiTaskLinks } from '@/api/links';
import { ComboboxItem, Select, SelectProps } from '@mantine/core';

export const LinkTypeSelect = ({
  value,
  onChange,
  ...props
}: {
  value: ApiTaskLinks.LinkType;
  onChange: (v: ApiTaskLinks.LinkType) => void;
} & Omit<SelectProps, 'value' | 'onChange'>) => {
  const { t } = useTranslation();

  const options = useMemo<ComboboxItem[]>(
    () => [
      {
        value: ApiTaskLinks.LinkType.Relates.toString(),
        label: t('taskLinkTypes.' + ApiTaskLinks.LinkType.Relates),
      },
      {
        value: ApiTaskLinks.LinkType.Duplicates.toString(),
        label: t('taskLinkTypes.' + ApiTaskLinks.LinkType.Duplicates),
      },
      {
        value: ApiTaskLinks.LinkType.Blocks.toString(),
        label: t('taskLinkTypes.' + ApiTaskLinks.LinkType.Blocks),
      },
      {
        value: ApiTaskLinks.LinkType.BlockedBy.toString(),
        label: t('taskLinkTypes.' + ApiTaskLinks.LinkType.BlockedBy),
      },
      {
        value: ApiTaskLinks.LinkType.StartWith.toString(),
        label: t('taskLinkTypes.' + ApiTaskLinks.LinkType.StartWith),
      },
      {
        value: ApiTaskLinks.LinkType.FinishWith.toString(),
        label: t('taskLinkTypes.' + ApiTaskLinks.LinkType.FinishWith),
      },
    ],
    [t],
  );

  return (
    <Select
      data-cy="type-select"
      checkIconPosition="right"
      variant="filled"
      data={options}
      size="sm"
      value={value.toString()}
      onChange={(_, opt) => onChange?.(Number(opt.value))}
      {...props}
    />
  );
};

import { useTranslation } from 'react-i18next';

import { useIsDarkTheme } from '@/helpers/themeUtils.ts';
import { Button, Group } from '@mantine/core';

import styles from '../Activity.module.css';

interface IProps {
  value: string;
  onChange: (value: string) => void;
}

export const Filters = ({ value, onChange }: IProps) => {
  const { t } = useTranslation();
  const isDark = useIsDarkTheme();

  const filters = ['comments', 'history'].map((value) => ({
    value,
    label: t(`activities.typeOptions.${value}`),
  }));

  return (
    <Group gap={8} pl={8}>
      <div className={styles.show}>{t('activities.typeTitle')}</div>
      {filters.map((el) => {
        return (
          <Button
            key={el.value}
            size="compact-sm"
            {...getButtonProps(value === el.value, isDark)}
            fw={400}
            onClick={() => onChange(el.value)}
          >
            {el.label}
          </Button>
        );
      })}
    </Group>
  );
};

const getButtonProps = (isActive: boolean, isDark: boolean) =>
  isActive
    ? { color: isDark ? 'dark.0' : 'blue.1', c: 'dark' }
    : { variant: 'light', color: 'dark' };

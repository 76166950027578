import { ApiProjectTask } from '@/api/tasks';
import { usersApi } from '@/api/users';
import { UserAvatar } from '@/components/ui/UserAvatar';
import { Flex, Text } from '@mantine/core';

export const TaskBar = ({
  task,
  isSticky,
}: {
  task: ApiProjectTask.ITask;
  isSticky?: boolean;
}) => {
  const { data } = usersApi.useGetByIdQuery({
    ids: [task.PerformerId].filter(Boolean),
  });

  const performerData = task.PerformerId ? data?.[0] : null;

  return (
    <Flex
      gap={8}
      align={'center'}
      px={4}
      h={24}
      pos={isSticky ? 'absolute' : undefined}
      left={isSticky ? 'calc(100% + 16px)' : undefined}
      maw={isSticky ? 400 : undefined}
    >
      <UserAvatar size={16} user={performerData} />
      <Text
        fz={12}
        c={isSticky ? 'light-color(black, white)' : 'white'}
        truncate="end"
      >
        {task.Title}
      </Text>
    </Flex>
  );
};

import { ReactNode, Ref, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { ApiProjectTask } from '@/api/tasks';
import { Flex, FlexProps, Text, TextProps } from '@mantine/core';
import {
  IconChevronDown,
  IconChevronUp,
  IconChevronsDown,
  IconChevronsUp,
  IconEqual,
} from '@tabler/icons-react';

type TaskPriorityBadgeProps = {
  priority: ApiProjectTask.IPriority;
  withLabel?: boolean;
  iconSize?: number;
  textSize?: TextProps['size'];
} & FlexProps;

const Badge = (
  {
    priority,
    withLabel = true,
    iconSize = 20,
    textSize = 'sm',
    ...props
  }: TaskPriorityBadgeProps,
  ref: Ref<HTMLDivElement>,
) => {
  const { t } = useTranslation();

  return (
    <Flex gap={12} ref={ref} align={'center'} {...props}>
      {iconRelation[priority](iconSize)}
      {withLabel && (
        <Text size={textSize}>{t('taskPriorities.' + priority)}</Text>
      )}
    </Flex>
  );
};

export const TaskPriorityBadge = forwardRef(Badge);

const iconRelation: Record<number, (size?: number) => ReactNode> = {
  1: (size?: number) => (
    <IconChevronsUp size={size} color={'var(--mantine-color-red-5)'} />
  ),
  2: (size?: number) => (
    <IconChevronUp size={size} color={'var(--mantine-color-red-5)'} />
  ),
  3: (size?: number) => (
    <IconEqual size={size} color={'var(--mantine-color-orange-5)'} />
  ),
  4: (size?: number) => (
    <IconChevronDown size={size} color={'var(--mantine-color-blue-5)'} />
  ),
  5: (size?: number) => (
    <IconChevronsDown size={size} color={'var(--mantine-color-blue-5)'} />
  ),
};

import { MouseEvent, useState } from 'react';

export const useOverflown = (check: 'x' | 'y' | 'xy' = 'xy') => {
  const [isOverflown, setIsOverflown] = useState(false);

  const checkOverflown = ({
    currentTarget: { clientWidth, scrollWidth, clientHeight, scrollHeight },
  }: Pick<MouseEvent<HTMLElement, MouseEvent>, 'currentTarget'>) => {
    const x = clientWidth >= scrollWidth;
    const y = clientHeight >= scrollHeight;
    const checks = { x, y, xy: x && y };

    setIsOverflown(!checks[check]);
  };

  return { isOverflown, checkOverflown };
};

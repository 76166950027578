import { SPRINT_VIEW_KEY } from './const';
import { ISprintView, isSprintView } from './guards';

const getSprintViewObject = (): Record<number, ISprintView> => {
  // migration from String value
  try {
    return JSON.parse(localStorage.getItem(SPRINT_VIEW_KEY) || '{}') as Record<
      number,
      ISprintView
    >;
  } catch {
    return {};
  }
};

export const getSprintView = (sprintId: number): ISprintView => {
  const stored = getSprintViewObject();

  const value = stored[sprintId];

  return isSprintView(value) ? value : 'list';
};

export const setSprintView = (sprintId: number, value: ISprintView) => {
  const stored = getSprintViewObject();

  localStorage.setItem(
    SPRINT_VIEW_KEY,
    JSON.stringify({ ...stored, [sprintId]: value }),
  );
};

import { useTranslation } from 'react-i18next';

import { IFilledActivity } from '../types';
import { HistoryComponent } from './HistoryComponent';

interface IProps {
  activity: IFilledActivity;
  showType?: boolean;
}

export const HistoryCreate = ({
  activity,
  // showType = true,
}: IProps) => {
  const { t } = useTranslation();

  return (
    <HistoryComponent
      name={activity.Author?.Name}
      action={t(`taskAction.create`)}
      predicate={t(`taskActivity.SprintId`)}
      timestamp={activity.Timestamp}
    />
  );
};

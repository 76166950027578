import { useTranslation } from 'react-i18next';

import { ApiCommon } from '@/api/common';
import { DEFAULT_VALUES } from '@/app/constants';
import { Button } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { t } from 'i18next';

import { useEntityFormContext } from '../../hooks/useForm';
import { IStatusForm } from '../../types';

interface ICreateStatusProps {
  lastOrder: number;
}

const getNewStatus = (order: number): IStatusForm => ({
  color: DEFAULT_VALUES.TASK_STATUS_COLOR,
  id: new Date().valueOf(),
  name: t('entitySettings.statusSection.newStatus'),
  type: ApiCommon.ITastStatusType.Custom,
  state: 'new',
  patternId: null,
  order,
});

export const CreateStatus = ({ lastOrder }: ICreateStatusProps) => {
  const { t } = useTranslation();

  const form = useEntityFormContext();

  return (
    <Button
      leftSection={<IconPlus size={12} />}
      size="xs"
      onClick={() =>
        form.setFieldValue('statuses.current', (prev) => [
          ...prev,
          getNewStatus(lastOrder + 1),
        ])
      }
    >
      {t('entitySettings.statusSection.addStatus')}
    </Button>
  );
};

import { useTranslation } from 'react-i18next';

import { ApiProjects } from '@/api/projects';
import { ApiTaskType } from '@/api/taskTypes';
import { ApiTaskActivity } from '@/api/tasks';
import { UserAvatar } from '@/components/ui/UserAvatar';
import { TaskPriorityBadge } from '@/components/ui/badges/TaskPriorityBadge';
import { TaskStatusBadge } from '@/components/ui/badges/TaskStatusBadge/TaskStatusBadge';
import { TaskTypeBadge } from '@/components/ui/badges/TaskTypeBadge';
import { formatDate } from '@/helpers/formatDate';
import { useSprintData } from '@/hooks/useSprintData';
import { useStatusesData } from '@/hooks/useStatusesData';
import { useTaskTypesData } from '@/hooks/useTaskTypesData';
import { Avatar, Flex, Group, Text, Tooltip } from '@mantine/core';
import { IconListDetails } from '@tabler/icons-react';
import { find, isString } from 'lodash-es';

import { IFilledActivity, IFilledAssignActivity } from '../types';
import { HistoryComponent } from './HistoryComponent';

interface IProps {
  sprintId: number;
  activity: IActivity;
  showType?: boolean;
}

export const HistoryActivity = ({
  sprintId,
  activity,
  // showType = true,
}: IProps) => {
  const { t } = useTranslation();

  const sprintData = useSprintData(sprintId);
  const { statuses } = useStatusesData(sprintData);
  const { taskTypes: types } = useTaskTypesData(sprintData?.ProjectId);

  const entity = activity.Json.Changes.at(-1);
  if (!entity || !availableChanges.includes(entity.FieldName)) return null;

  const { action, predicate } = getCopyKeys(entity);

  const isRemove = !!entity.OldValue && !entity.NewValue;

  return (
    <HistoryComponent
      name={activity.Author?.Name}
      action={t(`taskAction.${action}`)}
      predicate={t(`taskActivity.${predicate}`)}
      timestamp={activity.Timestamp}
      nextState={drawPredicate({
        predicate: entity.FieldName,
        value: isRemove ? entity.OldValue : entity.NewValue,
        statuses,
        types,
        emptyValue: t('activities.empty'),
      })}
    />
  );
};

const getCopyKeys = (change: IActivityChange) => {
  if (change.FieldName === 'SprintId')
    return {
      action: `move`,
      predicate: `SprintId`,
    };

  if (!change.OldValue && !!change.NewValue)
    return {
      action: `add`,
      predicate: `${change.FieldName}`,
    };

  if (!!change.OldValue && !!change.NewValue)
    return {
      action: `edit`,
      predicate: `${change.FieldName}`,
    };

  if (!!change.OldValue && !change.NewValue)
    return {
      action: `remove`,
      predicate: `${change.FieldName}`,
    };

  return { action: '', predicate: '' };
};

const drawPredicate = ({
  predicate,
  value,
  statuses,
  types,
  emptyValue,
}: {
  predicate: ApiTaskActivity.IActivityChange;
  value: IActivityChange['NewValue'];
  statuses: ApiProjects.IGetAvailableStatusesResponse;
  types: ApiTaskType.IType[];
  emptyValue: string;
}) => {
  if (!value)
    return (
      <Text td="line-through" style={{ wordBreak: 'normal' }}>
        {emptyValue}
      </Text>
    );

  if (predicate === 'Title' && isString(value)) {
    return value;
  }

  if (predicate === 'Description') {
    return (
      <div
        dangerouslySetInnerHTML={{ __html: value }}
        style={{ wordBreak: 'normal' }}
      />
    );
  }

  if (predicate === 'Status') {
    const status = find(statuses, ['Id', value]);

    return (
      <TaskStatusBadge
        status={status}
        fz="xs"
        lh="16px"
        mih={20}
        style={{ borderRadius: 3 }}
      />
    );
  }

  if (predicate === 'PerformerId' && !isString(value)) {
    return (
      <Group gap={6}>
        <UserAvatar size={16} user={value} />
        <Text fz="xs">{value.Name}</Text>
      </Group>
    );
  }

  if (predicate === 'Priority') {
    return (
      <TaskPriorityBadge
        priority={value as any}
        iconSize={16}
        gap={4}
        textSize="xs"
      />
    );
  }

  if (predicate === 'SprintId') {
    return <SprintDisplay sprintId={Number(value)} />;
  }

  if (predicate === 'TypeId') {
    return (
      <TaskTypeBadge
        type={find(types, ['Id', value])}
        iconSize={16}
        gap={4}
        textSize="xs"
      />
    );
  }

  if (
    ['StartDate', 'EndDate', 'DueDate'].includes(predicate) &&
    isString(value)
  ) {
    return <Text fz="xs">{formatDate(value)}</Text>;
  }
};

const SprintDisplay = ({ sprintId }: { sprintId: number }) => {
  const sprintData = useSprintData(sprintId);

  if (!sprintData) return null;

  return (
    <Flex align="center" gap={4} visibleFrom="xs">
      <Avatar
        variant="transparent"
        radius="sm"
        size={16}
        color={sprintData.Color || 'gray.5'}
      >
        <IconListDetails size={14} />
      </Avatar>
      <Tooltip label={sprintData.Name} openDelay={400}>
        <Text size="xs" truncate="end" maw={150}>
          {sprintData.Name}
        </Text>
      </Tooltip>
    </Flex>
  );
};

const availableChanges: ApiTaskActivity.IActivityChange[] = [
  'Priority',
  'StartDate',
  'EndDate',
  'DueDate',
  'PerformerId',
  'Status',
  'SprintId',
  'TypeId',
];

type IActivity = Extract<
  IFilledActivity,
  ApiTaskActivity.IHistoryActivity | IFilledAssignActivity
>;

type IActivityChange = IActivity['Json']['Changes'][0];

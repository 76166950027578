export const news = [
  {
    showInModal: true,
    date: '11.07.2024',
    label: 'new',
    // images: [
    //   'kanban.jpg',
    //   'gantt.jpg',
    //   'drag-and-drop-between-statuses.jpg',
    //   'task-links.jpg',
    //   'multiple-participants.jpg',
    // ],
    description: `
	  <br>
	  <img src="/images/updates/kanban.jpg">
	  <br>

	  <h3>Вид представления - Доска Kanban</h3>
	  <ul>
		<li><strong>Организация задач и проектов:</strong> Используйте доску Kanban для визуализации рабочего процесса.</li>
		<li><strong>Перемещение карточек:</strong> Перетаскивайте задачи между колонками для отслеживания прогресса.</li>
		<li><strong>Управление задачами:</strong> Создавайте карточки, назначайте участников и устанавливайте приоритеты.</li>
	  </ul>

	  <br>
	  <hr>
	  <img src="/images/updates/gantt.jpg">
	  <br>
	  <h3>Вид представления - Диаграмма Ганта</h3>
	  <ul>
		<li><strong>Планирование проектов:</strong> Используйте диаграмму Ганта для визуализации временных рамок и зависимостей задач.</li>
		<li><strong>Управление задачами:</strong> Создавайте задачи, устанавливайте даты выполнения и назначайте ответственных.</li>
		<li><strong>Определение зависимостей:</strong> Устанавливайте зависимости между задачами для оптимизации рабочего процесса.</li>
	  </ul>
	  <br>
	  <hr>
	  <img src="/images/updates/drag-and-drop-between-statuses.jpg">
	  <br>
	  <h3>Перемещение задачи между статусами</h3>
	  <ul>
		<li><strong>Обновление статусов:</strong> Легко изменяйте статусы задач, перетаскивая их между различными колонками для актуализации информации о прогрессе.</li>
	  </ul>
	  <br>
	  <hr>
	  <img src="/images/updates/task-links.jpg">
	  <br>
	  <h3>Связывание задач</h3>
	  <ul>
		<li><strong>Установление зависимостей:</strong> Связывайте задачи друг с другом для четкого планирования и координации.</li>
		<li><strong>Типы зависимостей:</strong> Устанавливайте различные типы зависимостей для эффективного управления проектами.</li>
	  </ul>
	  <br>
	  <hr>
	  <img src="/images/updates/multiple-participants.jpg">
	  <br>
	  <h3>Массовое добавление пользователей</h3>
	  <ul>
		<li><strong>Быстрое добавление:</strong> Добавляйте несколько пользователей одновременно для экономии времени.</li>
		<li><strong>Роли и права доступа:</strong> Назначайте роли и права доступа при добавлении пользователей для эффективного распределения обязанностей.</li>
	  </ul>
	  `,
  },
];

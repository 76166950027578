import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Alert, Text } from '@mantine/core';

export const EmptyStatuses = ({ message }: { message?: ReactNode }) => {
  const { t } = useTranslation();

  return (
    <Alert variant="light" color="gray">
      <Text mb={8} fw={'bold'}>
        {t('empty.noStatuses.title')}
      </Text>
      {message || t('empty.noStatuses.description')}
    </Alert>
  );
};

import { useEffect, useState } from 'react';

import { ComplexSprintData } from '@/api/projects';
import { openCreateTaskModal } from '@/features/CreateTaskModal';
import { Timeline } from '@/features/Sprint';
import { ProjectAccessProvider } from '@/hooks/useAccess';
import { useOpenedSprintData } from '@/hooks/useOpenedSprintData';
import { Stack } from '@mantine/core';
import { useInputState } from '@mantine/hooks';

import { useFolderData } from '../../hooks/useFolderData';
import { useTasksData } from '../../hooks/useTasksData';
import { NoResults } from './components/NoResults';
import { NoSprint } from './components/NoSprint';
import { NoTasks } from './components/NoTasks';
import { SprintHeader } from './components/SprintHeader';

export const TimelineSprintPage = () => {
  const sprintData = useOpenedSprintData();
  const projectData = useFolderData(sprintData?.ProjectId);

  if (!sprintData || !projectData) {
    return <NoSprint />;
  }

  return (
    <ProjectAccessProvider projectId={projectData.Id}>
      <Content sprintData={sprintData} />
    </ProjectAccessProvider>
  );
};

const Content = ({ sprintData }: { sprintData: ComplexSprintData }) => {
  const [performerIds, setPerformerIds] = useState<number[]>([]);
  const [search, setSearch] = useInputState('');

  const { groupedBySprint, isError, isLoading, isFetching, isSearching } =
    useTasksData({
      sprintId: sprintData.Id,
      performerIds,
      search,
    });

  const withFilters = Boolean(performerIds.length) || isSearching;

  // очиста поиска при переходе между спринтами
  useEffect(() => {
    setSearch('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sprintData.Id]);

  return (
    <>
      <Stack gap="lg" p={12} flex={1}>
        <SprintHeader
          sprintData={sprintData}
          search={search}
          setSearch={setSearch}
          performerIds={performerIds}
          setPerformerIds={setPerformerIds}
          loading={isFetching}
        />

        <Timeline
          sprint={sprintData}
          isError={isError}
          isLoading={isLoading}
          tasks={groupedBySprint[sprintData.Id]}
          emptyState={
            withFilters ? (
              <NoResults
                onClick={() => {
                  setPerformerIds([]);
                  setSearch('');
                }}
              />
            ) : (
              <NoTasks
                onClick={() =>
                  openCreateTaskModal({
                    defaultValues: {
                      sprint: sprintData,
                    },
                  })
                }
              />
            )
          }
        />
      </Stack>
    </>
  );
};

import { ReactNode, forwardRef } from 'react';

import { Avatar, AvatarProps, Text } from '@mantine/core';
import {
  IconActivity,
  IconAlertTriangle,
  IconApi,
  IconApps,
  IconArrowBigUpLine,
  IconArrowNarrowUp,
  IconBell,
  IconBolt,
  IconBook,
  IconBookmark,
  IconBrandJavascript,
  IconBriefcase,
  IconBrush,
  IconBug,
  IconBulb,
  IconCalendarMonth,
  IconCamera,
  IconChartLine,
  IconCheck,
  IconCheckbox,
  IconClock,
  IconCopy,
  IconCurrencyDollar,
  IconDeviceDesktop,
  IconDeviceDesktopSearch,
  IconEdit,
  IconExclamationMark,
  IconFileDescription,
  IconFileSearch,
  IconFlame,
  IconFolder,
  IconHeart,
  IconHome,
  IconListCheck,
  IconLock,
  IconMailOpened,
  IconMap,
  IconMinus,
  IconPennant,
  IconPhoto,
  IconPlus,
  IconPointFilled,
  IconPrinter,
  IconProgressCheck,
  IconPuzzle,
  IconQuestionMark,
  IconRocket,
  IconSearch,
  IconSelector,
  IconServerBolt,
  IconSettings,
  IconSquareCheck,
  IconSquareRotated,
  IconTargetArrow,
  IconThumbUp,
  IconTruckDelivery,
  IconUserSquareRounded,
  IconUsersGroup,
  IconWorld,
} from '@tabler/icons-react';
import { isNumber } from 'lodash-es';

export enum ProjectIconType {
  bug,
  chartLine,
  bolt,
  fileSearch,
  puzzle,
  pennant,
  edit,
  targetArrow,
  rocket,
  arrowBigUpLine,
  brush,
  alertTriangle,
  camera,
  calendarMonth,
  world,
  truckDelivery,
  apps,
  home,
  mailOpened,
  book,
  deviceDesktop,
  selector,
  map,
  photo,
  bookmark,
  lock,
  settings,
  bell,
  printer,
  usersGroup,
  fileDescription,
  briefcase,
  checkbox,
  bulb,
  search,
  deviceDesktopSearch,
  clock,
  userSquareRounded,
  serverBolt,
  thumbUp,
  api,
  listCheck,
  squareCheck,
  progressCheck,
  flame,
  currencyDollar,
  check,
  questionMark,
  brandJavascript,
  activity,
  plus,
  copy,
  minus,
  pointFilled,
  exclamationMark,
  heart,
  arrowNarrowUp,
  folder,
  squareRotated,
}

const styles = { width: '90%', height: '90%' };

export const projectIconsMap: Record<ProjectIconType, ReactNode> = {
  [ProjectIconType.bug]: <IconBug {...styles} />,
  [ProjectIconType.chartLine]: <IconChartLine {...styles} />,
  [ProjectIconType.bolt]: <IconBolt {...styles} />,
  [ProjectIconType.fileSearch]: <IconFileSearch {...styles} />,
  [ProjectIconType.puzzle]: <IconPuzzle {...styles} />,
  [ProjectIconType.pennant]: <IconPennant {...styles} />,
  [ProjectIconType.edit]: <IconEdit {...styles} />,
  [ProjectIconType.targetArrow]: <IconTargetArrow {...styles} />,
  [ProjectIconType.rocket]: <IconRocket {...styles} />,
  [ProjectIconType.arrowBigUpLine]: <IconArrowBigUpLine {...styles} />,
  [ProjectIconType.brush]: <IconBrush {...styles} />,
  [ProjectIconType.alertTriangle]: <IconAlertTriangle {...styles} />,
  [ProjectIconType.camera]: <IconCamera {...styles} />,
  [ProjectIconType.calendarMonth]: <IconCalendarMonth {...styles} />,
  [ProjectIconType.world]: <IconWorld {...styles} />,
  [ProjectIconType.truckDelivery]: <IconTruckDelivery {...styles} />,
  [ProjectIconType.apps]: <IconApps {...styles} />,
  [ProjectIconType.home]: <IconHome {...styles} />,
  [ProjectIconType.mailOpened]: <IconMailOpened {...styles} />,
  [ProjectIconType.book]: <IconBook {...styles} />,
  [ProjectIconType.deviceDesktop]: <IconDeviceDesktop {...styles} />,
  [ProjectIconType.selector]: <IconSelector {...styles} />,
  [ProjectIconType.map]: <IconMap {...styles} />,
  [ProjectIconType.photo]: <IconPhoto {...styles} />,
  [ProjectIconType.bookmark]: <IconBookmark {...styles} />,
  [ProjectIconType.lock]: <IconLock {...styles} />,
  [ProjectIconType.settings]: <IconSettings {...styles} />,
  [ProjectIconType.bell]: <IconBell {...styles} />,
  [ProjectIconType.printer]: <IconPrinter {...styles} />,
  [ProjectIconType.usersGroup]: <IconUsersGroup {...styles} />,
  [ProjectIconType.fileDescription]: <IconFileDescription {...styles} />,
  [ProjectIconType.briefcase]: <IconBriefcase {...styles} />,
  [ProjectIconType.checkbox]: <IconCheckbox {...styles} />,
  [ProjectIconType.bulb]: <IconBulb {...styles} />,
  [ProjectIconType.search]: <IconSearch {...styles} />,
  [ProjectIconType.deviceDesktopSearch]: (
    <IconDeviceDesktopSearch {...styles} />
  ),
  [ProjectIconType.clock]: <IconClock {...styles} />,
  [ProjectIconType.userSquareRounded]: <IconUserSquareRounded {...styles} />,
  [ProjectIconType.serverBolt]: <IconServerBolt {...styles} />,
  [ProjectIconType.thumbUp]: <IconThumbUp {...styles} />,
  [ProjectIconType.api]: <IconApi {...styles} />,
  [ProjectIconType.listCheck]: <IconListCheck {...styles} />,
  [ProjectIconType.squareCheck]: <IconSquareCheck {...styles} />,
  [ProjectIconType.progressCheck]: <IconProgressCheck {...styles} />,
  [ProjectIconType.flame]: <IconFlame {...styles} />,
  [ProjectIconType.currencyDollar]: <IconCurrencyDollar {...styles} />,
  [ProjectIconType.check]: <IconCheck {...styles} />,
  [ProjectIconType.questionMark]: <IconQuestionMark {...styles} />,
  [ProjectIconType.brandJavascript]: <IconBrandJavascript {...styles} />,
  [ProjectIconType.activity]: <IconActivity {...styles} />,
  [ProjectIconType.plus]: <IconPlus {...styles} />,
  [ProjectIconType.copy]: <IconCopy {...styles} />,
  [ProjectIconType.minus]: <IconMinus {...styles} />,
  [ProjectIconType.pointFilled]: <IconPointFilled {...styles} />,
  [ProjectIconType.exclamationMark]: <IconExclamationMark {...styles} />,
  [ProjectIconType.heart]: <IconHeart {...styles} />,
  [ProjectIconType.arrowNarrowUp]: <IconArrowNarrowUp {...styles} />,
  [ProjectIconType.folder]: <IconFolder {...styles} />,
  [ProjectIconType.squareRotated]: <IconSquareRotated {...styles} />,
};

type ProjectIconProps = {
  color?: string | null;
  icon?: ProjectIconType | null;
  name?: string | null;
} & Omit<AvatarProps, 'color'>;

/** Используется для отображения бокса с иконкой или первой буквы имени */
export const ProjectIcon = forwardRef<HTMLDivElement, ProjectIconProps>(
  ({ color, icon, name, ...props }, ref) => {
    return (
      <Avatar
        ref={ref}
        radius="sm"
        variant="filled"
        size={20}
        color={color || 'gray.5'}
        {...props}
      >
        <Text
          fz={'200%'}
          fw={400}
          tt={'uppercase'}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            lineHeight: 'normal',
          }}
        >
          {(isNumber(icon) && projectIconsMap[icon]) || getFirstChar(name)}
        </Text>
      </Avatar>
    );
  },
);

const getFirstChar = (value: string | null | undefined) =>
  String.fromCodePoint(value?.codePointAt(0) ?? 1);

import { useCallback } from 'react';

import {
  DndContext,
  DragEndEvent,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {
  Box,
  Checkbox,
  InputError,
  Overlay,
  Space,
  Stack,
  useComputedColorScheme,
} from '@mantine/core';
import { t } from 'i18next';

import { useEntityFormContext } from '../../hooks/useForm';
import { IStatusForm } from '../../types';
import { SortableStatus } from '../Dragging/SortableStatus';
import { StatusOverlay } from '../Dragging/StatusOverlay';
import { CreateStatus } from '../StatusRow/CreateStatus';

const labels = {
  project: t('entitySettings.statusSection.useProject'),
  folder: t('entitySettings.statusSection.useFolder'),
};

interface IProps {
  parentType?: 'project' | 'folder';
}

export const StatusesForm = ({ parentType }: IProps) => {
  const isLightTheme =
    useComputedColorScheme('light', {
      getInitialValueInEffect: true,
    }) === 'light';

  const form = useEntityFormContext();

  const statuses = form.getValues().statuses;
  const value = statuses.provide ? statuses.provided : statuses.current;

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 10,
    },
  });
  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      delay: 150,
      tolerance: 5,
    },
  });

  const sensors = useSensors(mouseSensor, touchSensor);

  const handleDargEnd = useCallback(
    (event: DragEndEvent) => {
      const dragItem = event.active.data.current as IStatusForm | null;
      const overItem = event.over?.data.current as IStatusForm | null;

      if (!dragItem || !overItem || dragItem.id === overItem.id) return;

      const dragIndex = value.findIndex((item) => item.id === dragItem.id);
      const overIndex = value.findIndex((item) => item.id === overItem.id);

      const reorderedList = arrayMove(
        value,
        dragIndex,
        overIndex,
      ).map<IStatusForm>((s, i) => ({
        ...s,
        state: ['new', 'removed'].includes(s.state) ? s.state : 'updated',
        order: i + 1,
      }));

      form.setFieldValue('statuses.current', reorderedList);
    },
    [form, value],
  );

  return (
    <Stack gap={24} onDragStart={(e) => e.preventDefault()}>
      {parentType && (
        <Checkbox
          radius="sm"
          label={labels[parentType]}
          {...form.getInputProps('statuses.provide', { type: 'checkbox' })}
        />
      )}
      <Box pos="relative">
        <DndContext sensors={sensors} onDragEnd={handleDargEnd}>
          <Stack gap={8}>
            <SortableContext
              items={value}
              strategy={verticalListSortingStrategy}
            >
              {value.map((el, idx) =>
                el.state === 'removed' ? null : (
                  <SortableStatus key={el.id} renderKey={idx} />
                ),
              )}
            </SortableContext>

            <InputError>{form.errors['statuses.current']}</InputError>
          </Stack>
          <StatusOverlay />
        </DndContext>

        <Space h={8} />
        <CreateStatus lastOrder={value.at(-1)?.order ?? 0} />

        {form.values.statuses.provide && (
          <Overlay
            radius={4}
            color={isLightTheme ? '#fff' : '#242424'}
            backgroundOpacity={0.75}
          />
        )}
      </Box>
    </Stack>
  );
};

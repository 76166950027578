import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { ApiAttachments } from '@/api/attachments/types';
import { useDeleteAttach } from '@/features/TaskPanel/hooks/useDeleteAttach';
import { ActionIcon, Flex, Text, Tooltip } from '@mantine/core';
import { IconDownload, IconTrash } from '@tabler/icons-react';

import { useAttachActions } from '../../../../hooks/useAttachActions';
import { useAttachIcon } from '../../../../hooks/useAttachIcon';

import styles from './ListAttachment.module.css';

export const ListAttachment = memo(
  ({
    attachment,
    onClick,
    readonly,
  }: {
    attachment: ApiAttachments.IAttachment;
    onClick: (
      v: ApiAttachments.IAttachment & { fileUrl: string | undefined },
    ) => void;
    readonly?: boolean;
  }) => {
    const { t } = useTranslation();

    const {
      handleDownload,
      isLoading: attachLoading,
      getFileUrl,
    } = useAttachActions(attachment);

    const {
      handleDelete,
      isLoading: deleteLoading,
      isSuccess: deleteSuccess,
    } = useDeleteAttach([attachment]);

    const icon = useAttachIcon(attachment.MimeType);

    const isBlocked = attachLoading || deleteLoading || deleteSuccess;

    const handleClick = async () => {
      if (isBlocked) return;

      const fileUrl = await getFileUrl();
      if (fileUrl) {
        onClick({ ...attachment, fileUrl });
      }
    };

    return (
      <Flex
        w={'100%'}
        gap={8}
        align={'center'}
        justify={'space-between'}
        style={{
          padding: '5px',
          borderRadius: '4px',
          background:
            'light-dark(var(--mantine-color-gray-1), var(--mantine-color-dark-5))',
        }}
      >
        <Flex gap={6} miw={0}>
          {icon}
          <Text
            miw={0}
            size="sm"
            c={
              attachment.Name
                ? 'light-dark(black, white)'
                : 'light-dark(var(--mantine-color-gray-6), var(--mantine-color-dark-4))'
            }
            className={styles.attachmentName}
            truncate="end"
            onClick={handleClick}
          >
            {attachment.Name || t('nodata.noname')}
          </Text>
        </Flex>

        <Flex gap={6}>
          <Tooltip
            label={t('common.action.download')}
            openDelay={200}
            keepMounted={false}
          >
            <ActionIcon
              size={20}
              variant="subtle"
              color={'dark'}
              onClick={handleDownload}
              disabled={isBlocked}
              loading={attachLoading}
            >
              <IconDownload size={16} />
            </ActionIcon>
          </Tooltip>
          {!readonly && (
            <Tooltip
              label={t('common.action.delete')}
              openDelay={200}
              keepMounted={false}
            >
              <ActionIcon
                size={20}
                variant="subtle"
                color={'dark'}
                className={styles.removeDoc}
                disabled={isBlocked}
                onClick={handleDelete}
                loading={deleteLoading}
              >
                <IconTrash size={16} />
              </ActionIcon>
            </Tooltip>
          )}
        </Flex>
      </Flex>
    );
  },
);

import { customBaseQuery } from '@/api/customBaseQuery';
import { ApiProjectTask, ApiTaskActivity } from '@/api/tasks/types';
import { PartialFields } from '@/types/fields';
import { createApi } from '@reduxjs/toolkit/query/react';
import { t } from 'i18next';
import { isEmpty, isNumber, uniq } from 'lodash-es';
import queryString from 'query-string';

import { showErrorNotification } from '../helpers/showNotifications';

const _projectTasksApi = createApi({
  reducerPath: 'projectTasksApi',
  baseQuery: customBaseQuery('/api'),
  tagTypes: ['Activity', 'Task', 'Project', 'Sprint', 'Performer'],
  endpoints: (builder) => ({
    getTasks: builder.query<ApiProjectTask.IGetResponse, IGetTasksPayload>({
      query: (params) => {
        const body: ApiProjectTask.IGetRequest = {
          ProjectIds: params.projectIds ?? [],
          SprintIds: params.sprintId ? [params.sprintId] : [],
          PerformerIds: params.performerIds,
          Like: params.like,
        };

        return { url: `/v1/ProjectTask/Items`, method: 'POST', body };
      },
      providesTags: (response, _, args) => {
        if (!response) return [];

        const projectIds = uniq(
          [
            ...response.map((t) => t.ProjectId),
            ...(args.projectIds ?? []),
          ].filter(isNumber),
        );
        const sprintIds = uniq(
          [...response.map((t) => t.SprintId), args.sprintId].filter(isNumber),
        );
        const performerIds = uniq(
          [
            ...response.map((t) => t.PerformerId),
            ...(args.performerIds ?? []),
          ].filter(isNumber),
        );

        return [
          ...response.map((t) => ({ type: 'Task' as const, id: t.Id })),
          ...projectIds.map((id) => ({ type: 'Project' as const, id })),
          ...sprintIds.map((id) => ({ type: 'Sprint' as const, id })),
          ...performerIds.map((id) => ({ type: 'Performer' as const, id })),
        ];
      },
    }),
    getTaskById: builder.query<ApiProjectTask.ITask, number | string>({
      query: (id) => ({ url: `/v1/ProjectTask?id=${id}`, method: 'GET' }),
      providesTags: (res) => (res ? [{ type: 'Task', id: res.Id }] : []),
    }),
    createTask: builder.mutation<
      ApiProjectTask.ICreateResponse,
      ApiProjectTask.ICreateRequest
    >({
      query: (params) => ({
        url: `/v1/ProjectTask`,
        method: 'POST',
        body: params,
      }),
      invalidatesTags: (res) => {
        if (!res) return [];

        return [
          { type: 'Task' as const, id: res.Id },
          { type: 'Project' as const, id: res.ProjectId },
          ...(isNumber(res.SprintId)
            ? [{ type: 'Sprint' as const, id: res.SprintId }]
            : []),
          ...(isNumber(res.PerformerId)
            ? [{ type: 'Performer' as const, id: res.PerformerId }]
            : []),
        ];
      },
    }),
    updateTask: builder.mutation<
      ApiProjectTask.IUpdateResponse,
      ApiProjectTask.IUpdateRequest
    >({
      query: (params) => ({
        url: `/v1/ProjectTask`,
        method: 'POST',
        body: params,
      }),
      invalidatesTags: (res) => {
        if (!res) return [];

        return [
          { type: 'Task' as const, id: res.Id },
          { type: 'Project' as const, id: res.ProjectId },
          ...(isNumber(res.SprintId)
            ? [{ type: 'Sprint' as const, id: res.SprintId }]
            : []),
          ...(isNumber(res.PerformerId)
            ? [{ type: 'Performer' as const, id: res.PerformerId }]
            : []),
        ];
      },
      async onQueryStarted(
        { Id, ...props },
        { dispatch, queryFulfilled, getState },
      ) {
        if (isEmpty(props)) return;

        const patchResult = [];
        for (const {
          endpointName,
          originalArgs,
        } of projectTasksApi.util.selectInvalidatedBy(getState(), [
          { type: 'Task', id: Id },
        ])) {
          if (endpointName !== 'getTasks') continue;
          patchResult.push(
            dispatch(
              projectTasksApi.util.updateQueryData(
                'getTasks',
                originalArgs!,
                (draft) => {
                  const item = draft.find((el) => el.Id === Id);

                  if (item && props.Order) {
                    patchOrder(draft, {
                      id: Id,
                      status: item?.Status,
                      order: props.Order,
                    });
                  }

                  if (item) Object.assign(item, props);
                },
              ),
            ),
          );
        }

        const patchResultTask = dispatch(
          projectTasksApi.util.updateQueryData(
            'getTaskById',
            Id.toString(),
            (draft) => {
              Object.assign(draft, props);
            },
          ),
        );

        try {
          await queryFulfilled;
        } catch (err) {
          showErrorNotification({
            message: 'Не удалось изменить задачу',
          });
          if (patchResult.length) {
            patchResult.forEach((result) => result.undo());
          }
          patchResultTask.undo();
        }
      },
    }),
    deleteTask: builder.mutation<void, number>({
      query: (taskId) => ({
        url: `/v1/ProjectTask?Id=${taskId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, __, id) => [{ type: 'Task', id }],
    }),
    updateOrder: builder.mutation<void, ApiProjectTask.IUpdateOrderRequest>({
      query: (params) => ({
        url: `/v1/ProjectTask/ChangeOrder?${queryString.stringify(params)}`,
        method: 'PUT',
      }),
      async onQueryStarted(
        { id, order },
        { dispatch, queryFulfilled, getState },
      ) {
        const patchResult = [];
        for (const {
          endpointName,
          originalArgs,
        } of projectTasksApi.util.selectInvalidatedBy(getState(), [
          { type: 'Task', id: id },
        ])) {
          if (endpointName !== 'getTasks') continue;
          patchResult.push(
            dispatch(
              projectTasksApi.util.updateQueryData(
                'getTasks',
                originalArgs!,
                (draft) => {
                  const item = draft.find((el) => el.Id === id);

                  if (item) {
                    patchOrder(draft, { id, status: item?.Status, order });
                    item.Order = order;
                  }
                },
              ),
            ),
          );
        }

        try {
          await queryFulfilled;
        } catch (err) {
          showErrorNotification({
            message: t('notification.error.editTasksOrder'),
          });
          if (patchResult.length) {
            patchResult.forEach((result) => result.undo());
          }
        }
      },
    }),
  }),
});

// With Activities
export const projectTasksApi = _projectTasksApi.injectEndpoints({
  endpoints: (builder) => ({
    getActivities: builder.query<
      ApiTaskActivity.IActivity[],
      IGetActivitiesPayload
    >({
      query: (params) => {
        const body: ApiTaskActivity.IGetListRequest = {
          TaskId: params.taskId,
          Types: params.types,
        };

        return { url: `/v1/ProjectTask/Activities`, method: 'POST', body };
      },
      providesTags: (res, _, params) => {
        if (!res) return [];

        return [
          ...res.map((el) => ({ type: 'Activity' as const, id: el.Id })),
          { type: 'Task', id: params.taskId },
        ];
      },
    }),
    createComment: builder.mutation<
      ApiTaskActivity.IActivity,
      ICreateCommentPayload
    >({
      query: (params) => {
        const body: ApiTaskActivity.ICreateItemRequest = {
          TaskId: params.taskId,
          ProjectId: params.projectId,
          Description: params.message,
          Type: ApiTaskActivity.IActivityType.Comment,
        };

        return { url: `/v1/ProjectTask/Activity`, method: 'POST', body };
      },
      invalidatesTags: (res) => {
        if (!res) return [];

        return [{ type: 'Task', id: res.TaskId }];
      },
    }),
    editComment: builder.mutation<
      ApiTaskActivity.IActivity,
      ICreateCommentPayload & { id: number }
    >({
      query: (params) => {
        const body: ApiTaskActivity.ICreateItemRequest = {
          Id: params.id,
          TaskId: params.taskId,
          ProjectId: params.projectId,
          Description: params.message,
          Type: ApiTaskActivity.IActivityType.Comment,
        };

        return { url: `/v1/ProjectTask/Activity`, method: 'POST', body };
      },
      invalidatesTags: (res) => {
        if (!res) return [];

        return [{ type: 'Activity', id: res.Id }];
      },
    }),
    deleteComment: builder.mutation<void, number>({
      query: (id) => {
        const body = { Ids: [id] };

        return { url: `/v1/ProjectTask/Activity`, method: 'DELETE', body };
      },
      invalidatesTags: (_, __, id) => {
        return [{ type: 'Activity', id }];
      },
    }),

    editAttribute: builder.mutation<
      void,
      PartialFields<ApiProjectTask.IAttribute, 'Id'>
    >({
      query: (body) => ({
        url: `/v1/ProjectTask/Attribute`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (_, __, args) => {
        return [{ type: 'Task', id: args.TaskId }];
      },
    }),
  }),
  overrideExisting: false,
});

interface IGetTasksPayload {
  projectIds: number[] | null;
  sprintId: number | null;
  performerIds: number[] | null;
  like?: string;
}

interface IGetActivitiesPayload {
  taskId: number;
  types?: ApiTaskActivity.IActivityType[];
}

interface ICreateCommentPayload {
  taskId: number;
  projectId: number;
  message: string;
}

const patchOrder = (
  draft: ApiProjectTask.IGetResponse,
  item: { id: number; status: number | undefined; order: number },
) => {
  draft.forEach((el) => {
    if (el.Id !== item.id && el.Order >= item.order) el.Order++;
  });
};

import {
  PropsWithChildren,
  RefObject,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Group, Stack, Textarea } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { useHotkeys } from '@mantine/hooks';
import { isEmpty } from 'lodash-es';
import { z } from 'zod';

import styles from './EditCommentForm.module.css';

interface IProps {
  anchor?: RefObject<HTMLDivElement>;
  editing?: boolean;
  onEditing: (v: boolean) => void;
  loading?: boolean;
  onChange?: (value: string) => void;
  defaultValue?: string;
}

export const EditCommentForm = ({
  anchor,
  editing = true,
  onEditing,
  loading,
  onChange,
  defaultValue,
}: PropsWithChildren<IProps>) => {
  const { t } = useTranslation();

  const form = useForm({
    initialValues: { message: defaultValue || '' },
    validateInputOnChange: true,

    validate: zodResolver(
      z.object({
        message: z
          .string()
          .trim()
          .min(1, t('activities.minComment', { count: 1 }))
          .max(1024, t('activities.maxComment', { count: 1024 })),
      }),
    ),
  });

  const ref = useRef<HTMLTextAreaElement>(null);
  const formRef = useRef<HTMLFormElement>(null);

  const isEdit = !!defaultValue;

  const handleCancel = useCallback(() => {
    form.reset();
    onEditing(false);
  }, [form, onEditing]);
  const handleChange = useCallback(
    (values: typeof form.values) => {
      if (loading) return;
      onChange?.(values.message);
      handleCancel();
    },
    [form, loading, handleCancel, onChange],
  );

  useEffect(() => {
    if (editing && ref.current) {
      const value = form.getInputProps('message')?.value;
      const position = value?.toString().length ?? 0;

      ref.current.selectionStart = position;
      ref.current.focus({ preventScroll: true });

      anchor?.current?.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editing]);

  useHotkeys([['mod + Enter', () => formRef?.current?.requestSubmit()]], []);

  return (
    <form
      ref={formRef}
      onSubmit={form.onSubmit(handleChange)}
      style={{ width: '100%' }}
    >
      <Stack
        w="100%"
        gap={8}
        data-active={editing}
        data-sticky={!!anchor}
        data-animate={!isEdit}
        className={styles.stack}
      >
        <Textarea
          ref={ref}
          placeholder={
            form.isDirty()
              ? t('activities.draftComment')
              : t('activities.commentPlaceholder')
          }
          onFocus={() => onEditing(true)}
          autosize={editing}
          classNames={{ input: styles.textarea }}
          {...form.getInputProps('message', { withFocus: false })}
          {...(!editing ? { value: '' } : {})}
        />
        <Group gap={8} justify="end">
          <Button
            variant="subtle"
            color="dark"
            size="sm"
            onClick={handleCancel}
          >
            {t('common.action.cancel')}
          </Button>
          <Button
            size="sm"
            type="submit"
            loading={loading}
            disabled={!isEmpty(form.errors)}
          >
            {t(`common.action.${isEdit ? 'save' : 'add'}`)}
          </Button>
        </Group>
      </Stack>
    </form>
  );
};

import { DragOverlay, useDndContext } from '@dnd-kit/core';
import { Paper } from '@mantine/core';

export const DraggableOverlay = () => {
  const { active } = useDndContext();

  return (
    <DragOverlay dropAnimation={null}>
      {active ? (
        <Paper
          px={6}
          py={4}
          shadow="lg"
          withBorder
          style={{
            cursor: 'grab',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
          maw={160}
        >
          {active.data.current?.Title}
        </Paper>
      ) : null}
    </DragOverlay>
  );
};

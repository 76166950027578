import { ProjectAccessProvider } from '@/hooks/useAccess';
import { useNumberQueryParam } from '@/hooks/useNumberQueryParam';

import { Board } from './components/Board/Board';
import { NoFolder } from './components/NoFolder';

export const FolderPage = () => {
  const projectId = useNumberQueryParam('folderId');

  if (!projectId) {
    return <NoFolder />;
  }

  return (
    <ProjectAccessProvider projectId={projectId}>
      <Board projectId={projectId} />
    </ProjectAccessProvider>
  );
};

import { useMemo } from 'react';

import { ProjectType } from '../types';

type ProjectSprintsProps<P extends ProjectType> = {
  projects?: P[];
  searchString?: string;
};

export const useProjectSprints = <P extends ProjectType>({
  projects,
  searchString = '',
}: ProjectSprintsProps<P>) => {
  const filteredProjects = useMemo(() => {
    const hasSprints = (p: ProjectType) => {
      return p.Sprints.length > 0 || p.Children.some(hasSprints);
    };

    const filterSprints = (p: ProjectType): ProjectType => {
      return {
        ...p,
        Sprints: p.Sprints?.filter((s) =>
          s.Name.toLowerCase().includes(searchString.toLowerCase()),
        ),
        Children: p.Children?.map((p) => filterSprints(p)).filter(hasSprints),
      };
    };

    const filtered = projects?.map(filterSprints).filter(hasSprints);

    return filtered;
  }, [projects, searchString]);

  const hasResults = useMemo(
    () =>
      filteredProjects?.some(
        (p) => p.Sprints.length > 0 || p.Children.length > 0,
      ),
    [filteredProjects],
  );

  return {
    hasResults,
    filteredProjects,
  };
};

import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { store } from '@/app/store';
import { openCreateProjectModal } from '@/features/EditEntityModals';
import { ActionIcon, Group, Text, Tooltip } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { IconPlus } from '@tabler/icons-react';

import { layoutSlice } from '../../../../AppLayout/slice';

const close = () => store.dispatch(layoutSlice.actions.closeSidebar());

export const NavHeader = ({
  label,
  showAdd,
}: {
  label: ReactNode;
  showAdd?: boolean;
}) => {
  const { t } = useTranslation();
  const isBurger = useMediaQuery('(max-width: 1200px)');

  return (
    <Group justify="space-between" mt={16} mb={8} gap={8} align="center">
      <Text size="xs" c="dimmed" tt={'uppercase'} pl={8}>
        {label}
      </Text>
      {showAdd && (
        <Tooltip label={t('sidebar.addProject')} withArrow position="right">
          <ActionIcon
            mr={8}
            variant="subtle"
            color={'dark'}
            onClick={() => {
              isBurger && close();
              openCreateProjectModal();
            }}
          >
            <IconPlus size={16} color="var(--mantine-color-gray-5)" />
          </ActionIcon>
        </Tooltip>
      )}
    </Group>
  );
};

import { memo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { ApiProjectTask } from '@/api/tasks';
import { store, useAppSelector } from '@/app/store';
import { Button, InputLabel, Modal, Stack, TextInput } from '@mantine/core';
import { useHotkeys } from '@mantine/hooks';

import { CreateTaskFormData, useCreateTask } from '../../hooks/useCreateTask';
import { PerformerSelectField } from './components/PerformerSelectField';
import { PrioritySelectField } from './components/PrioritySelectField';
import { SprintSelectField } from './components/SprintSelectField';
import { StatusSelectField } from './components/StatusSelectField';
import { TypeSelectField } from './components/TypeSelectField';
import { createTaskModalSlice } from './slice';

const close = () => store.dispatch(createTaskModalSlice.actions.close());

const Content = ({
  defaultValues,
  afterCreate,
  allowedProjectIds,
}: {
  defaultValues?: Partial<CreateTaskFormData>;
  afterCreate?: (res: ApiProjectTask.ITask) => void;
  allowedProjectIds?: number[];
}) => {
  const { t } = useTranslation();

  const formRef = useRef<HTMLFormElement>(null);

  const { handleAdd, form, isLoading } = useCreateTask({
    defaultValues,
    afterCreate: (res) => {
      close();
      afterCreate?.(res);
    },
  });

  useHotkeys([['mod + Enter', () => formRef?.current?.requestSubmit()]], []);

  return (
    <form ref={formRef} onSubmit={form.onSubmit(handleAdd)}>
      <Stack gap={24} style={{ flex: 1 }} justify="flex-start">
        <SprintSelectField form={form} allowedProjectIds={allowedProjectIds} />

        <TypeSelectField form={form} />

        <StatusSelectField form={form} />

        <div>
          <InputLabel required size="sm" mb={4}>
            {t('createTask.fields.title.label')}
          </InputLabel>
          <TextInput
            data-autofocus={defaultValues?.sprint}
            variant="filled"
            w={'100%'}
            size="md"
            placeholder={t('createTask.fields.title.placeholder')}
            maxLength={500}
            {...form.getInputProps('Title')}
          />
        </div>

        <PerformerSelectField form={form} />

        <PrioritySelectField form={form} />

        <Button
          variant="filled"
          fullWidth
          h={46}
          color="blue"
          type="submit"
          loading={isLoading}
          size="md"
          radius={4}
        >
          {t('createTask.action.create')}
        </Button>
      </Stack>
    </form>
  );
};

export const CreateTaskModal = memo(() => {
  const { t } = useTranslation();

  const opened = useAppSelector((s) => s.createTaskModal.opened);
  const modal = useAppSelector((s) => s.createTaskModal);

  return (
    <Modal
      size={'lg'}
      opened={opened}
      onClose={close}
      title={t('createTask.title')}
    >
      <Content
        defaultValues={modal.defaultValues}
        afterCreate={modal.afterCreate}
        allowedProjectIds={modal.allowedProjectIds}
      />
    </Modal>
  );
});

import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { showRequestNotifications } from '@/api/helpers/showNotifications';
import { ApiProjectTask, ApiTaskActivity, projectTasksApi } from '@/api/tasks';
import { store, useAppSelector } from '@/app/store';
import { Comment } from '@/components/ui/Comment/Comment';
import { NoData } from '@/components/ui/NoData/NoData';
import { openRemoveConfirmModal } from '@/components/ui/confirmModals/RemoveConfirmModal';
import { useTaskContext } from '@/features/TaskPanel/context/taskContext';
import { useAccess } from '@/hooks/useAccess';
import { Stack } from '@mantine/core';
import { t } from 'i18next';

import { useActivityData } from '../hooks/useActivityData';
import CommentsLoading from './CommentsLoading';
import { CreateComment } from './CreateComment/CreateComment';

interface IProps {
  task: ApiProjectTask.ITask;
}

export const Comments = ({ task }: IProps) => {
  const { t } = useTranslation();

  const { setDescriptionEdit, setCommentsEdit, commentsEdit } =
    useTaskContext();

  const edit = commentsEdit;
  const setEdit = (v: number | null | 'new') => {
    if (v !== null) setDescriptionEdit(false);
    setCommentsEdit(v);
  };

  const access = useAccess();

  const currentUser = useAppSelector((s) => s.usersCache.currentUser);

  const [
    editComment,
    { isLoading: isEditLoading, originalArgs: originalArgs },
  ] = projectTasksApi.useEditCommentMutation();

  const { data, isLoading } = useActivityData(task.Id, [
    ApiTaskActivity.IActivityType.Comment,
  ]);

  const handleEdit = useCallback(
    (id: number, message: string) => {
      editComment({
        id,
        projectId: task.ProjectId,
        taskId: task.Id,
        message: message,
      });
    },
    [task.Id, task.ProjectId, editComment],
  );

  if (isLoading) return <CommentsLoading />;

  return (
    <>
      <CreateComment
        task={task}
        creation={edit === 'new'}
        setCreation={setEdit}
      />
      {data.length > 0 ? (
        <Stack gap={12}>
          {data.map((el) => (
            <Comment
              key={el.Id}
              task={task}
              activity={el}
              currentUserId={currentUser.Id}
              loading={originalArgs?.id === el.Id ? isEditLoading : undefined}
              onDelete={access.comments.delete ? openRemoveModal : undefined}
              onChange={access.comments.edit ? handleEdit : undefined}
              editing={el.Id === edit}
              onCancel={setEdit}
              onEdit={setEdit}
            />
          ))}
        </Stack>
      ) : (
        <NoData
          description={t('nodata.noActivity.noComments')}
          illustration="noComments"
          size={120}
          p={20}
        />
      )}
    </>
  );
};

const openRemoveModal = (id: number) =>
  openRemoveConfirmModal({
    title: t('removeComment.title'),
    children: t('removeComment.description'),
    onConfirm: () => {
      const subscribe = store
        .dispatch(projectTasksApi.endpoints.deleteComment.initiate(id))
        .unwrap();

      showRequestNotifications(subscribe, {
        successMsg: t('notification.success.deleteComment'),
        errorMsg: t('notification.error.deleteComment'),
      });
    },
  });

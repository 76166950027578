import { ReactNode } from 'react';

import { Alert, AlertProps } from '@mantine/core';
import { IconBug } from '@tabler/icons-react';

type Props = { message: ReactNode } & AlertProps;

export const ErrorAlert = ({ message, ...others }: Props) => {
  return (
    <Alert variant="light" color="red" icon={<IconBug size={18} />} {...others}>
      {message || ''}
    </Alert>
  );
};

import { useTranslation } from 'react-i18next';

import { ApiProjectTask } from '@/api/tasks';
import { MoveTaskButton } from '@/features/MoveTaskButton/MoveTaskButton';
import { removeTask } from '@/helpers/removeTask';
import { useAccess } from '@/hooks/useAccess';
import { ActionIcon, Group, Menu, Paper } from '@mantine/core';
import { IconDotsVertical, IconPencil, IconTrash } from '@tabler/icons-react';

interface IProps {
  opened: boolean;
  hidden?: boolean;
  task: ApiProjectTask.ITask;
  onRename?: VoidFunction;
  onOpenChange: (v: boolean) => void;
}

export const TaskActions = ({
  opened,
  hidden,
  task,
  onRename,
  onOpenChange,
}: IProps) => {
  const { t } = useTranslation();
  const access = useAccess();

  if (!access.tasks.edit && !access.tasks.delete) return null;

  return (
    <Paper pos="absolute" top={2} right={2} p={2} opacity={Number(!hidden)}>
      <Group gap={4}>
        {access.tasks.edit && (
          <ActionIcon
            size={24}
            variant="subtle"
            color="gray.7"
            onClick={(e) => {
              e.stopPropagation();
              onRename?.();
              onOpenChange(false);
            }}
          >
            <IconPencil size={18} />
          </ActionIcon>
        )}

        <Menu
          opened={opened}
          closeOnItemClick={false}
          keepMounted={false}
          withinPortal={false}
          onChange={onOpenChange}
          position="bottom-end"
        >
          <Menu.Target>
            <ActionIcon
              size={24}
              variant="subtle"
              color="gray.7"
              onClick={(e) => e.stopPropagation()}
            >
              <IconDotsVertical size={18} />
            </ActionIcon>
          </Menu.Target>
          <Menu.Dropdown onClick={(e) => e.stopPropagation()}>
            {access.tasks.edit && <MoveTaskButton task={task} />}
            {access.tasks.edit && access.tasks.delete && <Menu.Divider />}
            {access.tasks.delete && (
              <Menu.Item
                leftSection={<IconTrash size={18} />}
                color="red.8"
                onClick={() => {
                  removeTask(task);
                  onOpenChange(false);
                }}
              >
                {t('common.action.delete')}
              </Menu.Item>
            )}
          </Menu.Dropdown>
        </Menu>
      </Group>
    </Paper>
  );
};

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { projectsApi } from '@/api/projects';
import { loginReturnUrl } from '@/app/routes/ProtectedRoute';
import { MainContentLoader } from '@/components/shared/SkeletonLoaders/MainContentLoader';
import { redirectToProject } from '@/helpers/redirect';

import { EmptyState } from './components/EmptyState/EmptyState';

export const RedirectToFirstProject = () => {
  const navigate = useNavigate();

  const [ready, setReady] = useState(false);

  const { data, isSuccess, isLoading } =
    projectsApi.endpoints.getProjects.useQueryState();

  useEffect(() => {
    const returnUrl = localStorage.getItem(loginReturnUrl);

    if (returnUrl) {
      localStorage.removeItem(loginReturnUrl);
      navigate(decodeURIComponent(returnUrl), { replace: true });
      return;
    }

    if (isSuccess && data.list.length > 0) {
      const id = data.list.at(0)?.Id;
      id && redirectToProject(id);
    }

    setReady(true);
  }, [data, isSuccess, navigate]);

  if (isLoading || !ready) {
    return <MainContentLoader />;
  }

  return <EmptyState />;
};

import { memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { projectsApi } from '@/api/projects';
import { useAppSelector } from '@/app/store';
import { ProjectAccessProvider } from '@/hooks/useAccess';
import { useOpenedFolderData } from '@/hooks/useOpenedFolderData';
import { useOpenedSprintData } from '@/hooks/useOpenedSprintData';
import { Group, Skeleton, Stack } from '@mantine/core';
import { orderBy, uniq } from 'lodash-es';

import { AddProject } from './components/AddProject';
import { NavHeader } from './components/NavHeader';
import { RecursiveLink } from './components/RecursiveLink';
import { SkeletonNavLoader } from './components/SkeletonNavLoader';

export const Navigation = memo(() => {
  const { t } = useTranslation();
  const currentUserId = useAppSelector((s) => s.usersCache.currentUser.Id);
  const { data, isLoading } = projectsApi.endpoints.getProjects.useQueryState();
  const skeleton = useAppSelector((s) => s.devSlice.skeletons.navigation);
  const [opened, setOpened] = useState<number[]>([]);

  const openedFolderData = useOpenedFolderData();
  const openedSprintData = useOpenedSprintData();

  const myProjects = useMemo(
    () =>
      orderBy(
        data?.list.filter((p) => p.OwnerId === currentUserId),
        'Order',
      ),
    [currentUserId, data?.list],
  );
  const otherProjects = useMemo(
    () =>
      orderBy(
        data?.list.filter((p) => p.OwnerId !== currentUserId),
        'Order',
      ),
    [currentUserId, data?.list],
  );

  useEffect(() => {
    if (openedFolderData) {
      setOpened((prev) => uniq([...prev, ...openedFolderData.pathById]));
    }
  }, [openedFolderData]);

  useEffect(() => {
    if (openedSprintData) {
      setOpened((prev) =>
        uniq([
          ...prev,
          ...(data
            ? data.folderEntities[openedSprintData.ProjectId].pathById
            : []),
        ]),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openedSprintData]);

  if (isLoading || skeleton) {
    return (
      <Stack gap="0" maw={300} px={10}>
        <Group
          justify="space-between"
          gap="lg"
          preventGrowOverflow={true}
          mt={16}
          mb={6}
          wrap={'nowrap'}
          mih={'30px'}
        >
          <Skeleton visible={true} height={10} width="45%" />
          <Skeleton visible={true} height={10} width="10px" />
        </Group>
        <SkeletonNavLoader />
      </Stack>
    );
  }

  return (
    <Stack gap="0" maw={300} px={10}>
      <NavHeader label={t('sidebar.myProjects')} showAdd />
      {myProjects.map((project) => (
        <ProjectAccessProvider projectId={project.Id} key={project.Id}>
          <RecursiveLink
            project={project}
            type="project"
            opened={opened}
            setOpened={setOpened}
            depth={0}
          />
        </ProjectAccessProvider>
      ))}
      <AddProject />

      {!!otherProjects.length && (
        <>
          <NavHeader label={t('sidebar.otherProjects')} />
          {otherProjects.map((project) => (
            <ProjectAccessProvider projectId={project.Id} key={project.Id}>
              <RecursiveLink
                project={project}
                type="project"
                opened={opened}
                setOpened={setOpened}
                depth={0}
              />
            </ProjectAccessProvider>
          ))}
        </>
      )}
    </Stack>
  );
});

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Shortcut } from '@/components/ui/Shorcut';
import { Group, Modal, Text, useComputedColorScheme } from '@mantine/core';

interface ModalShortcutProps {
  opened: boolean;
  onClose: () => void;
}

const ModalShortcut: React.FC<ModalShortcutProps> = ({ opened, onClose }) => {
  const { t } = useTranslation();

  const shortcutsData = useShortcutsData();

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title={t('userMenu.shortcuts')}
      size="md"
    >
      <div>
        {shortcutsData.map((section, index) => (
          <div key={index}>
            <Text fw={500} fz={15} my={8}>
              {section.category}
            </Text>
            {section.shortcuts.map((shortcut, idx) => (
              <Group
                justify="space-between"
                wrap="nowrap"
                gap="xl"
                key={idx}
                style={{ marginTop: idx !== 0 ? '10px' : '0' }}
              >
                <Text fz={13}>{shortcut.description}</Text>
                <Shortcut>{shortcut.keys}</Shortcut>
              </Group>
            ))}
          </div>
        ))}
      </div>
    </Modal>
  );
};

export const modKey =
  navigator.platform.toUpperCase().indexOf('MAC') >= 0 ? '⌘' : 'Ctrl';

const useShortcutsData = () => {
  const { t } = useTranslation();
  const isLightTheme =
    useComputedColorScheme('light', { getInitialValueInEffect: true }) ===
    'light';

  return [
    {
      category: t('shortcuts.common.title'),
      shortcuts: [
        { description: t('shortcuts.common.submit'), keys: `${modKey} + ↩` },
        {
          description: t('shortcuts.common.theme', {
            context: isLightTheme ? 'dark' : 'light',
          }),
          keys: `${modKey} + E`,
        },
      ],
    },
    {
      category: t('shortcuts.task.title'),
      shortcuts: [
        { description: t('shortcuts.task.copyLink'), keys: `${modKey} + I` },
        { description: t('shortcuts.task.copyKey'), keys: `Q` },
        { description: t('shortcuts.task.assignYourself'), keys: `M` },
      ],
    },
    {
      category: t('shortcuts.editor.title'),
      shortcuts: [
        {
          description: t('shortcuts.editor.pasteImage'),
          keys: `${modKey} + V`,
        },
      ],
    },
  ];
};

export default ModalShortcut;

import { showRequestNotifications } from '@/api/helpers/showNotifications';
import { tasksLinksApi } from '@/api/links';
import { ApiProjectTask, projectTasksApi } from '@/api/tasks';
import { store } from '@/app/store';
import { openRemoveConfirmModal } from '@/components/ui/confirmModals/RemoveConfirmModal';
import { Text } from '@mantine/core';
import { t } from 'i18next';

export const removeTask = (
  task: ApiProjectTask.ITask,
  onRemove?: () => void,
) => {
  openRemoveConfirmModal({
    title: t('removeTaskModal.title'),
    children: (
      <Text
        dangerouslySetInnerHTML={{
          __html: t('removeTaskModal.description', { title: task.Title }),
        }}
      />
    ),
    onConfirm: () => {
      onRemove?.();

      const request = store
        .dispatch(projectTasksApi.endpoints.deleteTask.initiate(task.Id))
        .unwrap();

      showRequestNotifications(request, {
        successMsg: t('notification.success.deleteTask'),
        errorMsg: t('notification.error.deleteTask'),
      }).then(() => {
        store.dispatch(
          tasksLinksApi.util.invalidateTags([
            {
              type: 'Task',
              id: task.Id,
            },
          ]),
        );
      });
    },
  });
};

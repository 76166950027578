import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TaskTypeBadge } from '@/components/ui/badges/TaskTypeBadge';
import { EmptyTypes } from '@/components/ui/selects/EmptyTypes';
import { CreateTaskFormData, emptyNumberValue } from '@/hooks/useCreateTask';
import { useTaskTypesData } from '@/hooks/useTaskTypesData';
import { Combobox, Text } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';

import { SelectField } from './SelectField';

type SelectProps = {
  form: UseFormReturnType<CreateTaskFormData>;
};

export const TypeSelectField = ({ form }: SelectProps) => {
  const { t } = useTranslation();

  const { taskTypes, isFetching } = useTaskTypesData(
    form.values.sprint?.rootProjectId,
  );

  useEffect(() => {
    // reset task type after change sprint
    if (isFetching) return;
    if (form.values.TypeId === emptyNumberValue && taskTypes.length > 0) {
      const id = taskTypes.at(0)?.Id;
      id && form.setFieldValue('TypeId', id);
    }
  }, [form, taskTypes, isFetching]);

  const value = form.values.TypeId;

  const typeValue = useMemo(
    () => taskTypes.find((s) => s.Id === value),
    [taskTypes, value],
  );

  const options = useMemo(
    () =>
      taskTypes.length > 0 ? (
        taskTypes.map((type) => (
          <Combobox.Option key={type.Id} value={type.Id.toString()}>
            <TaskTypeBadge type={type} />
          </Combobox.Option>
        ))
      ) : (
        <EmptyTypes
          message={
            !form.values.sprint
              ? t('createTask.fields.type.description')
              : undefined
          }
        />
      ),
    [form.values.sprint, taskTypes, t],
  );

  const error = form.errors['TypeId'];
  const hasError = !!error;

  return (
    <SelectField
      loading={isFetching}
      value={
        value && value !== emptyNumberValue ? (
          <TaskTypeBadge type={typeValue} />
        ) : (
          <Text
            c={
              hasError
                ? 'red.6'
                : 'light-dark(var(--mantine-color-gray-5), var(--mantine-color-dark-3))'
            }
          >
            {t('createTask.fields.type.placeholder')}
          </Text>
        )
      }
      required
      label={t('createTask.fields.type.label')}
      error={error}
      options={options}
      onChange={(val) => form.setFieldValue('TypeId', Number(val))}
    />
  );
};

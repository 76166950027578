import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface ILayoutState {
  sidebarOpened: boolean;
  withoutSidebar: boolean;
}

const initialState: ILayoutState = {
  sidebarOpened: false,
  withoutSidebar: false,
};

export const layoutSlice = createSlice({
  name: 'layoutSlice',
  initialState,
  reducers: {
    initSidebar(state) {
      state.sidebarOpened = window.matchMedia('(min-width: 1200px)').matches;
    },
    openSidebar(state) {
      state.sidebarOpened = true;
    },
    closeSidebar(state) {
      state.sidebarOpened = false;
    },
    toggleSidebar(state) {
      state.sidebarOpened = !state.sidebarOpened;
    },
    setWithoutSidebar(state, action: PayloadAction<boolean>) {
      state.withoutSidebar = action.payload;
    },
  },
});

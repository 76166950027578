import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { store, useAppSelector } from '@/app/store';
import { TaskStatusBadge } from '@/components/ui/badges/TaskStatusBadge/TaskStatusBadge';
import { TaskStatusSelect } from '@/components/ui/selects/TaskStatusSelect';
import { useSprintData } from '@/hooks/useSprintData';
import { useStatusesData } from '@/hooks/useStatusesData';
import { Box, Button, Flex, Grid, Modal, Stack, Text } from '@mantine/core';
import { IconArrowRight } from '@tabler/icons-react';

import { updateTaskSprint } from '../../helpers/moveTask';
import { diffStatusesModalSlice } from './slice';

const close = () => store.dispatch(diffStatusesModalSlice.actions.close());

const Content = () => {
  const { t } = useTranslation();

  const { task, sprintId } = useAppSelector((s) => s.diffStatusesModal);

  const currentSprint = useSprintData(task?.SprintId);
  const newSprint = useSprintData(sprintId);

  const { statuses: currentStatuses } = useStatusesData(currentSprint);
  const { statuses: newStatuses } = useStatusesData(newSprint);

  const statusValue = useMemo(
    () => currentStatuses?.find((s) => s.Id === task?.Status),
    [currentStatuses, task?.Status],
  );

  const [status, setStatus] = useState<number | null>(null);

  const handleUpdate = () => {
    if (!task || !newSprint || !status) return;
    updateTaskSprint({
      task,
      sprint: newSprint,
      status: status,
    });
    close();
  };

  return (
    <>
      <Text>{t('diffStatus.description')}</Text>

      <Box flex={1} py={32}>
        <Grid>
          <Grid.Col span={{ base: 12, md: 6 }}>
            <Stack gap={16}>
              <Text fw={'bold'}>{t('diffStatus.current')}</Text>
              <Flex gap={8} justify={'space-between'}>
                <TaskStatusBadge status={statusValue} />
                <IconArrowRight
                  color={'var(--mantine-color-gray-5)'}
                  size={20}
                />
              </Flex>
            </Stack>
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6 }}>
            <Stack gap={16}>
              <Text fw={'bold'}>{t('diffStatus.new')}</Text>
              <Flex>
                <TaskStatusSelect
                  placeholder={t('diffStatus.choose')}
                  value={status}
                  onChange={(s) => setStatus(s.Id)}
                  statuses={newStatuses || []}
                />
              </Flex>
            </Stack>
          </Grid.Col>
        </Grid>
      </Box>
      <Flex gap={16}>
        <Button
          size="md"
          fullWidth
          radius={'md'}
          variant="subtle"
          color="dark.3"
          onClick={close}
        >
          {t('common.action.cancel')}
        </Button>
        <Button
          size="md"
          fullWidth
          radius={'md'}
          onClick={handleUpdate}
          disabled={!status}
        >
          {t('common.action.save')}
        </Button>
      </Flex>
    </>
  );
};

export const DiffStatusesModal = () => {
  const { t } = useTranslation();

  const opened = useAppSelector((s) => s.diffStatusesModal.opened);

  return (
    <Modal
      size="lg"
      opened={opened}
      onClose={close}
      title={t('diffStatus.title')}
    >
      <Content />
    </Modal>
  );
};

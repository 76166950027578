import { useTranslation } from 'react-i18next';

import { ApiProjectTask } from '@/api/tasks';
import { useAccess } from '@/hooks/useAccess';
import { Button, Flex, Stack, Tooltip } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { IconLink, IconPaperclip, IconSubtask } from '@tabler/icons-react';

import { useTaskContext } from '../../context/taskContext';
import { openDropFilesModal } from '../Attachments/components/DropFilesModal';
import { TaskTitle } from './components/TaskTitle';

export const TaskAbove = ({ task }: { task: ApiProjectTask.ITask }) => {
  const { t } = useTranslation();
  const access = useAccess();

  const { setShowLinksForm } = useTaskContext();

  const onLinkClick = () => {
    setShowLinksForm(true);
    setTimeout(() => {
      document.querySelector('[data-is-links-form]')?.scrollIntoView();
    }, 100);
  };

  const isMobile = useMediaQuery('(max-width: 992px)');

  return (
    <Stack gap={16}>
      <TaskTitle task={task} />
      {access.tasks.edit && (
        <Flex gap={8} wrap={'wrap'} pl={8}>
          <Button
            leftSection={<IconPaperclip size={16} />}
            size={isMobile ? 'compact-sm' : 'compact-md'}
            color={'dark'}
            variant="light"
            onClick={() => openDropFilesModal(task)}
            fw={'500'}
          >
            {t('createTask.action.attach')}
          </Button>
          <Button
            leftSection={<IconLink size={16} />}
            size={isMobile ? 'compact-sm' : 'compact-md'}
            color={'dark'}
            variant="light"
            fw={'500'}
            onClick={onLinkClick}
          >
            {t('createTask.action.addLink')}
          </Button>
          <Tooltip label={t('common.inDev')}>
            <Button
              leftSection={<IconSubtask size={16} />}
              size={isMobile ? 'compact-sm' : 'compact-md'}
              color={'dark'}
              variant="light"
              fw={'500'}
            >
              {t('createTask.action.addSubtask')}
            </Button>
          </Tooltip>
        </Flex>
      )}
    </Stack>
  );
};

import { useCallback } from 'react';
import { DraggableLocation, OnDragEndResponder } from 'react-beautiful-dnd';

import { ApiCommon } from '@/api/common';
import { ApiProjectTask, projectTasksApi } from '@/api/tasks';
import { useGroupTasks } from '@/hooks/useGroupTasks';
import { find, isEqual, isNumber } from 'lodash-es';

interface IProps {
  tasks: ApiProjectTask.ITask[];
  statuses: ApiCommon.ITaskStatus[];
  includeAllStatuses?: boolean;
  revertStatusesOrder?: boolean;
}

export const useDnd = ({
  tasks,
  statuses,
  includeAllStatuses,
  revertStatusesOrder,
}: IProps) => {
  const [updateTask] = projectTasksApi.useUpdateTaskMutation();
  const [updateOrder] = projectTasksApi.useUpdateOrderMutation();

  const { groupedData } = useGroupTasks({
    statuses,
    tasks,
    includeAllStatuses,
    revertStatusesOrder,
  });

  const onDragEnd = useCallback<OnDragEndResponder>(
    ({ draggableId, source, destination }) => {
      if (!destination) return;
      const currentTask = find(tasks, ['Id', Number(draggableId)]);
      if (!currentTask) return;

      const newOrder = (function () {
        if (isEqual(source, destination)) return undefined;

        const data =
          groupedData.find(
            ({ status }) => String(status.Id) === destination.droppableId,
          )?.tasks ?? [];
        const newOrder = getNewOrder({ data, source, destination });

        return newOrder;
      })();

      if (source.droppableId !== destination.droppableId) {
        updateTask({
          ...currentTask,
          Status: Number(destination.droppableId),
          ...(isNumber(newOrder) ? { Order: newOrder } : {}),
        });

        return;
      }

      if (isNumber(newOrder)) {
        updateOrder({ id: currentTask.Id, order: newOrder });
      }
    },
    [tasks, groupedData, updateOrder, updateTask],
  );

  return { groupedData, onDragEnd };
};

const getNewOrder = ({
  data,
  source,
  destination,
}: {
  data: ApiProjectTask.ITask[];
  source: DraggableLocation;
  destination: DraggableLocation;
}) => {
  const firstTask = data.at(0);
  if (
    !firstTask ||
    isEqual(source, {
      ...destination,
      index: Math.min(destination.index, data.length - 1),
    })
  )
    return;

  const delta =
    !isEqual(source.droppableId, destination.droppableId) ||
    source.index > destination.index ||
    destination.index >= data.length
      ? 1
      : 0;
  const prevTask = data[Math.min(destination.index - delta, data.length - 1)];

  return prevTask ? prevTask.Order + 1 : firstTask.Order - 1;
};

import { format as formatFn, isThisYear } from 'date-fns';
import { isNil, isString, merge } from 'lodash-es';

export const formatDate = (
  date: IValidValue | null | undefined,
  options?: IFormat | IOptions,
): string => {
  const _options = mergeOptions(date, options);

  if (!checkDate(date)) return _options.invalidValue;

  return formatFn(date, formats[_options.format]);
};

const checkDate = (date: IValidValue | null | undefined): date is IValidValue =>
  !isNil(date);
const mergeOptions = (
  date: IValidValue | null | undefined,
  options?: IFormat | IOptions,
): Required<IOptions> => {
  const defaultOptions = {
    format: isThisYear(date ?? '') ? 'short' : 'long',
    invalidValue: '',
  };

  return merge(
    defaultOptions,
    isString(options) ? { format: options } : options,
  );
};

const formats: Record<IFormat, string> = {
  short: 'd MMMM', // 4 june
  long: 'dd.MM.y', // 04.06.2024
  longWithTime: 'Pp', // 04.06.2024
};

type IValidValue = string | number | Date;
type IFormat = 'short' | 'long' | 'longWithTime';
interface IOptions {
  format?: IFormat;
  invalidValue?: string;
}

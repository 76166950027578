import { SyntheticEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { attachmentsApi } from '@/api/attachments/slice';
import { ApiAttachments } from '@/api/attachments/types';
import { downloadURL } from '@/helpers/download';
import { showNotification } from '@mantine/notifications';
import { IconCircleXFilled } from '@tabler/icons-react';

export const useAttachActions = (attachment: ApiAttachments.IAttachment) => {
  const { t } = useTranslation();

  const [getFile, { isLoading }] = attachmentsApi.useLazyGetQuery();

  const getFileUrl = useCallback(async () => {
    const res = await getFile({
      id: attachment.Id,
    })
      .unwrap()
      .catch(() => {
        showNotification({
          color: 'red',
          icon: <IconCircleXFilled size={30} />,
          message: t('notification.error.getFile'),
        });

        return null;
      });

    return res;
  }, [attachment.Id, getFile, t]);

  const handleDownload = useCallback(
    async (e: SyntheticEvent) => {
      e.stopPropagation();
      const fileUrl = await getFileUrl();

      if (fileUrl) {
        downloadURL(fileUrl, attachment.Name || 'file');
      }
    },
    [attachment.Name, getFileUrl],
  );

  return { handleDownload, isLoading, getFileUrl };
};

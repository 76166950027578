import { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ApiProjectTask } from '@/api/tasks';
import { useAccess } from '@/hooks/useAccess';
import { useEditTextValue } from '@/hooks/useEditTextValue';
import { useUpdateTask } from '@/hooks/useUpdateTask';
import { Button, Flex, Stack, Textarea, Title } from '@mantine/core';
import { useFocusTrap, useMergedRef } from '@mantine/hooks';
import { IconCornerDownLeft } from '@tabler/icons-react';

import styles from '../../../TaskPanel.module.css';

export const TaskTitle = memo(({ task }: { task: ApiProjectTask.ITask }) => {
  const { t } = useTranslation();
  const access = useAccess();

  const updateTask = useUpdateTask(task);

  const onUpdate = useCallback(
    (Title: string) => updateTask({ ...task, Title }),
    [task, updateTask],
  );

  const {
    isEdit,
    setIsEdit,
    value,
    setValue,
    valueEmpty,
    handleEdit,
    handleUpdate,
    ref: outsideRef,
  } = useEditTextValue({
    updateOnBlur: true,
    originalValue: task.Title,
    onUpdate,
  });

  const focusTrapRef = useFocusTrap();
  const inputRef = useMergedRef(focusTrapRef, outsideRef);

  const controls = useMemo(
    () => (
      <Flex gap={8}>
        <Button
          variant="subtle"
          color="dark"
          size="compact-sm"
          onClick={() => setIsEdit(false)}
        >
          {t('common.action.cancel')}
        </Button>
        <Button
          variant="filled"
          color="blue"
          size="compact-sm"
          rightSection={<IconCornerDownLeft size={16} />}
          type="submit"
        >
          {t('common.action.save')}
        </Button>
      </Flex>
    ),
    [setIsEdit, t],
  );

  return (
    <>
      {isEdit ? (
        <form onSubmit={handleUpdate} ref={inputRef}>
          <Stack gap={8} align="flex-end">
            <Textarea
              w={'100%'}
              maxLength={500}
              styles={{
                input: {
                  paddingLeft: '8px',
                  fontSize: '22px',
                  fontWeight: 500,
                  height: '40px',
                  borderColor: valueEmpty
                    ? 'var(--mantine-color-red-6)'
                    : undefined,
                },
              }}
              value={value}
              autosize
              minRows={1}
              onChange={(e) =>
                setValue(e.currentTarget.value.replaceAll('\n', ' '))
              }
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleUpdate();
                }
              }}
              rightSectionWidth={200}
            />
            {controls}
          </Stack>
        </form>
      ) : (
        <Title
          size={'h3'}
          order={1}
          data-editable={access.tasks.edit}
          className={styles.editableSection}
          onClick={access.tasks.edit ? handleEdit : undefined}
        >
          {task.Title}
        </Title>
      )}
    </>
  );
});

import { createSlice } from '@reduxjs/toolkit';

interface IState {
  opened: boolean;
}

const initialState: IState = {
  opened: false,
};

export const manageUsersModalSlice = createSlice({
  name: 'manageUsersModal',
  initialState,
  reducers: {
    close(state) {
      state.opened = false;
    },
    open(state) {
      state.opened = true;
    },
  },
});

import { PropsWithChildren, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Flex, Modal, Stack } from '@mantine/core';

import { useEntityFormContext } from '../hooks/useForm';
import { IEntityFormValues } from '../types';

interface IProps {
  confirmLabel: string;
  isLoading?: boolean;
  onClose: VoidFunction;
  onConfirm: (v: IEntityFormValues) => void;
}

let saved: IEntityFormValues | null = null;

export const EntityFormBody = ({
  confirmLabel,
  isLoading,
  onClose,
  onConfirm,
  children,
}: PropsWithChildren<IProps>) => {
  const { t } = useTranslation();

  const form = useEntityFormContext();

  const formType = form.getValues().formType;

  const handleCancel = useCallback(() => {
    formType ? form.setFieldValue('formType', null) : onClose();
  }, [form, formType, onClose]);
  const handleSubmit = useCallback(
    (values: IEntityFormValues) => {
      if (values.formType) {
        saved = null;
        form.setFieldValue('formType', null);
      } else {
        onConfirm(values);
      }
    },
    [form, onConfirm],
  );

  form.watch('formType', ({ previousValue, value }) => {
    if (previousValue === null) {
      saved = form.getValues();
    } else if (saved) {
      form.setValues({ ...saved, formType: value });
    }
  });

  return (
    <Modal.Body px={24} pb={32}>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Stack gap={24}>
          {children}
          <Flex gap={16}>
            {formType && (
              <Button
                fullWidth
                size="md"
                variant="subtle"
                color="light-dark(var(--mantine-color-gray-8), var(--mantine-color-dark-1))"
                onClick={handleCancel}
              >
                {t('common.action.cancel')}
              </Button>
            )}
            <Button fullWidth size="md" type="submit" loading={isLoading}>
              {formType ? t('common.action.save') : confirmLabel}
            </Button>
          </Flex>
        </Stack>
      </form>
    </Modal.Body>
  );
};

import { Stack } from '@mantine/core';

import { TaskGroupLoader } from './TaskGroupLoader';

interface IProps {
  /**
   * taskCount = 4;
   *
   * groupCount = [taskCount, taskCount, taskCount];
   *
   * builder={groupCount}
   */
  builder?: number[];
}

export const SprintLoader = ({ builder = [4, 4, 4, 4, 4, 4] }: IProps) => {
  return (
    <Stack>
      {builder.map((el, i) => (
        <TaskGroupLoader key={i} builder={el} />
      ))}
    </Stack>
  );
};

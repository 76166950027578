import { memo } from 'react';

import { ApiCommon } from '@/api/common';
import { ApiTaskType } from '@/api/taskTypes';
import { ApiProjectTask } from '@/api/tasks';
import { TaskPrioritySelect } from '@/components/ui/selects/TaskPrioritySelect';
import { TaskStatusSelect } from '@/components/ui/selects/TaskStatusSelect';
import { TaskTypeSelect } from '@/components/ui/selects/TaskTypeSelect';
import { useAccess } from '@/hooks/useAccess';
import { Box, BoxProps, Flex, Text } from '@mantine/core';

import { usePerformerData } from '../../hooks/usePerformerData';
import { useUpdateTask } from '../../hooks/useUpdateTask';
import { PerformerSelect } from '../PerformerSelect';
import { openTaskModal } from '../TaskModal/store';
import { TaskTitle } from './components/TaskTitle';

import styles from './TaskCard.module.css';

type EditableTaskCardProps = {
  task: ApiProjectTask.ITask;
  statuses: ApiCommon.ITaskStatus[];
  types: ApiTaskType.IType[];
  border?: boolean;
} & BoxProps;

export const EditableTaskCard = memo(
  ({ task, statuses, types, border, ...props }: EditableTaskCardProps) => {
    const access = useAccess();
    const { Key, Status, TypeId, ProjectId } = task;

    const performerData = usePerformerData(task);
    const updateTask = useUpdateTask(task);

    const handleTaskClick = () => {
      openTaskModal(task.Id);
    };

    return (
      <Box
        className={styles.root}
        onClick={handleTaskClick}
        tabIndex={0}
        data-border={border}
        {...props}
      >
        <TaskTitle task={task} onUpdate={(Title) => updateTask({ Title })} />
        <Flex gap={8} className={styles.type}>
          <TaskTypeSelect
            className={styles.type}
            value={TypeId}
            onChange={(TypeId) => updateTask({ TypeId })}
            types={types}
            withLabel={false}
            readonly={!access.tasks.edit}
          />
          <Text size={'sm'} className={styles.cardProjectId + ' ' + styles.key}>
            {Key}
          </Text>
        </Flex>
        <Flex
          className={styles.controls}
          gap="sm"
          align="center"
          onClick={(e) => e.stopPropagation()}
        >
          <TaskStatusSelect
            value={Status}
            onChange={(s) => updateTask({ Status: s.Id })}
            statuses={statuses}
            readonly={!access.tasks.edit}
          />
          <TaskPrioritySelect
            priority={task.Priority}
            onChange={(Priority) => updateTask({ Priority })}
            withLabel={false}
            readonly={!access.tasks.edit}
          />
          <PerformerSelect
            projectId={ProjectId}
            value={performerData}
            onChange={(opt) => updateTask({ PerformerId: opt?.Id || null })}
            withLabel={false}
            readonly={!access.tasks.edit}
          />
        </Flex>
      </Box>
    );
  },
);

import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ComplexSprintData } from '@/api/projects';
import { ApiTaskType } from '@/api/taskTypes';
import { openCreateTaskModal } from '@/features/CreateTaskModal';
import { Button } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { IconPlus } from '@tabler/icons-react';

import { CreateForm } from './components/CreateForm/CreateForm';

import styles from './CreateTask.module.css';

type CreateTaskProps = {
  /** Спринт в котором создаём задачу */
  sprint: ComplexSprintData;
  /** Список доступных типов для задачи */
  types: ApiTaskType.IType[];
  /** Статус с которым будет создана задача */
  status?: number;
  /** Номер предыдущей задачи, новая будет создана с добавлением +1 */
  prevOrder?: number;
  disabled?: boolean;
};

export const CreateTask = ({
  sprint,
  types,
  status,
  prevOrder,
  disabled,
}: CreateTaskProps) => {
  const { t } = useTranslation();

  const [creation, setCreation] = useState<boolean>(false);
  const isMobile = useMediaQuery('(max-width: 992px)');

  if (creation) {
    return (
      <CreateForm
        sprint={sprint}
        types={types}
        status={status}
        prevOrder={prevOrder}
        onCancel={() => setCreation(false)}
        afterCreate={() => setCreation(false)}
      />
    );
  }

  return (
    <Button
      className={styles.button}
      leftSection={<IconPlus size={12} />}
      variant="subtle"
      size="xs"
      color="gray"
      onClick={() => {
        if (disabled) return;

        if (isMobile) {
          openCreateTaskModal({
            defaultValues: {
              sprint,
              Status: status,
            },
          });
        } else {
          setCreation(true);
        }
      }}
      fullWidth
      justify="left"
    >
      {t('sidebar.addTask')}
    </Button>
  );
};
